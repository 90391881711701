import React, { useEffect, useState } from 'react';
import * as SimpleWebAuthnBrowser from '@simplewebauthn/browser';
import { browserSupportsWebAuthn } from '@simplewebauthn/browser';
import styles from './Auth.module.css';
import TitleHeader from '../TitleHeader';
import {
    UserCircle2,
    KeyRound,
    Fingerprint,
    Sparkles,
    ChevronRight,
    Edit2,
    Check,
    X,
    LogOut,
    RefreshCw,
    Plus
} from 'lucide-react';
import { motion } from 'framer-motion';

import { useUserContext } from '../hooks/useUserContext';
export const authApi = 'https://guava.pack.wtf';
export const sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

const Auth: React.FC = () => {
    // const [username, setUsername] = useState('');
    // const [profileImage, setProfileImage] = useState<string | null>(null);

    const {
        username,
        avatar,
        isAuthenticating,
        setUsername,
        setAvatar,
        setIsAuthenticating
    } = useUserContext();

    // const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [logs, setLogs] = useState<string[]>([]);
    const [view, setView] = useState('auth');
    const [accessKeys, setAccessKeys] = useState([]);
    const [editingKeyId, setEditingKeyId] = useState<string | null>(null);
    const [newKeyName, setNewKeyName] = useState('');
    const [isLogLoading, setIsLogLoading] = useState(false);
    const [newLogId, setNewLogId] = useState<number | null>(null);
    const [existingUser, setExistingUser] = useState(false);

    const addLog = (message: string) => {
        setIsLogLoading(true);
        const logId = Date.now();
        setNewLogId(logId);

        setLogs((prevLogs) => [...prevLogs, message]);

        // Keep loading state visible for a moment to show animation
        setTimeout(() => {
            setIsLogLoading(false);
            // Clear new log highlight after animation
            setTimeout(() => {
                setNewLogId(null);
            }, 1000);
        }, 1500);
    };

    const handleAuthenticate = async (username?: string) => {
        setIsAuthenticating(true);
        try {
            addLog('Starting authentication process');
            try {
                const profileResponse = await fetch(
                    `${authApi}/api/auth/user/${username}`,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );
                if (profileResponse.ok) {
                    setExistingUser(true);
                    const { profileUrl, username } =
                        await profileResponse.json();
                    setAvatar(profileUrl);
                    setUsername(username);
                    localStorage.setItem('profileImage', profileUrl);
                    localStorage.setItem('username', username);
                }
            } catch (error) {
                console.log('Error fetching profile');
                setExistingUser(false);
            }

            if (!browserSupportsWebAuthn()) {
                throw new Error('Browser does not support WebAuthn');
            }

            // Try login first
            try {
                const loginResponse = await fetch(`${authApi}/api/auth/login`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ username })
                });

                if (!loginResponse.ok) {
                    console.log(loginResponse);
                    throw new Error('Login failed, attempting registration');
                }

                const options = await loginResponse.json();
                console.log(options);
                // delete options.rpId;
                const assertionResp =
                    await SimpleWebAuthnBrowser.startAuthentication({
                        optionsJSON: options
                    });

                const loginVerifyResp = await fetch(
                    `${authApi}/api/auth/login/verify`,
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            username,
                            response: assertionResp
                        })
                    }
                );

                if (!loginVerifyResp.ok) {
                    throw new Error('Login verification failed');
                }

                const loginVerifyJson = await loginVerifyResp.json();
                addLog('Login successful');
                localStorage.setItem('token', loginVerifyJson.token);
                getMeInfo();
            } catch (error) {
                console.log(error);
                // If login fails, try registration
                addLog('Attempting registration');
                sleep(6000);
                const registerResponse = await fetch(
                    `${authApi}/api/auth/register`,
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ username })
                    }
                );

                if (!registerResponse.ok) {
                    throw new Error('Failed to start registration');
                }

                const registerResponseJson = await registerResponse.json();
                // delete registerResponseJson.rp.id;

                const attResp = await SimpleWebAuthnBrowser.startRegistration({
                    optionsJSON: registerResponseJson
                });

                const registerVerifyReq = await fetch(
                    `${authApi}/api/auth/register/verify`,
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ username, response: attResp })
                    }
                );

                if (!registerVerifyReq.ok) {
                    throw new Error('Registration failed');
                }

                const registerVerifyResp = await registerVerifyReq.json();
                addLog('Registration successful');
                localStorage.setItem('token', registerVerifyResp.token);
                getMeInfo();
            }
        } catch (error) {
            if (error instanceof Error) {
                await sleep(10000);
                addLog(`Authentication failed: ${error.message}`);
            } else {
                addLog('Authentication failed: An unknown error occurred');
            }
        } finally {
            setIsAuthenticating(false);
        }
    };

    const getMeInfo = async () => {
        const token = localStorage.getItem('token');
        if (!token) return;

        const response = await fetch(`${authApi}/api/auth/me`, {
            headers: {
                Authorization: `${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            const me = await response.json();
            setUsername(me.username);
            setAvatar(me.profileUrl ?? '/default-avatar.png');
            setIsAuthenticating(false);
            addLog('Fetched user info');
            setView('me');
            getAccessKeys();
        } else {
            addLog('Failed to get user info');
        }
    };

    const getAccessKeys = async () => {
        const token = localStorage.getItem('token');
        if (!token) return;

        const response = await fetch(`${authApi}/api/auth/passkeys`, {
            headers: {
                Authorization: `${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            const keys = await response.json();
            setAccessKeys(keys);
            addLog('Fetched access keys');
        } else {
            addLog('Failed to get access keys');
        }
    };

    const addPasskey = async () => {
        const token = localStorage.getItem('token');
        if (!token) return;

        try {
            const response = await fetch(`${authApi}/api/auth/passkey/new`, {
                method: 'POST',
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) throw new Error('Failed to start registration');

            const addPasskeyResponseJSON = await response.json();
            // delete addPasskeyResponseJSON.rp.id;

            const attResp = await SimpleWebAuthnBrowser.startRegistration({
                optionsJSON: addPasskeyResponseJSON
            });

            const verifyReq = await fetch(
                `${authApi}/api/auth/passkey/new/verify`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `${token}`
                    },
                    body: JSON.stringify({ response: attResp })
                }
            );

            if (!verifyReq.ok) throw new Error('Registration failed');

            addLog('Access key registration successful');
            getAccessKeys();
        } catch (error) {
            addLog('Failed to add access key');
        }
    };

    const handleRenamePasskey = async (passkeyId: string) => {
        if (!newKeyName.trim()) {
            setEditingKeyId(null);
            return;
        }

        const token = localStorage.getItem('token');
        if (!token) return;

        try {
            const response = await fetch(
                `${authApi}/api/auth/passkey/${passkeyId}`,
                {
                    method: 'PUT',
                    headers: {
                        Authorization: `${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        passkeyId,
                        name: newKeyName.trim()
                    })
                }
            );

            if (response.ok) {
                addLog('Renamed access key');
                getAccessKeys();
            } else {
                addLog('Failed to rename access key');
            }
        } catch (error) {
            addLog('Error renaming access key');
        }

        setEditingKeyId(null);
        setNewKeyName('');
    };

    const logout = () => {
        localStorage.removeItem('token');
        setView('auth');
        setLogs([]);
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            addLog('Token found');
            getMeInfo();
        }
    }, []);

    useEffect(() => {
        const logContainer = document.querySelector(
            `.${styles.logContainer} ul`
        );
        if (logContainer) {
            logContainer.scrollTop = logContainer.scrollHeight;
        }
    }, [logs]);

    // listen for enter key to submit form
    useEffect(() => {
        const handleEnter = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                if (view === 'auth' && !isAuthenticating) {
                    handleAuthenticate(username || undefined);
                }
            }
        };

        document.addEventListener('keydown', handleEnter);

        return () => {
            document.removeEventListener('keydown', handleEnter);
        };
    }, [view, isAuthenticating, username]);

    return (
        <div className={styles.container}>
            <TitleHeader />
            {view === 'auth' && (
                <motion.div
                    className={`${styles.authContainer} ${styles.isAuth}`}
                    animate={isAuthenticating ? 'authenticating' : 'idle'}>
                    {isAuthenticating && existingUser && (
                        <>
                            <motion.div
                                className={styles.profileImageWrapper}
                                initial={{ scale: 0, rotate: -180 }}
                                animate={{ scale: 1, rotate: 0 }}
                                transition={{
                                    type: 'spring',
                                    duration: 0.8
                                }}>
                                <img
                                    src={avatar || '/default-avatar.png'}
                                    alt="Profile"
                                    className={styles.profileImage}
                                />
                                <div className={styles.profileSparkles}>
                                    <div className={styles.sparkle}></div>
                                    <div className={styles.sparkle}></div>
                                    <div className={styles.sparkle}></div>
                                    <div className={styles.sparkle}></div>
                                </div>
                            </motion.div>
                            <motion.div
                                className={styles.welcomeText}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.3 }}>
                                Welcome back, {username}!
                            </motion.div>
                        </>
                    )}

                    {isAuthenticating && !existingUser && (
                        <>
                            <motion.div
                                className={styles.profileImageWrapper}
                                initial={{ scale: 0, rotate: -180 }}
                                animate={{ scale: 1, rotate: 0 }}
                                transition={{
                                    type: 'spring',
                                    duration: 0.8
                                }}>
                                <img
                                    src={avatar || '/default-avatar-2.png'}
                                    alt="Profile"
                                    className={styles.profileImage}
                                />
                                <div className={styles.profileSparkles}>
                                    <div className={styles.sparkle}></div>
                                    <div className={styles.sparkle}></div>
                                    <div className={styles.sparkle}></div>
                                    <div className={styles.sparkle}></div>
                                </div>
                            </motion.div>
                            <motion.div
                                className={styles.welcomeText}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.3 }}>
                                Hi there, {username}!
                            </motion.div>
                            <motion.div
                                className={styles.welcomeText2}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 1 }}>
                                Looks like you're new here 😏
                            </motion.div>
                        </>
                    )}

                    {!isAuthenticating && (
                        <motion.div
                            className={styles.authHeader}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.3 }}>
                            <div className={styles.headerIcon}>
                                <Fingerprint size={48} />
                            </div>
                            <h1>Welcome!</h1>
                        </motion.div>
                    )}

                    <div className={styles.inputWrapper}>
                        <div className={styles.inputGroup}>
                            <UserCircle2 className={styles.inputIcon} />
                            <input
                                type="text"
                                disabled={isAuthenticating}
                                placeholder="Enter your username"
                                name="username"
                                autoComplete="webauthn"
                                value={username || ''}
                                onChange={(e) => setUsername(e.target.value)}
                                className={styles.input}
                            />
                        </div>
                    </div>
                    <button
                        onClick={() => handleAuthenticate(username || '')}
                        disabled={isAuthenticating || !username?.trim()}
                        className={`${styles.button} ${isAuthenticating ? styles.isLoading : ''}`}>
                        <div className={styles.sparkleContainer}>
                            <div className={styles.sparkle}></div>
                            <div className={styles.sparkle}></div>
                            <div className={styles.sparkle}></div>
                            <div className={styles.sparkle}></div>
                        </div>
                        <span>
                            {isAuthenticating ? 'Please wait...' : 'Continue'}
                        </span>
                        <ChevronRight className={styles.buttonIcon} />
                    </button>
                </motion.div>
            )}
            {view === 'me' && (
                <div className={styles.authContainer}>
                    <div className={styles.meHeader}>
                        <KeyRound size={32} />
                        <h1>Your Passkeys</h1>
                    </div>

                    <div className={styles.keysList}>
                        {accessKeys.map((key: any) => (
                            <div key={key.passkeyId} className={styles.keyCard}>
                                {editingKeyId === key.passkeyId ? (
                                    <div className={styles.keyEditForm}>
                                        <input
                                            type="text"
                                            value={newKeyName}
                                            onChange={(e) =>
                                                setNewKeyName(e.target.value)
                                            }
                                            placeholder="Enter new name"
                                            className={styles.keyEditInput}
                                            autoFocus
                                        />
                                        <button
                                            onClick={() =>
                                                handleRenamePasskey(
                                                    key.passkeyId
                                                )
                                            }
                                            className={styles.keyEditButton}>
                                            <Check size={16} />
                                        </button>
                                        <button
                                            onClick={() =>
                                                setEditingKeyId(null)
                                            }
                                            className={`${styles.keyEditButton} ${styles.cancelButton}`}>
                                            <X size={16} />
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <div className={styles.keyInfo}>
                                            <span className={styles.keyName}>
                                                {key.name || 'Unnamed Key'}
                                            </span>
                                            <span className={styles.keyId}>
                                                {key.passkeyId}
                                            </span>
                                        </div>
                                        <button
                                            onClick={() => {
                                                setEditingKeyId(key.passkeyId);
                                                setNewKeyName(key.name || '');
                                            }}
                                            className={styles.keyEditTrigger}>
                                            <Edit2 size={16} />
                                        </button>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>

                    <div className={styles.buttonGroup}>
                        <button
                            onClick={addPasskey}
                            className={`${styles.button} ${styles.addButton}`}>
                            <Plus size={20} />
                            <span>Add New Passkey</span>
                        </button>
                        <button
                            onClick={getMeInfo}
                            className={`${styles.button} ${styles.refreshButton}`}>
                            <RefreshCw size={20} />
                            <span>Refresh</span>
                        </button>
                        <button
                            onClick={logout}
                            className={`${styles.button} ${styles.logoutButton}`}>
                            <LogOut size={20} />
                            <span>Logout</span>
                        </button>
                    </div>
                </div>
            )}
            <div
                className={`${styles.logContainer} ${isLogLoading ? styles.isLoading : ''}`}>
                <h2>
                    <Sparkles size={2} className={styles.logIcon} />
                    Activity Log
                </h2>
                {isLogLoading && (
                    <div className={styles.loadingSparkles}>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                    </div>
                )}
                <ul>
                    {logs.map((log, index) => (
                        <li
                            key={index}
                            className={`${styles.logEntry} ${
                                index === logs.length - 1 && newLogId
                                    ? styles.isNew
                                    : ''
                            }`}>
                            {log}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Auth;
