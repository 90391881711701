export const groupImages = [
    {
        name: 'aespa',
        imageUrl:
            'https://image-cdn-ak.spotifycdn.com/image/ab67706c0000da841bc23aabdce2e2f3b51e805a'
    },
    {
        name: 'BLACKPINK',
        imageUrl:
            'https://i.scdn.co/image/ab67616100005174c9690bc711d04b3d4fd4b87c'
    },
    {
        name: 'IVE',
        imageUrl:
            'https://i.scdn.co/image/ab6772690000dd225dd6b2cee7b6f3f786b7fa08'
    },
    {
        name: 'TWICE',
        imageUrl: '/kpop/twice/pp.webp'
    },
    {
        name: 'G-IDLE',
        imageUrl:
            'https://i.scdn.co/image/ab676161000051747fd16327c86d500f83be1d6a'
    },
    {
        name: 'ITZY',
        imageUrl:
            'https://i.scdn.co/image/ab676161000051743448062884d4ad30473e964b'
    },
    {
        name: 'NewJeans',
        imageUrl:
            'https://i.scdn.co/image/ab6761610000e5eb80668ba2b15094d083780ea9'
    },
    {
        name: 'JENNIE',
        imageUrl:
            'https://i.scdn.co/image/ab67616100005174cab652097be01bf194cdf11d'
    },
    {
        name: 'KATSEYE',
        imageUrl:
            'https://i.scdn.co/image/ab67616100005174031e8e2a9c4893810a02f863'
    },
    {
        name: 'BABYMONSTER',
        imageUrl:
            'https://i.scdn.co/image/ab67616100005174b419d66ef21661311c806bfe'
    },
    {
        name: 'Red Velvet',
        imageUrl:
            'https://i.scdn.co/image/ab6761610000517402a562ea6b1dc718394010ac'
    },
    {
        name: 'ILLIT',
        imageUrl:
            'https://i.scdn.co/image/ab6761610000517433a8ccf7ddd38cea1a0eac0d'
    },
    {
        name: 'FIFTY FIFTY',
        imageUrl:
            'https://i.scdn.co/image/ab67616100005174f5b576e445d9bb5862084db2'
    },
    {
        name: 'LE SSERAFIM',
        imageUrl:
            'https://i.scdn.co/image/ab6761610000517432863b8dca4574c09e4b9295'
    },
    {
        name: 'NMIXX',
        imageUrl:
            'https://i.scdn.co/image/ab6772690000dd2203bd0c2e93b22b8934918900'
    }
];

// 'itzy',
//     'loona',
//     'newjeans',
//     'twice',
//     'red velvet',
//     'illit',
//     'ive',
//     'gidle',
//     'momoland',
//     'afterschool',
//     'fiftyfifty',
//     'lesserafim',
//     'nmixx'

export const allKPopArtists = [
    {
        'Stage Name': '2Soul',
        'Full Name': 'Kim Younghoon',
        'Korean Name': '김영훈',
        'K Stage Name': '이솔',
        'Date of Birth': '10/09/1997',
        Group: "7 O'clock",
        Debut: '26/08/2014',
        Company: 'Jungle',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: 55,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'A.M',
        'Full Name': 'Seong Hyunwoo',
        'Korean Name': '성현우',
        'K Stage Name': '에이엠',
        'Date of Birth': '31/12/1996',
        Group: 'Limitless',
        Debut: '9/07/2019',
        Company: 'ONO',
        Country: 'South Korea',
        'Second Country': '',
        Height: 181,
        Weight: 62,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ace',
        'Full Name': 'Jang Wooyoung',
        'Korean Name': '장우영',
        'K Stage Name': '에이스',
        'Date of Birth': '28/08/1992',
        Group: 'VAV',
        Debut: '31/10/2015',
        Company: 'A team',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 63,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Aeji',
        'Full Name': 'Kwon Aeji',
        'Korean Name': '권애지',
        'K Stage Name': '애지',
        'Date of Birth': '25/10/1999',
        Group: 'Hash Tag',
        Debut: '11/10/2017',
        Company: 'LUK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'AhIn',
        'Full Name': 'Lee Ahin',
        'Korean Name': '이아인',
        'K Stage Name': '아인',
        'Date of Birth': '27/09/1999',
        Group: 'MOMOLAND',
        Debut: '9/11/2016',
        Company: 'Double Kick',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 44,
        Birthplace: 'Wonju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ahra',
        'Full Name': 'Go Ahra',
        'Korean Name': '고아라',
        'K Stage Name': '아라',
        'Date of Birth': '21/02/2001',
        Group: 'Favorite',
        Debut: '5/07/2017',
        Company: 'Astory',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yeosu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ahyeon',
        'Full Name': 'Jung Ahyeon',
        'Korean Name': '정아현',
        'K Stage Name': '아현',
        'Date of Birth': '11/04/2007',
        Group: 'BABYMONSTER',
        Debut: '0/01/1900',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ahyoon',
        'Full Name': 'Choi Subin',
        'Korean Name': '최수빈',
        'K Stage Name': '아윤',
        'Date of Birth': '23/10/2004',
        Group: 'BOTOPASS',
        Debut: '26/08/2020',
        Company: 'WKS ENE',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ahyoon',
        'Full Name': 'Shin Ahyoon',
        'Korean Name': '신아윤',
        'K Stage Name': '아윤',
        'Date of Birth': '24/09/2003',
        Group: 'Queenz Eye',
        Debut: '24/10/2022',
        Company: 'Big Mountain',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ahyoung',
        'Full Name': 'Cho Jayoung',
        'Korean Name': '조자영',
        'K Stage Name': '아영',
        'Date of Birth': '26/05/1991',
        Group: 'Dal Shabet',
        Debut: '3/01/2011',
        Company: 'Happy Face',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ahyung',
        'Full Name': 'Kang Ahyoung',
        'Korean Name': '강아형',
        'K Stage Name': '아형',
        'Date of Birth': '27/08/1996',
        Group: 'P.O.P',
        Debut: '26/07/2017',
        Company: 'DWM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Pohang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ailee',
        'Full Name': 'Lee Yejin',
        'Korean Name': '이예진',
        'K Stage Name': '에일리',
        'Date of Birth': '30/05/1989',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': 'USA',
        Height: 165,
        Weight: '',
        Birthplace: 'Denver',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Aini',
        'Full Name': 'Kim Heejung',
        'Korean Name': '김희정',
        'K Stage Name': '아이니',
        'Date of Birth': '13/07/1991',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: 44,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Pink Fantasy',
        Gender: 'F'
    },
    {
        'Stage Name': 'Aisha',
        'Full Name': 'Heo Yoorim',
        'Korean Name': '허유림',
        'K Stage Name': '아샤',
        'Date of Birth': '21/07/2000',
        Group: 'Everglow',
        Debut: '18/03/2019',
        Company: 'Yuehua',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Aki',
        'Full Name': 'Jang Minju',
        'Korean Name': '장민주',
        'K Stage Name': '아키',
        'Date of Birth': '12/02/2001',
        Group: 'MAJORS',
        Debut: '9/03/2021',
        Company: 'ANS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: 48,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Alex',
        'Full Name': 'Alexander Schmidt Lee',
        'Korean Name': '알렉산더 슈미트 리',
        'K Stage Name': '알렉스',
        'Date of Birth': '6/10/2004',
        Group: 'DRIPPIN',
        Debut: '26/10/2020',
        Company: 'Woollim',
        Country: 'Germany',
        'Second Country': 'South Korea',
        Height: 180,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'AleXa',
        'Full Name': 'Kim Seri',
        'Korean Name': '김세리',
        'K Stage Name': '알렉사',
        'Date of Birth': '6/12/1996',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'USA',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Alice',
        'Full Name': 'Song Joohee',
        'Korean Name': '송주희',
        'K Stage Name': '앨리스',
        'Date of Birth': '21/03/1990',
        Group: 'Hello Venus',
        Debut: '9/05/2012',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 47,
        Birthplace: 'Wonju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Alice',
        'Full Name': 'Cheon Jaeyoung',
        'Korean Name': '천재영',
        'K Stage Name': '앨리스',
        'Date of Birth': '20/02/2002',
        Group: 'TRACER',
        Debut: '3/04/2022',
        Company: 'Gleamedia',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Allen',
        'Full Name': 'Allen Ma',
        'Korean Name': '앨런 마',
        'K Stage Name': '앨런',
        'Date of Birth': '26/04/1999',
        Group: 'CRAVITY',
        Debut: '14/04/2020',
        Company: 'Starship',
        Country: 'USA',
        'Second Country': 'South Korea',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Amber',
        'Full Name': 'Amber Josephine Liu',
        'Korean Name': '엠버 조세핀 리우',
        'K Stage Name': '엠버',
        'Date of Birth': '18/09/1992',
        Group: 'f(x)',
        Debut: '5/09/2009',
        Company: 'SM',
        Country: 'USA',
        'Second Country': 'Taiwan',
        Height: 167,
        Weight: '',
        Birthplace: 'Los Angeles',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'A-min',
        'Full Name': 'Cho Minwoo',
        'Korean Name': '조민우',
        'K Stage Name': '아민',
        'Date of Birth': '22/05/2004',
        Group: 'EPEX',
        Debut: '8/06/2021',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 54,
        Birthplace: 'Namyangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Andy',
        'Full Name': 'Lui Chunyeung',
        'Korean Name': '루이쯔양',
        'K Stage Name': '앤디',
        'Date of Birth': '6/10/1994',
        Group: "7 O'clock",
        Debut: '26/08/2014',
        Company: 'Jungle',
        Country: 'Hong Kong',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Andy',
        'Full Name': 'Lee Sunho',
        'Korean Name': '이선호',
        'K Stage Name': '앤디',
        'Date of Birth': '21/01/1981',
        Group: 'Shinhwa',
        Debut: '24/03/1998',
        Company: 'SM| Good| Shinhwa',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 62,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Anne',
        'Full Name': 'Lee Seoyoung',
        'Korean Name': '이서영',
        'K Stage Name': '앤',
        'Date of Birth': '17/10/2000',
        Group: 'GWSN',
        Debut: '5/09/2018',
        Company: 'Kiwi Pop',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Anthony',
        'Full Name': 'Anthony Lo',
        'Korean Name': '엔써니 루오',
        'K Stage Name': '엔써니',
        'Date of Birth': '12/03/1998',
        Group: 'VARSITY',
        Debut: '5/01/2017',
        Company: 'GKC',
        Country: 'China',
        'Second Country': 'USA',
        Height: 179,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ara',
        'Full Name': 'Lee Yunji',
        'Korean Name': '이윤지',
        'K Stage Name': '아라',
        'Date of Birth': '23/01/2002',
        Group: 'ILY:1',
        Debut: '4/04/2022',
        Company: 'FC ENM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 158,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'A-ra',
        'Full Name': 'Choi Eunji',
        'Korean Name': '최은지',
        'K Stage Name': '아라',
        'Date of Birth': '25/07/2003',
        Group: "Girls' World",
        Debut: '20/06/2002',
        Company: 'Liz',
        Country: 'South Korea',
        'Second Country': '',
        Height: 158,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Aran',
        'Full Name': 'Jeong Eunah',
        'Korean Name': '정은아',
        'K Stage Name': '아란',
        'Date of Birth': '11/10/2004',
        Group: 'FIFTY FIFTY',
        Debut: '18/11/2022',
        Company: 'ATTRAKT',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: 44,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Arang',
        'Full Name': 'Son Mnjung',
        'Korean Name': '손민정',
        'K Stage Name': '아랑',
        'Date of Birth': '8/03/2000',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'NeonPunch',
        Gender: 'F'
    },
    {
        'Stage Name': 'Arang',
        'Full Name': 'Park Yerim',
        'Korean Name': '박예림',
        'K Stage Name': '아랑',
        'Date of Birth': '22/01/2001',
        Group: 'Pink Fantasy',
        Debut: '28/10/2018',
        Company: 'My Doll',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 49,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Areum',
        'Full Name': 'Kim Daun',
        'Korean Name': '김다운',
        'K Stage Name': '아름',
        'Date of Birth': '31/01/1998',
        Group: 'AREAL',
        Debut: '21/08/2020',
        Company: 'JZ Factory',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Rose Finger',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ari',
        'Full Name': 'Kim Sunyoung',
        'Korean Name': '김선영',
        'K Stage Name': '아리',
        'Date of Birth': '23/10/1994',
        Group: 'Tahiti',
        Debut: '23/06/2012',
        Company: 'J-line',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Aria',
        'Full Name': 'Baby Gauthami',
        'Korean Name': '베이비 고타미',
        'K Stage Name': '아리아',
        'Date of Birth': '12/03/2003',
        Group: 'X:IN',
        Debut: '11/04/2023',
        Company: 'Escrow',
        Country: 'India',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Arie',
        'Full Name': 'Jeon Minjae',
        'Korean Name': '전민재',
        'K Stage Name': '아리',
        'Date of Birth': '7/04/2000',
        Group: 'MIXX',
        Debut: '2/05/2016',
        Company: 'Chiko',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: 45,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Arin',
        'Full Name': 'Choi Yewon',
        'Korean Name': '최예원',
        'K Stage Name': '아린',
        'Date of Birth': '18/06/1999',
        Group: 'Oh My Girl',
        Debut: '21/04/2015',
        Company: 'WM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: 47,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Aron',
        'Full Name': 'Kwak Youngmin',
        'Korean Name': '곽영민',
        'K Stage Name': '아론',
        'Date of Birth': '21/05/1993',
        Group: "NU'EST",
        Debut: '15/03/2012',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': 'USA',
        Height: '',
        Weight: '',
        Birthplace: 'California',
        'Other Group': "NU'EST W",
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Arthur',
        'Full Name': 'Jang Yunho',
        'Korean Name': '장윤호',
        'K Stage Name': '아서',
        'Date of Birth': '15/04/2000',
        Group: 'Kingdom',
        Debut: '18/02/2021',
        Company: 'GF',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 57,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'VARSITY',
        Gender: 'M'
    },
    {
        'Stage Name': 'Asa',
        'Full Name': 'Enami Asa',
        'Korean Name': '에나미 아사',
        'K Stage Name': '아사',
        'Date of Birth': '17/04/2006',
        Group: 'BABYMONSTER',
        Debut: '0/01/1900',
        Company: 'YG',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Asahi',
        'Full Name': 'Hanada Asahi',
        'Korean Name': '하나다 아사히',
        'K Stage Name': '아사히',
        'Date of Birth': '20/08/2001',
        Group: 'Treasure',
        Debut: '7/08/2020',
        Company: 'YG',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ashley',
        'Full Name': 'Ashley Choi',
        'Korean Name': '애슐리 최',
        'K Stage Name': '애슐리',
        'Date of Birth': '9/11/1991',
        Group: "Ladies' Code",
        Debut: '7/03/2013',
        Company: 'Polaris',
        Country: 'South Korea',
        'Second Country': 'USA',
        Height: '',
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Aurora',
        'Full Name': 'Yang Lan',
        'Korean Name': '양안',
        'K Stage Name': '오로라',
        'Date of Birth': '14/01/1997',
        Group: 'NATURE',
        Debut: '3/08/2018',
        Company: 'n.CH',
        Country: 'China',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ayamy',
        'Full Name': 'Suzuki Ayami',
        'Korean Name': '스즈키 아야미',
        'K Stage Name': '아야미',
        'Date of Birth': '15/07/2000',
        Group: 'Bling Bling',
        Debut: '17/11/2020',
        Company: 'Major9',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ayden',
        'Full Name': 'Kwon Yejun',
        'Korean Name': '권예준',
        'K Stage Name': '에이든',
        'Date of Birth': '24/01/2005',
        Group: 'EPEX',
        Debut: '8/06/2021',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: 174,
        Weight: 55,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ayeon',
        'Full Name': 'Son Minji',
        'Korean Name': '손민지',
        'K Stage Name': '아연',
        'Date of Birth': '29/09/2002',
        Group: 'Saturday',
        Debut: '18/07/2018',
        Company: 'SD',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: 47,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ayno',
        'Full Name': 'Noh Yoonho',
        'Korean Name': '노윤호',
        'K Stage Name': '에이노',
        'Date of Birth': '1/05/1996',
        Group: 'VAV',
        Debut: '31/10/2015',
        Company: 'A team',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: 61,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'B.I',
        'Full Name': 'Kim Hanbin',
        'Korean Name': '김한빈',
        'K Stage Name': '비아이',
        'Date of Birth': '22/10/1996',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Goyang',
        'Other Group': '',
        'Former Group': 'iKON',
        Gender: 'M'
    },
    {
        'Stage Name': 'B.S',
        'Full Name': 'Lee Youngsoo',
        'Korean Name': '이영수',
        'K Stage Name': '비에스',
        'Date of Birth': '14/04/1995',
        Group: '14U',
        Debut: '17/04/2017',
        Company: 'BG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Babysoul',
        'Full Name': 'Lee Soojung',
        'Korean Name': '이수정',
        'K Stage Name': '베이비소울',
        'Date of Birth': '6/07/1992',
        Group: 'Lovelyz',
        Debut: '17/11/2014',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 158,
        Weight: 41,
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Bada',
        'Full Name': 'Kim Bada',
        'Korean Name': '김바다',
        'K Stage Name': '바다',
        'Date of Birth': '28/05/2002',
        Group: 'Hinapia',
        Debut: '3/11/2019',
        Company: 'Alseulbit',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: 49,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Bae',
        'Full Name': 'Bae Jinsol',
        'Korean Name': '배진솔',
        'K Stage Name': '배이',
        'Date of Birth': '28/12/2004',
        Group: 'NMIXX',
        Debut: '22/02/2022',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Baek Seung',
        'Full Name': 'Kim Hyunwoo',
        'Korean Name': '김현우',
        'K Stage Name': '백승',
        'Date of Birth': '5/10/2004',
        Group: 'EPEX',
        Debut: '8/06/2021',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: 186,
        Weight: 64,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Baekah',
        'Full Name': 'Kim Sua',
        'Korean Name': '김수아',
        'K Stage Name': '백아',
        'Date of Birth': '24/10/1999',
        Group: 'XUM',
        Debut: '22/09/2020',
        Company: 'A100',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'NeonPunch',
        Gender: 'F'
    },
    {
        'Stage Name': 'Baekgyeul',
        'Full Name': 'Baek Sunwoo',
        'Korean Name': '백결',
        'K Stage Name': '백선우',
        'Date of Birth': '1/12/1999',
        Group: "E'LAST",
        Debut: '9/06/2020',
        Company: 'E Entertainment',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 61,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Baekho',
        'Full Name': 'Kang Dongho',
        'Korean Name': '강동호',
        'K Stage Name': '백호',
        'Date of Birth': '21/07/1995',
        Group: "NU'EST",
        Debut: '15/03/2012',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Jeju',
        'Other Group': "NU'EST W",
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Baekhyun',
        'Full Name': 'Byun Baekhyun',
        'Korean Name': '변백현',
        'K Stage Name': '백현',
        'Date of Birth': '6/05/1992',
        Group: 'EXO',
        Debut: '8/04/2012',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 174,
        Weight: 58,
        Birthplace: 'Wonmi',
        'Other Group': 'EXO-CBX| SuperM',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Bahiyyih',
        'Full Name': 'Huening Bahiyyih',
        'Korean Name': '휴닝바히에',
        'K Stage Name': '바히에',
        'Date of Birth': '27/07/2004',
        Group: 'Kep1er',
        Debut: '1/03/2022',
        Company: 'WakeOne',
        Country: 'USA',
        'Second Country': 'South Korea',
        Height: 166,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Bain',
        'Full Name': 'Song Byeonghee',
        'Korean Name': '송병희',
        'K Stage Name': '배인',
        'Date of Birth': '4/05/2001',
        Group: 'JUST B',
        Debut: '30/06/2021',
        Company: 'Bluedot',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'BamBam',
        'Full Name': 'Kunpimook Bhuwakul',
        'Korean Name': '꾼삐묵 부와꾼',
        'K Stage Name': '뱀뱀',
        'Date of Birth': '2/05/1997',
        Group: 'GOT7',
        Debut: '16/01/2014',
        Company: 'JYP',
        Country: 'Thailand',
        'Second Country': '',
        Height: 170,
        Weight: 52,
        Birthplace: 'Bangkok',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Bang Chan',
        'Full Name': 'Bang Chan',
        'Korean Name': '방찬',
        'K Stage Name': '방찬',
        'Date of Birth': '3/10/1997',
        Group: 'Stray Kids',
        Debut: '25/03/2018',
        Company: 'JYP',
        Country: 'Australia',
        'Second Country': 'South Korea',
        Height: '',
        Weight: '',
        Birthplace: 'Sydney',
        'Other Group': '3RACHA',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Bao',
        'Full Name': 'Bae Sungyeob',
        'Korean Name': '배성엽',
        'K Stage Name': '바오',
        'Date of Birth': '12/05/1996',
        Group: 'XEED',
        Debut: '1/12/2022',
        Company: 'Nature Space',
        Country: 'South Korea',
        'Second Country': '',
        Height: 182,
        Weight: 63,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'LUCENTE',
        Gender: 'M'
    },
    {
        'Stage Name': 'Baro',
        'Full Name': 'Cha Sunwoo',
        'Korean Name': '차선우',
        'K Stage Name': '바로',
        'Date of Birth': '5/09/1992',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 63,
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': 'B1A4',
        Gender: 'M'
    },
    {
        'Stage Name': 'Baron',
        'Full Name': 'Choi Chunghyeop',
        'Korean Name': '최충협',
        'K Stage Name': '바론',
        'Date of Birth': '19/04/1992',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 60,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'VAV',
        Gender: 'M'
    },
    {
        'Stage Name': 'B-Bomb',
        'Full Name': 'Lee Minhyuk',
        'Korean Name': '이민혁',
        'K Stage Name': '비범',
        'Date of Birth': '14/12/1990',
        Group: 'Block B',
        Debut: '15/04/2011',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 60,
        Birthplace: 'Seoul',
        'Other Group': 'Bastarz',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'BC',
        'Full Name': 'Jin Sungho',
        'Korean Name': '진성호',
        'K Stage Name': '비시',
        'Date of Birth': '18/07/1994',
        Group: '1TEAM',
        Debut: '27/03/2019',
        Company: 'LiveWorks',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Bekah',
        'Full Name': 'Rebecca Kim',
        'Korean Name': '김레베카',
        'K Stage Name': '베카',
        'Date of Birth': '11/08/1989',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': 'USA',
        Height: '',
        Weight: '',
        Birthplace: 'Honolulu',
        'Other Group': '',
        'Former Group': 'After School',
        Gender: 'F'
    },
    {
        'Stage Name': 'BEL',
        'Full Name': 'Choi Sunghoon',
        'Korean Name': '최성훈',
        'K Stage Name': '벨',
        'Date of Birth': '21/05/2000',
        Group: 'DUSTIN',
        Debut: '6/01/2020',
        Company: 'LPA',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Belle',
        'Full Name': 'Jin Hyeonjoo',
        'Korean Name': '진현주',
        'K Stage Name': '벨',
        'Date of Birth': '3/11/2001',
        Group: 'Cignature',
        Debut: '4/02/2020',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Goyang',
        'Other Group': '',
        'Former Group': 'Good Day',
        Gender: 'F'
    },
    {
        'Stage Name': 'Benji',
        'Full Name': 'Baek Jaewook',
        'Korean Name': '배제욱',
        'K Stage Name': '벤지',
        'Date of Birth': '3/05/1992',
        Group: 'B.I.G',
        Debut: '9/07/2014',
        Company: 'GH',
        Country: 'USA',
        'Second Country': 'South Korea',
        Height: 183,
        Weight: 72,
        Birthplace: 'Indianapolis',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Beomgyu',
        'Full Name': 'Choi Beomgyu',
        'Korean Name': '최범규',
        'K Stage Name': '범규',
        'Date of Birth': '13/03/2001',
        Group: 'TXT',
        Debut: '4/03/2019',
        Company: 'Big Hit',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Bessie',
        'Full Name': 'Kim Susanna',
        'Korean Name': '김수산나',
        'K Stage Name': '베시',
        'Date of Birth': '15/07/2004',
        Group: 'Lapillus',
        Debut: '22/06/2022',
        Company: 'MLD',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Changwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Bian',
        'Full Name': 'Yu Jiwon',
        'Korean Name': '유지원',
        'K Stage Name': '비안',
        'Date of Birth': '13/02/2001',
        Group: 'MAJORS',
        Debut: '9/03/2021',
        Company: 'ANS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'ANS',
        Gender: 'F'
    },
    {
        'Stage Name': 'BIBI',
        'Full Name': 'Kim Hyungseo',
        'Korean Name': '김형서',
        'K Stage Name': '비비',
        'Date of Birth': '27/09/1998',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Bic',
        'Full Name': 'Nam Seungmin',
        'Korean Name': '남승민',
        'K Stage Name': '빅',
        'Date of Birth': '25/04/2001',
        Group: 'MCND',
        Debut: '27/02/2020',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Bin',
        'Full Name': 'Kwon Soobin',
        'Korean Name': '김수빈',
        'K Stage Name': '빈',
        'Date of Birth': '28/10/1993',
        Group: 'Hot Blood Youth',
        Debut: '17/05/2017',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Bit',
        'Full Name': 'No Minjae',
        'Korean Name': '노민재',
        'K Stage Name': '빛',
        'Date of Birth': '21/07/2004',
        Group: 'BAE173',
        Debut: '19/11/2020',
        Company: 'PocketDol',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Bitsaeon',
        'Full Name': 'Kim Sangyeon',
        'Korean Name': '김상연',
        'K Stage Name': '빛새온',
        'Date of Birth': '4/06/1995',
        Group: 'MONT',
        Debut: '14/05/2014',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 181,
        Weight: 60,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Bitto',
        'Full Name': 'Lee Changhyun',
        'Korean Name': '이창현',
        'K Stage Name': '비토',
        'Date of Birth': '24/08/1996',
        Group: 'UP10TION',
        Debut: '10/09/2015',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Dongducheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'B-Joo',
        'Full Name': 'Kim Byungjoo',
        'Korean Name': '김병주',
        'K Stage Name': '비주',
        'Date of Birth': '8/01/1994',
        Group: 'Topp Dogg',
        Debut: '1/04/2020',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Blue',
        'Full Name': 'Jung Gahee',
        'Korean Name': '정가희',
        'K Stage Name': '파랑',
        'Date of Birth': '18/01/1994',
        Group: 'Pungdeng-E',
        Debut: '11/12/2013',
        Company: 'DOMA',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'BM',
        'Full Name': 'Matthew Kim',
        'Korean Name': '매튜김',
        'K Stage Name': '비엠',
        'Date of Birth': '20/10/1992',
        Group: 'KARD',
        Debut: '29/03/2007',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'BoA',
        'Full Name': 'Kwon Boa',
        'Korean Name': '권보아',
        'K Stage Name': '보아',
        'Date of Birth': '5/11/1986',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Guri',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Boa',
        'Full Name': 'Kim Boah',
        'Korean Name': '김보아',
        'K Stage Name': '보아',
        'Date of Birth': '14/01/1987',
        Group: 'KEEMBO',
        Debut: '10/04/2020',
        Company: 'ARA-LINE',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'SPICA',
        Gender: 'F'
    },
    {
        'Stage Name': 'Bobby',
        'Full Name': 'Kim Jiwon',
        'Korean Name': '김지원',
        'K Stage Name': '바비',
        'Date of Birth': '21/12/1995',
        Group: 'iKON',
        Debut: '15/09/2015',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': 'USA',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Boeun',
        'Full Name': 'Park Boeun',
        'Korean Name': '박보은',
        'K Stage Name': '보은',
        'Date of Birth': '11/02/2008',
        Group: 'CLASS:y',
        Debut: '5/05/2022',
        Company: 'Universal',
        Country: 'South Korea',
        'Second Country': '',
        Height: 155,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Bohyung',
        'Full Name': 'Kim Bohyung',
        'Korean Name': '김보형',
        'K Stage Name': '보형',
        'Date of Birth': '31/03/1991',
        Group: 'KEEMBO',
        Debut: '10/04/2020',
        Company: 'ARA-LINE',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'SPICA-S',
        'Former Group': 'SPICA',
        Gender: 'F'
    },
    {
        'Stage Name': 'Bokeun',
        'Full Name': 'Kim Bokeun',
        'Korean Name': '김복은',
        'K Stage Name': '복은',
        'Date of Birth': '12/10/1994',
        Group: 'SIGNAL',
        Debut: '28/08/2019',
        Company: 'J-Star',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Bom',
        'Full Name': 'Park Bom',
        'Korean Name': '박봄',
        'K Stage Name': '봄',
        'Date of Birth': '24/03/1984',
        Group: '2NE1',
        Debut: '6/05/2009',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Bomi',
        'Full Name': 'Yoon Bomi',
        'Korean Name': '윤보미',
        'K Stage Name': '보미',
        'Date of Birth': '13/08/1993',
        Group: 'Apink',
        Debut: '19/04/2011',
        Company: 'Plan A',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: 'Suwon',
        'Other Group': 'Chobom',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Bomin',
        'Full Name': 'Choi Bomin',
        'Korean Name': '최보민',
        'K Stage Name': '보민',
        'Date of Birth': '24/08/2000',
        Group: 'Golden Child',
        Debut: '28/08/2017',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Bomin',
        'Full Name': 'Kim Bomin',
        'Korean Name': '김보민',
        'K Stage Name': '보민',
        'Date of Birth': '24/09/2001',
        Group: 'RedSquare',
        Debut: '19/05/2020',
        Company: 'About',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': 'Good Day',
        Gender: 'F'
    },
    {
        'Stage Name': 'Bona',
        'Full Name': 'Kim Jiyeon',
        'Korean Name': '김지연',
        'K Stage Name': '보나',
        'Date of Birth': '19/08/1995',
        Group: 'WJSN',
        Debut: '25/02/2016',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': 'WJSN The Black',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Boni',
        'Full Name': 'Choi Euijeong',
        'Korean Name': '최의정',
        'K Stage Name': '보니',
        'Date of Birth': '30/10/1999',
        Group: 'Dreamnote',
        Debut: '7/11/2018',
        Company: 'iME',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 45,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Bora',
        'Full Name': 'Kim Bora',
        'Korean Name': '김보라',
        'K Stage Name': '보라',
        'Date of Birth': '3/03/1999',
        Group: 'Cherry Bullet',
        Debut: '21/01/2019',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Bora',
        'Full Name': 'Yoon Bora',
        'Korean Name': '윤보라',
        'K Stage Name': '보라',
        'Date of Birth': '30/12/1989',
        Group: 'SISTAR',
        Debut: '6/03/2010',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'SISTAR19',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Boram',
        'Full Name': 'Jeon Boram',
        'Korean Name': '전보람',
        'K Stage Name': '보람',
        'Date of Birth': '22/03/1986',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 155,
        Weight: 45,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'T-ara',
        Gender: 'F'
    },
    {
        'Stage Name': 'Bullet',
        'Full Name': 'Jin Junwoo',
        'Korean Name': '진준우',
        'K Stage Name': '블릿',
        'Date of Birth': '2/12/1995',
        Group: 'VARSITY',
        Debut: '5/01/2017',
        Company: 'GKC',
        Country: 'South Korea',
        'Second Country': '',
        Height: 182,
        Weight: 65,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'BX',
        'Full Name': 'Lee Byounggon',
        'Korean Name': '이병곤',
        'K Stage Name': '비엑스',
        'Date of Birth': '5/03/1998',
        Group: 'CIX',
        Debut: '23/07/2019',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 61,
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Byeongkwan',
        'Full Name': 'Kim Byeongkwan',
        'Korean Name': '김병관',
        'K Stage Name': '병관',
        'Date of Birth': '13/08/1996',
        Group: 'A.C.E',
        Debut: '23/05/2017',
        Company: 'Beat',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Byungchan',
        'Full Name': 'Choi Byungchan',
        'Korean Name': '최병찬',
        'K Stage Name': '병찬',
        'Date of Birth': '12/11/1997',
        Group: 'VICTON',
        Debut: '9/11/2016',
        Company: 'Plan A',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'C.A.P',
        'Full Name': 'Bang Minsoo',
        'Korean Name': '방민수',
        'K Stage Name': '캡',
        'Date of Birth': '4/11/1992',
        Group: 'Teen Top',
        Debut: '9/07/2010',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seongnam',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Cao Lu',
        'Full Name': 'Cao Lu',
        'Korean Name': '차오루',
        'K Stage Name': '차오루',
        'Date of Birth': '30/08/1987',
        Group: 'Fiestar',
        Debut: '31/08/2012',
        Company: 'LOEN',
        Country: 'China',
        'Second Country': '',
        Height: 166,
        Weight: '',
        Birthplace: 'Zhangjiajie',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Casper',
        'Full Name': 'Chu Xiaoxiang',
        'Korean Name': '저효상',
        'K Stage Name': '캐스퍼',
        'Date of Birth': '20/03/1991',
        Group: 'CROSS GENE',
        Debut: '8/07/2012',
        Company: 'Amuse',
        Country: 'China',
        'Second Country': '',
        Height: 176,
        Weight: 66,
        Birthplace: 'Shanghai',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Castle J',
        'Full Name': 'Son Sangjoon',
        'Korean Name': '손성준',
        'K Stage Name': '캐슬제이',
        'Date of Birth': '31/05/1999',
        Group: 'MCND',
        Debut: '27/02/2020',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Cha Hoon',
        'Full Name': 'Cha Hoon',
        'Korean Name': '차훈',
        'K Stage Name': '차훈',
        'Date of Birth': '12/07/1994',
        Group: 'N.Flying',
        Debut: '20/05/2015',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Chaebin',
        'Full Name': 'Choi Yoobin',
        'Korean Name': '최유빈',
        'K Stage Name': '채빈',
        'Date of Birth': '28/07/1999',
        Group: 'NATURE',
        Debut: '3/08/2018',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaehyun',
        'Full Name': 'Kim Chaehyun',
        'Korean Name': '김채현',
        'K Stage Name': '채현',
        'Date of Birth': '29/11/1999',
        Group: 'Bonus Baby',
        Debut: '1/01/2017',
        Company: 'Maroo',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaehyun',
        'Full Name': 'Kim Chaehyun',
        'Korean Name': '김채현',
        'K Stage Name': '채현',
        'Date of Birth': '26/04/2002',
        Group: 'Kep1er',
        Debut: '1/03/2022',
        Company: 'WakeOne',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaein',
        'Full Name': 'Lee Chaeyoung',
        'Korean Name': '이채영',
        'K Stage Name': '채인',
        'Date of Birth': '5/12/2002',
        Group: 'Purple Kiss',
        Debut: '15/03/2021',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaejeong',
        'Full Name': 'Lee Chaejeong',
        'Korean Name': '이채정',
        'K Stage Name': '채정',
        'Date of Birth': '26/08/1999',
        Group: 'ALICE',
        Debut: '1/07/2017',
        Company: 'IOK Company',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 48,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaejin',
        'Full Name': 'Chae Jinseok',
        'Korean Name': '채진석',
        'K Stage Name': '채진',
        'Date of Birth': '26/12/1995',
        Group: 'MYNAME',
        Debut: '27/10/2011',
        Company: 'H2',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Chaekyung',
        'Full Name': 'Yoon Chaekyung',
        'Korean Name': '윤채경',
        'K Stage Name': '채경',
        'Date of Birth': '7/07/1996',
        Group: 'APRIL',
        Debut: '24/08/2015',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 45,
        Birthplace: 'Incheon',
        'Other Group': 'C.I.V.A| I.B.I',
        'Former Group': 'Puretty',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaelin',
        'Full Name': 'Lee Chaelin',
        'Korean Name': '이채린',
        'K Stage Name': '채린',
        'Date of Birth': '20/10/1999',
        Group: 'FANATICS',
        Debut: '6/08/2019',
        Company: 'FENT',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: 47,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaerin',
        'Full Name': 'Park Chaerin',
        'Korean Name': '박채린',
        'K Stage Name': '채린',
        'Date of Birth': '13/03/2002',
        Group: 'Cherry Bullet',
        Debut: '21/01/2019',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaerin',
        'Full Name': 'Park Chaerin',
        'Korean Name': '박채린',
        'K Stage Name': '채린',
        'Date of Birth': '31/03/2003',
        Group: "ICHILLIN'",
        Debut: '8/09/2021',
        Company: 'KM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaeryeong',
        'Full Name': 'Lee Chaeryeong',
        'Korean Name': '이채령',
        'K Stage Name': '채령',
        'Date of Birth': '5/06/2001',
        Group: 'ITZY',
        Debut: '12/02/2019',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yongin',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaesol',
        'Full Name': 'Maeng Chaesol',
        'Korean Name': '맹채솔',
        'K Stage Name': '채솔',
        'Date of Birth': '14/07/1998',
        Group: 'Cignature',
        Debut: '4/02/2020',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yeosu',
        'Other Group': '',
        'Former Group': 'Good Day',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaewon',
        'Full Name': 'Kim Chaewon',
        'Korean Name': '김채원',
        'K Stage Name': '채원',
        'Date of Birth': '8/11/1997',
        Group: 'APRIL',
        Debut: '24/08/2015',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gongju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaewon',
        'Full Name': 'Yoon Chaewon',
        'Korean Name': '윤채원',
        'K Stage Name': '채원',
        'Date of Birth': '4/06/2003',
        Group: 'CLASS:y',
        Debut: '5/05/2022',
        Company: 'Universal',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaewon',
        'Full Name': 'Kim Chaewon',
        'Korean Name': '김채원',
        'K Stage Name': '채원',
        'Date of Birth': '1/08/2000',
        Group: 'LE SSERAFIM',
        Debut: '2/05/2022',
        Company: 'Source',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'IZ*ONE',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaewon',
        'Full Name': 'Lee Chaewon',
        'Korean Name': '이채원',
        'K Stage Name': '채원',
        'Date of Birth': '18/03/2000',
        Group: 'Maka Maka',
        Debut: '4/08/2020',
        Company: 'Dreamus',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'ChaeY',
        'Full Name': 'Song Chaeyeon',
        'Korean Name': '송채연',
        'K Stage Name': '채이',
        'Date of Birth': '6/01/2003',
        Group: 'CRAXY',
        Debut: '3/03/2020',
        Company: 'SAITEINMENT',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaeyeon',
        'Full Name': 'Jung Chaeyeon',
        'Korean Name': '정채연',
        'K Stage Name': '채연',
        'Date of Birth': '1/12/1997',
        Group: 'DIA',
        Debut: '14/07/2015',
        Company: 'MBK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 49,
        Birthplace: 'Suncheon',
        'Other Group': '',
        'Former Group': 'I.O.I',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaeyeon',
        'Full Name': 'Lee Chaeyeon',
        'Korean Name': '이채연',
        'K Stage Name': '채연',
        'Date of Birth': '11/01/2000',
        Group: 'IZ*ONE',
        Debut: '29/10/2018',
        Company: 'Off The Record',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yongin',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaeyeon',
        'Full Name': 'Kim Chaeyeon',
        'Korean Name': '김채연',
        'K Stage Name': '채연',
        'Date of Birth': '4/12/2004',
        Group: 'tripleS',
        Debut: '13/02/2023',
        Company: 'MODHAUS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Busters',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaeyoung',
        'Full Name': 'Lee Chaeyoung',
        'Korean Name': '이채영',
        'K Stage Name': '채영',
        'Date of Birth': '14/05/2000',
        Group: 'fromis_9',
        Debut: '24/01/2018',
        Company: 'Off The Record',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Pohang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chaeyoung',
        'Full Name': 'Son Chaeyoung',
        'Korean Name': '손채영',
        'K Stage Name': '채영',
        'Date of Birth': '23/04/1999',
        Group: 'Twice',
        Debut: '20/10/2015',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 159,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chahee',
        'Full Name': 'Park Sooyoung',
        'Korean Name': '박수영',
        'K Stage Name': '차희',
        'Date of Birth': '24/03/1996',
        Group: 'MELODYDAY',
        Debut: '25/09/2019',
        Company: 'KD',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chan',
        'Full Name': 'Kang Yoochan',
        'Korean Name': '강유찬',
        'K Stage Name': '찬',
        'Date of Birth': '31/12/1997',
        Group: 'A.C.E',
        Debut: '23/05/2017',
        Company: 'Beat',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Jeju',
        'Other Group': 'UNB',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Chan',
        'Full Name': 'Jung Chanwoo',
        'Korean Name': '정찬우',
        'K Stage Name': '찬',
        'Date of Birth': '26/01/1998',
        Group: 'iKON',
        Debut: '15/09/2015',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yongin',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Chan',
        'Full Name': 'Jo Chanhyuk',
        'Korean Name': '조찬혁',
        'K Stage Name': '찬',
        'Date of Birth': '8/12/1999',
        Group: 'TOO',
        Debut: '1/04/2020',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Changbin',
        'Full Name': 'Seo Changbin',
        'Korean Name': '서창빈',
        'K Stage Name': '창빈',
        'Date of Birth': '11/08/1999',
        Group: 'Stray Kids',
        Debut: '25/03/2018',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yongin',
        'Other Group': '3RACHA',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Changbum',
        'Full Name': 'Woo Changbum',
        'Korean Name': '우창범',
        'K Stage Name': '창범',
        'Date of Birth': '7/10/1993',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Sokcho',
        'Other Group': '',
        'Former Group': '100%',
        Gender: 'M'
    },
    {
        'Stage Name': 'Changgyu',
        'Full Name': 'Song Changgyu',
        'Korean Name': '송창규',
        'K Stage Name': '창규',
        'Date of Birth': '6/07/1993',
        Group: 'UNVS',
        Debut: '23/02/2020',
        Company: 'CHITWN',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Changjo',
        'Full Name': 'Choi Jonghyun',
        'Korean Name': '최종현',
        'K Stage Name': '창조',
        'Date of Birth': '16/11/1995',
        Group: 'Teen Top',
        Debut: '9/07/2010',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Chuncheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Changsub',
        'Full Name': 'Lee Changsub',
        'Korean Name': '이창섭',
        'K Stage Name': '창섭',
        'Date of Birth': '26/02/1991',
        Group: 'BtoB',
        Debut: '21/03/2012',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 64,
        Birthplace: 'Suwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Changsun',
        'Full Name': 'Lee Changsun',
        'Korean Name': '이창선',
        'K Stage Name': '창선',
        'Date of Birth': '17/03/1996',
        Group: '24K',
        Debut: '6/09/2012',
        Company: 'Choeun',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 58,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Changuk',
        'Full Name': 'Joo Changuk',
        'Korean Name': '주창욱',
        'K Stage Name': '창욱',
        'Date of Birth': '25/07/2001',
        Group: 'DRIPPIN',
        Debut: '26/10/2020',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 184,
        Weight: 59,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Chani',
        'Full Name': 'Kang Chanhee',
        'Korean Name': '강찬희',
        'K Stage Name': '찬희',
        'Date of Birth': '17/01/2000',
        Group: 'SF9',
        Debut: '5/10/2016',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Chanmi',
        'Full Name': 'Kim Chanmi',
        'Korean Name': '김찬미',
        'K Stage Name': '찬미',
        'Date of Birth': '19/06/1996',
        Group: 'AoA',
        Debut: '30/07/2012',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 47,
        Birthplace: 'Gumi',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chansung',
        'Full Name': 'Hwang Chansung',
        'Korean Name': '황찬성',
        'K Stage Name': '찬성',
        'Date of Birth': '11/02/1990',
        Group: '2PM',
        Debut: '4/07/2008',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 184,
        Weight: 75,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Chanty',
        'Full Name': 'Maria Chantal Videla',
        'Korean Name': '마리아 샹탈 비델라',
        'K Stage Name': '샨티',
        'Date of Birth': '15/12/2002',
        Group: 'Lapillus',
        Debut: '22/06/2022',
        Company: 'MLD',
        Country: 'Philippines',
        'Second Country': 'Argentina',
        Height: 170,
        Weight: '',
        Birthplace: 'Manila',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chanyeol',
        'Full Name': 'Park Chanyeol',
        'Korean Name': '박찬열',
        'K Stage Name': '찬열',
        'Date of Birth': '27/11/1992',
        Group: 'EXO',
        Debut: '8/04/2012',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 185,
        Weight: 70,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Chanyong',
        'Full Name': 'Kim Chanyong',
        'Korean Name': '김찬용',
        'K Stage Name': '찬용',
        'Date of Birth': '29/04/1993',
        Group: '100%',
        Debut: '18/09/2012',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Suwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Chanyoung',
        'Full Name': 'Kim Chanyoung',
        'Korean Name': '김찬영',
        'K Stage Name': '찬영',
        'Date of Birth': '20/11/2000',
        Group: 'D-CRUNCH',
        Debut: '6/08/2018',
        Company: 'All-S',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Chen',
        'Full Name': 'Kim Jongdae',
        'Korean Name': '김종대',
        'K Stage Name': '첸',
        'Date of Birth': '21/09/1992',
        Group: 'EXO',
        Debut: '8/04/2012',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: 64,
        Birthplace: 'Silheung',
        'Other Group': 'EXO-CBX',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Cheng Xiao',
        'Full Name': 'Cheng Xiao',
        'Korean Name': '청샤오',
        'K Stage Name': '성소',
        'Date of Birth': '15/07/1998',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'China',
        'Second Country': '',
        Height: 167,
        Weight: '',
        Birthplace: 'Shenzhen',
        'Other Group': '',
        'Former Group': 'WJSN',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chenle',
        'Full Name': 'Zhong Chenle',
        'Korean Name': '중천러',
        'K Stage Name': '천러',
        'Date of Birth': '22/11/2001',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'China',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Shanghai',
        'Other Group': 'NCT Dream',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Cheongeum',
        'Full Name': 'Jang Cheongeum',
        'Korean Name': '장청음',
        'K Stage Name': '청음',
        'Date of Birth': '19/06/1996',
        Group: 'Ho1iday',
        Debut: '26/04/2013',
        Company: 'LOEN',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chi.u',
        'Full Name': 'Lee Jaeyi',
        'Korean Name': '이재이',
        'K Stage Name': '치유',
        'Date of Birth': '16/08/1998',
        Group: 'X:IN',
        Debut: '11/04/2023',
        Company: 'Escrow',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chiayi',
        'Full Name': 'Li Chiayi',
        'Korean Name': '지아이',
        'K Stage Name': '지아이',
        'Date of Birth': '22/01/2000',
        Group: 'FANATICS',
        Debut: '6/08/2019',
        Company: 'FENT',
        Country: 'Taiwan',
        'Second Country': '',
        Height: 164,
        Weight: '',
        Birthplace: '',
        'Other Group': 'FANATICS FLAVOR',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chihoon',
        'Full Name': 'Choi Chihoon',
        'Korean Name': '최치훈',
        'K Stage Name': '치훈',
        'Date of Birth': '27/04/1999',
        Group: 'TOO',
        Debut: '1/04/2020',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Chiquita',
        'Full Name': 'Riracha Phondechaphiphat',
        'Korean Name': '리라차 폰데차피팟',
        'K Stage Name': '치키타',
        'Date of Birth': '17/02/2009',
        Group: 'BABYMONSTER',
        Debut: '0/01/1900',
        Company: 'YG',
        Country: 'Thailand',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chiwoo',
        'Full Name': 'Guk Seungjun',
        'Korean Name': '국승준',
        'K Stage Name': '치우',
        'Date of Birth': '2/09/2002',
        Group: 'Kingdom',
        Debut: '18/02/2021',
        Company: 'GF',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Chloe',
        'Full Name': 'Yun Jiwon',
        'Korean Name': '윤지원',
        'K Stage Name': '클로이',
        'Date of Birth': '6/01/2001',
        Group: 'Cignature',
        Debut: '4/02/2020',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Choa',
        'Full Name': 'Park Choa',
        'Korean Name': '박초아',
        'K Stage Name': '초아',
        'Date of Birth': '6/03/1990',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 42,
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': 'AoA| AOA BLACK',
        Gender: 'F'
    },
    {
        'Stage Name': 'Choa',
        'Full Name': 'Heo Minjin',
        'Korean Name': '허민진',
        'K Stage Name': '초아',
        'Date of Birth': '12/07/1990',
        Group: 'Crayon Pop',
        Debut: '18/07/2012',
        Company: 'Chrome',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Choerry',
        'Full Name': 'Choi Yerim',
        'Korean Name': '최예림',
        'K Stage Name': '최리',
        'Date of Birth': '4/06/2001',
        Group: 'LOONA',
        Debut: '20/08/2018',
        Company: 'Blockberry',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'Loona Odd Eye Circle| ARTMS',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Choi In',
        'Full Name': 'Choi In',
        'Korean Name': '최인',
        'K Stage Name': '최인',
        'Date of Birth': '11/02/1996',
        Group: "E'LAST",
        Debut: '9/06/2020',
        Company: 'E Entertainment',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 50,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Choi Jiann',
        'Full Name': 'Choi Jaewoo',
        'Korean Name': '최재우',
        'K Stage Name': '최지안',
        'Date of Birth': '30/09/1997',
        Group: 'Newkidd',
        Debut: '25/04/2019',
        Company: 'J-FLO',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Chorong',
        'Full Name': 'Park Chorong',
        'Korean Name': '박초롱',
        'K Stage Name': '초롱',
        'Date of Birth': '3/03/1991',
        Group: 'Apink',
        Debut: '19/04/2011',
        Company: 'Plan A',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Chungwongoon',
        'Other Group': 'Chobom',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chowon',
        'Full Name': 'Kim Chowon',
        'Korean Name': '김초원',
        'K Stage Name': '초원',
        'Date of Birth': '18/08/2005',
        Group: "ICHILLIN'",
        Debut: '8/09/2021',
        Company: 'KM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chowon',
        'Full Name': 'Han Chowon',
        'Korean Name': '한초원',
        'K Stage Name': '초원',
        'Date of Birth': '16/09/2002',
        Group: 'LIGHTSUM',
        Debut: '10/06/2021',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: 50,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Choyeon',
        'Full Name': 'Kim Choyeon',
        'Korean Name': '김초연',
        'K Stage Name': '초연',
        'Date of Birth': '1/08/2001',
        Group: 'bugAboo',
        Debut: '25/10/2021',
        Company: 'A team',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 44,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chris',
        'Full Name': 'Han Chris',
        'Korean Name': '한 크리스',
        'K Stage Name': '크리스',
        'Date of Birth': '19/11/2002',
        Group: 'BLITZERS',
        Debut: '12/05/2021',
        Company: 'Wuzo',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Chungha',
        'Full Name': 'Kim Chungha',
        'Korean Name': '김청하',
        'K Stage Name': '청하',
        'Date of Birth': '9/02/1996',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Dallas',
        'Other Group': '',
        'Former Group': 'I.O.I',
        Gender: 'F'
    },
    {
        'Stage Name': 'Chunji',
        'Full Name': 'Lee Chanhee',
        'Korean Name': '이찬희',
        'K Stage Name': '천지',
        'Date of Birth': '5/10/1993',
        Group: 'Teen Top',
        Debut: '9/07/2010',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seongnam',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Chuu',
        'Full Name': 'Kim Jiwoo',
        'Korean Name': '김지우',
        'K Stage Name': '츄',
        'Date of Birth': '20/10/1999',
        Group: 'LOONA',
        Debut: '20/08/2018',
        Company: 'Blockberry',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Cheongju',
        'Other Group': 'Loona yyxy',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'CL',
        'Full Name': 'Lee Chaerin',
        'Korean Name': '이채린',
        'K Stage Name': '씨엘',
        'Date of Birth': '26/02/1991',
        Group: '2NE1',
        Debut: '6/05/2009',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'CNU',
        'Full Name': 'Shin Dongwoo',
        'Korean Name': '신동우',
        'K Stage Name': '신우',
        'Date of Birth': '16/06/1991',
        Group: 'B1A4',
        Debut: '19/04/2010',
        Company: 'WM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 182,
        Weight: 64,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Cory',
        'Full Name': 'Hong Joohyung',
        'Korean Name': '홍주현',
        'K Stage Name': '코리',
        'Date of Birth': '25/11/1990',
        Group: '24K',
        Debut: '6/09/2012',
        Company: 'Choeun',
        Country: 'South Korea',
        'Second Country': 'USA',
        Height: 175,
        Weight: 58,
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Cui Xiang',
        'Full Name': 'Cui Xiang',
        'Korean Name': '최상',
        'K Stage Name': '최상',
        'Date of Birth': '26/01/1995',
        Group: 'BOTOPASS',
        Debut: '26/08/2020',
        Company: 'WKS ENE',
        Country: 'China',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'ILUV',
        Gender: 'F'
    },
    {
        'Stage Name': 'Cya',
        'Full Name': 'Lee Giwook',
        'Korean Name': '이기욱',
        'K Stage Name': '키야',
        'Date of Birth': '24/01/2000',
        Group: 'Onewe',
        Debut: '9/01/2019',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'MAS',
        Gender: 'M'
    },
    {
        'Stage Name': 'Cyan',
        'Full Name': 'Lee Chaeeun',
        'Korean Name': '이채은',
        'K Stage Name': '시안',
        'Date of Birth': '4/04/2001',
        Group: 'bugAboo',
        Debut: '25/10/2021',
        Company: 'A team',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Sanbon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'D.ana',
        'Full Name': 'Cho Eunae',
        'Korean Name': '조은애',
        'K Stage Name': '디애나',
        'Date of Birth': '10/09/1995',
        Group: 'Sonamoo',
        Debut: '29/12/2014',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'D.O.',
        'Full Name': 'Do Kyungsoo',
        'Korean Name': '도경수',
        'K Stage Name': '디오',
        'Date of Birth': '12/01/1993',
        Group: 'EXO',
        Debut: '8/04/2012',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: 60,
        Birthplace: 'Gyeonggi',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'D1',
        'Full Name': 'Jang Dongil',
        'Korean Name': '장동일',
        'K Stage Name': '디원',
        'Date of Birth': '15/02/1998',
        Group: 'DKB',
        Debut: '3/02/2020',
        Company: 'Brave',
        Country: 'South Korea',
        'Second Country': '',
        Height: 174,
        Weight: 59,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dabi',
        'Full Name': 'Park Jiwon',
        'Korean Name': '박지원',
        'K Stage Name': '다비',
        'Date of Birth': '19/01/2000',
        Group: 'AREAL',
        Debut: '21/08/2020',
        Company: 'JZ Factory',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'MIDNIGHT',
        Gender: 'F'
    },
    {
        'Stage Name': 'Daehwi',
        'Full Name': 'Lee Daehwi',
        'Korean Name': '이대휘',
        'K Stage Name': '대휘',
        'Date of Birth': '29/01/2001',
        Group: 'AB6IX',
        Debut: '22/05/2019',
        Company: 'Brand New',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'Wanna One',
        Gender: 'M'
    },
    {
        'Stage Name': 'Daehyeon',
        'Full Name': 'Jang Daehyeon',
        'Korean Name': '장대현',
        'K Stage Name': '대현',
        'Date of Birth': '11/02/1997',
        Group: 'WEi',
        Debut: '5/10/2020',
        Company: 'OUI',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'RAINZ',
        Gender: 'M'
    },
    {
        'Stage Name': 'Daehyun',
        'Full Name': 'Jung Daehyun',
        'Korean Name': '정대현',
        'K Stage Name': '대현',
        'Date of Birth': '28/06/1993',
        Group: 'B.A.P',
        Debut: '27/01/2012',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 63,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Daeil',
        'Full Name': 'Kim Daeil',
        'Korean Name': '김대일',
        'K Stage Name': '대일',
        'Date of Birth': '10/05/1992',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '24K',
        Gender: 'M'
    },
    {
        'Stage Name': 'Daesung',
        'Full Name': 'Daesung',
        'Korean Name': '강대성',
        'K Stage Name': '대성',
        'Date of Birth': '26/04/1989',
        Group: 'BIGBANG',
        Debut: '19/08/2006',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 63,
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Daeun',
        'Full Name': 'Jung Daeun',
        'Korean Name': '정다은',
        'K Stage Name': '정다은',
        'Date of Birth': '3/06/1994',
        Group: '2EYES',
        Debut: '20/07/2013',
        Company: 'SidusHQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: '',
        Birthplace: 'Bucheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Daewang Nim',
        'Full Name': 'Daewang Nim',
        'Korean Name': '대왕님',
        'K Stage Name': '대왕님',
        'Date of Birth': '8/04/1996',
        Group: 'Pink Fantasy',
        Debut: '28/10/2018',
        Company: 'My Doll',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: 49,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Daeyeol',
        'Full Name': 'Lee Daeyeol',
        'Korean Name': '이대열',
        'K Stage Name': '대열',
        'Date of Birth': '11/02/1993',
        Group: 'Golden Child',
        Debut: '28/08/2017',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yongin',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dahye',
        'Full Name': 'Song Dahye',
        'Korean Name': '송다혜',
        'K Stage Name': '다혜',
        'Date of Birth': '12/06/1993',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'EXID| BESTie',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dahyun',
        'Full Name': 'Jeong Dahyun',
        'Korean Name': '정다현',
        'K Stage Name': '다현',
        'Date of Birth': '29/04/2005',
        Group: 'Rocket Punch',
        Debut: '7/08/2019',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dahyun',
        'Full Name': 'Seo Dahyun',
        'Korean Name': '서다현',
        'K Stage Name': '다현',
        'Date of Birth': '8/01/2003',
        Group: 'tripleS',
        Debut: '13/02/2023',
        Company: 'MODHAUS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dahyun',
        'Full Name': 'Kim Dahyun',
        'Korean Name': '김다현',
        'K Stage Name': '다현',
        'Date of Birth': '28/05/1998',
        Group: 'Twice',
        Debut: '20/10/2015',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Seongnam',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dain',
        'Full Name': 'Song Jieun',
        'Korean Name': '송지은',
        'K Stage Name': '다인',
        'Date of Birth': '25/06/1990',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Wassup',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dain',
        'Full Name': 'Jung Dain',
        'Korean Name': '정다인',
        'K Stage Name': '다인',
        'Date of Birth': '15/12/2004',
        Group: 'HOT ISSUE',
        Debut: '17/05/2017',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Daisy',
        'Full Name': 'Yoo Jeongahn',
        'Korean Name': '유정안',
        'K Stage Name': '데이지',
        'Date of Birth': '22/01/1999',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'MOMOLAND',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dajeong',
        'Full Name': 'Kim Dajeong',
        'Korean Name': '김다정',
        'K Stage Name': '다정',
        'Date of Birth': '8/08/1995',
        Group: 'Hash Tag',
        Debut: '11/10/2017',
        Company: 'LUK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dajeong',
        'Full Name': 'Jung Dajeong',
        'Korean Name': '정다정',
        'K Stage Name': '다정',
        'Date of Birth': '31/07/2003',
        Group: 'Pixy',
        Debut: '24/02/2021',
        Company: 'ALLART',
        Country: 'South Korea',
        'Second Country': '',
        Height: 157,
        Weight: 42,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dakyung',
        'Full Name': 'Jung Dakyung',
        'Korean Name': '정다경',
        'K Stage Name': '다경',
        'Date of Birth': '23/07/2001',
        Group: 'Hi-L',
        Debut: '11/08/2021',
        Company: 'Kpop Live',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dalyn',
        'Full Name': 'Lee Sohyun',
        'Korean Name': '이소현',
        'K Stage Name': '달린',
        'Date of Birth': '27/08/1999',
        Group: 'ANS',
        Debut: '16/09/2019',
        Company: 'ANS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Damhee',
        'Full Name': 'Park Damhee',
        'Korean Name': '박담희',
        'K Stage Name': '담희',
        'Date of Birth': '21/06/2000',
        Group: 'ARTBEAT',
        Debut: '16/11/2022',
        Company: 'AB Creative',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dami',
        'Full Name': 'Hong Dayoung',
        'Korean Name': '홍다영',
        'K Stage Name': '담이',
        'Date of Birth': '7/06/2001',
        Group: 'ANS',
        Debut: '16/09/2019',
        Company: 'ANS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dami',
        'Full Name': 'Lee Yoobin',
        'Korean Name': '이유빈',
        'K Stage Name': '다미',
        'Date of Birth': '7/03/1997',
        Group: 'Dreamcatcher',
        Debut: '13/01/2017',
        Company: 'Happy Face',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'Minx',
        Gender: 'F'
    },
    {
        'Stage Name': 'Damin',
        'Full Name': 'Kang Damin',
        'Korean Name': '강다민',
        'K Stage Name': '다민',
        'Date of Birth': '24/03/2004',
        Group: 'Queenz Eye',
        Debut: '24/10/2022',
        Company: 'Big Mountain',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 42,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Damon',
        'Full Name': 'Hu Boohan',
        'Korean Name': '후보한',
        'K Stage Name': '데이먼',
        'Date of Birth': '2/07/1996',
        Group: 'VARSITY',
        Debut: '5/01/2017',
        Company: 'GKC',
        Country: 'China',
        'Second Country': '',
        Height: 181,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dana',
        'Full Name': 'Jang Dana',
        'Korean Name': '장다나',
        'K Stage Name': '다나',
        'Date of Birth': '25/12/2003',
        Group: 'HOT ISSUE',
        Debut: '17/05/2017',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dan-a',
        'Full Name': 'Park Seungyeon',
        'Korean Name': '박승연',
        'K Stage Name': '단아',
        'Date of Birth': '26/06/1993',
        Group: 'Matilda',
        Debut: '18/03/2016',
        Company: 'Box',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: 160,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Daniel',
        'Full Name': 'Kang Daniel',
        'Korean Name': '강다니엘',
        'K Stage Name': '다니엘',
        'Date of Birth': '10/12/1996',
        Group: 'Wanna One',
        Debut: '7/08/2017',
        Company: 'YMC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Danielle',
        'Full Name': 'Mo Jihye',
        'Korean Name': '모지혜',
        'K Stage Name': '다니엘',
        'Date of Birth': '11/04/2005',
        Group: 'NewJeans',
        Debut: '22/07/2022',
        Company: 'ADOR',
        Country: 'South Korea',
        'Second Country': 'Australia',
        Height: 165,
        Weight: '',
        Birthplace: 'Munsan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F',
        'Image URL': '/kpop/newjeans/danielle/198916_iJUD.png'
    },
    {
        'Stage Name': 'Dann',
        'Full Name': 'Jung Seungbo',
        'Korean Name': '정승보',
        'K Stage Name': '단',
        'Date of Birth': '1/11/1997',
        Group: 'Kingdom',
        Debut: '18/02/2021',
        Company: 'GF',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: '',
        Birthplace: 'Ilsan',
        'Other Group': '',
        'Former Group': 'VARSITY',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dany',
        'Full Name': 'Jeon Eunji',
        'Korean Name': '전은지',
        'K Stage Name': '다니',
        'Date of Birth': '20/01/1998',
        Group: 'Ho1iday',
        Debut: '26/04/2013',
        Company: 'LOEN',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Daon',
        'Full Name': 'Ahn Seonil',
        'Korean Name': '안선일',
        'K Stage Name': '다온',
        'Date of Birth': '10/12/2000',
        Group: 'DUSTIN',
        Debut: '6/01/2020',
        Company: 'LPA',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dara',
        'Full Name': 'Park Sandara',
        'Korean Name': '박산다라',
        'K Stage Name': '다라',
        'Date of Birth': '12/11/1984',
        Group: '2NE1',
        Debut: '6/05/2009',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Daseul',
        'Full Name': 'Yang Daseul',
        'Korean Name': '양다슬',
        'K Stage Name': '다슬',
        'Date of Birth': '14/09/2000',
        Group: 'Maka Maka',
        Debut: '4/08/2020',
        Company: 'Dreamus',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dasom',
        'Full Name': 'Lee Dasom',
        'Korean Name': '이다솜',
        'K Stage Name': '다솜',
        'Date of Birth': '13/11/1993',
        Group: '2EYES',
        Debut: '20/07/2013',
        Company: 'SidusHQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: '',
        Birthplace: 'Cheongju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dasom',
        'Full Name': 'Kim Dasom',
        'Korean Name': '김다솜',
        'K Stage Name': '다솜',
        'Date of Birth': '6/05/1993',
        Group: 'SISTAR',
        Debut: '6/03/2010',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dawon',
        'Full Name': 'Lee Dawon',
        'Korean Name': '이다원',
        'K Stage Name': '다원',
        'Date of Birth': '2/12/1996',
        Group: 'ARIAZ',
        Debut: '23/10/2019',
        Company: 'Rising Star',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dawon',
        'Full Name': 'Lee Sanghyuk',
        'Korean Name': '이상혁',
        'K Stage Name': '다원',
        'Date of Birth': '24/07/1995',
        Group: 'SF9',
        Debut: '5/10/2016',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dawon',
        'Full Name': 'Cho Dawon',
        'Korean Name': '조다원',
        'K Stage Name': '다원',
        'Date of Birth': '12/05/2000',
        Group: 'VARSITY',
        Debut: '5/01/2017',
        Company: 'GKC',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: 69,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dawon',
        'Full Name': 'Nam Dawon',
        'Korean Name': '남다원',
        'K Stage Name': '다원',
        'Date of Birth': '16/03/1997',
        Group: 'WJSN',
        Debut: '25/02/2016',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Daye',
        'Full Name': 'Kim Hyunjung',
        'Korean Name': '김현정',
        'K Stage Name': '다예',
        'Date of Birth': '25/02/1998',
        Group: 'Berry Good',
        Debut: '21/05/2014',
        Company: 'Asia Bridge',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dayeon',
        'Full Name': 'Kim Dayeon',
        'Korean Name': '김다연',
        'K Stage Name': '다연',
        'Date of Birth': '2/03/2003',
        Group: 'Kep1er',
        Debut: '1/03/2022',
        Company: 'WakeOne',
        Country: 'South Korea',
        'Second Country': '',
        Height: 158,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dayeon',
        'Full Name': 'Hwang Da Kyeon',
        'Korean Name': '황연경',
        'K Stage Name': '다연',
        'Date of Birth': '17/11/1995',
        Group: 'XUM',
        Debut: '22/09/2020',
        Company: 'A100',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'NeonPunch',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dayoung',
        'Full Name': 'Kim Dayoung',
        'Korean Name': '김다영',
        'K Stage Name': '다영',
        'Date of Birth': '2/02/1991',
        Group: 'Blady',
        Debut: '16/05/2011',
        Company: 'Star Planet',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Jeonju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dayoung',
        'Full Name': 'Im Dayoung',
        'Korean Name': '임다영',
        'K Stage Name': '다영',
        'Date of Birth': '14/05/1999',
        Group: 'WJSN',
        Debut: '25/02/2016',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: '',
        Birthplace: 'Jeju',
        'Other Group': 'WJSN Chocome',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dayun',
        'Full Name': 'Kim Dayun',
        'Korean Name': '김다윤',
        'K Stage Name': '다윤',
        'Date of Birth': '20/11/2000',
        Group: 'Bonus Baby',
        Debut: '1/01/2017',
        Company: 'Maroo',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Della',
        'Full Name': 'Park Sehyun',
        'Korean Name': '박세현',
        'K Stage Name': '델라',
        'Date of Birth': '3/09/1995',
        Group: 'Rockit Girl',
        Debut: '30/05/2019',
        Company: 'Rolling',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Denise',
        'Full Name': 'Kim Denise',
        'Korean Name': '김데니스',
        'K Stage Name': '데니스',
        'Date of Birth': '11/01/2001',
        Group: 'SECRET NUMBER',
        Debut: '19/05/2020',
        Company: 'Vine',
        Country: 'South Korea',
        'Second Country': 'USA',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dey',
        'Full Name': 'Kim Sehyun',
        'Korean Name': '김세현',
        'K Stage Name': '데이',
        'Date of Birth': '11/06/2003',
        Group: 'YOUNITE',
        Debut: '20/04/2022',
        Company: 'Brand New',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dia',
        'Full Name': 'Kwak Minnyeong',
        'Korean Name': '곽민녕',
        'K Stage Name': '디아',
        'Date of Birth': '3/09/1999',
        Group: 'Maka Maka',
        Debut: '4/08/2020',
        Company: 'Dreamus',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dia',
        'Full Name': 'Choi Eunji',
        'Korean Name': '최은지',
        'K Stage Name': '디아',
        'Date of Birth': '16/07/2001',
        Group: 'Pixy',
        Debut: '24/02/2021',
        Company: 'ALLART',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dino',
        'Full Name': 'Lee Chan',
        'Korean Name': '이찬',
        'K Stage Name': '디노',
        'Date of Birth': '11/02/1999',
        Group: 'Seventeen',
        Debut: '26/05/2015',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 54,
        Birthplace: 'Iksan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dita',
        'Full Name': 'Dita Karang',
        'Korean Name': '디타 가랑',
        'K Stage Name': '디타',
        'Date of Birth': '25/12/1996',
        Group: 'SECRET NUMBER',
        Debut: '19/05/2020',
        Company: 'Vine',
        Country: 'Indonesia',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yogyakarta',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'DK',
        'Full Name': 'Kim Dokyun',
        'Korean Name': '김도균',
        'K Stage Name': '닥',
        'Date of Birth': '16/02/1998',
        Group: 'BLANK2Y',
        Debut: '24/05/2022',
        Company: 'Keystone',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'DK',
        'Full Name': 'Kim Donghyuk',
        'Korean Name': '김동혁',
        'K Stage Name': '디케이',
        'Date of Birth': '3/01/1997',
        Group: 'iKON',
        Debut: '15/09/2015',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'DK',
        'Full Name': 'Lee Seongmin',
        'Korean Name': '이석민',
        'K Stage Name': '도겸',
        'Date of Birth': '18/02/1997',
        Group: 'Seventeen',
        Debut: '26/05/2015',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 68,
        Birthplace: 'Yongin',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Doa',
        'Full Name': 'Im Youngseo',
        'Korean Name': '임영서',
        'K Stage Name': '도아',
        'Date of Birth': '29/10/2001',
        Group: 'We;Na',
        Debut: '5/09/2022',
        Company: 'Shine E&M',
        Country: 'South Korea',
        'Second Country': '',
        Height: 158,
        Weight: 44,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Do-A',
        'Full Name': 'Choi Yuna',
        'Korean Name': '최윤아',
        'K Stage Name': '도아',
        'Date of Birth': '2/02/1999',
        Group: 'ALICE',
        Debut: '1/07/2017',
        Company: 'IOK Company',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Doah',
        'Full Name': 'Kim Doah',
        'Korean Name': '김도아',
        'K Stage Name': '도아',
        'Date of Birth': '4/12/2003',
        Group: 'FANATICS',
        Debut: '6/08/2019',
        Company: 'FENT',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: 42,
        Birthplace: '',
        'Other Group': 'FANATICS FLAVOR',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Doha',
        'Full Name': 'Na Gyumin',
        'Korean Name': '나규민',
        'K Stage Name': '도하',
        'Date of Birth': '7/03/2004',
        Group: 'BAE173',
        Debut: '19/11/2020',
        Company: 'PocketDol',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Doha',
        'Full Name': 'Park Doha',
        'Korean Name': '박도하',
        'K Stage Name': '도하',
        'Date of Birth': '27/03/1992',
        Group: 'XEED',
        Debut: '1/12/2022',
        Company: 'Nature Space',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 65,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dohee',
        'Full Name': 'Yoon Dohee',
        'Korean Name': '윤도희',
        'K Stage Name': '도희',
        'Date of Birth': '24/09/1999',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'NeonPunch',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dohee',
        'Full Name': 'Kwon Dohee',
        'Korean Name': '권도희',
        'K Stage Name': '도희',
        'Date of Birth': '1/08/2002',
        Group: 'Cignature',
        Debut: '4/02/2020',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: 158,
        Weight: 39,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dohee',
        'Full Name': 'Min Dohee',
        'Korean Name': '민도희',
        'K Stage Name': '도희',
        'Date of Birth': '25/09/1994',
        Group: 'Tiny-G',
        Debut: '23/08/2012',
        Company: 'GNG',
        Country: 'South Korea',
        'Second Country': '',
        Height: 152,
        Weight: 39,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dohwan',
        'Full Name': 'Gil Dohwan',
        'Korean Name': '길도환',
        'K Stage Name': '도환',
        'Date of Birth': '26/08/2003',
        Group: 'Ciipher',
        Debut: '15/03/2021',
        Company: 'Rain',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 61,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dohyon',
        'Full Name': 'Nam Dohyon',
        'Korean Name': '남도현',
        'K Stage Name': '도현',
        'Date of Birth': '10/11/2004',
        Group: 'BAE173',
        Debut: '19/11/2020',
        Company: 'PocketDol',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 62,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'X1',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dohyuk',
        'Full Name': 'Kim Dohyuk',
        'Korean Name': '김도혁',
        'K Stage Name': '도혁',
        'Date of Birth': '16/01/1998',
        Group: '14U',
        Debut: '17/04/2017',
        Company: 'BG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Doi',
        'Full Name': 'Kim Doi',
        'Korean Name': '김도이',
        'K Stage Name': '도이',
        'Date of Birth': '12/04/1994',
        Group: 'FANATICS',
        Debut: '6/08/2019',
        Company: 'FENT',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Dojin',
        'Full Name': 'Jeon Yongtae',
        'Korean Name': '전용태',
        'K Stage Name': '도진',
        'Date of Birth': '7/03/1997',
        Group: 'ENOi',
        Debut: '19/04/2019',
        Company: 'KITHEWHALE',
        Country: 'South Korea',
        'Second Country': '',
        Height: 181,
        Weight: 61,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dokyun',
        'Full Name': 'Na Dokyun',
        'Korean Name': '나도균',
        'K Stage Name': '도균',
        'Date of Birth': '11/02/1991',
        Group: 'HISTORY',
        Debut: '26/04/2013',
        Company: 'LOEN',
        Country: 'South Korea',
        'Second Country': '',
        Height: 184,
        Weight: 65,
        Birthplace: 'Daejeon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Donggeon',
        'Full Name': 'Song Donggeon',
        'Korean Name': '송동건',
        'K Stage Name': '동건',
        'Date of Birth': '15/07/1999',
        Group: 'TOO',
        Debut: '1/04/2020',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Donghae',
        'Full Name': 'Lee Donghae',
        'Korean Name': '이동해',
        'K Stage Name': '동해',
        'Date of Birth': '15/10/1986',
        Group: 'Super Junior',
        Debut: '6/11/2005',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 60,
        Birthplace: 'Mokpo',
        'Other Group': 'Super Junior-M',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Donghan',
        'Full Name': 'Kim Donghan',
        'Korean Name': '김동한',
        'K Stage Name': '동한',
        'Date of Birth': '3/07/1998',
        Group: 'WEi',
        Debut: '5/10/2020',
        Company: 'OUI',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': 'JBJ',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dongheon',
        'Full Name': 'Lee Dongheon',
        'Korean Name': '이동헌',
        'K Stage Name': '동헌',
        'Date of Birth': '4/08/1995',
        Group: 'Verivery',
        Debut: '1/09/2019',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Donghun',
        'Full Name': 'Lee Donghun',
        'Korean Name': '이동훈',
        'K Stage Name': '동훈',
        'Date of Birth': '15/05/1993',
        Group: 'A.C.E',
        Debut: '23/05/2017',
        Company: 'Beat',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Suncheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Donghyuk',
        'Full Name': 'Park Donghyuk',
        'Korean Name': '박동혁',
        'K Stage Name': '동혁',
        'Date of Birth': '18/12/1999',
        Group: 'BLANK2Y',
        Debut: '24/05/2022',
        Company: 'Keystone',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 61,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'ENOi',
        Gender: 'M'
    },
    {
        'Stage Name': 'Donghyun',
        'Full Name': 'Kim Donghyun',
        'Korean Name': '김동현',
        'K Stage Name': '동현',
        'Date of Birth': '17/09/1998',
        Group: 'AB6IX',
        Debut: '22/05/2019',
        Company: 'Brand New',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daejeon',
        'Other Group': 'MXM',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Donghyun',
        'Full Name': 'Kim Donghyun',
        'Korean Name': '김동현',
        'K Stage Name': '동현',
        'Date of Birth': '12/02/1989',
        Group: 'Boyfriend',
        Debut: '26/05/2011',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 60,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Donghyun',
        'Full Name': 'Kim Donghyun',
        'Korean Name': '김동현',
        'K Stage Name': '동현',
        'Date of Birth': '23/02/1999',
        Group: 'Golden Child',
        Debut: '28/08/2017',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dongjun',
        'Full Name': 'Hwang Dongjun',
        'Korean Name': '황동준',
        'K Stage Name': '동준',
        'Date of Birth': '7/09/1999',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Ghost9',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dongjun',
        'Full Name': 'Kim Dongjun',
        'Korean Name': '김동준',
        'K Stage Name': '동준',
        'Date of Birth': '11/02/1992',
        Group: 'ZE:A',
        Debut: '7/01/2010',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dongkyu',
        'Full Name': 'Moon Dongkyu',
        'Korean Name': '문동규',
        'K Stage Name': '동규',
        'Date of Birth': '16/11/1992',
        Group: 'Spectrum',
        Debut: '9/05/2018',
        Company: 'WYNN',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dongmyeong',
        'Full Name': 'Son Dongmyeong',
        'Korean Name': '곤동명',
        'K Stage Name': '동명',
        'Date of Birth': '20/01/2000',
        Group: 'Onewe',
        Debut: '9/01/2019',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'MAS',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dongpyo',
        'Full Name': 'Son Dongpyo',
        'Korean Name': '손동표',
        'K Stage Name': '동표',
        'Date of Birth': '9/09/2002',
        Group: 'Mirae',
        Debut: '17/03/2021',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 48,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'X1',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dongwan',
        'Full Name': 'Kim Dongwan',
        'Korean Name': '김동완',
        'K Stage Name': '동완',
        'Date of Birth': '21/11/1979',
        Group: 'Shinhwa',
        Debut: '24/03/1998',
        Company: 'SM| Good| Shinhwa',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: 68,
        Birthplace: 'Pohang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dongwon',
        'Full Name': 'Lee Dongwon',
        'Korean Name': '이동원',
        'K Stage Name': '동원',
        'Date of Birth': '1/01/1994',
        Group: 'KNK',
        Debut: '3/03/2016',
        Company: 'YNB',
        Country: 'South Korea',
        'Second Country': '',
        Height: 184,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dongwoo',
        'Full Name': 'Jang Dongwoo',
        'Korean Name': '장동우',
        'K Stage Name': '동우',
        'Date of Birth': '22/11/1990',
        Group: 'Infinite',
        Debut: '9/06/2010',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: 58,
        Birthplace: 'Guri',
        'Other Group': 'Infinite H',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dongwoon',
        'Full Name': 'Son Dongwoon',
        'Korean Name': '손동운',
        'K Stage Name': '동운',
        'Date of Birth': '6/06/1991',
        Group: 'Highlight',
        Debut: '14/10/2009',
        Company: 'Around Us',
        Country: 'South Korea',
        'Second Country': '',
        Height: 181,
        Weight: 64,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': 'Beast',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dongyun',
        'Full Name': 'Kim Dongyun',
        'Korean Name': '김동윤',
        'K Stage Name': '동윤',
        'Date of Birth': '18/02/2002',
        Group: 'DRIPPIN',
        Debut: '26/10/2020',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 61,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Doojoon',
        'Full Name': 'Yoon Doojoon',
        'Korean Name': '윤두준',
        'K Stage Name': '두준',
        'Date of Birth': '4/07/1989',
        Group: 'Highlight',
        Debut: '14/10/2009',
        Company: 'Around Us',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 66,
        Birthplace: 'Goyang',
        'Other Group': '',
        'Former Group': 'Beast',
        Gender: 'M'
    },
    {
        'Stage Name': 'Doryun',
        'Full Name': 'Kim Doryun',
        'Korean Name': '킴도륜',
        'K Stage Name': '도륜',
        'Date of Birth': '1/05/2000',
        Group: 'AIMERS',
        Debut: '17/11/2022',
        Company: 'Hyper Rhythm',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dosie',
        'Full Name': 'Jang Eunseung',
        'Korean Name': '장은성',
        'K Stage Name': '도시',
        'Date of Birth': '11/02/2000',
        Group: 'Purple Kiss',
        Debut: '15/03/2021',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Douhyun',
        'Full Name': 'Yoo Douhyun',
        'Korean Name': '유도현',
        'K Stage Name': '도현',
        'Date of Birth': '25/12/2000',
        Group: 'Mirae',
        Debut: '17/03/2021',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dowoon',
        'Full Name': 'Yoon Dowoon',
        'Korean Name': '윤도운',
        'K Stage Name': '도운',
        'Date of Birth': '25/08/1995',
        Group: 'DAY6',
        Debut: '7/09/2015',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Doyeon',
        'Full Name': 'Kim Doyeon',
        'Korean Name': '김도연',
        'K Stage Name': '도연',
        'Date of Birth': '4/12/1999',
        Group: 'Weki Meki',
        Debut: '8/08/2017',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': 'I.O.I| WJMK',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Doyoung',
        'Full Name': 'Kim Dongyoung',
        'Korean Name': '김동영',
        'K Stage Name': '도영',
        'Date of Birth': '1/02/1996',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Guri',
        'Other Group': 'NCT U| NCT 127',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Doyoung',
        'Full Name': 'Kim Doyoung',
        'Korean Name': '김도영',
        'K Stage Name': '도영',
        'Date of Birth': '4/12/2003',
        Group: 'Treasure',
        Debut: '7/08/2020',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Duna',
        'Full Name': 'Kang Duna',
        'Korean Name': '강두나',
        'K Stage Name': '두나',
        'Date of Birth': '28/04/2005',
        Group: 'CSR',
        Debut: '27/07/2022',
        Company: 'A2Z',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gangwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'DY',
        'Full Name': 'Jeon Doyum',
        'Korean Name': '전도염',
        'K Stage Name': '도염',
        'Date of Birth': '21/02/2002',
        Group: 'JUST B',
        Debut: '30/06/2021',
        Company: 'Bluedot',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 55,
        Birthplace: 'Daejeon',
        'Other Group': '',
        'Former Group': '1the9',
        Gender: 'M'
    },
    {
        'Stage Name': 'Dylan',
        'Full Name': 'Park Yeonjae',
        'Korean Name': '박연재',
        'K Stage Name': '딜란',
        'Date of Birth': '26/07/2002',
        Group: 'D-CRUNCH',
        Debut: '6/08/2018',
        Company: 'All-S',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'E.Ji',
        'Full Name': 'Choi Jiwon',
        'Korean Name': '최지원',
        'K Stage Name': '이지',
        'Date of Birth': '8/11/2000',
        Group: "ICHILLIN'",
        Debut: '8/09/2021',
        Company: 'KM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'E.sha',
        'Full Name': 'Kwon Yena',
        'Korean Name': '권예나',
        'K Stage Name': '이샤',
        'Date of Birth': '29/12/1998',
        Group: 'X:IN',
        Debut: '11/04/2023',
        Company: 'Escrow',
        Country: 'Australia',
        'Second Country': 'South Korea',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'E.sol',
        'Full Name': 'Jeon Jaehyuk',
        'Korean Name': '전재혁',
        'K Stage Name': '이솔',
        'Date of Birth': '17/09/1992',
        Group: '14U',
        Debut: '17/04/2017',
        Company: 'BG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'E:U',
        'Full Name': 'Park Jiwon',
        'Korean Name': '박지원',
        'K Stage Name': '이유',
        'Date of Birth': '19/05/1998',
        Group: 'Everglow',
        Debut: '18/03/2019',
        Company: 'Yuehua',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'E-Chan',
        'Full Name': 'Lee Changmin',
        'Korean Name': '이창민',
        'K Stage Name': '이찬',
        'Date of Birth': '19/02/1997',
        Group: 'DKB',
        Debut: '3/02/2020',
        Company: 'Brave',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 63,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': "E'Dawn",
        'Full Name': 'Kim Hyojong',
        'Korean Name': '김효종',
        'K Stage Name': '이던',
        'Date of Birth': '1/06/1994',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'PENTAGON',
        Gender: 'M'
    },
    {
        'Stage Name': 'Eden',
        'Full Name': 'Jae Won',
        'Korean Name': '재원',
        'K Stage Name': '이든',
        'Date of Birth': '27/03/1998',
        Group: 'NINE.i',
        Debut: '30/03/2022',
        Company: 'FirstOne',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'EJ',
        'Full Name': 'Kim Eunji',
        'Korean Name': '김은지',
        'K Stage Name': '이제이',
        'Date of Birth': '13/08/1997',
        Group: 'ALICE',
        Debut: '1/07/2017',
        Company: 'IOK Company',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 47,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eli',
        'Full Name': 'Ellison Kim',
        'Korean Name': '엘리슨 킴',
        'K Stage Name': '일라이',
        'Date of Birth': '13/03/1991',
        Group: 'U-KISS',
        Debut: '28/08/2008',
        Company: 'NH Media',
        Country: 'USA',
        'Second Country': 'South Korea',
        Height: '',
        Weight: '',
        Birthplace: 'Los Angeles',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Elkie',
        'Full Name': 'Chong Ting Yan',
        'Korean Name': '쫑딩얀',
        'K Stage Name': '엘키',
        'Date of Birth': '2/11/1998',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'Hong Kong',
        'Second Country': '',
        Height: 163,
        Weight: 45,
        Birthplace: 'Hong Kong',
        'Other Group': '',
        'Former Group': 'CLC',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ella',
        'Full Name': 'Kim Kyungjoo',
        'Korean Name': '김경주',
        'K Stage Name': '엘라',
        'Date of Birth': '26/03/1998',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Cherry Bullet| Pixy',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ellin',
        'Full Name': 'Kim Minyoung',
        'Korean Name': '김민영',
        'K Stage Name': '엘린',
        'Date of Birth': '2/04/1990',
        Group: 'Crayon Pop',
        Debut: '18/07/2012',
        Company: 'Chrome',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 44,
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Elly',
        'Full Name': 'Jung Haerim',
        'Korean Name': '정해림',
        'K Stage Name': '엘리',
        'Date of Birth': '20/07/1998',
        Group: 'Weki Meki',
        Debut: '8/08/2017',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ellyn',
        'Full Name': 'Bang Sunhee',
        'Korean Name': '방선희',
        'K Stage Name': '엘린',
        'Date of Birth': '19/10/2002',
        Group: 'Girlkind',
        Debut: '16/01/2018',
        Company: 'Nextlevel',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: 170,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Elva',
        'Full Name': 'Lee Yonghwa',
        'Korean Name': '이용화',
        'K Stage Name': '엘바',
        'Date of Birth': '5/05/2003',
        Group: 'ILY:1',
        Debut: '4/04/2022',
        Company: 'FC ENM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eric',
        'Full Name': 'Moon Junghyuk',
        'Korean Name': '문정혁',
        'K Stage Name': '에릭',
        'Date of Birth': '16/02/1979',
        Group: 'Shinhwa',
        Debut: '24/03/1998',
        Company: 'SM| Good| Shinhwa',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 70,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Eric',
        'Full Name': 'Son Youngjae',
        'Korean Name': '손영재',
        'K Stage Name': '에릭',
        'Date of Birth': '22/12/2000',
        Group: 'The Boyz',
        Debut: '31/12/2017',
        Company: 'Cre.Ker',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: 56,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Eseo',
        'Full Name': 'Noh Hyunjung',
        'Korean Name': '노현정',
        'K Stage Name': '이서',
        'Date of Birth': '4/03/1996',
        Group: 'LUNARSOLAR',
        Debut: '2/09/2020',
        Company: 'J Planet',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eson',
        'Full Name': 'Kim Jungmin',
        'Korean Name': '김정민',
        'K Stage Name': '이슨',
        'Date of Birth': '28/12/1996',
        Group: 'We In The Zone',
        Debut: '27/05/2019',
        Company: 'Choon',
        Country: 'South Korea',
        'Second Country': 'USA',
        Height: 177,
        Weight: 63,
        Birthplace: 'Honolulu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'E-tion',
        'Full Name': 'Lee Changyoon',
        'Korean Name': '이창윤',
        'K Stage Name': '이션',
        'Date of Birth': '24/12/1994',
        Group: 'ONF',
        Debut: '2/08/2017',
        Company: 'WM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Jeonju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Euaerin',
        'Full Name': 'Lee Hyemin',
        'Korean Name': '이혜민',
        'K Stage Name': '이유애린',
        'Date of Birth': '3/05/1988',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 174,
        Weight: 48,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '9Muses',
        Gender: 'F'
    },
    {
        'Stage Name': 'Euijin',
        'Full Name': 'Lee Euijin',
        'Korean Name': '이의진',
        'K Stage Name': '의진',
        'Date of Birth': '15/02/1990',
        Group: 'Bigflo',
        Debut: '19/07/2014',
        Company: 'HO',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Euijin',
        'Full Name': 'Hong Euijin',
        'Korean Name': '홍의진',
        'K Stage Name': '의진',
        'Date of Birth': '8/10/1996',
        Group: 'Sonamoo',
        Debut: '29/12/2014',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': 'UNI.T',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eun',
        'Full Name': 'Choi Eunmi',
        'Korean Name': '최은미',
        'K Stage Name': '은',
        'Date of Birth': '14/05/1990',
        Group: 'Two X',
        Debut: '20/08/2012',
        Company: 'J. Tune',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Euna',
        'Full Name': '',
        'Korean Name': '',
        'K Stage Name': '은아',
        'Date of Birth': '25/05/2003',
        Group: 'We;Na',
        Debut: '5/09/2022',
        Company: 'Shine E&M',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'EunB',
        'Full Name': 'Shin Eunbi',
        'Korean Name': '신은비',
        'K Stage Name': '은비',
        'Date of Birth': '17/09/2000',
        Group: 'Maka Maka',
        Debut: '4/08/2020',
        Company: 'Dreamus',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Lip Bubble',
        Gender: 'F'
    },
    {
        'Stage Name': 'EunB',
        'Full Name': 'Byun Eunbi',
        'Korean Name': '변은비',
        'K Stage Name': '은비',
        'Date of Birth': '23/11/2000',
        Group: 'PRECIOUS',
        Debut: '23/09/2020',
        Company: 'Umi',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 49,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eunbi',
        'Full Name': 'Kwon Eunbi',
        'Korean Name': '권은비',
        'K Stage Name': '은비',
        'Date of Birth': '27/09/1995',
        Group: 'IZ*ONE',
        Debut: '29/10/2018',
        Company: 'Off The Record',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eunbin',
        'Full Name': 'Kwon Eunbin',
        'Korean Name': '권은빈',
        'K Stage Name': '은빈',
        'Date of Birth': '6/01/2000',
        Group: 'CLC',
        Debut: '19/03/2015',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 48,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eunchae',
        'Full Name': 'Son Eunchae',
        'Korean Name': '손은채',
        'K Stage Name': '은채',
        'Date of Birth': '6/10/1999',
        Group: 'bugAboo',
        Debut: '25/10/2021',
        Company: 'A team',
        Country: 'South Korea',
        'Second Country': '',
        Height: 154,
        Weight: 38,
        Birthplace: 'Pohang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eunchae',
        'Full Name': 'Kwon Chaewon',
        'Korean Name': '권채원',
        'K Stage Name': '은채',
        'Date of Birth': '26/05/1999',
        Group: 'DIA',
        Debut: '14/07/2015',
        Company: 'MBK',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eunchae',
        'Full Name': 'Hong Eunchae',
        'Korean Name': '홍은채',
        'K Stage Name': '은채',
        'Date of Birth': '10/11/2006',
        Group: 'LE SSERAFIM',
        Debut: '2/05/2022',
        Company: 'Source',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eunchan',
        'Full Name': 'Choi Byeongseop',
        'Korean Name': '최병섭',
        'K Stage Name': '은찬',
        'Date of Birth': '27/02/2001',
        Group: 'TEMPEST',
        Debut: '2/03/2022',
        Company: 'Yuehua',
        Country: 'South Korea',
        'Second Country': '',
        Height: 184,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Eunha',
        'Full Name': 'Jung Eunbi',
        'Korean Name': '정은비',
        'K Stage Name': '은하',
        'Date of Birth': '30/05/1997',
        Group: 'VIVIZ',
        Debut: '9/02/2022',
        Company: 'Big Planet Made',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'Gfriend',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eunho',
        'Full Name': 'Shin Donggeun',
        'Korean Name': '신동근',
        'K Stage Name': '은호',
        'Date of Birth': '10/09/1992',
        Group: 'UNVS',
        Debut: '23/02/2020',
        Company: 'CHITWN',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Eunho',
        'Full Name': 'Myung Eunho',
        'Korean Name': '명은호',
        'K Stage Name': '은호',
        'Date of Birth': '25/03/2001',
        Group: 'YOUNITE',
        Debut: '20/04/2022',
        Company: 'Brand New',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Eunhyuk',
        'Full Name': 'Lee Hyukjae',
        'Korean Name': '이혁재',
        'K Stage Name': '은혁',
        'Date of Birth': '4/04/1986',
        Group: 'Super Junior',
        Debut: '6/11/2005',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 58,
        Birthplace: 'Ilsan',
        'Other Group': 'Super Junior-M',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Eunice',
        'Full Name': 'Heo Sooyeon',
        'Korean Name': '허수연',
        'K Stage Name': '유니스',
        'Date of Birth': '2/09/1991',
        Group: 'DIA',
        Debut: '14/07/2015',
        Company: 'MBK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 49,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eunil',
        'Full Name': 'Kim Eunil',
        'Korean Name': '김은일',
        'K Stage Name': '은일',
        'Date of Birth': '21/11/2003',
        Group: 'TRENDZ',
        Debut: '5/01/2022',
        Company: 'Interpark',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Eunjae',
        'Full Name': 'Kwak Eunjae',
        'Korean Name': '곽은재',
        'K Stage Name': '은재',
        'Date of Birth': '2/05/1996',
        Group: '14U',
        Debut: '17/04/2017',
        Company: 'BG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Eunji',
        'Full Name': 'Jung Eunji',
        'Korean Name': '정은지',
        'K Stage Name': '은지',
        'Date of Birth': '18/08/1993',
        Group: 'Apink',
        Debut: '19/04/2011',
        Company: 'Plan A',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eunji',
        'Full Name': 'Hong Eunji',
        'Korean Name': '홍은지',
        'K Stage Name': '은지',
        'Date of Birth': '19/07/1992',
        Group: 'Brave Girls',
        Debut: '8/04/2011',
        Company: 'Brave',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eunjin',
        'Full Name': 'Ahn Eunjin',
        'Korean Name': '안은진',
        'K Stage Name': '은진',
        'Date of Birth': '31/08/1997',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 49,
        Birthplace: 'Mokpo',
        'Other Group': '',
        'Former Group': 'DIA',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eunjin',
        'Full Name': 'Moon Eunjin',
        'Korean Name': '문은진',
        'K Stage Name': '은진',
        'Date of Birth': '5/11/1998',
        Group: 'Highteen',
        Debut: '14/10/2016',
        Company: 'Elijah',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eunjin',
        'Full Name': 'Ma Eunjin',
        'Korean Name': '마은진',
        'K Stage Name': '은진',
        'Date of Birth': '23/05/1997',
        Group: 'Playback',
        Debut: '25/06/2015',
        Company: 'Coridel',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 49,
        Birthplace: 'Wonju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eunjo',
        'Full Name': 'Park Eunjo',
        'Korean Name': '박은조',
        'K Stage Name': '은조',
        'Date of Birth': '7/03/2002',
        Group: 'Dreamnote',
        Debut: '7/11/2018',
        Company: 'iME',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 46,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eunjun',
        'Full Name': 'Choi Eunjun',
        'Korean Name': '최은준',
        'K Stage Name': '은준',
        'Date of Birth': '6/08/1999',
        Group: 'AIMERS',
        Debut: '17/11/2022',
        Company: 'Hyper Rhythm',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Spectrum',
        Gender: 'M'
    },
    {
        'Stage Name': 'Eunjung',
        'Full Name': 'Ham Eunjung',
        'Korean Name': '함은정',
        'K Stage Name': '은정',
        'Date of Birth': '12/12/1988',
        Group: 'T-ara',
        Debut: '29/07/2009',
        Company: 'MBK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 47,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eunki',
        'Full Name': 'Hong Eunki',
        'Korean Name': '홍은기',
        'K Stage Name': '은기',
        'Date of Birth': '29/09/1997',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 182,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'RAINZ',
        Gender: 'M'
    },
    {
        'Stage Name': 'Eunkwang',
        'Full Name': 'Seo Eunkwang',
        'Korean Name': '서은광',
        'K Stage Name': '은광',
        'Date of Birth': '22/11/1990',
        Group: 'BtoB',
        Debut: '21/03/2012',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: 62,
        Birthplace: 'Yongin',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Eunsang',
        'Full Name': 'Lee Eunsang',
        'Korean Name': '이은상',
        'K Stage Name': '은상',
        'Date of Birth': '26/10/2002',
        Group: 'YOUNITE',
        Debut: '20/04/2022',
        Company: 'Brand New',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 58,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'X1',
        Gender: 'M'
    },
    {
        'Stage Name': 'Eunseo',
        'Full Name': 'Son Juyeon',
        'Korean Name': '손주연',
        'K Stage Name': '은서',
        'Date of Birth': '27/05/1998',
        Group: 'WJSN',
        Debut: '25/02/2016',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': 'WJSN The Black',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eunsung',
        'Full Name': 'Lee Eunsung',
        'Korean Name': '이은성',
        'K Stage Name': '은성',
        'Date of Birth': '7/09/2000',
        Group: 'TheEastLight',
        Debut: '15/11/2018',
        Company: 'Stardium',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Eunwoo',
        'Full Name': 'Cha Eunwoo',
        'Korean Name': '차은우',
        'K Stage Name': '은우',
        'Date of Birth': '30/03/1997',
        Group: 'ASTRO',
        Debut: '23/02/2016',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gunpo',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Eunwoo',
        'Full Name': 'Jeong Eunwoo',
        'Korean Name': '정은우',
        'K Stage Name': '은우',
        'Date of Birth': '1/07/1998',
        Group: 'Hinapia',
        Debut: '3/11/2019',
        Company: 'Alseulbit',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 48,
        Birthplace: '',
        'Other Group': 'Pristin V',
        'Former Group': 'Pristin',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eunyoung',
        'Full Name': 'Joo Eunyoung',
        'Korean Name': '주은영',
        'K Stage Name': '은영',
        'Date of Birth': '12/06/1992',
        Group: 'Two X',
        Debut: '20/08/2012',
        Company: 'J. Tune',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Exy',
        'Full Name': 'Chu Sojung',
        'Korean Name': '추소정',
        'K Stage Name': '엑시',
        'Date of Birth': '6/11/1995',
        Group: 'WJSN',
        Debut: '25/02/2016',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': 'WJSN The Black',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Eyedi',
        'Full Name': 'Nam Yujin',
        'Korean Name': '남유진',
        'K Stage Name': '아이디',
        'Date of Birth': '8/12/1995',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'E-Young',
        'Full Name': 'Noh Yiyoung',
        'Korean Name': '노이영',
        'K Stage Name': '이영',
        'Date of Birth': '16/08/1992',
        Group: 'After School',
        Debut: '15/01/2009',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Chuncheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Fatou',
        'Full Name': 'Fatou Samba',
        'Korean Name': '파투 삼바',
        'K Stage Name': '파투',
        'Date of Birth': '23/03/1995',
        Group: 'Blackswan',
        Debut: '16/10/2020',
        Company: 'DR',
        Country: 'Belgium',
        'Second Country': 'Senegal',
        Height: 173,
        Weight: '',
        Birthplace: 'Yoff',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Fei',
        'Full Name': 'Wang FeiFei',
        'Korean Name': '왕페이페이',
        'K Stage Name': '페이',
        'Date of Birth': '27/04/1987',
        Group: 'miss A',
        Debut: '1/07/2010',
        Company: 'JYP',
        Country: 'China',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Haikou',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Felix',
        'Full Name': 'Lee Youngbok',
        'Korean Name': '이용복',
        'K Stage Name': '필릭스',
        'Date of Birth': '15/09/2000',
        Group: 'Stray Kids',
        Debut: '25/03/2018',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Sydney',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'G.NA',
        'Full Name': 'Gina Jane Choi',
        'Korean Name': '최지나',
        'K Stage Name': '지나',
        'Date of Birth': '13/09/1987',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'Canada',
        'Second Country': 'South Korea',
        Height: 167,
        Weight: '',
        Birthplace: 'Edmonton',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'G.O',
        'Full Name': 'Jung Byunghee',
        'Korean Name': '정병희',
        'K Stage Name': '지오',
        'Date of Birth': '6/11/1987',
        Group: 'MBLAQ',
        Debut: '14/10/2009',
        Company: 'J. Tune',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Changwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Gabin',
        'Full Name': 'Kim Gabin',
        'Korean Name': '김가빈',
        'K Stage Name': '가빈',
        'Date of Birth': '18/09/1989',
        Group: 'Blady',
        Debut: '16/05/2011',
        Company: 'Star Planet',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gabin',
        'Full Name': 'Yoo Seunghyun',
        'Korean Name': '유승현',
        'K Stage Name': '가빈',
        'Date of Birth': '11/09/2001',
        Group: 'JWiiver',
        Debut: '17/02/2022',
        Company: 'JTG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Gaeul',
        'Full Name': 'Park Gaeul',
        'Korean Name': '박가을',
        'K Stage Name': '가을',
        'Date of Birth': '8/05/1999',
        Group: 'Favorite',
        Debut: '5/07/2017',
        Company: 'Astory',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 48,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gaeul',
        'Full Name': 'Kim Gaeul',
        'Korean Name': '김가을',
        'K Stage Name': '가을',
        'Date of Birth': '24/09/2002',
        Group: 'IVE',
        Debut: '1/12/2021',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 46,
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gaeun',
        'Full Name': 'Cho Kaeun',
        'Korean Name': '조가은',
        'K Stage Name': '가은',
        'Date of Birth': '28/07/1992',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Changwon',
        'Other Group': '',
        'Former Group': 'Dal Shabet',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gaeun',
        'Full Name': 'Lee Gaeun',
        'Korean Name': '이가은',
        'K Stage Name': '가은',
        'Date of Birth': '18/07/2000',
        Group: 'ARTBEAT',
        Debut: '16/11/2022',
        Company: 'AB Creative',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gaeun',
        'Full Name': 'Oh Gaeun',
        'Korean Name': '오가은',
        'K Stage Name': '가은',
        'Date of Birth': '8/11/2005',
        Group: 'LIMELIGHT',
        Debut: '17/02/2023',
        Company: 143,
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gaga',
        'Full Name': 'Li Jia Jia',
        'Korean Name': '리 지아 지아',
        'K Stage Name': '가가',
        'Date of Birth': '1/09/1999',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'China',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'NATURE',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gaga',
        'Full Name': 'Lee Soobin',
        'Korean Name': '이수빈',
        'K Stage Name': '가가',
        'Date of Birth': '10/11/1994',
        Group: 'Gate9',
        Debut: '26/01/1999',
        Company: 'JYP| SidusHQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gahyeon',
        'Full Name': 'Lee Gahyeon',
        'Korean Name': '이가현',
        'K Stage Name': '가현',
        'Date of Birth': '3/02/1999',
        Group: 'Dreamcatcher',
        Debut: '13/01/2017',
        Company: 'Happy Face',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seongnam',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gain',
        'Full Name': 'Son Gain',
        'Korean Name': '손가인',
        'K Stage Name': '가인',
        'Date of Birth': '20/09/1987',
        Group: 'Brown Eyed Girls',
        Debut: '2/03/2006',
        Company: 'Mystic',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gaon',
        'Full Name': 'Beom Gaon',
        'Korean Name': '범가온',
        'K Stage Name': '가온',
        'Date of Birth': '9/03/2001',
        Group: 'Bonus Baby',
        Debut: '1/01/2017',
        Company: 'Maroo',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Garam',
        'Full Name': 'Kim Garam',
        'Korean Name': '김가람',
        'K Stage Name': '가람',
        'Date of Birth': '16/11/2005',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'LE SSERAFIM',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gayoon',
        'Full Name': 'Heo Gayoon',
        'Korean Name': '허가윤',
        'K Stage Name': '가윤',
        'Date of Birth': '18/05/1990',
        Group: '4minute',
        Debut: '15/06/2009',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '2YOON',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gayoung',
        'Full Name': 'Kim Gayoung',
        'Korean Name': '김가영',
        'K Stage Name': '가영',
        'Date of Birth': '2/12/1991',
        Group: 'Stellar',
        Debut: '28/08/2011',
        Company: 'Pascal',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'G-Dragon',
        'Full Name': 'Kwon Jiyong',
        'Korean Name': '권지용',
        'K Stage Name': '지드래곤',
        'Date of Birth': '18/08/1988',
        Group: 'BIGBANG',
        Debut: '19/08/2006',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 58,
        Birthplace: 'Seoul',
        'Other Group': 'GD&TOP',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Genie',
        'Full Name': 'Cho Heejin',
        'Korean Name': '조희진',
        'K Stage Name': '지니',
        'Date of Birth': '19/03/2002',
        Group: 'TRACER',
        Debut: '3/04/2022',
        Company: 'Gleamedia',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Geonu',
        'Full Name': 'Lee Geonwoo',
        'Korean Name': '이건우',
        'K Stage Name': '건우',
        'Date of Birth': '2/02/2001',
        Group: 'JUST B',
        Debut: '30/06/2021',
        Company: 'Bluedot',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Geumhee',
        'Full Name': 'Han Geumhee',
        'Korean Name': '한금희',
        'K Stage Name': '금희',
        'Date of Birth': '4/03/2005',
        Group: 'CSR',
        Debut: '27/07/2022',
        Company: 'A2Z',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Geummi',
        'Full Name': 'Baek Boram',
        'Korean Name': '백보람',
        'K Stage Name': '금미',
        'Date of Birth': '18/06/1988',
        Group: 'Crayon Pop',
        Debut: '18/07/2012',
        Company: 'Chrome',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gihwan',
        'Full Name': 'Kim Gihwan',
        'Korean Name': '김기환',
        'K Stage Name': '기환',
        'Date of Birth': '24/11/1993',
        Group: 'SIGNAL',
        Debut: '28/08/2019',
        Company: 'J-Star',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Gijung',
        'Full Name': 'Kim Gijung',
        'Korean Name': '김기중',
        'K Stage Name': '기중',
        'Date of Birth': '24/01/2001',
        Group: 'IM',
        Debut: '4/04/2022',
        Company: 'FC ENM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Gikwang',
        'Full Name': 'Lee Gikwang',
        'Korean Name': '이기광',
        'K Stage Name': '기광',
        'Date of Birth': '30/03/1990',
        Group: 'Highlight',
        Debut: '14/10/2009',
        Company: 'Around Us',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: 58,
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': 'Beast',
        Gender: 'M'
    },
    {
        'Stage Name': 'Giru',
        'Full Name': 'Park Giru',
        'Korean Name': '박기루',
        'K Stage Name': '기루',
        'Date of Birth': '14/08/1991',
        Group: 'Blady',
        Debut: '16/05/2011',
        Company: 'Star Planet',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Giselle',
        'Full Name': 'Uchinaga Aeri',
        'Korean Name': '우치나가 애리',
        'K Stage Name': '지젤',
        'Date of Birth': '30/10/2000',
        Group: 'aespa',
        Debut: '17/11/2020',
        Company: 'SM',
        Country: 'Japan',
        'Second Country': '',
        Height: 166,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F',
        'Image URL': '/kpop/aespa/giselle/1.jpg'
    },
    {
        'Stage Name': 'Giseok',
        'Full Name': 'Jun Giseok',
        'Korean Name': '정기석',
        'K Stage Name': '기석',
        'Date of Birth': '2/05/1997',
        Group: 'IM',
        Debut: '4/04/2022',
        Company: 'FC ENM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'GK',
        'Full Name': 'Kim Kwanghyun',
        'Korean Name': '김광현',
        'K Stage Name': '지케이',
        'Date of Birth': '29/09/1998',
        Group: 'DKB',
        Debut: '3/02/2020',
        Company: 'Brave',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 60,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Go_U',
        'Full Name': 'Jang Junho',
        'Korean Name': '고유',
        'K Stage Name': '장준호',
        'Date of Birth': '15/06/2001',
        Group: 'BLITZERS',
        Debut: '12/05/2021',
        Company: 'Wuzo',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Goeun',
        'Full Name': 'Na Goeun',
        'Korean Name': '나고은',
        'K Stage Name': '고은',
        'Date of Birth': '3/09/1999',
        Group: 'Purple Kiss',
        Debut: '15/03/2021',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 44,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gohn',
        'Full Name': 'Kim Dongsung',
        'Korean Name': '김동성',
        'K Stage Name': '곤',
        'Date of Birth': '1/08/1992',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'Topp Dogg',
        Gender: 'M'
    },
    {
        'Stage Name': 'Gohyeon',
        'Full Name': 'Go Hyeon',
        'Korean Name': '고현',
        'K Stage Name': '고현',
        'Date of Birth': '1/10/1994',
        Group: '14U',
        Debut: '17/04/2017',
        Company: 'BG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Gon',
        'Full Name': 'Kim Seongjung',
        'Korean Name': '김성중',
        'K Stage Name': '곤',
        'Date of Birth': '13/11/1998',
        Group: 'ARGON',
        Debut: '11/03/2019',
        Company: 'MSH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Gongchan',
        'Full Name': 'Gong Chansik',
        'Korean Name': '공찬식',
        'K Stage Name': '공찬',
        'Date of Birth': '14/08/1993',
        Group: 'B1A4',
        Debut: '19/04/2010',
        Company: 'WM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 181,
        Weight: 60,
        Birthplace: 'Suncheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Gowon',
        'Full Name': 'Park Chaewon',
        'Korean Name': '박채원',
        'K Stage Name': '고원',
        'Date of Birth': '19/11/2000',
        Group: 'LOONA',
        Debut: '20/08/2018',
        Company: 'Blockberry',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'Loona yyxy',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gowoon',
        'Full Name': 'Moon Yujeong',
        'Korean Name': '문유정',
        'K Stage Name': '고운',
        'Date of Birth': '18/12/1998',
        Group: 'Berry Good',
        Debut: '21/05/2014',
        Company: 'Asia Bridge',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gracie',
        'Full Name': 'Kim Gaeun',
        'Korean Name': '김가운',
        'K Stage Name': '그레이시',
        'Date of Birth': '28/10/1996',
        Group: 'H.U.B',
        Debut: '9/02/2017',
        Company: 'New Planet',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gun',
        'Full Name': 'Kwak Gun',
        'Korean Name': '곽건',
        'K Stage Name': '건',
        'Date of Birth': '29/09/2001',
        Group: '14U',
        Debut: '17/04/2017',
        Company: 'BG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Gunmin',
        'Full Name': 'Lee Gunmin',
        'Korean Name': '이건민',
        'K Stage Name': '건민',
        'Date of Birth': '3/10/1994',
        Group: 'B.I.G',
        Debut: '9/07/2014',
        Company: 'GH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 64,
        Birthplace: 'Gangwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Gunwoo',
        'Full Name': 'Lee Gunwoo',
        'Korean Name': '이건우',
        'K Stage Name': '건우',
        'Date of Birth': '30/01/1989',
        Group: 'MYNAME',
        Debut: '27/10/2011',
        Company: 'H2',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daejeon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Gunwoo',
        'Full Name': 'Kim Gunwoo',
        'Korean Name': '김건우',
        'K Stage Name': '건우',
        'Date of Birth': '28/05/2002',
        Group: 'T1419',
        Debut: '21/09/2007',
        Company: 'CJ E&M',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Gyehyeon',
        'Full Name': 'Jo Gyehyeon',
        'Korean Name': '조계현',
        'K Stage Name': '계현',
        'Date of Birth': '14/05/1999',
        Group: 'Verivery',
        Debut: '1/09/2019',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Gyeongtae',
        'Full Name': 'Kwon Gyeongtae',
        'Korean Name': '권경태',
        'K Stage Name': '경태',
        'Date of Birth': '23/07/2000',
        Group: '14U',
        Debut: '17/04/2017',
        Company: 'BG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Gyujin',
        'Full Name': 'Han Gyujin',
        'Korean Name': '한규진',
        'K Stage Name': '규진',
        'Date of Birth': '21/11/1997',
        Group: 'UP10TION',
        Debut: '10/09/2015',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Icheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Gyuri',
        'Full Name': 'Jang Gyuri',
        'Korean Name': '장규리',
        'K Stage Name': '규리',
        'Date of Birth': '27/12/1997',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'fromis_9',
        Gender: 'F'
    },
    {
        'Stage Name': 'Gyuri',
        'Full Name': 'Park Gyuri',
        'Korean Name': '박규리',
        'K Stage Name': '규리',
        'Date of Birth': '21/05/1988',
        Group: 'KARA',
        Debut: '29/03/2007',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Habin',
        'Full Name': 'Yoo Habin',
        'Korean Name': '유하빈',
        'K Stage Name': '하빈',
        'Date of Birth': '10/03/2002',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 48,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Dreamnote',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haebin',
        'Full Name': 'Han Haebin',
        'Korean Name': '한해빈',
        'K Stage Name': '해빈',
        'Date of Birth': '16/08/1995',
        Group: 'Gugudan',
        Debut: '28/06/2016',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haechan',
        'Full Name': 'Lee Donghyuk',
        'Korean Name': '이동혁',
        'K Stage Name': '해찬',
        'Date of Birth': '6/06/2000',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'NCT 127| NCT Dream',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Haein',
        'Full Name': 'Yeom Haein',
        'Korean Name': '염해인',
        'K Stage Name': '해인',
        'Date of Birth': '19/05/1995',
        Group: 'LABOUM',
        Debut: '27/08/2014',
        Company: 'NH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: 45,
        Birthplace: 'Ojeonggu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haemin',
        'Full Name': 'Jung Haemin',
        'Korean Name': '정해민',
        'K Stage Name': '해민',
        'Date of Birth': '16/03/2004',
        Group: '8TURN',
        Debut: '30/01/2023',
        Company: 'MNH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: 63,
        Birthplace: 'Cheonan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Haena',
        'Full Name': 'Jeon Eunbi',
        'Korean Name': '전은비',
        'K Stage Name': '해나',
        'Date of Birth': '19/02/2002',
        Group: 'ANS',
        Debut: '16/09/2019',
        Company: 'ANS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haena',
        'Full Name': 'Lee Haena',
        'Korean Name': '이해나',
        'K Stage Name': '해나',
        'Date of Birth': '2/06/1991',
        Group: 'Matilda',
        Debut: '18/03/2016',
        Company: 'Box',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: 165,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haeri',
        'Full Name': 'Lee Haeri',
        'Korean Name': '이해리',
        'K Stage Name': '해리',
        'Date of Birth': '14/02/1985',
        Group: 'Davichi',
        Debut: '4/02/2008',
        Company: 'CJ E&M',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haeri',
        'Full Name': 'Jung Haeri',
        'Korean Name': '정해리',
        'K Stage Name': '해리',
        'Date of Birth': '14/01/1997',
        Group: 'P.O.P',
        Debut: '26/07/2017',
        Company: 'DWM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haerin',
        'Full Name': 'Kang Haerin',
        'Korean Name': '강해린',
        'K Stage Name': '해린',
        'Date of Birth': '15/05/2006',
        Group: 'NewJeans',
        Debut: '22/07/2022',
        Company: 'ADOR',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Gimcheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F',
        'Image URL': '/kpop/newjeans/haerin/198916_Ogjf.png'
    },
    {
        'Stage Name': 'Haeryeong',
        'Full Name': 'Na Haeryeong',
        'Korean Name': '나해령',
        'K Stage Name': '해령',
        'Date of Birth': '11/11/1994',
        Group: 'BESTie',
        Debut: '11/07/2013',
        Company: 'YNB',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haeun',
        'Full Name': 'Yoon Haeun',
        'Korean Name': '윤하은',
        'K Stage Name': '하은',
        'Date of Birth': '12/03/1999',
        Group: '3YE',
        Debut: '21/05/2019',
        Company: 'GH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Apple.B',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haeun',
        'Full Name': 'Lee Haeun',
        'Korean Name': '윤하은',
        'K Stage Name': '하은',
        'Date of Birth': '25/06/2002',
        Group: 'ARTBEAT',
        Debut: '16/11/2022',
        Company: 'AB Creative',
        Country: 'South Korea',
        'Second Country': '',
        Height: 157,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haeun',
        'Full Name': 'Lim Haeun',
        'Korean Name': '임하은',
        'K Stage Name': '하은',
        'Date of Birth': '2/11/2008',
        Group: 'Lapillus',
        Debut: '22/06/2022',
        Company: 'MLD',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haewon',
        'Full Name': 'Oh Haewon',
        'Korean Name': '오해원',
        'K Stage Name': '해원',
        'Date of Birth': '25/02/2003',
        Group: 'NMIXX',
        Debut: '22/02/2022',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haeyeon',
        'Full Name': 'Lee Taeyeon',
        'Korean Name': '이태연',
        'K Stage Name': '해연',
        'Date of Birth': '11/01/1994',
        Group: 'Gate9',
        Debut: '26/01/1999',
        Company: 'JYP| SidusHQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haeyoon',
        'Full Name': 'Park Haeyoon',
        'Korean Name': '박해윤',
        'K Stage Name': '해윤',
        'Date of Birth': '10/01/1996',
        Group: 'Cherry Bullet',
        Debut: '21/01/2019',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: 157,
        Weight: 43,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hajeong',
        'Full Name': 'Lee Hajeong',
        'Korean Name': '이하정',
        'K Stage Name': '하정',
        'Date of Birth': '23/09/1997',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'NeonPunch',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hakmin',
        'Full Name': 'Lee Hakmin',
        'Korean Name': '이학민',
        'K Stage Name': '학민',
        'Date of Birth': '20/09/2000',
        Group: 'TRCNG',
        Debut: '10/10/2017',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hamin',
        'Full Name': 'Jo Hamin',
        'Korean Name': '조하민',
        'K Stage Name': '하민',
        'Date of Birth': '24/10/1997',
        Group: 'ENOi',
        Debut: '19/04/2019',
        Company: 'KITHEWHALE',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: 65,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Han',
        'Full Name': 'Han Jisung',
        'Korean Name': '한지성',
        'K Stage Name': '한',
        'Date of Birth': '14/09/2000',
        Group: 'Stray Kids',
        Debut: '25/03/2018',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '3RACHA',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Han Geng',
        'Full Name': 'Han Geng',
        'Korean Name': '한경',
        'K Stage Name': '한경',
        'Date of Birth': '9/02/1984',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'China',
        'Second Country': '',
        Height: 181,
        Weight: 66,
        Birthplace: 'Mudanjiang',
        'Other Group': '',
        'Former Group': 'Super Junior',
        Gender: 'M'
    },
    {
        'Stage Name': 'Han Hyeri',
        'Full Name': 'Han Hyeri',
        'Korean Name': '한혜리',
        'K Stage Name': '한혜리',
        'Date of Birth': '24/08/1997',
        Group: 'I.B.I',
        Debut: '29/10/2014',
        Company: 'Star Crew',
        Country: 'South Korea',
        'Second Country': '',
        Height: 159,
        Weight: 42,
        Birthplace: 'Gwangmyeong',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Han Leeseul',
        'Full Name': 'Han Jiyoon',
        'Korean Name': '한지윤',
        'K Stage Name': '한이슬',
        'Date of Birth': '12/02/2000',
        Group: 'Rockit Girl',
        Debut: '30/05/2019',
        Company: 'Rolling',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hana',
        'Full Name': 'Shin Bora',
        'Korean Name': '신보라',
        'K Stage Name': '하나',
        'Date of Birth': '30/04/1993',
        Group: 'Gugudan',
        Debut: '28/06/2016',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hana',
        'Full Name': 'Hayase Hana',
        'Korean Name': '하야세 하나',
        'K Stage Name': '하나',
        'Date of Birth': '27/02/2000',
        Group: 'ILY:1',
        Debut: '4/04/2022',
        Company: 'FC ENM',
        Country: 'Japan',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hana',
        'Full Name': 'Jung Hana',
        'Korean Name': '정하나',
        'K Stage Name': '하나',
        'Date of Birth': '2/02/1990',
        Group: 'SECRET',
        Debut: '13/10/2009',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Uljeongbu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hanbin',
        'Full Name': 'Ngo Ngoc Hung',
        'Korean Name': '응오 응옥 흥',
        'K Stage Name': '한빈',
        'Date of Birth': '19/01/1998',
        Group: 'TEMPEST',
        Debut: '2/03/2022',
        Company: 'Yuehua',
        Country: 'Vietnam',
        'Second Country': '',
        Height: 176,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hanbyeol',
        'Full Name': 'Ahn Hanbyeol',
        'Korean Name': '안한별',
        'K Stage Name': '한별',
        'Date of Birth': '13/10/2003',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 44,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Dreamnote',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hanbyul',
        'Full Name': 'Kyung Hanbyul',
        'Korean Name': '경한별',
        'K Stage Name': '한별',
        'Date of Birth': '1/07/2005',
        Group: 'PRECIOUS',
        Debut: '23/09/2020',
        Company: 'Umi',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 49,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Handong',
        'Full Name': 'Han Dong',
        'Korean Name': '한동',
        'K Stage Name': '한동',
        'Date of Birth': '26/03/1996',
        Group: 'Dreamcatcher',
        Debut: '13/01/2017',
        Company: 'Happy Face',
        Country: 'China',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Wuhan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haneul',
        'Full Name': 'Lee Haneul',
        'Korean Name': '이하늘',
        'K Stage Name': '하늘',
        'Date of Birth': '3/06/1996',
        Group: 'ARGON',
        Debut: '11/03/2019',
        Company: 'MSH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Haneul',
        'Full Name': 'Choi Haneul',
        'Korean Name': '최하늘',
        'K Stage Name': '하늘',
        'Date of Birth': '28/11/2000',
        Group: 'Saturday',
        Debut: '18/07/2018',
        Company: 'SD',
        Country: 'South Korea',
        'Second Country': '',
        Height: 158,
        Weight: 43,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hangyeom',
        'Full Name': 'Song Hangyeom',
        'Korean Name': '송한겸',
        'K Stage Name': '한겸',
        'Date of Birth': '17/07/1996',
        Group: 'OMEGA X',
        Debut: '17/10/2016',
        Company: 'JT Corea',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': "7 O'clock",
        Gender: 'M'
    },
    {
        'Stage Name': 'Hangyul',
        'Full Name': 'Lee Hangyul',
        'Korean Name': '이한결',
        'K Stage Name': '한결',
        'Date of Birth': '7/12/1999',
        Group: 'BAE173',
        Debut: '19/11/2020',
        Company: 'PocketDol',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'X1| IM',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hani',
        'Full Name': 'Ahn Hee-yeon',
        'Korean Name': '안희연',
        'K Stage Name': '하니',
        'Date of Birth': '1/05/1992',
        Group: 'EXID',
        Debut: '16/02/2012',
        Company: 'Yedang',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hankook',
        'Full Name': 'Cho Hankook',
        'Korean Name': '조한국',
        'K Stage Name': '한국',
        'Date of Birth': '3/04/2002',
        Group: 'TRENDZ',
        Debut: '5/01/2022',
        Company: 'Interpark',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hanna',
        'Full Name': 'Shin Jiyeon',
        'Korean Name': '신지연',
        'K Stage Name': '한나',
        'Date of Birth': '19/02/1994',
        Group: 'MIXX',
        Debut: '2/05/2016',
        Company: 'Chiko',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 48,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hannah',
        'Full Name': 'Bae Hyunyoung',
        'Korean Name': '배현영',
        'K Stage Name': '해나',
        'Date of Birth': '23/10/1999',
        Group: 'Queenz Eye',
        Debut: '24/10/2022',
        Company: 'Big Mountain',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seongnam',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hanni',
        'Full Name': 'Pham Ngoc Han',
        'Korean Name': '팜응옥헌',
        'K Stage Name': '하니',
        'Date of Birth': '6/10/2004',
        Group: 'NewJeans',
        Debut: '22/07/2022',
        Company: 'ADOR',
        Country: 'Vietnam',
        'Second Country': '',
        Height: 162,
        Weight: '',
        Birthplace: 'Melbourne',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F',
        'Image URL': '/kpop/newjeans/hanni/286225_rpbv.png'
    },
    {
        'Stage Name': 'Hanse',
        'Full Name': 'Do Hanse',
        'Korean Name': '도한세',
        'K Stage Name': '한세',
        'Date of Birth': '25/09/1997',
        Group: 'VICTON',
        Debut: '9/11/2016',
        Company: 'Plan A',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hansol',
        'Full Name': 'Kim Hansol',
        'Korean Name': '김한솔',
        'K Stage Name': '한솔',
        'Date of Birth': '15/06/1993',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': 'Topp Dogg',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hanwoom',
        'Full Name': 'Byun Hanwoom',
        'Korean Name': '변한움',
        'K Stage Name': '한움',
        'Date of Birth': '5/06/2001',
        Group: 'PRECIOUS',
        Debut: '23/09/2020',
        Company: 'Umi',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 43,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hara†',
        'Full Name': 'Koo Hara',
        'Korean Name': '구하라',
        'K Stage Name': '하라',
        'Date of Birth': '13/01/1991',
        Group: 'KARA',
        Debut: '29/03/2007',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haram',
        'Full Name': 'Shin Haram',
        'Korean Name': '신하람',
        'K Stage Name': '하람',
        'Date of Birth': '17/10/2007',
        Group: 'BABYMONSTER',
        Debut: '0/01/1900',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haram',
        'Full Name': 'Kim Haram',
        'Korean Name': '김하람',
        'K Stage Name': '하람',
        'Date of Birth': '13/01/2001',
        Group: 'Billlie',
        Debut: '10/11/2021',
        Company: 'MYSTIC',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: 45,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hari',
        'Full Name': '',
        'Korean Name': '',
        'K Stage Name': '하리',
        'Date of Birth': '8/04/2005',
        Group: "Girls' World",
        Debut: '20/06/2002',
        Company: 'Liz',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Kagoshima',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Harin',
        'Full Name': 'Seo Harin',
        'Korean Name': '서하린',
        'K Stage Name': '하린',
        'Date of Birth': '7/12/2000',
        Group: 'BOTOPASS',
        Debut: '26/08/2020',
        Company: 'WKS ENE',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Harin',
        'Full Name': '',
        'Korean Name': '',
        'K Stage Name': '하린',
        'Date of Birth': '21/11/1995',
        Group: 'Lusty',
        Debut: '26/06/2019',
        Company: 'babaplay',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 43,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Harin',
        'Full Name': 'Ju Harin',
        'Korean Name': '주하린',
        'K Stage Name': '주하린',
        'Date of Birth': '29/03/1998',
        Group: 'Onewe',
        Debut: '9/01/2019',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'MAS',
        Gender: 'M'
    },
    {
        'Stage Name': 'Harin',
        'Full Name': 'Park Guenhye',
        'Korean Name': '박근혜',
        'K Stage Name': '하린',
        'Date of Birth': '26/05/2000',
        Group: 'Pink Fantasy',
        Debut: '28/10/2018',
        Company: 'My Doll',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 48,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Harry-June',
        'Full Name': 'Han Harry-June',
        'Korean Name': '한해리준',
        'K Stage Name': '해리준',
        'Date of Birth': '1/01/2004',
        Group: 'DKB',
        Debut: '3/02/2020',
        Company: 'Brave',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 61,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Haru',
        'Full Name': 'Han Sangsook',
        'Korean Name': '한상숙',
        'K Stage Name': '하루',
        'Date of Birth': '6/07/1994',
        Group: 'Gate9',
        Debut: '26/01/1999',
        Company: 'JYP| SidusHQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: '',
        Birthplace: 'Bucheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haru',
        'Full Name': 'Lee Yoojeong',
        'Korean Name': '이유정',
        'K Stage Name': '하루',
        'Date of Birth': '26/02/1997',
        Group: 'Ho1iday',
        Debut: '26/04/2013',
        Company: 'LOEN',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'MyB',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haru',
        'Full Name': 'Abe Haruno',
        'Korean Name': '아베 하루노',
        'K Stage Name': '하루',
        'Date of Birth': '21/02/2000',
        Group: 'NATURE',
        Debut: '3/08/2018',
        Company: 'n.CH',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haruna',
        'Full Name': 'Osato Haruna',
        'Korean Name': '오사토 하루나',
        'K Stage Name': '하루나',
        'Date of Birth': '30/01/2006',
        Group: 'Billlie',
        Debut: '10/11/2021',
        Company: 'MYSTIC',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Haruto',
        'Full Name': 'Watanabe Haruto',
        'Korean Name': '와타나베 하루토',
        'K Stage Name': '하루토',
        'Date of Birth': '5/04/2004',
        Group: 'Treasure',
        Debut: '7/08/2020',
        Company: 'YG',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Haseul',
        'Full Name': 'Jo Haseul',
        'Korean Name': '조하슬',
        'K Stage Name': '하슬',
        'Date of Birth': '18/08/1997',
        Group: 'LOONA',
        Debut: '20/08/2018',
        Company: 'Blockberry',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Ansan',
        'Other Group': 'Loona 1/3',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Havit',
        'Full Name': 'Lee Havit',
        'Korean Name': '이하빛',
        'K Stage Name': '하빛',
        'Date of Birth': '7/06/1999',
        Group: 'TRENDZ',
        Debut: '5/01/2022',
        Company: 'Interpark',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hayoon',
        'Full Name': 'Jung Hayoon',
        'Korean Name': '정하윤',
        'K Stage Name': '하윤',
        'Date of Birth': '21/11/1998',
        Group: 'Bonus Baby',
        Debut: '1/01/2017',
        Company: 'Maroo',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'MyB',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hayoung',
        'Full Name': 'Oh Hayoung',
        'Korean Name': '오하영',
        'K Stage Name': '하영',
        'Date of Birth': '19/07/1996',
        Group: 'Apink',
        Debut: '19/04/2011',
        Company: 'Plan A',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hayoung',
        'Full Name': 'Song Hayoung',
        'Korean Name': '송하영',
        'K Stage Name': '하영',
        'Date of Birth': '29/09/1997',
        Group: 'fromis_9',
        Debut: '24/01/2018',
        Company: 'Off The Record',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hayoung',
        'Full Name': 'Lee Hayoung',
        'Korean Name': '이하영',
        'K Stage Name': '하영',
        'Date of Birth': '3/08/1993',
        Group: 'Playback',
        Debut: '25/06/2015',
        Company: 'Coridel',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hayoung',
        'Full Name': 'Choi Hayoung',
        'Korean Name': '최하영',
        'K Stage Name': '하영',
        'Date of Birth': '22/08/2000',
        Group: 'TRCNG',
        Debut: '10/10/2017',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 181,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hayun',
        'Full Name': 'Lee Hayun',
        'Korean Name': '이하윤',
        'K Stage Name': '하윤',
        'Date of Birth': '29/08/1994',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Changwon',
        'Other Group': '',
        'Former Group': 'Brave Girls',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hayun',
        'Full Name': 'Choi Hayun',
        'Korean Name': '최하윤',
        'K Stage Name': '하윤',
        'Date of Birth': '16/01/2004',
        Group: 'Hi-L',
        Debut: '11/08/2021',
        Company: 'Kpop Live',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Heechan',
        'Full Name': 'Yang Heechan',
        'Korean Name': '양희찬',
        'K Stage Name': '희찬',
        'Date of Birth': '31/07/1999',
        Group: 'DKB',
        Debut: '3/02/2020',
        Company: 'Brave',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 63,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Heechul',
        'Full Name': 'Kim Heechul',
        'Korean Name': '김희철',
        'K Stage Name': '희철',
        'Date of Birth': '10/07/1983',
        Group: 'Super Junior',
        Debut: '6/11/2005',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 60,
        Birthplace: 'Hoengseong',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Heechul',
        'Full Name': 'Jung Heechul',
        'Korean Name': '정희철',
        'K Stage Name': '희철',
        'Date of Birth': '9/12/1989',
        Group: 'ZE:A',
        Debut: '7/01/2010',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Jeju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Heedo',
        'Full Name': 'Yoon Heedo',
        'Korean Name': '유희도',
        'K Stage Name': '희도',
        'Date of Birth': '22/04/1996',
        Group: 'B.I.G',
        Debut: '9/07/2014',
        Company: 'GH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 60,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Heejin',
        'Full Name': 'Song Heejin',
        'Korean Name': '송희진',
        'K Stage Name': '희진',
        'Date of Birth': '19/08/1995',
        Group: 'Good Day',
        Debut: '30/08/2017',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Heejin',
        'Full Name': 'Jeon Heejin',
        'Korean Name': '전희진',
        'K Stage Name': '희진',
        'Date of Birth': '19/10/2000',
        Group: 'LOONA',
        Debut: '20/08/2018',
        Company: 'Blockberry',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seongnam',
        'Other Group': 'Loona 1/3|ARTMS',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Heejoo',
        'Full Name': 'Jeon Heejoo',
        'Korean Name': '전희주',
        'K Stage Name': '희주',
        'Date of Birth': '30/11/1996',
        Group: 'Ho1iday',
        Debut: '26/04/2013',
        Company: 'LOEN',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'MyB',
        Gender: 'F'
    },
    {
        'Stage Name': 'Heejun',
        'Full Name': 'Oh Heejun',
        'Korean Name': '오희준',
        'K Stage Name': '희준',
        'Date of Birth': '8/05/1996',
        Group: 'KNK',
        Debut: '3/03/2016',
        Company: 'YNB',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 61,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Heeo',
        'Full Name': 'Gwak Heeoh',
        'Korean Name': '곽히오',
        'K Stage Name': '히오',
        'Date of Birth': '2/05/1994',
        Group: '4TEN',
        Debut: '26/08/2014',
        Company: 'Jungle',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Heeseok',
        'Full Name': 'Yun Heeseok',
        'Korean Name': '윤희석',
        'K Stage Name': '희석',
        'Date of Birth': '10/06/1997',
        Group: 'Limitless',
        Debut: '9/07/2019',
        Company: 'ONO',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 61,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Heeseung',
        'Full Name': 'Lee Heeseung',
        'Korean Name': '이희승',
        'K Stage Name': '희승',
        'Date of Birth': '15/10/2001',
        Group: 'ENHYPEN',
        Debut: '30/11/2020',
        Company: 'Be:lift',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Heesu',
        'Full Name': 'Kim Heesu',
        'Korean Name': '김희수',
        'K Stage Name': '희수',
        'Date of Birth': '24/03/1998',
        Group: 'Maka Maka',
        Debut: '4/08/2020',
        Company: 'Dreamus',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Heesun',
        'Full Name': 'Park Heesun',
        'Korean Name': '박희선',
        'K Stage Name': '희선',
        'Date of Birth': '25/01/2005',
        Group: 'Pink Fantasy',
        Debut: '28/10/2018',
        Company: 'My Doll',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: 45,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Heeyu',
        'Full Name': 'Choi Heejae',
        'Korean Name': '최희재',
        'K Stage Name': '희유',
        'Date of Birth': '30/06/1995',
        Group: 'MIXX',
        Debut: '2/05/2016',
        Company: 'Chiko',
        Country: 'South Korea',
        'Second Country': '',
        Height: 159,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Henny',
        'Full Name': 'Kang Gayoung',
        'Korean Name': '강가영',
        'K Stage Name': '헤니',
        'Date of Birth': '1/12/1994',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: 50,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'MIXX',
        Gender: 'F'
    },
    {
        'Stage Name': 'Henry',
        'Full Name': 'Henry Lau',
        'Korean Name': '헨리 라우',
        'K Stage Name': '헨리',
        'Date of Birth': '11/10/1989',
        Group: 'Super Junior-M',
        Debut: '6/11/2005',
        Company: 'SM',
        Country: 'Canada',
        'Second Country': 'Hong Kong| Taiwan',
        Height: 176,
        Weight: 63,
        Birthplace: 'Toronto',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Heo Chan',
        'Full Name': 'Heo Chan',
        'Korean Name': '허찬',
        'K Stage Name': '허찬',
        'Date of Birth': '14/12/1995',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'VICTON',
        Gender: 'M'
    },
    {
        'Stage Name': 'High.D',
        'Full Name': 'Kim Dohee',
        'Korean Name': '김도희',
        'K Stage Name': '하이디',
        'Date of Birth': '21/12/1996',
        Group: 'Sonamoo',
        Debut: '29/12/2014',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Suwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'HighTop',
        'Full Name': 'Lim Hyeontae',
        'Korean Name': '임현태',
        'K Stage Name': '하이탑',
        'Date of Birth': '19/03/1994',
        Group: 'Bigflo',
        Debut: '19/07/2014',
        Company: 'HO',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 60,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hikaru',
        'Full Name': 'Ezaki Hikaru',
        'Korean Name': '에자키 히카루',
        'K Stage Name': '히카루',
        'Date of Birth': '12/03/2004',
        Group: 'Kep1er',
        Debut: '1/03/2022',
        Company: 'WakeOne',
        Country: 'Japan',
        'Second Country': '',
        Height: 155,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Himchan',
        'Full Name': 'Kim Himchan',
        'Korean Name': '김힘찬',
        'K Stage Name': '힘찬',
        'Date of Birth': '19/04/1990',
        Group: 'B.A.P',
        Debut: '27/01/2012',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 69,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hina',
        'Full Name': 'Nagai Hina',
        'Korean Name': '나가이 히나',
        'K Stage Name': '히나',
        'Date of Birth': '7/04/2003',
        Group: 'LIGHTSUM',
        Debut: '10/06/2021',
        Company: 'Cube',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Kanagawa',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hitomi',
        'Full Name': 'Honda Hitomi',
        'Korean Name': '혼다 히토미',
        'K Stage Name': '히토미',
        'Date of Birth': '6/10/2001',
        Group: 'IZ*ONE',
        Debut: '29/10/2018',
        Company: 'Off The Record',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Toghichi',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hohyeon',
        'Full Name': 'Lee Hohyeon',
        'Korean Name': '이호현',
        'K Stage Name': '호현',
        'Date of Birth': '14/10/2001',
        Group: 'TRCNG',
        Debut: '10/10/2017',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hojoon',
        'Full Name': 'Jeon Hojoon',
        'Korean Name': '전호준',
        'K Stage Name': '호준',
        'Date of Birth': '31/10/1992',
        Group: 'Topp Dogg',
        Debut: '1/04/2020',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hojung',
        'Full Name': 'Go Hojung',
        'Korean Name': '고호정',
        'K Stage Name': '호정',
        'Date of Birth': '20/10/1994',
        Group: 'HOTSHOT',
        Debut: '29/10/2014',
        Company: 'Star Crew',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hongbin',
        'Full Name': 'Lee Hongbin',
        'Korean Name': '이홍빈',
        'K Stage Name': '홍빈',
        'Date of Birth': '29/09/1993',
        Group: 'VIXX',
        Debut: '24/05/2012',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: 181,
        Weight: 62,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Honggi',
        'Full Name': 'Lee Honggi',
        'Korean Name': '이홍기',
        'K Stage Name': '홍기',
        'Date of Birth': '2/03/1990',
        Group: 'FTISLAND',
        Debut: '7/06/2007',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hongil',
        'Full Name': 'Yoon Hongil',
        'Korean Name': '윤홍일',
        'K Stage Name': '홍일',
        'Date of Birth': '11/01/1999',
        Group: 'Astin',
        Debut: '16/11/2022',
        Company: 'AB Creative',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hongjoong',
        'Full Name': 'Kim Hongjoong',
        'Korean Name': '김홍중',
        'K Stage Name': '홍중',
        'Date of Birth': '7/11/1998',
        Group: 'ATEEZ',
        Debut: '24/10/2018',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hongseob',
        'Full Name': 'Shim Hongseob',
        'Korean Name': '심홍섭',
        'K Stage Name': '홍섭',
        'Date of Birth': '8/01/1998',
        Group: '24K',
        Debut: '6/09/2012',
        Company: 'Choeun',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 58,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hongseok',
        'Full Name': 'Yang Hongseok',
        'Korean Name': '양홍석',
        'K Stage Name': '홍석',
        'Date of Birth': '17/04/1994',
        Group: 'PENTAGON',
        Debut: '10/10/2016',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hoon',
        'Full Name': 'Yeo Hoonmin',
        'Korean Name': '여훈민',
        'K Stage Name': '훈',
        'Date of Birth': '16/08/1991',
        Group: 'U-KISS',
        Debut: '28/08/2008',
        Company: 'NH Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Namyangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hoony',
        'Full Name': 'Lee Seunghoon',
        'Korean Name': '이승훈',
        'K Stage Name': '승훈',
        'Date of Birth': '11/01/1992',
        Group: 'WINNER',
        Debut: '12/08/2014',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hoshi',
        'Full Name': 'Kwon Soonyoung',
        'Korean Name': '권순영',
        'K Stage Name': '호시',
        'Date of Birth': '15/06/1996',
        Group: 'Seventeen',
        Debut: '26/05/2015',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 63,
        Birthplace: 'Namyangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hoya',
        'Full Name': 'Lee Howon',
        'Korean Name': '이호원',
        'K Stage Name': '호야',
        'Date of Birth': '28/03/1991',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 60,
        Birthplace: 'Busan',
        'Other Group': 'Infinite H',
        'Former Group': 'Infinite',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hoyoung',
        'Full Name': 'Bae Hoyoung',
        'Korean Name': '배호영',
        'K Stage Name': '호영',
        'Date of Birth': '10/08/1998',
        Group: 'Verivery',
        Debut: '1/09/2019',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hueningkai',
        'Full Name': 'Kai Kamal Huening',
        'Korean Name': '카이 카말 휴닝',
        'K Stage Name': '휴닝카이',
        'Date of Birth': '14/08/2002',
        Group: 'TXT',
        Debut: '4/03/2019',
        Company: 'Big Hit',
        Country: 'USA',
        'Second Country': 'South Korea',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hui',
        'Full Name': 'Liang Hui',
        'Korean Name': '양휘',
        'K Stage Name': '양휘',
        'Date of Birth': '18/07/1995',
        Group: '24K',
        Debut: '6/09/2012',
        Company: 'Choeun',
        Country: 'China',
        'Second Country': '',
        Height: 178,
        Weight: 58,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hui',
        'Full Name': 'Lee Hwitaek',
        'Korean Name': '이회택',
        'K Stage Name': '후이',
        'Date of Birth': '28/08/1993',
        Group: 'PENTAGON',
        Debut: '10/10/2016',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Huihyeon',
        'Full Name': 'Ki Huihyeon',
        'Korean Name': '기희현',
        'K Stage Name': '희현',
        'Date of Birth': '16/06/1995',
        Group: 'DIA',
        Debut: '14/07/2015',
        Company: 'MBK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 46,
        Birthplace: 'Namwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Huijun',
        'Full Name': 'Noh Huijun',
        'Korean Name': '노휘준',
        'K Stage Name': '휘준',
        'Date of Birth': '7/10/2003',
        Group: 'MCND',
        Debut: '27/02/2020',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Huiyeon',
        'Full Name': 'Oh Huiyeon',
        'Korean Name': '오휘연',
        'K Stage Name': '휘연',
        'Date of Birth': '1/08/2005',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'LIGHTSUM',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hunter',
        'Full Name': 'Papungkorn Lertkiatdamrong',
        'Korean Name': '빠팡콘 륵키앗둠롱',
        'K Stage Name': '헌터',
        'Date of Birth': '5/10/2005',
        Group: 'xikers',
        Debut: '30/03/2023',
        Company: 'KQ',
        Country: 'Thailand',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hwall',
        'Full Name': 'Heo Hyunjoon',
        'Korean Name': '허현준',
        'K Stage Name': '활',
        'Date of Birth': '9/03/2000',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 56,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': 'The Boyz',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hwanhee',
        'Full Name': 'Lee Hwanhee',
        'Korean Name': '이환희',
        'K Stage Name': '환희',
        'Date of Birth': '6/05/1998',
        Group: 'UP10TION',
        Debut: '10/09/2015',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daejeon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hwani',
        'Full Name': 'Jo Sihyeon',
        'Korean Name': '조시환',
        'K Stage Name': '화니',
        'Date of Birth': '16/11/1994',
        Group: 'DUSTIN',
        Debut: '6/01/2020',
        Company: 'LPA',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hwanwoong',
        'Full Name': 'Yeo Hwanwoong',
        'Korean Name': '여환웅',
        'K Stage Name': '환웅',
        'Date of Birth': '26/08/1998',
        Group: 'Oneus',
        Debut: '9/01/2019',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hwarang',
        'Full Name': 'Park Jonghan',
        'Korean Name': '박종찬',
        'K Stage Name': '종찬',
        'Date of Birth': '5/12/1995',
        Group: 'Spectrum',
        Debut: '9/05/2018',
        Company: 'WYNN',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hwarang',
        'Full Name': 'Son Jaewon',
        'Korean Name': '송재원',
        'K Stage Name': '화랑',
        'Date of Birth': '23/04/2001',
        Group: 'TEMPEST',
        Debut: '2/03/2022',
        Company: 'Yuehua',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 61,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hwasa',
        'Full Name': 'Ahn Hyejin',
        'Korean Name': '안혜진',
        'K Stage Name': '화사',
        'Date of Birth': '23/07/1995',
        Group: 'Mamamoo',
        Debut: '18/06/2014',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: 44,
        Birthplace: 'Jeonju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hweseung',
        'Full Name': 'Yoo Hweseung',
        'Korean Name': '유회승',
        'K Stage Name': '회승',
        'Date of Birth': '28/02/1995',
        Group: 'N.Flying',
        Debut: '20/05/2015',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hwi',
        'Full Name': 'Lee Choonghyun',
        'Korean Name': '이충현',
        'K Stage Name': '휘',
        'Date of Birth': '4/02/1999',
        Group: 'Ciipher',
        Debut: '15/03/2021',
        Company: 'Rain',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: 61,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hwi',
        'Full Name': 'Jo Mingyu',
        'Korean Name': '조민규',
        'K Stage Name': '휘',
        'Date of Birth': '26/09/2002',
        Group: 'Newkidd',
        Debut: '25/04/2019',
        Company: 'J-FLO',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hwi',
        'Full Name': 'Eun Hwi',
        'Korean Name': '은휘',
        'K Stage Name': '휘',
        'Date of Birth': '11/11/2004',
        Group: 'TNX',
        Debut: '17/05/2022',
        Company: 'P NATION',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gangwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hwichan',
        'Full Name': 'Lee Heechan',
        'Korean Name': '이휘찬',
        'K Stage Name': '휘찬',
        'Date of Birth': '18/04/1996',
        Group: 'Limitless',
        Debut: '9/07/2019',
        Company: 'ONO',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'OMEGA X',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hwiseo',
        'Full Name': 'Jo Hwihyeon',
        'Korean Name': '조휘현',
        'K Stage Name': '화서',
        'Date of Birth': '31/07/2002',
        Group: 'H1-KEY',
        Debut: '5/01/2022',
        Company: 'GLG',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hwiyoung',
        'Full Name': 'Kim Youngkyun',
        'Korean Name': '김영균',
        'K Stage Name': '휘영',
        'Date of Birth': '11/05/1999',
        Group: 'SF9',
        Debut: '5/10/2016',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyangsuk',
        'Full Name': 'Lee Hyangsuk',
        'Korean Name': '이향숙',
        'K Stage Name': '향숙',
        'Date of Birth': '24/04/1991',
        Group: '2EYES',
        Debut: '20/07/2013',
        Company: 'SidusHQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyebin',
        'Full Name': 'Kim Hyebin',
        'Korean Name': '김혜빈',
        'K Stage Name': '혜빈',
        'Date of Birth': '29/03/1997',
        Group: 'AREAL',
        Debut: '21/08/2020',
        Company: 'JZ Factory',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Rose Finger',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyebin',
        'Full Name': 'Im Hyebin',
        'Korean Name': '임혜빈',
        'K Stage Name': '혜빈',
        'Date of Birth': '10/11/1999',
        Group: 'Highteen',
        Debut: '14/10/2016',
        Company: 'Elijah',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyebin',
        'Full Name': 'Lee Hyebin',
        'Korean Name': '이혜빈',
        'K Stage Name': '혜빈',
        'Date of Birth': '12/01/1996',
        Group: 'MOMOLAND',
        Debut: '9/11/2016',
        Company: 'Double Kick',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 44,
        Birthplace: 'Andong',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyein',
        'Full Name': 'Lee Hyein',
        'Korean Name': '이혜인',
        'K Stage Name': '혜인',
        'Date of Birth': '21/04/2008',
        Group: 'NewJeans',
        Debut: '22/07/2022',
        Company: 'ADOR',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F',
        'Image URL': '/kpop/newjeans/hyein/newjeans-hyein-2.webp'
    },
    {
        'Stage Name': 'Hyejeong',
        'Full Name': 'Shin Hyejeong',
        'Korean Name': '신혜정',
        'K Stage Name': '혜정',
        'Date of Birth': '10/08/1993',
        Group: 'AoA',
        Debut: '30/07/2012',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 48,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyeji',
        'Full Name': 'Jeong Hyeji',
        'Korean Name': '정혜지',
        'K Stage Name': '혜지',
        'Date of Birth': '2/04/1992',
        Group: '4TEN',
        Debut: '26/08/2014',
        Company: 'Jungle',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyejin',
        'Full Name': 'Baek Hyejin',
        'Korean Name': '백혜진',
        'K Stage Name': '혜진',
        'Date of Birth': '21/11/1996',
        Group: '4TEN',
        Debut: '26/08/2014',
        Company: 'Jungle',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Sacheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyejin',
        'Full Name': 'Kang Hyejin',
        'Korean Name': '강혜진',
        'K Stage Name': '혜진',
        'Date of Birth': '13/07/2000',
        Group: 'CRAXY',
        Debut: '3/03/2020',
        Company: 'SAITEINMENT',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyejoo',
        'Full Name': 'Park Hyunjoo',
        'Korean Name': '박현주',
        'K Stage Name': '혜주',
        'Date of Birth': '12/01/1999',
        Group: 'Highteen',
        Debut: '14/10/2016',
        Company: 'Elijah',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyeju',
        'Full Name': 'Hong Hyeju',
        'Korean Name': '홍혜주',
        'K Stage Name': '혜주',
        'Date of Birth': '9/12/2003',
        Group: 'CLASS:y',
        Debut: '5/05/2022',
        Company: 'Universal',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyemi',
        'Full Name': 'Pyo Hyemi',
        'Korean Name': '표혜미',
        'K Stage Name': '혜미',
        'Date of Birth': '3/04/1991',
        Group: '9Muses',
        Debut: '12/08/2010',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: 46,
        Birthplace: 'Gwangju',
        'Other Group': '9MUSES A',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyemi',
        'Full Name': 'Kim Hyemi',
        'Korean Name': '김혜미',
        'K Stage Name': '혜미',
        'Date of Birth': '10/08/1990',
        Group: 'Fiestar',
        Debut: '31/08/2012',
        Company: 'LOEN',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyeoeun',
        'Full Name': 'Lee Hyoeun',
        'Korean Name': '이효은',
        'K Stage Name': '효은',
        'Date of Birth': '16/03/1993',
        Group: 'Stellar',
        Debut: '28/08/2011',
        Company: 'Pascal',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyeongjun',
        'Full Name': 'Song Hyeongjun',
        'Korean Name': '송형준',
        'K Stage Name': '형준',
        'Date of Birth': '30/11/2002',
        Group: 'CRAVITY',
        Debut: '14/04/2020',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 174,
        Weight: 55,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'X1',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyeongseok',
        'Full Name': 'Chu Hyeongseok',
        'Korean Name': '추형석',
        'K Stage Name': '형석',
        'Date of Birth': '13/09/2000',
        Group: 'Astin',
        Debut: '16/11/2022',
        Company: 'AB Creative',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyeongshin',
        'Full Name': 'Kim Hyungshin',
        'Korean Name': '김형신',
        'K Stage Name': '형신',
        'Date of Birth': '3/05/2002',
        Group: 'HOT ISSUE',
        Debut: '17/05/2017',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyeran',
        'Full Name': 'Noh Hyeran',
        'Korean Name': '노혜란',
        'K Stage Name': '혜란',
        'Date of Birth': '9/04/1993',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Brave Girls',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyeri',
        'Full Name': 'Lee Hyeri',
        'Korean Name': '이혜리',
        'K Stage Name': '혜리',
        'Date of Birth': '9/06/1994',
        Group: "Girl's Day",
        Debut: '9/07/2010',
        Company: 'DreamT',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 46,
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyerim',
        'Full Name': 'Woo Hyerim',
        'Korean Name': '우혜림',
        'K Stage Name': '혜림',
        'Date of Birth': '1/09/1992',
        Group: 'Wonder Girls',
        Debut: '13/02/2007',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyerin',
        'Full Name': 'Kim Hyerin',
        'Korean Name': '김혜린',
        'K Stage Name': '혜린',
        'Date of Birth': '13/06/1993',
        Group: '2EYES',
        Debut: '20/07/2013',
        Company: 'SidusHQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyerin',
        'Full Name': 'Seo Hyerin',
        'Korean Name': '서혜린',
        'K Stage Name': '혜린',
        'Date of Birth': '23/08/1993',
        Group: 'EXID',
        Debut: '16/02/2012',
        Company: 'Yedang',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyerin',
        'Full Name': 'Jeong Hyerin',
        'Korean Name': '정혜린',
        'K Stage Name': '혜린',
        'Date of Birth': '12/04/2007',
        Group: 'tripleS',
        Debut: '13/02/2023',
        Company: 'MODHAUS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyewon',
        'Full Name': 'Kang Hyewon',
        'Korean Name': '강혜원',
        'K Stage Name': '혜원',
        'Date of Birth': '5/07/1999',
        Group: 'IZ*ONE',
        Debut: '29/10/2018',
        Company: 'Off The Record',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yangsan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyeyeon',
        'Full Name': 'Cho Hyeyeon',
        'Korean Name': '조혜연',
        'K Stage Name': '혜연',
        'Date of Birth': '5/08/2000',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'Gugudan Oguogu',
        'Former Group': 'Gugudan',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyeyeon',
        'Full Name': 'Kang Hyeyeon',
        'Korean Name': '강혜연',
        'K Stage Name': '혜연',
        'Date of Birth': '8/12/1990',
        Group: 'BESTie',
        Debut: '11/07/2013',
        Company: 'YNB',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'EXID',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyogyeong',
        'Full Name': 'Jang Hyogyeong',
        'Korean Name': '장효경',
        'K Stage Name': '효경',
        'Date of Birth': '15/11/1999',
        Group: 'ARIAZ',
        Debut: '23/10/2019',
        Company: 'Rising Star',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyojin',
        'Full Name': 'Kim Hyojin',
        'Korean Name': '김효진',
        'K Stage Name': '효진',
        'Date of Birth': '22/04/1994',
        Group: 'ONF',
        Debut: '2/08/2017',
        Company: 'WM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyojung',
        'Full Name': 'Choi Hyojung',
        'Korean Name': '최효정',
        'K Stage Name': '효정',
        'Date of Birth': '28/07/1994',
        Group: 'Oh My Girl',
        Debut: '21/04/2015',
        Company: 'WM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 46,
        Birthplace: 'Yangyang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyomin',
        'Full Name': 'Park Sunyoung',
        'Korean Name': '박선영',
        'K Stage Name': '효민',
        'Date of Birth': '30/05/1989',
        Group: 'T-ara',
        Debut: '29/07/2009',
        Company: 'MBK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 43,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyori',
        'Full Name': 'Inn Hyori',
        'Korean Name': '인효리',
        'K Stage Name': '효리',
        'Date of Birth': '2/06/2000',
        Group: 'mimiirose',
        Debut: '16/09/2022',
        Company: 'YES IM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyorin',
        'Full Name': 'Kim Hyojung',
        'Korean Name': '김효정',
        'K Stage Name': '효린',
        'Date of Birth': '11/12/1990',
        Group: 'SISTAR',
        Debut: '6/03/2010',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'SISTAR19',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyosun',
        'Full Name': 'Lim Hyosun',
        'Korean Name': '임효선',
        'K Stage Name': '효선',
        'Date of Birth': '10/07/1998',
        Group: 'H.U.B',
        Debut: '9/02/2017',
        Company: 'New Planet',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 54,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Kiwi Band',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyosung',
        'Full Name': 'Jun Hyoseong',
        'Korean Name': '전효성',
        'K Stage Name': '효성',
        'Date of Birth': '13/10/1989',
        Group: 'SECRET',
        Debut: '13/10/2009',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Cheongju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyoyeon',
        'Full Name': 'Kim Hyoyeon',
        'Korean Name': '김효연',
        'K Stage Name': '효연',
        'Date of Birth': '22/09/1989',
        Group: 'SNSD',
        Debut: '4/08/2021',
        Company: 'Good Luck',
        Country: 'South Korea',
        'Second Country': '',
        Height: 158,
        Weight: 48,
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyuk',
        'Full Name': 'Yang Hyuk',
        'Korean Name': '양혁',
        'K Stage Name': '혁',
        'Date of Birth': '15/03/2000',
        Group: 'OMEGA X',
        Debut: '17/10/2016',
        Company: 'JT Corea',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: 67,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'ENOi',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyuk',
        'Full Name': 'Koo Bonhyuk',
        'Korean Name': '구본혁',
        'K Stage Name': '혁',
        'Date of Birth': '17/04/2000',
        Group: 'TEMPEST',
        Debut: '2/03/2022',
        Company: 'Yuehua',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyuk',
        'Full Name': 'Han Sanghyuk',
        'Korean Name': '한상혁',
        'K Stage Name': '혁',
        'Date of Birth': '5/07/1995',
        Group: 'VIXX',
        Debut: '24/05/2012',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: 181,
        Weight: 62,
        Birthplace: 'Daejeon',
        'Other Group': 'Big Byung',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyukjin',
        'Full Name': 'Jang Hyukjin',
        'Korean Name': '장혁진',
        'K Stage Name': '혁진',
        'Date of Birth': '20/12/1993',
        Group: '100%',
        Debut: '18/09/2012',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyun',
        'Full Name': 'Park Sunghyun',
        'Korean Name': '박성현',
        'K Stage Name': '성현',
        'Date of Birth': '25/08/1997',
        Group: "7 O'clock",
        Debut: '26/08/2014',
        Company: 'Jungle',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 58,
        Birthplace: 'Suwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyuna',
        'Full Name': 'Moon Hyuna',
        'Korean Name': '문현아',
        'K Stage Name': '현아',
        'Date of Birth': '19/01/1987',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: 50,
        Birthplace: 'Yeosu',
        'Other Group': '',
        'Former Group': '9Muses',
        Gender: 'F'
    },
    {
        'Stage Name': 'HyunA',
        'Full Name': 'Kim Hyuna',
        'Korean Name': '김현아',
        'K Stage Name': '현아',
        'Date of Birth': '6/06/1992',
        Group: '4minute',
        Debut: '15/06/2009',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: '',
        Birthplace: 'Jeolla',
        'Other Group': '',
        'Former Group': 'Wonder Girls',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyunbin',
        'Full Name': 'Moon Hyunbin',
        'Korean Name': '문현빈',
        'K Stage Name': '현빈',
        'Date of Birth': '26/02/2000',
        Group: 'Ciipher',
        Debut: '15/03/2021',
        Company: 'Rain',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 61,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunbin',
        'Full Name': 'Kwon Hyunbin',
        'Korean Name': '권현빈',
        'K Stage Name': '현빈',
        'Date of Birth': '4/03/1997',
        Group: 'JBJ',
        Debut: '18/10/2017',
        Company: 'Fave',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunbin',
        'Full Name': 'Kim Hyunbin',
        'Korean Name': '김현빈‬',
        'K Stage Name': '현빈‬',
        'Date of Birth': '26/05/2004',
        Group: 'TRI.BE',
        Debut: '17/02/2021',
        Company: 'TR - Mellow',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyungseo',
        'Full Name': 'Myeong Hyeongseo',
        'Korean Name': '명형서',
        'K Stage Name': '형서',
        'Date of Birth': '25/06/2001',
        Group: 'CLASS:y',
        Debut: '5/05/2022',
        Company: 'Universal',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 44,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Busters',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyungseok',
        'Full Name': 'Song Hyungseok',
        'Korean Name': '송형석',
        'K Stage Name': '형석',
        'Date of Birth': '6/11/2002',
        Group: 'YOUNITE',
        Debut: '20/04/2022',
        Company: 'Brand New',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyungseop',
        'Full Name': 'Ahn Hyungseop',
        'Korean Name': '안형섭',
        'K Stage Name': '형섭',
        'Date of Birth': '9/08/1999',
        Group: 'TEMPEST',
        Debut: '2/03/2022',
        Company: 'Yuehua',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: 58,
        Birthplace: '',
        'Other Group': 'HyeongseopXEuiwoong',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyungsik',
        'Full Name': 'Park Hyungsik',
        'Korean Name': '박형식',
        'K Stage Name': '형식',
        'Date of Birth': '16/11/1991',
        Group: 'ZE:A',
        Debut: '7/01/2010',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yongin',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyungwon',
        'Full Name': 'Chae Hyungwon',
        'Korean Name': '채형원',
        'K Stage Name': '형원',
        'Date of Birth': '15/01/1994',
        Group: 'MONSTA X',
        Debut: '14/05/2014',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunho',
        'Full Name': 'Lee Hyunho',
        'Korean Name': '이현호',
        'K Stage Name': '현호',
        'Date of Birth': '4/11/1998',
        Group: 'D-CRUNCH',
        Debut: '6/08/2018',
        Company: 'All-S',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunjae',
        'Full Name': 'Lee Jaehyun',
        'Korean Name': '이재현',
        'K Stage Name': '현재',
        'Date of Birth': '13/09/1997',
        Group: 'The Boyz',
        Debut: '31/12/2017',
        Company: 'Cre.Ker',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 62,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunji',
        'Full Name': 'Kim Hyunji',
        'Korean Name': '김현지',
        'K Stage Name': '현지',
        'Date of Birth': '30/06/1992',
        Group: 'Hash Tag',
        Debut: '11/10/2017',
        Company: 'LUK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyunjin',
        'Full Name': 'Kim Hyunjin',
        'Korean Name': '김현진',
        'K Stage Name': '현진',
        'Date of Birth': '15/11/2000',
        Group: 'LOONA',
        Debut: '20/08/2018',
        Company: 'Blockberry',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Jeonju',
        'Other Group': 'Loona 1/3',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyunjin',
        'Full Name': 'Hwang Hyunjin',
        'Korean Name': '황현진',
        'K Stage Name': '현진',
        'Date of Birth': '20/03/2000',
        Group: 'Stray Kids',
        Debut: '25/03/2018',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunjoo',
        'Full Name': 'Lee Hyunjoo',
        'Korean Name': '이현주',
        'K Stage Name': '현주',
        'Date of Birth': '5/02/1998',
        Group: 'UNI.T',
        Debut: '18/05/2018',
        Company: 'Unit Culture',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'APRIL',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyunjun',
        'Full Name': 'Bae Hyunjun',
        'Korean Name': '배현준',
        'K Stage Name': '현준',
        'Date of Birth': '6/06/2003',
        Group: 'ATBO',
        Debut: '27/07/2022',
        Company: 'IST',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunjun',
        'Full Name': 'Lee Hyunjun',
        'Korean Name': '이현준',
        'K Stage Name': '현준',
        'Date of Birth': '21/12/1999',
        Group: 'IZ',
        Debut: '31/08/2017',
        Company: 'Music K',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunkyung',
        'Full Name': 'Kim Hyunjong',
        'Korean Name': '김현종',
        'K Stage Name': '현경',
        'Date of Birth': '5/09/1998',
        Group: 'Romeo',
        Debut: '4/11/2015',
        Company: 'CT',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunmin',
        'Full Name': 'Byun Hyunmin',
        'Korean Name': '변현민',
        'K Stage Name': '현민',
        'Date of Birth': '17/04/1999',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'RAINZ',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunoh',
        'Full Name': 'Park Hyunoh',
        'Korean Name': '백현우',
        'K Stage Name': '현우',
        'Date of Birth': '12/02/1999',
        Group: 'D-CRUNCH',
        Debut: '6/08/2018',
        Company: 'All-S',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunseo',
        'Full Name': 'Go Hyunseo',
        'Korean Name': '고현서',
        'K Stage Name': '현서',
        'Date of Birth': '17/06/1999',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'G-reyish',
        Gender: 'F'
    },
    {
        'Stage Name': 'Hyunseong',
        'Full Name': 'Shim Hyunseong',
        'Korean Name': '심현성',
        'K Stage Name': '현성',
        'Date of Birth': '9/06/1993',
        Group: 'Boyfriend',
        Debut: '26/05/2011',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 58,
        Birthplace: 'Yeongcheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunseung',
        'Full Name': 'Jang Hyunseung',
        'Korean Name': '장현승',
        'K Stage Name': '현승',
        'Date of Birth': '3/09/1989',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 58,
        Birthplace: 'Suncheon',
        'Other Group': 'Troublemaker',
        'Former Group': 'Beast',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunseung',
        'Full Name': 'Kim Hyunseung',
        'Korean Name': '김현승',
        'K Stage Name': '현승',
        'Date of Birth': '15/10/2002',
        Group: 'YOUNITE',
        Debut: '20/04/2022',
        Company: 'Brand New',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunsik',
        'Full Name': 'Lim Hyunsik',
        'Korean Name': '임현식',
        'K Stage Name': '현식',
        'Date of Birth': '7/03/1992',
        Group: 'BtoB',
        Debut: '21/03/2012',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 66,
        Birthplace: 'Ilsan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunsoo',
        'Full Name': 'Kim Hyunsoo',
        'Korean Name': '김현수',
        'K Stage Name': '현수',
        'Date of Birth': '12/04/1995',
        Group: 'D1CE',
        Debut: '1/08/2019',
        Company: 'Happyface',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 60,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunsoo',
        'Full Name': 'Jang Hyunsoo',
        'Korean Name': '장현수',
        'K Stage Name': '현수',
        'Date of Birth': '16/09/2003',
        Group: 'TNX',
        Debut: '17/05/2022',
        Company: 'P NATION',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Sangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunsuk',
        'Full Name': 'Yoon Hyunsuk',
        'Korean Name': '윤현석',
        'K Stage Name': '현석',
        'Date of Birth': '8/09/2001',
        Group: 'CIX',
        Debut: '23/07/2019',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: 188,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunsuk',
        'Full Name': 'Choi Hyunsuk',
        'Korean Name': '최현석',
        'K Stage Name': '현석',
        'Date of Birth': '21/04/1999',
        Group: 'Treasure',
        Debut: '7/08/2020',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunuk',
        'Full Name': 'Han Hyunuk',
        'Korean Name': '한현욱',
        'K Stage Name': '현욱',
        'Date of Birth': '26/09/1994',
        Group: 'IN2IT',
        Debut: '27/01/2016',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 63,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunwoo',
        'Full Name': 'Baek Hyunwoo',
        'Korean Name': '박현오',
        'K Stage Name': '현오',
        'Date of Birth': '19/03/1999',
        Group: 'D-CRUNCH',
        Debut: '6/08/2018',
        Company: 'All-S',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunwoo',
        'Full Name': 'Kim Hyunwoo',
        'Korean Name': '김현우',
        'K Stage Name': '현우',
        'Date of Birth': '21/01/2001',
        Group: 'TRCNG',
        Debut: '10/10/2017',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunwoo',
        'Full Name': 'Choi Hyunwoo',
        'Korean Name': '최현우',
        'K Stage Name': '현우',
        'Date of Birth': '4/12/2004',
        Group: 'xikers',
        Debut: '30/03/2023',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gangwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunwook',
        'Full Name': 'Ji Hyunwook',
        'Korean Name': '지현욱',
        'K Stage Name': '현욱',
        'Date of Birth': '1/07/1998',
        Group: 'D-CRUNCH',
        Debut: '6/08/2018',
        Company: 'All-S',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunwoong',
        'Full Name': 'Kim Hyunwoong',
        'Korean Name': '김현웅',
        'K Stage Name': '현웅',
        'Date of Birth': '8/02/1998',
        Group: '14U',
        Debut: '17/04/2017',
        Company: 'BG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Hyunyoung',
        'Full Name': 'Cho Hyunyoung',
        'Korean Name': '조현영',
        'K Stage Name': '현영',
        'Date of Birth': '11/08/1991',
        Group: 'Rainbow',
        Debut: '12/11/2009',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 41,
        Birthplace: 'Seoul',
        'Other Group': 'Rainbow Blaxx| Rainbow Pixie',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'I',
        'Full Name': 'Cha Yoonji',
        'Korean Name': '차윤지',
        'K Stage Name': '아이',
        'Date of Birth': '2/12/1996',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'I.L',
        'Full Name': 'Kim Jiwon',
        'Korean Name': '김지원',
        'K Stage Name': '아이엘',
        'Date of Birth': '1/07/1997',
        Group: 'IRRIS',
        Debut: '6/07/2022',
        Company: 'Mellow',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': 'Good Day| RedSquare',
        Gender: 'F'
    },
    {
        'Stage Name': 'I.M',
        'Full Name': 'Lim Changkyun',
        'Korean Name': '임창균',
        'K Stage Name': '아이엠',
        'Date of Birth': '26/01/1996',
        Group: 'MONSTA X',
        Debut: '14/05/2014',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'I.N',
        'Full Name': 'Yang Jeongin',
        'Korean Name': '양정인',
        'K Stage Name': '아이엔',
        'Date of Birth': '8/02/2001',
        Group: 'Stray Kids',
        Debut: '25/03/2018',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Iaan',
        'Full Name': 'Yoo Dongju',
        'Korean Name': '유동주',
        'K Stage Name': '이안',
        'Date of Birth': '22/03/2002',
        Group: 'XUM',
        Debut: '22/09/2020',
        Company: 'A100',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'NeonPunch',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ida',
        'Full Name': 'Ko Chaeyoung',
        'Korean Name': '고채영',
        'K Stage Name': '아이다',
        'Date of Birth': '19/05/2000',
        Group: 'MAJORS',
        Debut: '9/03/2021',
        Company: 'ANS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: 43,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ilhoon',
        'Full Name': 'Jung Ilhoon',
        'Korean Name': '정일훈',
        'K Stage Name': '일훈',
        'Date of Birth': '4/10/1994',
        Group: 'BtoB',
        Debut: '21/03/2012',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 64,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Inho',
        'Full Name': 'Hwang Inho',
        'Korean Name': '황인호',
        'K Stage Name': '인호',
        'Date of Birth': '21/06/1993',
        Group: 'IN2IT',
        Debut: '27/01/2016',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 58,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Inpyo',
        'Full Name': 'Lee Inpyo',
        'Korean Name': '이인표',
        'K Stage Name': '인표',
        'Date of Birth': '14/08/1995',
        Group: 'IN2IT',
        Debut: '27/01/2016',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: 182,
        Weight: 66,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Inseong',
        'Full Name': 'Jeong Inseong',
        'Korean Name': '정인성',
        'K Stage Name': '인성',
        'Date of Birth': '1/07/1994',
        Group: 'KNK',
        Debut: '3/03/2016',
        Company: 'YNB',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: 71,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Inseong',
        'Full Name': 'Kim Inseong',
        'Korean Name': '김인성',
        'K Stage Name': '인성',
        'Date of Birth': '12/07/1993',
        Group: 'SF9',
        Debut: '5/10/2016',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Insoo',
        'Full Name': 'Kang Insoo',
        'Korean Name': '강인수',
        'K Stage Name': '인수',
        'Date of Birth': '10/03/1988',
        Group: 'MYNAME',
        Debut: '27/10/2011',
        Company: 'H2',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Suwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Intak',
        'Full Name': 'Hwang Intak',
        'Korean Name': '황인탁',
        'K Stage Name': '인탁',
        'Date of Birth': '31/08/2003',
        Group: 'P1Harmony',
        Debut: '28/10/2020',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ireh',
        'Full Name': 'Cho Seoyoung',
        'Korean Name': '조서영',
        'K Stage Name': '이레',
        'Date of Birth': '30/04/2002',
        Group: 'Purple Kiss',
        Debut: '15/03/2021',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Irene',
        'Full Name': 'Bae Juhyun',
        'Korean Name': '배주현',
        'K Stage Name': '아이린',
        'Date of Birth': '29/03/1991',
        Group: 'Red Velvet',
        Debut: '1/08/2014',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': 'Red Velvet - IRENE SEULGI',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Isa',
        'Full Name': 'Lee Chaeyoung',
        'Korean Name': '이채영',
        'K Stage Name': '아이사',
        'Date of Birth': '23/01/2002',
        Group: 'STAYC',
        Debut: '12/11/2020',
        Company: 'Hi-Up',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 45,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Isaac',
        'Full Name': 'Voo Isaak',
        'Korean Name': '부 아이젝',
        'K Stage Name': '아이젝',
        'Date of Birth': '12/12/1994',
        Group: 'IN2IT',
        Debut: '27/01/2016',
        Company: 'Star Empire',
        Country: 'Malaysia',
        'Second Country': '',
        Height: 176,
        Weight: 56,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Isol',
        'Full Name': '',
        'Korean Name': '',
        'K Stage Name': '이솔',
        'Date of Birth': '1/05/1997',
        Group: 'Lusty',
        Debut: '26/06/2019',
        Company: 'babaplay',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 48,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'IU',
        'Full Name': 'Lee Jieun',
        'Korean Name': '이지은',
        'K Stage Name': '아이유',
        'Date of Birth': '16/05/1993',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ivan',
        'Full Name': 'Park Yooseong',
        'Korean Name': '박유성',
        'K Stage Name': '아이반',
        'Date of Birth': '12/10/2001',
        Group: 'Kingdom',
        Debut: '18/02/2021',
        Company: 'GF',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'J',
        'Full Name': 'Lee Yeji',
        'Korean Name': '이예지',
        'K Stage Name': '제이',
        'Date of Birth': '12/02/1998',
        Group: 'ANS',
        Debut: '16/09/2019',
        Company: 'ANS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'J',
        'Full Name': 'Jang Yeeun',
        'Korean Name': '장예은',
        'K Stage Name': '제이',
        'Date of Birth': '9/12/2004',
        Group: 'STAYC',
        Debut: '12/11/2020',
        Company: 'Hi-Up',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 48,
        Birthplace: 'Daejeon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'J.Min',
        'Full Name': 'Shin Minjung',
        'Korean Name': '신민정',
        'K Stage Name': '제이민',
        'Date of Birth': '20/11/1994',
        Group: 'Tiny-G',
        Debut: '23/08/2012',
        Company: 'GNG',
        Country: 'South Korea',
        'Second Country': '',
        Height: 155,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'J.seph',
        'Full Name': 'Kim Taehyung',
        'Korean Name': '김태형',
        'K Stage Name': '제이셉',
        'Date of Birth': '21/06/1992',
        Group: 'KARD',
        Debut: '29/03/2007',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': 'USA',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'J.YOU',
        'Full Name': 'Kim Jaeyu',
        'Korean Name': '김제유',
        'K Stage Name': '제유',
        'Date of Birth': '2/11/2000',
        Group: 'TOO',
        Debut: '1/04/2020',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jackie',
        'Full Name': 'Kang Chaeyeon',
        'Korean Name': '강채연',
        'K Stage Name': '재키',
        'Date of Birth': '17/11/2001',
        Group: "ICHILLIN'",
        Debut: '8/09/2021',
        Company: 'KM',
        Country: 'USA',
        'Second Country': 'South Korea',
        Height: '',
        Weight: '',
        Birthplace: 'Los Angeles',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jackson',
        'Full Name': 'Jackson Wang',
        'Korean Name': '잭슨 왕',
        'K Stage Name': '잭슨',
        'Date of Birth': '28/03/1994',
        Group: 'GOT7',
        Debut: '16/01/2014',
        Company: 'JYP',
        Country: 'Hong Kong',
        'Second Country': '',
        Height: 174,
        Weight: 63,
        Birthplace: 'Hong Kong',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jacob',
        'Full Name': 'Bae Joonyoung',
        'Korean Name': '배준영',
        'K Stage Name': '제이콥',
        'Date of Birth': '30/05/1997',
        Group: 'The Boyz',
        Debut: '31/12/2017',
        Company: 'Cre.Ker',
        Country: 'South Korea',
        'Second Country': 'Canada',
        Height: 175,
        Weight: 60,
        Birthplace: 'Toronto',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jacob',
        'Full Name': 'Zhang Peng',
        'Korean Name': '장펑',
        'K Stage Name': '제이콥',
        'Date of Birth': '7/09/1996',
        Group: 'VAV',
        Debut: '31/10/2015',
        Company: 'A team',
        Country: 'China',
        'Second Country': '',
        Height: 184,
        Weight: 63,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jae',
        'Full Name': 'Park Jaehyung',
        'Korean Name': '박제형',
        'K Stage Name': '제이',
        'Date of Birth': '15/09/1992',
        Group: 'DAY6',
        Debut: '7/09/2015',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': 'Argentina|USA',
        Height: '',
        Weight: '',
        Birthplace: 'Buenos Aires',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaebin',
        'Full Name': 'Deung Bin',
        'Korean Name': '등빈',
        'K Stage Name': '재빈',
        'Date of Birth': '13/02/1997',
        Group: 'VARSITY',
        Debut: '5/01/2017',
        Company: 'GKC',
        Country: 'China',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: 'Hunan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaechan',
        'Full Name': 'Park Jaechan',
        'Korean Name': '박재찬',
        'K Stage Name': '재찬',
        'Date of Birth': '6/12/2001',
        Group: 'Dongkiz',
        Debut: '24/04/2019',
        Company: 'Dongyo',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 58,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaehan',
        'Full Name': 'Kim Jaehan',
        'Korean Name': '김재한',
        'K Stage Name': '재한',
        'Date of Birth': '1/07/1995',
        Group: 'OMEGA X',
        Debut: '17/10/2016',
        Company: 'JT Corea',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Spectrum',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaehee',
        'Full Name': 'Lee Jaehee',
        'Korean Name': '이재희',
        'K Stage Name': '재희',
        'Date of Birth': '18/03/2004',
        Group: 'Weeekly',
        Debut: '30/07/2020',
        Company: 'Play M',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jaeho',
        'Full Name': 'Kim Jaeho',
        'Korean Name': '김재호',
        'K Stage Name': '재호',
        'Date of Birth': '17/09/1992',
        Group: 'HISTORY',
        Debut: '26/04/2013',
        Company: 'LOEN',
        Country: 'South Korea',
        'Second Country': '',
        Height: 174,
        Weight: 58,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaehwan',
        'Full Name': 'Kim Jaehwan',
        'Korean Name': '김재환',
        'K Stage Name': '재환',
        'Date of Birth': '27/05/1996',
        Group: 'Wanna One',
        Debut: '7/08/2017',
        Company: 'YMC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaehyo',
        'Full Name': 'Ahn Jaehyo',
        'Korean Name': '안재효',
        'K Stage Name': '재효',
        'Date of Birth': '23/12/1990',
        Group: 'Block B',
        Debut: '15/04/2011',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 182,
        Weight: 60,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaehyuk',
        'Full Name': 'Yoon Jaehyuk',
        'Korean Name': '윤재혁',
        'K Stage Name': '재혁',
        'Date of Birth': '23/07/2001',
        Group: 'Treasure',
        Debut: '7/08/2020',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaehyun',
        'Full Name': 'Bong Jaehyun',
        'Korean Name': '봉재현',
        'K Stage Name': '재현',
        'Date of Birth': '4/01/1999',
        Group: 'Golden Child',
        Debut: '28/08/2017',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaehyun',
        'Full Name': 'Kim Jaehyun',
        'Korean Name': '김재현',
        'K Stage Name': '재현',
        'Date of Birth': '15/07/1994',
        Group: 'N.Flying',
        Debut: '20/05/2015',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaehyun',
        'Full Name': 'Jung Yoonoh',
        'Korean Name': '정윤오',
        'K Stage Name': '재현',
        'Date of Birth': '14/02/1997',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': 'NCT U| NCT 127',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaejin',
        'Full Name': 'Lee Jaejin',
        'Korean Name': '이재진',
        'K Stage Name': '재진',
        'Date of Birth': '17/12/1991',
        Group: 'FTISLAND',
        Debut: '7/06/2007',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Cheongju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaejoong',
        'Full Name': 'Kim Jaejoong',
        'Korean Name': '김재중',
        'K Stage Name': '재중',
        'Date of Birth': '26/01/1986',
        Group: 'JYJ',
        Debut: '8/09/2010',
        Company: 'C-JeS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gongju',
        'Other Group': '',
        'Former Group': 'TVXQ',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaekyung',
        'Full Name': 'Kim Jaekyung',
        'Korean Name': '김재경',
        'K Stage Name': '재경',
        'Date of Birth': '24/12/1988',
        Group: 'Rainbow',
        Debut: '12/11/2009',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: 45,
        Birthplace: 'Seoul',
        'Other Group': 'Rainbow Blaxx',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jaemin',
        'Full Name': 'Na Jaemin',
        'Korean Name': '나재민',
        'K Stage Name': '재민',
        'Date of Birth': '13/08/2000',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'NCT Dream',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaemin',
        'Full Name': 'Shin Jaemin',
        'Korean Name': '신재민',
        'K Stage Name': '재민',
        'Date of Birth': '8/06/1996',
        Group: 'XEED',
        Debut: '1/12/2022',
        Company: 'Nature Space',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaeseok',
        'Full Name': 'Park Jaeseok',
        'Korean Name': '박재석',
        'K Stage Name': '재석',
        'Date of Birth': '20/11/1995',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Golden Child',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaeun',
        'Full Name': 'Ha Jaeun',
        'Korean Name': '한재운',
        'K Stage Name': '재운',
        'Date of Birth': '28/09/1999',
        Group: 'ARGON',
        Debut: '11/03/2019',
        Company: 'MSH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaeyoon',
        'Full Name': 'Lee Jaeyoon',
        'Korean Name': '이재윤',
        'K Stage Name': '재윤',
        'Date of Birth': '9/08/1994',
        Group: 'SF9',
        Debut: '5/10/2016',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaeyun',
        'Full Name': 'Moon Jaeyun',
        'Korean Name': '문재윤',
        'K Stage Name': '재윤',
        'Date of Birth': '2/07/2002',
        Group: '8TURN',
        Debut: '30/01/2023',
        Company: 'MNH',
        Country: 'New Zealand',
        'Second Country': 'South Korea',
        Height: 178,
        Weight: 64,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jaeyun',
        'Full Name': 'Lee Jaeyun',
        'Korean Name': '이재윤',
        'K Stage Name': '이재윤',
        'Date of Birth': '16/08/2000',
        Group: 'TOO',
        Debut: '1/04/2020',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jahan',
        'Full Name': 'Im Jihun',
        'Korean Name': '임지훈',
        'K Stage Name': '자한',
        'Date of Birth': '1/08/2002',
        Group: 'Kingdom',
        Debut: '18/02/2021',
        Company: 'GF',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jake',
        'Full Name': 'Shim Jaeyoon',
        'Korean Name': '심재윤',
        'K Stage Name': '제이크',
        'Date of Birth': '15/11/2002',
        Group: 'ENHYPEN',
        Debut: '30/11/2020',
        Company: 'Be:lift',
        Country: 'South Korea',
        'Second Country': 'Australia',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jamie',
        'Full Name': 'Park Jimin',
        'Korean Name': '박지민',
        'K Stage Name': '박지민',
        'Date of Birth': '5/07/1997',
        Group: '15&',
        Debut: '5/10/2012',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: '',
        Birthplace: 'Junggu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jane',
        'Full Name': 'Sung Jiyeon',
        'Korean Name': '성지연',
        'K Stage Name': '제인',
        'Date of Birth': '20/12/1997',
        Group: 'MOMOLAND',
        Debut: '9/11/2016',
        Company: 'Double Kick',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 46,
        Birthplace: 'Changwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jangjun',
        'Full Name': 'Lee Jangjun',
        'Korean Name': '이장준',
        'K Stage Name': '장준',
        'Date of Birth': '3/03/1997',
        Group: 'Golden Child',
        Debut: '28/08/2017',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jay',
        'Full Name': 'Park Jongsung',
        'Korean Name': '박종성',
        'K Stage Name': '제이',
        'Date of Birth': '20/04/2002',
        Group: 'ENHYPEN',
        Debut: '30/11/2020',
        Company: 'Be:lift',
        Country: 'South Korea',
        'Second Country': 'United States',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jay',
        'Full Name': 'Kim Jinhwan',
        'Korean Name': '김진환',
        'K Stage Name': '제이',
        'Date of Birth': '7/02/1994',
        Group: 'iKON',
        Debut: '15/09/2015',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Jeju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'JB',
        'Full Name': 'Im Jaebum',
        'Korean Name': '임재범',
        'K Stage Name': '제이비',
        'Date of Birth': '6/01/1994',
        Group: 'GOT7',
        Debut: '16/01/2014',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 63,
        Birthplace: 'Goyang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jea',
        'Full Name': 'Kim Hyojin',
        'Korean Name': '김효진',
        'K Stage Name': '제아',
        'Date of Birth': '18/09/1981',
        Group: 'Brown Eyed Girls',
        Debut: '2/03/2006',
        Company: 'Mystic',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jeewon',
        'Full Name': 'Kim Jiwon',
        'Korean Name': '김지원',
        'K Stage Name': '지원',
        'Date of Birth': '1/04/1999',
        Group: 'Cignature',
        Debut: '4/02/2020',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': 'Good Day',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jeff',
        'Full Name': 'Lee Jaeho',
        'Korean Name': '이재호',
        'K Stage Name': '제프',
        'Date of Birth': '21/04/2005',
        Group: 'EPEX',
        Debut: '8/06/2021',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 61,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jehyun',
        'Full Name': 'Moon Jehyun',
        'Korean Name': '문제현',
        'K Stage Name': '제현',
        'Date of Birth': '20/04/1999',
        Group: 'OMEGA X',
        Debut: '17/10/2016',
        Company: 'JT Corea',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '1TEAM',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jei',
        'Full Name': 'Kim Jinhee',
        'Korean Name': '김진희',
        'K Stage Name': '재이',
        'Date of Birth': '5/09/1989',
        Group: 'Fiestar',
        Debut: '31/08/2012',
        Company: 'LOEN',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: '',
        Birthplace: 'Bucheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jen',
        'Full Name': 'Kim Yehyeon',
        'Korean Name': '김예현',
        'K Stage Name': '젠',
        'Date of Birth': '27/03/1997',
        Group: 'UNVS',
        Debut: '23/02/2020',
        Company: 'CHITWN',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jenissi',
        'Full Name': 'Kim Taeyang',
        'Korean Name': '김태양',
        'K Stage Name': '제니씨',
        'Date of Birth': '2/08/1991',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'Topp Dogg',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jenna',
        'Full Name': 'Kim Minji',
        'Korean Name': '김민지',
        'K Stage Name': '제나',
        'Date of Birth': '6/10/2006',
        Group: 'Queenz Eye',
        Debut: '24/10/2022',
        Company: 'Big Mountain',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Changwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jennie',
        'Full Name': 'Kim Jennie',
        'Korean Name': '김제니',
        'K Stage Name': '제니',
        'Date of Birth': '16/01/1996',
        Group: 'BLACKPINK',
        Debut: '8/08/2016',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: 50,
        Birthplace: 'Anyang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F',
        'Image URL': '/kpop/blackpink/jennie/1.webp'
    },
    {
        'Stage Name': 'Jenny',
        'Full Name': 'Lee Soyul',
        'Korean Name': '이소율',
        'K Stage Name': '제니',
        'Date of Birth': '4/09/1996',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 47,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'DIA',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jeno',
        'Full Name': 'Lee Jeno',
        'Korean Name': '이제노',
        'K Stage Name': '제노',
        'Date of Birth': '23/04/2000',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'NCT Dream',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jeon Woong',
        'Full Name': 'Jeon Woong',
        'Korean Name': '전웅',
        'K Stage Name': '전웅',
        'Date of Birth': '15/10/1997',
        Group: 'AB6IX',
        Debut: '22/05/2019',
        Company: 'Brand New',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jeonggyu',
        'Full Name': 'Lim Jeonggyu',
        'Korean Name': '임정규',
        'K Stage Name': '정규',
        'Date of Birth': '5/11/1997',
        Group: "7 O'clock",
        Debut: '26/08/2014',
        Company: 'Jungle',
        Country: 'South Korea',
        'Second Country': '',
        Height: 181,
        Weight: 62,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jeonghan',
        'Full Name': 'Yoon Jeonghan',
        'Korean Name': '윤정한',
        'K Stage Name': '정한',
        'Date of Birth': '4/10/1995',
        Group: 'Seventeen',
        Debut: '26/05/2015',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 54,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jeonghee',
        'Full Name': 'Go Jeonghee',
        'Korean Name': '고정희',
        'K Stage Name': '정희',
        'Date of Birth': '11/03/2000',
        Group: 'Favorite',
        Debut: '5/07/2017',
        Company: 'Astory',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jeongkyun',
        'Full Name': 'Jeong Jeongkyun',
        'Korean Name': '정정균',
        'K Stage Name': '정균',
        'Date of Birth': '27/11/1987',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 60,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'N-Train| Bigflo',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jeongmin',
        'Full Name': 'Lee Jeongmin',
        'Korean Name': '이정민',
        'K Stage Name': '정민',
        'Date of Birth': '1/02/1994',
        Group: 'Boyfriend',
        Debut: '26/05/2011',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: 60,
        Birthplace: 'Suwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jeongseung',
        'Full Name': 'Park Jeongseung',
        'Korean Name': '박정승',
        'K Stage Name': '정승',
        'Date of Birth': '20/05/2002',
        Group: 'D-CRUNCH',
        Debut: '6/08/2018',
        Company: 'All-S',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jeonguk',
        'Full Name': 'Kim Jeonguk',
        'Korean Name': '김정욱',
        'K Stage Name': '정욱',
        'Date of Birth': '20/03/1993',
        Group: '24K',
        Debut: '6/09/2012',
        Company: 'Choeun',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 58,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jeongwoo',
        'Full Name': 'Park Jeongwoo',
        'Korean Name': '박정우',
        'K Stage Name': '정우',
        'Date of Birth': '28/09/2004',
        Group: 'Treasure',
        Debut: '7/08/2020',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jeongyeon',
        'Full Name': 'Yoo Jeongyeon',
        'Korean Name': '유정연',
        'K Stage Name': '정연',
        'Date of Birth': '1/11/1996',
        Group: 'Twice',
        Debut: '20/10/2015',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: 'Suwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jeonyul',
        'Full Name': 'Jeon Yuri',
        'Korean Name': '전유리',
        'K Stage Name': '전율',
        'Date of Birth': '20/03/1994',
        Group: 'Stellar',
        Debut: '28/08/2011',
        Company: 'Pascal',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jerome',
        'Full Name': 'Oh Sungmin',
        'Korean Name': '오성민',
        'K Stage Name': '제롬',
        'Date of Birth': '25/08/2001',
        Group: 'TOO',
        Debut: '1/04/2020',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jerrry',
        'Full Name': 'Ahn Sohyun',
        'Korean Name': '안소현',
        'K Stage Name': '제리',
        'Date of Birth': '27/08/1992',
        Group: 'Tahiti',
        Debut: '23/06/2012',
        Company: 'J-line',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jessica',
        'Full Name': 'Jessica Jung',
        'Korean Name': '제시카 정',
        'K Stage Name': '제시카',
        'Date of Birth': '18/04/1989',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'SNSD',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jewon',
        'Full Name': 'Woo Jewon',
        'Korean Name': '우제원',
        'K Stage Name': '제원',
        'Date of Birth': '28/11/1998',
        Group: 'NINE.i',
        Debut: '30/03/2022',
        Company: 'FirstOne',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 55,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'J-Hoon',
        'Full Name': 'Lim Junghoon',
        'Korean Name': '임정훈',
        'K Stage Name': '제이훈',
        'Date of Birth': '15/07/1990',
        Group: 'B.I.G',
        Debut: '9/07/2014',
        Company: 'GH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: 62,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'J-Hope',
        'Full Name': 'Jung Hoseok',
        'Korean Name': '정호석',
        'K Stage Name': '제이홉',
        'Date of Birth': '18/02/1994',
        Group: 'BTS',
        Debut: '13/06/2013',
        Company: 'Big Hit',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 65,
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ji Hansol',
        'Full Name': 'Ji Hansol',
        'Korean Name': '지한솔',
        'K Stage Name': '지한솔',
        'Date of Birth': '21/11/1994',
        Group: 'Newkidd',
        Debut: '25/04/2019',
        Company: 'J-FLO',
        Country: 'South Korea',
        'Second Country': '',
        Height: 182,
        Weight: 63,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': 'UNB',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jia',
        'Full Name': 'Yoon Jia',
        'Korean Name': '윤지아',
        'K Stage Name': '지아',
        'Date of Birth': '16/01/2004',
        Group: 'mimiimiii',
        Debut: '27/04/2015',
        Company: 'Midas',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jia',
        'Full Name': 'Yoon Jia',
        'Korean Name': '윤지아',
        'K Stage Name': '지아',
        'Date of Birth': '16/01/2004',
        Group: 'mimiirose',
        Debut: '16/09/2022',
        Company: 'YES IM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jia',
        'Full Name': 'Meng Jia',
        'Korean Name': '멍지아',
        'K Stage Name': '지아',
        'Date of Birth': '3/02/1989',
        Group: 'miss A',
        Debut: '1/07/2010',
        Company: 'JYP',
        Country: 'China',
        'Second Country': '',
        Height: 166,
        Weight: '',
        Birthplace: 'Changja',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jia',
        'Full Name': 'Guo Jiajia',
        'Korean Name': '궈 지아지아',
        'K Stage Name': '지아',
        'Date of Birth': '30/07/2005',
        Group: 'TRI.BE',
        Debut: '17/02/2021',
        Company: 'TR - Mellow',
        Country: 'China',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiae',
        'Full Name': 'Yoo Jiae',
        'Korean Name': '유지애',
        'K Stage Name': '지애',
        'Date of Birth': '21/05/1993',
        Group: 'Lovelyz',
        Debut: '17/11/2014',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: 43,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiae',
        'Full Name': 'Kim Jiae',
        'Korean Name': '김지애',
        'K Stage Name': '지애',
        'Date of Birth': '31/10/1995',
        Group: 'Wassup',
        Debut: '7/08/2013',
        Company: 'Mafia',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiahn',
        'Full Name': 'Yoo Jiahn',
        'Korean Name': '유지안',
        'K Stage Name': '지안',
        'Date of Birth': '1/04/1992',
        Group: 'IN2IT',
        Debut: '27/01/2016',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 57,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jian',
        'Full Name': 'Kim Jian',
        'Korean Name': '김지안',
        'K Stage Name': '지안',
        'Date of Birth': '4/11/2006',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'LIGHTSUM',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jian',
        'Full Name': 'Kwon Jieun',
        'Korean Name': '권지은',
        'K Stage Name': '지안',
        'Date of Birth': '14/03/2001',
        Group: 'LUNARSOLAR',
        Debut: '2/09/2020',
        Company: 'J Planet',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'S.E.T',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jibeom',
        'Full Name': 'Kim Jibeom',
        'Korean Name': '김지범',
        'K Stage Name': '지범',
        'Date of Birth': '3/02/1999',
        Group: 'Golden Child',
        Debut: '28/08/2017',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jieun',
        'Full Name': 'Park Jieun',
        'Korean Name': '박지은',
        'K Stage Name': '지은',
        'Date of Birth': '4/09/1997',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 49,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Purple Kiss',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jieun',
        'Full Name': 'Choi Jieun',
        'Korean Name': '최지은',
        'K Stage Name': '지은',
        'Date of Birth': '3/03/2001',
        Group: 'Bling Bling',
        Debut: '17/11/2020',
        Company: 'Major9',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jieun',
        'Full Name': 'Song Jieun',
        'Korean Name': '송지은',
        'K Stage Name': '지은',
        'Date of Birth': '5/05/1990',
        Group: 'SECRET',
        Debut: '13/10/2009',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jihan',
        'Full Name': 'Han Jihyo',
        'Korean Name': '한지효',
        'K Stage Name': '지한',
        'Date of Birth': '12/07/2004',
        Group: 'Weeekly',
        Debut: '30/07/2020',
        Company: 'Play M',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiheon',
        'Full Name': 'Baek Jiheon',
        'Korean Name': '백지헌',
        'K Stage Name': '지헌',
        'Date of Birth': '17/04/2003',
        Group: 'fromis_9',
        Debut: '24/01/2018',
        Company: 'Off The Record',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Jeolla',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiho',
        'Full Name': 'Kim Jiho',
        'Korean Name': '김지호',
        'K Stage Name': '지호',
        'Date of Birth': '4/04/1997',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 49,
        Birthplace: 'Okcheon',
        'Other Group': '',
        'Former Group': 'Oh My Girl',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiho',
        'Full Name': 'Jang Jiho',
        'Korean Name': '장지호',
        'K Stage Name': '지호',
        'Date of Birth': '10/08/2004',
        Group: 'NINE.i',
        Debut: '30/03/2022',
        Company: 'FirstOne',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gyeonggi',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jihoo',
        'Full Name': 'Lim Soojung',
        'Korean Name': '임수정',
        'K Stage Name': '지후',
        'Date of Birth': '5/08/1998',
        Group: 'IZ',
        Debut: '31/08/2017',
        Company: 'Music K',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jihoon',
        'Full Name': 'Park Jihoon',
        'Korean Name': '박지훈',
        'K Stage Name': '지훈',
        'Date of Birth': '14/03/2000',
        Group: 'Treasure',
        Debut: '7/08/2020',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jihoon',
        'Full Name': 'Park Jihoon',
        'Korean Name': '박지훈',
        'K Stage Name': '지훈',
        'Date of Birth': '29/05/1999',
        Group: 'Wanna One',
        Debut: '7/08/2017',
        Company: 'YMC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Masan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jihun',
        'Full Name': 'Kim Jihun',
        'Korean Name': '김지훈',
        'K Stage Name': '지훈',
        'Date of Birth': '20/02/1995',
        Group: 'KNK',
        Debut: '3/03/2016',
        Company: 'YNB',
        Country: 'South Korea',
        'Second Country': '',
        Height: 186,
        Weight: 73,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jihun',
        'Full Name': 'Kim Jihun',
        'Korean Name': '김지훈',
        'K Stage Name': '지훈',
        'Date of Birth': '9/01/2000',
        Group: 'TRCNG',
        Debut: '10/10/2017',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jihyo',
        'Full Name': 'Lee Jihyo',
        'Korean Name': '이지효',
        'K Stage Name': '지효',
        'Date of Birth': '22/04/2001',
        Group: 'PRECIOUS',
        Debut: '23/09/2020',
        Company: 'Umi',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 49,
        Birthplace: 'Gyeonggi',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jihyo',
        'Full Name': 'Park Jisoo',
        'Korean Name': '박지수',
        'K Stage Name': '지효',
        'Date of Birth': '1/02/1997',
        Group: 'Twice',
        Debut: '20/10/2015',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: '',
        Birthplace: 'Guri',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jihyun',
        'Full Name': 'Nam Jihyun',
        'Korean Name': '남지현',
        'K Stage Name': '지현',
        'Date of Birth': '9/01/1990',
        Group: '4minute',
        Debut: '15/06/2009',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jimin',
        'Full Name': 'Shin Jimin',
        'Korean Name': '신지민',
        'K Stage Name': '지민',
        'Date of Birth': '8/01/1991',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 43,
        Birthplace: 'Seoul',
        'Other Group': 'AOA BLACK',
        'Former Group': 'AoA',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jimin',
        'Full Name': 'Park Jimin',
        'Korean Name': '박지민',
        'K Stage Name': '지민',
        'Date of Birth': '13/10/1995',
        Group: 'BTS',
        Debut: '13/06/2013',
        Company: 'Big Hit',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 61,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jimin',
        'Full Name': 'Won Jimin',
        'Korean Name': '원지민',
        'K Stage Name': '지민',
        'Date of Birth': '25/11/2007',
        Group: 'CLASS:y',
        Debut: '5/05/2022',
        Company: 'Universal',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jin',
        'Full Name': 'Kim Seokjin',
        'Korean Name': '김석진',
        'K Stage Name': '진',
        'Date of Birth': '4/12/1992',
        Group: 'BTS',
        Debut: '13/06/2013',
        Company: 'Big Hit',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 63,
        Birthplace: 'Gwacheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'JIN',
        'Full Name': 'Park Myungeun',
        'Korean Name': '박명은',
        'K Stage Name': '진',
        'Date of Birth': '12/06/1996',
        Group: 'Lovelyz',
        Debut: '17/11/2014',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 47,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jin Jin',
        'Full Name': 'Park Jinwoo',
        'Korean Name': '박진우',
        'K Stage Name': '진진',
        'Date of Birth': '15/03/1996',
        Group: 'ASTRO',
        Debut: '23/02/2016',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Nowon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'JinE',
        'Full Name': 'Shin Hyejin',
        'Korean Name': '신혜진',
        'K Stage Name': '진이',
        'Date of Birth': '22/01/1995',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 48,
        Birthplace: 'Pohang',
        'Other Group': '',
        'Former Group': 'Oh My Girl',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jingyu',
        'Full Name': 'Lee Jingyu',
        'Korean Name': '이진규',
        'K Stage Name': '진규',
        'Date of Birth': '22/04/1998',
        Group: 'Astin',
        Debut: '16/11/2022',
        Company: 'AB Creative',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jinha',
        'Full Name': 'Kim Jinha',
        'Korean Name': '김진하',
        'K Stage Name': '진하',
        'Date of Birth': '21/11/2003',
        Group: 'TRI.BE',
        Debut: '17/02/2021',
        Company: 'TR - Mellow',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jinho',
        'Full Name': 'Jo Jinho',
        'Korean Name': '조진호',
        'K Stage Name': '조진호',
        'Date of Birth': '17/04/1992',
        Group: 'PENTAGON',
        Debut: '10/10/2016',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jinhong',
        'Full Name': 'Kim Jinhong',
        'Korean Name': '김진홍',
        'K Stage Name': '진홍',
        'Date of Birth': '2/01/1998',
        Group: '24K',
        Debut: '6/09/2012',
        Company: 'Choeun',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 53,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jinhoo',
        'Full Name': 'Kim Jinwook',
        'Korean Name': '김진욱',
        'K Stage Name': '진후',
        'Date of Birth': '2/08/1995',
        Group: 'UP10TION',
        Debut: '10/09/2015',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Changwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jinhui',
        'Full Name': '',
        'Korean Name': '',
        'K Stage Name': '진희',
        'Date of Birth': '21/11/1996',
        Group: 'Lusty',
        Debut: '26/06/2019',
        Company: 'babaplay',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 42,
        Birthplace: 'Seongnam',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jinhwa',
        'Full Name': 'Choi Jinhwa',
        'Korean Name': '최진화',
        'K Stage Name': '진화',
        'Date of Birth': '15/01/2002',
        Group: 'BLITZERS',
        Debut: '12/05/2021',
        Company: 'Wuzo',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 64,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jinju',
        'Full Name': 'Park Jinju',
        'Korean Name': '박진주',
        'K Stage Name': '진주',
        'Date of Birth': '28/04/1990',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Wassup',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jinkwon',
        'Full Name': 'Kim Jinkwon',
        'Korean Name': '김진권',
        'K Stage Name': '진권',
        'Date of Birth': '31/01/2001',
        Group: 'Newkidd',
        Debut: '25/04/2019',
        Company: 'J-FLO',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jinni',
        'Full Name': 'Choi Yujin',
        'Korean Name': '최윤진',
        'K Stage Name': '지니',
        'Date of Birth': '16/04/2004',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'NMIXX',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jinny',
        'Full Name': 'Park Jinhee',
        'Korean Name': '박진희',
        'K Stage Name': '지니',
        'Date of Birth': '19/01/1998',
        Group: 'SECRET NUMBER',
        Debut: '19/05/2020',
        Company: 'Vine',
        Country: 'South Korea',
        'Second Country': 'USA',
        Height: '',
        Weight: '',
        Birthplace: 'California',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jinsik',
        'Full Name': 'Han Jinsik',
        'Korean Name': '함진식',
        'K Stage Name': '진식',
        'Date of Birth': '30/07/2004',
        Group: 'xikers',
        Debut: '30/03/2023',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jinsol',
        'Full Name': 'Lee Jinsol',
        'Korean Name': '이진솔',
        'K Stage Name': '진솔',
        'Date of Birth': '4/12/2001',
        Group: 'APRIL',
        Debut: '24/08/2015',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Andong',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'JinSoul',
        'Full Name': 'Jung Jinsol',
        'Korean Name': '정진솔',
        'K Stage Name': '진솔',
        'Date of Birth': '13/06/1997',
        Group: 'LOONA',
        Debut: '20/08/2018',
        Company: 'Blockberry',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'Loona Odd Eye Circle| ARTMS',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jinsung',
        'Full Name': 'Jung Jinsung',
        'Korean Name': '정진성',
        'K Stage Name': '진성',
        'Date of Birth': '30/03/2002',
        Group: '1the9',
        Debut: '9/02/2019',
        Company: 'MBK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 58,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jinu',
        'Full Name': 'Kim Jinwoo',
        'Korean Name': '김진우',
        'K Stage Name': '진우',
        'Date of Birth': '26/09/1991',
        Group: 'WINNER',
        Debut: '12/08/2014',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Imja',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jinwoo',
        'Full Name': 'Lee Jinwoo',
        'Korean Name': '이진우',
        'K Stage Name': '진우',
        'Date of Birth': '13/09/2004',
        Group: 'Ghost9',
        Debut: '23/09/2020',
        Company: 'Maroo',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 57,
        Birthplace: '',
        'Other Group': 'TEEN TEEN',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jinyoung',
        'Full Name': 'Jung Jinyoung',
        'Korean Name': '정진영',
        'K Stage Name': '진영',
        'Date of Birth': '18/11/1991',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 58,
        Birthplace: 'Chungju',
        'Other Group': '',
        'Former Group': 'B1A4',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jinyoung',
        'Full Name': 'Bae Jinyoung',
        'Korean Name': '배진영',
        'K Stage Name': '진영',
        'Date of Birth': '10/05/2000',
        Group: 'CIX',
        Debut: '23/07/2019',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'Wanna One',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jinyoung',
        'Full Name': 'Woo Jinyoung',
        'Korean Name': '우진영',
        'K Stage Name': '진영',
        'Date of Birth': '31/05/1997',
        Group: 'D1CE',
        Debut: '1/08/2019',
        Company: 'Happyface',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 51,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jinyoung',
        'Full Name': 'Park Jinyoung',
        'Korean Name': '박진영',
        'K Stage Name': '진영',
        'Date of Birth': '22/09/1994',
        Group: 'GOT7',
        Debut: '16/01/2014',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 63,
        Birthplace: 'Changwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jisan',
        'Full Name': 'Choi Jinsang',
        'Korean Name': '최지상',
        'K Stage Name': '지산',
        'Date of Birth': '3/11/1999',
        Group: 'Hot Blood Youth',
        Debut: '17/05/2017',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jisoo',
        'Full Name': 'Kim Jisoo',
        'Korean Name': '김지수',
        'K Stage Name': '지수',
        'Date of Birth': '3/01/1995',
        Group: 'BLACKPINK',
        Debut: '8/08/2016',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: 45,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jisoo',
        'Full Name': 'Jung Jisoo',
        'Korean Name': '정지수',
        'K Stage Name': '지수',
        'Date of Birth': '22/11/2003',
        Group: 'Busters',
        Debut: '27/11/2017',
        Company: 'JTG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jisoo',
        'Full Name': 'Seo Jisoo',
        'Korean Name': '서지수',
        'K Stage Name': '지수',
        'Date of Birth': '11/02/1994',
        Group: 'Lovelyz',
        Debut: '17/11/2014',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 46,
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jisoo',
        'Full Name': 'Shin Jisoo',
        'Korean Name': '신지수',
        'K Stage Name': '지수',
        'Date of Birth': '28/02/1994',
        Group: 'Tahiti',
        Debut: '23/06/2012',
        Company: 'J-line',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Chuncheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jisook',
        'Full Name': 'Kim Jisook',
        'Korean Name': '김지숙',
        'K Stage Name': '지숙',
        'Date of Birth': '18/07/1990',
        Group: 'Rainbow',
        Debut: '12/11/2009',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 41,
        Birthplace: 'Suwon',
        'Other Group': 'Rainbow Pixie',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jisu',
        'Full Name': 'Choi Jisu',
        'Korean Name': '최지수',
        'K Stage Name': '지수',
        'Date of Birth': '19/01/2000',
        Group: 'TOO',
        Debut: '1/04/2020',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jisun',
        'Full Name': 'Roh Jisun',
        'Korean Name': '노지선',
        'K Stage Name': '지선',
        'Date of Birth': '23/11/1998',
        Group: 'fromis_9',
        Debut: '24/01/2018',
        Company: 'Off The Record',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jisun',
        'Full Name': 'Hwang Jisun',
        'Korean Name': '황지선',
        'K Stage Name': '지선',
        'Date of Birth': '17/10/1989',
        Group: 'S#aFLA',
        Debut: '14/07/2021',
        Company: 'Winnerzone',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 46,
        Birthplace: '',
        'Other Group': '',
        'Former Group': "Girl's Day| New F.O",
        Gender: 'F'
    },
    {
        'Stage Name': 'Jisung',
        'Full Name': 'Park Jisung',
        'Korean Name': '박지성',
        'K Stage Name': '지성',
        'Date of Birth': '5/02/2002',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'NCT Dream',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jisung',
        'Full Name': 'Kim Jisung',
        'Korean Name': '김지성',
        'K Stage Name': '지성',
        'Date of Birth': '21/12/2000',
        Group: 'TRCNG',
        Debut: '10/10/2017',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jisung',
        'Full Name': 'Yoon Jisung',
        'Korean Name': '윤지성',
        'K Stage Name': '지성',
        'Date of Birth': '8/03/1991',
        Group: 'Wanna One',
        Debut: '7/08/2017',
        Company: 'YMC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Wonju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jiu',
        'Full Name': 'Kim Minji',
        'Korean Name': '김민지',
        'K Stage Name': '지유',
        'Date of Birth': '17/05/1994',
        Group: 'Dreamcatcher',
        Debut: '13/01/2017',
        Company: 'Happy Face',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: '',
        Birthplace: 'Daejeon',
        'Other Group': '',
        'Former Group': 'Minx',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiung',
        'Full Name': 'Choi Jiwoong',
        'Korean Name': '최지웅',
        'K Stage Name': '지웅',
        'Date of Birth': '7/10/2001',
        Group: 'P1Harmony',
        Debut: '28/10/2020',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jiwon',
        'Full Name': 'Park Jiwon',
        'Korean Name': '박지원',
        'K Stage Name': '지원',
        'Date of Birth': '17/03/1997',
        Group: 'BOTOPASS',
        Debut: '26/08/2020',
        Company: 'WKS ENE',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 45,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'ILUV',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiwon',
        'Full Name': 'Heo Jiwon',
        'Korean Name': '허지원',
        'K Stage Name': '지원',
        'Date of Birth': '4/09/2000',
        Group: 'Cherry Bullet',
        Debut: '21/01/2019',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiwon',
        'Full Name': 'Park Jiwon',
        'Korean Name': '박지원',
        'K Stage Name': '지원',
        'Date of Birth': '20/03/1998',
        Group: 'fromis_9',
        Debut: '24/01/2018',
        Company: 'Off The Record',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiwon',
        'Full Name': 'Yang Jiwon',
        'Korean Name': '양지원',
        'K Stage Name': '지원',
        'Date of Birth': '5/04/1988',
        Group: 'UNI.T',
        Debut: '18/05/2018',
        Company: 'Unit Culture',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': 'SPICA-S',
        'Former Group': 'SPICA',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiwoo',
        'Full Name': 'Jeon Jiwoo',
        'Korean Name': '전지우',
        'K Stage Name': '지우',
        'Date of Birth': '4/10/1996',
        Group: 'KARD',
        Debut: '29/03/2007',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiwoo',
        'Full Name': 'Kim Jiwoo',
        'Korean Name': '김지우',
        'K Stage Name': '지우',
        'Date of Birth': '13/04/2005',
        Group: 'NMIXX',
        Debut: '22/02/2022',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiwoo',
        'Full Name': 'Lee Jiwoo',
        'Korean Name': '이지우',
        'K Stage Name': '지우',
        'Date of Birth': '24/10/2005',
        Group: 'tripleS',
        Debut: '13/02/2023',
        Company: 'MODHAUS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiyeon',
        'Full Name': 'Park Jiyeon',
        'Korean Name': '박지연',
        'K Stage Name': '지연',
        'Date of Birth': '7/06/1993',
        Group: 'T-ara',
        Debut: '29/07/2009',
        Company: 'MBK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 45,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiyoon',
        'Full Name': 'Shin Jiyoon',
        'Korean Name': '신지윤',
        'K Stage Name': '지윤',
        'Date of Birth': '2/03/2002',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Weeekly',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiyoon',
        'Full Name': 'Jeon Jiyoon',
        'Korean Name': '전지윤',
        'K Stage Name': '지윤',
        'Date of Birth': '15/10/1990',
        Group: '4minute',
        Debut: '15/06/2009',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '2YOON',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiyoon',
        'Full Name': 'Woo Jiyoon',
        'Korean Name': '우지윤',
        'K Stage Name': '지윤',
        'Date of Birth': '6/01/1996',
        Group: 'Bolbbalgan4',
        Debut: '22/04/2016',
        Company: 'Shofar',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yeongju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiyoon',
        'Full Name': 'Jeong Jiyoon',
        'Korean Name': '정지윤',
        'K Stage Name': '지윤',
        'Date of Birth': '15/09/2000',
        Group: "ICHILLIN'",
        Debut: '8/09/2021',
        Company: 'KM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiyou',
        'Full Name': 'Im Changsook',
        'Korean Name': '임창숙',
        'K Stage Name': '지유',
        'Date of Birth': '15/06/1989',
        Group: 'Two X',
        Debut: '20/08/2012',
        Company: 'J. Tune',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiyoung',
        'Full Name': 'Ahn Jiyoung',
        'Korean Name': '안지영',
        'K Stage Name': '지영',
        'Date of Birth': '14/09/1995',
        Group: 'Bolbbalgan4',
        Debut: '22/04/2016',
        Company: 'Shofar',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yeongju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiyoung',
        'Full Name': 'Kang Jiyoung',
        'Korean Name': '강지영',
        'K Stage Name': '지영',
        'Date of Birth': '18/01/1994',
        Group: 'KARA',
        Debut: '29/03/2007',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Paju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jiyul',
        'Full Name': 'Yang Jungyoon',
        'Korean Name': '양정윤',
        'K Stage Name': '지율',
        'Date of Birth': '30/07/1991',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'Dal Shabet',
        Gender: 'F'
    },
    {
        'Stage Name': 'JJ',
        'Full Name': 'Jun Jihyeok',
        'Korean Name': '전진혁',
        'K Stage Name': '제이제이',
        'Date of Birth': '11/12/1998',
        Group: 'DUSTIN',
        Debut: '6/01/2020',
        Company: 'LPA',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'JK',
        'Full Name': 'Kim Jikang',
        'Korean Name': '김지강',
        'K Stage Name': '지강',
        'Date of Birth': '17/03/1998',
        Group: 'Girlkind',
        Debut: '16/01/2018',
        Company: 'Nextlevel',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: 161,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'JM',
        'Full Name': 'Chu Jimin',
        'Korean Name': '추지민',
        'K Stage Name': '지민',
        'Date of Birth': '11/10/2001',
        Group: 'JUST B',
        Debut: '30/06/2021',
        Company: 'Bluedot',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'J-Min',
        'Full Name': 'Jeon Minwook',
        'Korean Name': '전민욱',
        'K Stage Name': '제이민',
        'Date of Birth': '16/10/1999',
        Group: 'BAE173',
        Debut: '19/11/2020',
        Company: 'PocketDol',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Johnny',
        'Full Name': 'John Seo',
        'Korean Name': '존 서',
        'K Stage Name': '쟈니',
        'Date of Birth': '9/02/1995',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'USA',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Chicago',
        'Other Group': 'NCT 127',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Johyun',
        'Full Name': 'Shin Jiwon',
        'Korean Name': '신지원',
        'K Stage Name': '조현',
        'Date of Birth': '14/04/1996',
        Group: 'Berry Good',
        Debut: '21/05/2014',
        Company: 'Asia Bridge',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jongho',
        'Full Name': 'Choi Jongho',
        'Korean Name': '최종호',
        'K Stage Name': '종호',
        'Date of Birth': '12/10/2000',
        Group: 'ATEEZ',
        Debut: '24/10/2018',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jonghoon',
        'Full Name': 'Choi Jonghoon',
        'Korean Name': '최종훈',
        'K Stage Name': '종훈',
        'Date of Birth': '7/03/1990',
        Group: 'FTISLAND',
        Debut: '7/06/2007',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jonghwan',
        'Full Name': 'Jo Jonghwan',
        'Korean Name': '조종환',
        'K Stage Name': '종환',
        'Date of Birth': '23/11/1992',
        Group: '100%',
        Debut: '18/09/2012',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jonghyeong',
        'Full Name': 'Kim Jonghyeong',
        'Korean Name': '김종형',
        'K Stage Name': '종형',
        'Date of Birth': '13/04/2002',
        Group: 'Dongkiz',
        Debut: '24/04/2019',
        Company: 'Dongyo',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 65,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jonghyun',
        'Full Name': 'Lee Jonghyun',
        'Korean Name': '이종현',
        'K Stage Name': '종현',
        'Date of Birth': '15/05/1990',
        Group: 'CNBLUE',
        Debut: '14/01/2010',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jonghyun†',
        'Full Name': 'Kim Jonghyun',
        'Korean Name': '김종현',
        'K Stage Name': '종현',
        'Date of Birth': '8/04/1990',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: 56,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'SHINee',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jongseob',
        'Full Name': 'Kim Jongseob',
        'Korean Name': '김종섭',
        'K Stage Name': '종섭',
        'Date of Birth': '19/11/2005',
        Group: 'P1Harmony',
        Debut: '28/10/2020',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jongup',
        'Full Name': 'Moon Jongup',
        'Korean Name': '문종업',
        'K Stage Name': '종업',
        'Date of Birth': '6/02/1995',
        Group: 'B.A.P',
        Debut: '27/01/2012',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 66,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'JOO',
        'Full Name': 'Jung Minjoo',
        'Korean Name': '정민주',
        'K Stage Name': '주',
        'Date of Birth': '11/10/1990',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Joo E',
        'Full Name': 'Lee Joowon',
        'Korean Name': '이주원',
        'K Stage Name': '주이',
        'Date of Birth': '18/08/1999',
        Group: 'MOMOLAND',
        Debut: '9/11/2016',
        Company: 'Double Kick',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 45,
        Birthplace: 'Bucheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'JooA',
        'Full Name': 'Lee Juhyun',
        'Korean Name': '이주현',
        'K Stage Name': '주아',
        'Date of Birth': '4/03/2002',
        Group: 'Hi-L',
        Debut: '11/08/2021',
        Company: 'Kpop Live',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jooan',
        'Full Name': 'Im Jimyoung',
        'Korean Name': '임지명',
        'K Stage Name': '주안',
        'Date of Birth': '4/10/1996',
        Group: 'We In The Zone',
        Debut: '27/05/2019',
        Company: 'Choon',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: 58,
        Birthplace: 'Dongtan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Joochan',
        'Full Name': 'Hong Joochan',
        'Korean Name': '홍주찬',
        'K Stage Name': '주찬',
        'Date of Birth': '31/07/1999',
        Group: 'Golden Child',
        Debut: '28/08/2017',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jooheon',
        'Full Name': 'Lee Jooheon',
        'Korean Name': '이주헌',
        'K Stage Name': '주헌',
        'Date of Birth': '6/10/1994',
        Group: 'MONSTA X',
        Debut: '14/05/2014',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Joohyoung',
        'Full Name': 'Kim Joohyoung',
        'Korean Name': '김주형',
        'K Stage Name': '주형',
        'Date of Birth': '15/03/2003',
        Group: 'NINE.i',
        Debut: '30/03/2022',
        Company: 'FirstOne',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Joonie',
        'Full Name': 'Park Junhee',
        'Korean Name': '박준희',
        'K Stage Name': '주니',
        'Date of Birth': '24/05/2002',
        Group: "ICHILLIN'",
        Debut: '8/09/2021',
        Company: 'KM',
        Country: 'USA',
        'Second Country': 'South Korea',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jooyeon',
        'Full Name': 'Lee Jooyeon',
        'Korean Name': '이주연',
        'K Stage Name': '주연',
        'Date of Birth': '19/03/1987',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'After School',
        Gender: 'F'
    },
    {
        'Stage Name': 'Joshua',
        'Full Name': 'Hong Jisoo',
        'Korean Name': '홍지수',
        'K Stage Name': '조슈아',
        'Date of Birth': '30/12/1995',
        Group: 'Seventeen',
        Debut: '26/05/2015',
        Company: 'Pledis',
        Country: 'USA',
        'Second Country': 'South Korea',
        Height: 176,
        Weight: 63,
        Birthplace: 'Los Angeles',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Joy',
        'Full Name': 'Park Sooyoung',
        'Korean Name': '박수영',
        'K Stage Name': '조이',
        'Date of Birth': '3/09/1996',
        Group: 'Red Velvet',
        Debut: '1/08/2014',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: 'Jeju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'JR',
        'Full Name': 'Kim Jonghyun',
        'Korean Name': '김종현',
        'K Stage Name': '제이알',
        'Date of Birth': '8/06/1995',
        Group: "NU'EST",
        Debut: '15/03/2012',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gangwon',
        'Other Group': "NU'EST W",
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Judy',
        'Full Name': 'Kim Dahye',
        'Korean Name': '김다혜',
        'K Stage Name': '주디',
        'Date of Birth': '16/05/1995',
        Group: 'Blackswan',
        Debut: '16/10/2020',
        Company: 'DR',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jueun',
        'Full Name': 'Jo Jueun',
        'Korean Name': '조주은',
        'K Stage Name': '주은',
        'Date of Birth': '27/01/2001',
        Group: 'ARIAZ',
        Debut: '23/10/2019',
        Company: 'Rising Star',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jueun',
        'Full Name': 'Lee Jooeun',
        'Korean Name': '이주은',
        'K Stage Name': '주은',
        'Date of Birth': '7/07/1995',
        Group: 'DIA',
        Debut: '14/07/2015',
        Company: 'MBK',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Suwon',
        'Other Group': 'BinChaenHyunSeuS',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Juhaknyeon',
        'Full Name': 'Ju Haknyeon',
        'Korean Name': '주학년',
        'K Stage Name': '주학년',
        'Date of Birth': '9/03/1999',
        Group: 'The Boyz',
        Debut: '31/12/2017',
        Company: 'Cre.Ker',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 58,
        Birthplace: 'Jeju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Juhan',
        'Full Name': 'Hong Seunghyun',
        'Korean Name': '홍승현',
        'K Stage Name': '주한',
        'Date of Birth': '21/06/2001',
        Group: 'BLITZERS',
        Debut: '12/05/2021',
        Company: 'Wuzo',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Juhyeon',
        'Full Name': 'Lee Juhyeon',
        'Korean Name': '이주현',
        'K Stage Name': '주현',
        'Date of Birth': '8/04/2004',
        Group: 'LIGHTSUM',
        Debut: '10/06/2021',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: 48,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Juhyun',
        'Full Name': 'Cha Juhyun',
        'Korean Name': '차주현',
        'K Stage Name': '주현',
        'Date of Birth': '1/04/2000',
        Group: 'Bling Bling',
        Debut: '17/11/2020',
        Company: 'Major9',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Juju',
        'Full Name': 'Park Juyoung',
        'Korean Name': '박주영',
        'K Stage Name': '쥬쥬',
        'Date of Birth': '3/01/1996',
        Group: 'Gate9',
        Debut: '26/01/1999',
        Company: 'JYP| SidusHQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: 'Changwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jukang',
        'Full Name': 'Lee Hyowon',
        'Korean Name': '이효원',
        'K Stage Name': '주강',
        'Date of Birth': '22/11/2002',
        Group: 'JWiiver',
        Debut: '17/02/2022',
        Company: 'JTG',
        Country: 'South Korea',
        'Second Country': '',
        Height: 186,
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jun',
        'Full Name': 'Park Junhee',
        'Korean Name': '박준희',
        'K Stage Name': '준',
        'Date of Birth': '2/06/1994',
        Group: 'A.C.E',
        Debut: '23/05/2017',
        Company: 'Beat',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Jeolla',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jun',
        'Full Name': 'Weng Junhui',
        'Korean Name': '원쥔훼이',
        'K Stage Name': '준',
        'Date of Birth': '10/06/1996',
        Group: 'Seventeen',
        Debut: '26/05/2015',
        Company: 'Pledis',
        Country: 'China',
        'Second Country': '',
        Height: 180,
        Weight: 63,
        Birthplace: 'Shenzen',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jun',
        'Full Name': 'Lee Junyoung',
        'Korean Name': '이준영',
        'K Stage Name': '준',
        'Date of Birth': '22/01/1997',
        Group: 'U-KISS',
        Debut: '28/08/2008',
        Company: 'NH Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jun H.',
        'Full Name': 'Im Joonhyun',
        'Korean Name': '임준현',
        'K Stage Name': '준현',
        'Date of Birth': '28/08/1991',
        Group: 'UNVS',
        Debut: '23/02/2020',
        Company: 'CHITWN',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jun Jin',
        'Full Name': 'Park Choongjae',
        'Korean Name': '박충재',
        'K Stage Name': '전진',
        'Date of Birth': '19/08/1980',
        Group: 'Shinhwa',
        Debut: '24/03/1998',
        Company: 'SM| Good| Shinhwa',
        Country: 'South Korea',
        'Second Country': '',
        Height: 182,
        Weight: 75,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jun. K',
        'Full Name': 'Kim Minjun',
        'Korean Name': '김민준',
        'K Stage Name': '준케이',
        'Date of Birth': '15/01/1988',
        Group: '2PM',
        Debut: '4/07/2008',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 68,
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ju-ne',
        'Full Name': 'Koo Junhoe',
        'Korean Name': '구준회',
        'K Stage Name': '준회',
        'Date of Birth': '31/03/1997',
        Group: 'iKON',
        Debut: '15/09/2015',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jungah',
        'Full Name': 'Kim Jungah',
        'Korean Name': '김정아',
        'K Stage Name': '정아',
        'Date of Birth': '2/08/1983',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': 'After School',
        Gender: 'F'
    },
    {
        'Stage Name': 'Junghoon',
        'Full Name': 'Lee Junghoon',
        'Korean Name': '이정훈',
        'K Stage Name': '정훈',
        'Date of Birth': '4/07/2000',
        Group: '1TEAM',
        Debut: '27/03/2019',
        Company: 'LiveWorks',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junghoon',
        'Full Name': 'Han Jeonghoon',
        'Korean Name': '한정훈',
        'K Stage Name': '정훈',
        'Date of Birth': '14/02/2000',
        Group: 'OMEGA X',
        Debut: '17/10/2016',
        Company: 'JT Corea',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 61,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'ENOi',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junghoon',
        'Full Name': 'Kim Junghoon',
        'Korean Name': '김정훈',
        'K Stage Name': '정훈',
        'Date of Birth': '5/07/2005',
        Group: 'xikers',
        Debut: '30/03/2023',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junghun',
        'Full Name': 'Jang Junghun',
        'Korean Name': '장정훈',
        'K Stage Name': '정훈',
        'Date of Birth': '27/02/1999',
        Group: 'Astin',
        Debut: '16/11/2022',
        Company: 'AB Creative',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junghwa',
        'Full Name': 'Park Junghwa',
        'Korean Name': '박정화',
        'K Stage Name': '정화',
        'Date of Birth': '8/05/1995',
        Group: 'EXID',
        Debut: '16/02/2012',
        Company: 'Yedang',
        Country: 'South Korea',
        'Second Country': '',
        Height: 169,
        Weight: '',
        Birthplace: 'Anyang',
        'Other Group': '',
        'Former Group': 'EXID',
        Gender: 'F'
    },
    {
        'Stage Name': 'Junghwan',
        'Full Name': 'So Junghwan',
        'Korean Name': '소정환',
        'K Stage Name': '정환',
        'Date of Birth': '18/02/2005',
        Group: 'Treasure',
        Debut: '7/08/2020',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jungkook',
        'Full Name': 'Jeon Jungkook',
        'Korean Name': '전정국',
        'K Stage Name': '정국',
        'Date of Birth': '1/09/1997',
        Group: 'BTS',
        Debut: '13/06/2013',
        Company: 'Big Hit',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 66,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jungmo',
        'Full Name': 'Koo Jungmo',
        'Korean Name': '구정모',
        'K Stage Name': '정모',
        'Date of Birth': '5/02/2000',
        Group: 'CRAVITY',
        Debut: '14/04/2020',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 181,
        Weight: 58,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jungshin',
        'Full Name': 'Lee Jungshin',
        'Korean Name': '이정신',
        'K Stage Name': '정신',
        'Date of Birth': '15/09/1991',
        Group: 'CNBLUE',
        Debut: '14/01/2010',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jungwon',
        'Full Name': 'Yang Jungwon',
        'Korean Name': '양정원',
        'K Stage Name': '정원',
        'Date of Birth': '9/02/2004',
        Group: 'ENHYPEN',
        Debut: '30/11/2020',
        Company: 'Be:lift',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Jungwoo',
        'Full Name': 'Uhm Jungwoo',
        'Korean Name': '엄정우',
        'K Stage Name': '정우',
        'Date of Birth': '2/04/1999',
        Group: 'BVNDIT',
        Debut: '10/04/2019',
        Company: 'MNH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Jungwoo',
        'Full Name': 'Kim Jungwoo',
        'Korean Name': '김정우',
        'K Stage Name': '정우',
        'Date of Birth': '19/02/1998',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junho',
        'Full Name': 'Lee Junho',
        'Korean Name': '이준호',
        'K Stage Name': '준호',
        'Date of Birth': '25/01/1990',
        Group: '2PM',
        Debut: '4/07/2008',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 67,
        Birthplace: 'Ilsan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junho',
        'Full Name': 'Cha Junho',
        'Korean Name': '차준호',
        'K Stage Name': '준호',
        'Date of Birth': '9/07/2002',
        Group: 'DRIPPIN',
        Debut: '26/10/2020',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 63,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'X1',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junhyeok',
        'Full Name': 'Kim Junhyeok',
        'Korean Name': '임준혁',
        'K Stage Name': '준혁',
        'Date of Birth': '17/07/1993',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'DAY6',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junhyeok',
        'Full Name': 'Cheon Junhyeok',
        'Korean Name': '천준혁',
        'K Stage Name': '준혁',
        'Date of Birth': '20/09/2004',
        Group: 'TNX',
        Debut: '17/05/2022',
        Company: 'P NATION',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gyeonggi',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junhyuk',
        'Full Name': 'Choi Junhyuk',
        'Korean Name': '최준혁',
        'K Stage Name': '준혁',
        'Date of Birth': '21/04/1992',
        Group: 'HOTSHOT',
        Debut: '29/10/2014',
        Company: 'Star Crew',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Ulsan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junhyuk',
        'Full Name': 'Lee Junhyuk',
        'Korean Name': '이준혁',
        'K Stage Name': '이준혁',
        'Date of Birth': '16/05/2000',
        Group: 'Mirae',
        Debut: '17/03/2021',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 63,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junhyung',
        'Full Name': 'Son Junhyung',
        'Korean Name': '손준형',
        'K Stage Name': '준형',
        'Date of Birth': '21/10/2000',
        Group: 'Ghost9',
        Debut: '23/09/2020',
        Company: 'Maroo',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junhyung',
        'Full Name': 'Yong Junyung',
        'Korean Name': '용준형',
        'K Stage Name': '준형',
        'Date of Birth': '19/12/1989',
        Group: 'Highlight',
        Debut: '14/10/2009',
        Company: 'Around Us',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 64,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'Beast',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junji',
        'Full Name': 'Kim Junhyung',
        'Korean Name': '김준형',
        'K Stage Name': '준지',
        'Date of Birth': '6/04/1998',
        Group: 'OnlyOneOf',
        Debut: '28/05/2019',
        Company: 'RSVP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junkyu',
        'Full Name': 'Kim Junkyu',
        'Korean Name': '김준규',
        'K Stage Name': '준규',
        'Date of Birth': '9/09/2000',
        Group: 'Treasure',
        Debut: '7/08/2020',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junmin',
        'Full Name': 'Ryu Junmin',
        'Korean Name': '류준민',
        'K Stage Name': '준민',
        'Date of Birth': '5/04/2003',
        Group: 'ATBO',
        Debut: '27/07/2022',
        Company: 'IST',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: '',
        Birthplace: 'Jinju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junmin',
        'Full Name': 'Park Junmin',
        'Korean Name': '박준민',
        'K Stage Name': '준민',
        'Date of Birth': '24/05/2003',
        Group: 'xikers',
        Debut: '30/03/2023',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'JunQ',
        'Full Name': 'Kang Junkyu',
        'Korean Name': '강준규',
        'K Stage Name': '준규',
        'Date of Birth': '9/08/1993',
        Group: 'MYNAME',
        Debut: '27/10/2011',
        Company: 'H2',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Uljeongbu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junseo',
        'Full Name': 'Park Junseo',
        'Korean Name': '박준서',
        'K Stage Name': '준서',
        'Date of Birth': '28/12/2001',
        Group: 'BAE173',
        Debut: '19/11/2020',
        Company: 'PocketDol',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junseo',
        'Full Name': 'Hwang Junseo',
        'Korean Name': '황준서',
        'K Stage Name': '준서',
        'Date of Birth': '16/01/2001',
        Group: 'DKB',
        Debut: '3/02/2020',
        Company: 'Brave',
        Country: 'South Korea',
        'Second Country': '',
        Height: 182,
        Weight: 67,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junseo',
        'Full Name': 'Kim Junseo',
        'Korean Name': '김준서',
        'K Stage Name': '준서',
        'Date of Birth': '20/11/2001',
        Group: 'WEi',
        Debut: '5/10/2020',
        Company: 'OUI',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 64,
        Birthplace: 'Ulsan',
        'Other Group': '',
        'Former Group': '1the9',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junseok',
        'Full Name': 'Oh Junseok',
        'Korean Name': '오준석',
        'K Stage Name': '준석',
        'Date of Birth': '3/03/2003',
        Group: 'ATBO',
        Debut: '27/07/2022',
        Company: 'IST',
        Country: 'South Korea',
        'Second Country': '',
        Height: 174,
        Weight: '',
        Birthplace: 'Jeju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junseong',
        'Full Name': 'Choi Junseong',
        'Korean Name': '최준성',
        'K Stage Name': '준성',
        'Date of Birth': '29/09/2002',
        Group: 'Ghost9',
        Debut: '23/09/2020',
        Company: 'Maroo',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 55,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Juntae',
        'Full Name': 'Kim Juntae',
        'Korean Name': '김준태',
        'K Stage Name': '준태',
        'Date of Birth': '4/09/1995',
        Group: 'MY.st',
        Debut: '26/03/2020',
        Company: 'MYStar',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junwook',
        'Full Name': 'Kim Junwook',
        'Korean Name': '김준욱',
        'K Stage Name': '준욱',
        'Date of Birth': '10/01/2002',
        Group: 'TheEastLight',
        Debut: '15/11/2018',
        Company: 'Stardium',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Junyoung',
        'Full Name': 'Lee Junyoung',
        'Korean Name': '이준영',
        'K Stage Name': '준영',
        'Date of Birth': '23/09/2000',
        Group: 'IZ',
        Debut: '31/08/2017',
        Company: 'Music K',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Juri',
        'Full Name': 'Takahashi Juri',
        'Korean Name': '타카하시 쥬리',
        'K Stage Name': '쥬리',
        'Date of Birth': '3/10/1997',
        Group: 'Rocket Punch',
        Debut: '7/08/2019',
        Company: 'Woollim',
        Country: 'Japan',
        'Second Country': '',
        Height: 160,
        Weight: 44,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'J-Us',
        'Full Name': 'Lee Seungjoon',
        'Korean Name': '이승준',
        'K Stage Name': '제이어스',
        'Date of Birth': '13/01/1995',
        Group: 'ONF',
        Debut: '2/08/2017',
        Company: 'WM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Juyeon',
        'Full Name': 'Lee Juyeon',
        'Korean Name': '이주연',
        'K Stage Name': '주연',
        'Date of Birth': '6/11/2001',
        Group: 'Saturday',
        Debut: '18/07/2018',
        Company: 'SD',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 44,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Juyeon',
        'Full Name': 'Lee Juyeon',
        'Korean Name': '이주연',
        'K Stage Name': '주연',
        'Date of Birth': '16/01/1998',
        Group: 'The Boyz',
        Debut: '31/12/2017',
        Company: 'Cre.Ker',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 67,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'K.Me',
        'Full Name': 'Na Miri',
        'Korean Name': '나미리',
        'K Stage Name': '케이미',
        'Date of Birth': '10/04/1992',
        Group: 'BADKIZ',
        Debut: '24/03/2014',
        Company: 'Zoo',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yeosu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kady',
        'Full Name': 'Gu Dokyoung',
        'Korean Name': '구도경',
        'K Stage Name': '케이디',
        'Date of Birth': '7/04/1995',
        Group: 'Gate9',
        Debut: '26/01/1999',
        Company: 'JYP| SidusHQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kaede',
        'Full Name': 'Yamada Kaede',
        'Korean Name': '야마다 카에데',
        'K Stage Name': '카에데',
        'Date of Birth': '20/12/2005',
        Group: 'tripleS',
        Debut: '13/02/2023',
        Company: 'MODHAUS',
        Country: 'Japan',
        'Second Country': '',
        Height: 160,
        Weight: '',
        Birthplace: 'Toyama',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kaeun',
        'Full Name': 'Lee Kaeun',
        'Korean Name': '이가은',
        'K Stage Name': '가은',
        'Date of Birth': '20/08/1994',
        Group: 'After School',
        Debut: '15/01/2009',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kahi',
        'Full Name': 'Park Jiyoung',
        'Korean Name': '박지영',
        'K Stage Name': '가희',
        'Date of Birth': '25/12/1980',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': 'After School',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kai',
        'Full Name': 'Kim Jongin',
        'Korean Name': '김종인',
        'K Stage Name': '카이',
        'Date of Birth': '14/01/1994',
        Group: 'EXO',
        Debut: '8/04/2012',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 182,
        Weight: 65,
        Birthplace: 'Suncheon',
        'Other Group': 'SuperM',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kain',
        'Full Name': 'Bang Junho',
        'Korean Name': '방준호',
        'K Stage Name': '카인',
        'Date of Birth': '25/03/1997',
        Group: 'ARGON',
        Debut: '11/03/2019',
        Company: 'MSH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kairi',
        'Full Name': 'Imai Kairi',
        'Korean Name': '이마이 카이리',
        'K Stage Name': '카이리',
        'Date of Birth': '24/02/2003',
        Group: 'T1419',
        Debut: '21/09/2007',
        Company: 'CJ E&M',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kanghyun',
        'Full Name': 'Kang Hyungu',
        'Korean Name': '강현구',
        'K Stage Name': '강현',
        'Date of Birth': '24/11/1998',
        Group: 'Onewe',
        Debut: '9/01/2019',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'MAS',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kangin',
        'Full Name': 'Kim Youngwoon',
        'Korean Name': '김영운',
        'K Stage Name': '강인',
        'Date of Birth': '17/01/1985',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 70,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'Super Junior',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kangmin',
        'Full Name': 'Noh Kangmin',
        'Korean Name': '노강민',
        'K Stage Name': '강민',
        'Date of Birth': '5/09/1999',
        Group: 'Romeo',
        Debut: '4/11/2015',
        Company: 'CT',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kangmin',
        'Full Name': 'Kim Kangmin',
        'Korean Name': '김강민',
        'K Stage Name': '강민',
        'Date of Birth': '13/11/2001',
        Group: 'TRCNG',
        Debut: '10/10/2017',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 169,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kangmin',
        'Full Name': 'Yoo Kangmin',
        'Korean Name': '유강민',
        'K Stage Name': '강민',
        'Date of Birth': '25/01/2003',
        Group: 'Verivery',
        Debut: '1/09/2019',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kangsung',
        'Full Name': 'Lee Kangsung',
        'Korean Name': '이강성',
        'K Stage Name': '강성',
        'Date of Birth': '8/08/2002',
        Group: 'Ghost9',
        Debut: '23/09/2020',
        Company: 'Maroo',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Karin',
        'Full Name': 'Min Karin',
        'Korean Name': '민가린',
        'K Stage Name': '가린',
        'Date of Birth': '5/01/2002',
        Group: 'ALICE',
        Debut: '1/07/2017',
        Company: 'IOK Company',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Karin',
        'Full Name': 'Baek Yerin',
        'Korean Name': '백예린',
        'K Stage Name': '카린',
        'Date of Birth': '23/04/2000',
        Group: 'CRAXY',
        Debut: '3/03/2020',
        Company: 'SAITEINMENT',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Karina',
        'Full Name': 'Yoo Jimin',
        'Korean Name': '유지민',
        'K Stage Name': '카리나',
        'Date of Birth': '11/04/2000',
        Group: 'aespa',
        Debut: '17/11/2020',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 45,
        Birthplace: 'Gyeonggi',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F',
        'Image URL': '/kpop/aespa/karina/1.png'
    },
    {
        'Stage Name': 'Kazuha',
        'Full Name': 'Nakamura Kazuha',
        'Korean Name': '나카무라 카즈하',
        'K Stage Name': '카즈하',
        'Date of Birth': '9/08/2003',
        Group: 'LE SSERAFIM',
        Debut: '2/05/2022',
        Company: 'Source',
        Country: 'Japan',
        'Second Country': '',
        Height: 174,
        Weight: '',
        Birthplace: 'Osaka',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'KB',
        'Full Name': 'Shin Kyubin',
        'Korean Name': '신규빈',
        'K Stage Name': '규빈',
        'Date of Birth': '23/04/1994',
        Group: 'OnlyOneOf',
        Debut: '28/05/2019',
        Company: 'RSVP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Keeho',
        'Full Name': 'Yoon Kiho',
        'Korean Name': '윤기호',
        'K Stage Name': '기호',
        'Date of Birth': '27/09/2001',
        Group: 'P1Harmony',
        Debut: '28/10/2020',
        Company: 'FNC',
        Country: 'Canada',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Keena',
        'Full Name': 'Song Jakyung',
        'Korean Name': '송자경',
        'K Stage Name': '키나',
        'Date of Birth': '9/07/2002',
        Group: 'FIFTY FIFTY',
        Debut: '18/11/2022',
        Company: 'ATTRAKT',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 45,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kei',
        'Full Name': 'Kim Jiyeon',
        'Korean Name': '김지연',
        'K Stage Name': '케이',
        'Date of Birth': '20/03/1995',
        Group: 'Lovelyz',
        Debut: '17/11/2014',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: 47,
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Keina',
        'Full Name': 'Lee Yoona',
        'Korean Name': '이유나',
        'K Stage Name': '케이나',
        'Date of Birth': '12/02/1999',
        Group: 'H.U.B',
        Debut: '9/02/2017',
        Company: 'New Planet',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Keita',
        'Full Name': 'Terazono Keita',
        'Korean Name': '테라조노 케이타',
        'K Stage Name': '케이타',
        'Date of Birth': '4/07/2001',
        Group: 'Ciipher',
        Debut: '15/03/2021',
        Company: 'Rain',
        Country: 'Japan',
        'Second Country': '',
        Height: 170,
        Weight: 59,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kelly',
        'Full Name': 'Lin Kelly',
        'Korean Name': '린 켈리',
        'K Stage Name': '켈리',
        'Date of Birth': '16/01/2002',
        Group: 'TRI.BE',
        Debut: '17/02/2021',
        Company: 'TR - Mellow',
        Country: 'Taiwan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Kaohsiung',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ken',
        'Full Name': 'Lee Jaehwan',
        'Korean Name': '이재환',
        'K Stage Name': '켄',
        'Date of Birth': '6/04/1992',
        Group: 'VIXX',
        Debut: '24/05/2012',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 65,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kenta',
        'Full Name': 'Takada Kenta',
        'Korean Name': '타카다 켄타',
        'K Stage Name': '켄타',
        'Date of Birth': '10/01/1995',
        Group: 'JBJ',
        Debut: '18/10/2017',
        Company: 'Fave',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Fujioka',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Keonhee',
        'Full Name': 'Lee Keonhee',
        'Korean Name': '김건희',
        'K Stage Name': '건희',
        'Date of Birth': '27/06/1998',
        Group: 'Oneus',
        Debut: '9/01/2019',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Keonwoo',
        'Full Name': 'Kim Keonwoo',
        'Korean Name': '김건우',
        'K Stage Name': '건우',
        'Date of Birth': '17/12/1996',
        Group: 'MY.st',
        Debut: '26/03/2020',
        Company: 'MYStar',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Keum',
        'Full Name': 'Keum Donghyun',
        'Korean Name': '금동현',
        'K Stage Name': '금',
        'Date of Birth': '14/05/2003',
        Group: 'EPEX',
        Debut: '8/06/2021',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 57,
        Birthplace: 'Okcheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Keumjo',
        'Full Name': 'Lee Keumjo',
        'Korean Name': '이금조',
        'K Stage Name': '금조',
        'Date of Birth': '17/12/1992',
        Group: '9Muses',
        Debut: '12/08/2010',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 48,
        Birthplace: 'Daegu',
        'Other Group': '9MUSES A',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kevin',
        'Full Name': 'Park Jinwoo',
        'Korean Name': '박진우',
        'K Stage Name': '케빈',
        'Date of Birth': '12/01/2000',
        Group: 'OMEGA X',
        Debut: '17/10/2016',
        Company: 'JT Corea',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 51,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'ENOi',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kevin',
        'Full Name': 'Baek Seungbin',
        'Korean Name': '백승빈',
        'K Stage Name': '케빈',
        'Date of Birth': '8/04/2001',
        Group: 'T1419',
        Debut: '21/09/2007',
        Company: 'CJ E&M',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kevin',
        'Full Name': 'Moon Hyungseo',
        'Korean Name': '문형서',
        'K Stage Name': '케빈',
        'Date of Birth': '23/02/1998',
        Group: 'The Boyz',
        Debut: '31/12/2017',
        Company: 'Cre.Ker',
        Country: 'Canada',
        'Second Country': 'South Korea',
        Height: 176,
        Weight: 66,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kevin',
        'Full Name': 'Christopher Woo',
        'Korean Name': '크리스토퍼 우',
        'K Stage Name': '케빈',
        'Date of Birth': '25/11/1991',
        Group: 'U-KISS',
        Debut: '28/08/2008',
        Company: 'NH Media',
        Country: 'USA',
        'Second Country': 'South Korea',
        Height: '',
        Weight: '',
        Birthplace: 'Los Angeles',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kevin',
        'Full Name': 'Kevin Jiyeop Kim',
        'Korean Name': '케빈 지엽 킴',
        'K Stage Name': '케빈',
        'Date of Birth': '23/02/1988',
        Group: 'ZE:A',
        Debut: '7/01/2010',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': 'Australia',
        Height: '',
        Weight: '',
        Birthplace: 'Sydney',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Key',
        'Full Name': 'Kim Kibum',
        'Korean Name': '김기범',
        'K Stage Name': '키',
        'Date of Birth': '23/09/1991',
        Group: 'SHINee',
        Debut: '25/05/2008',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 59,
        Birthplace: 'Daegu',
        'Other Group': 'Toheart',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Khael',
        'Full Name': 'Lee Sangmin',
        'Korean Name': '이상민',
        'K Stage Name': '카엘',
        'Date of Birth': '18/01/2002',
        Group: 'Mirae',
        Debut: '17/03/2021',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 58,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kiara',
        'Full Name': 'Baek Jinjoo',
        'Korean Name': '백진주',
        'K Stage Name': '키아라',
        'Date of Birth': '20/08/1998',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kibum',
        'Full Name': 'Kim Kibum',
        'Korean Name': '김기범',
        'K Stage Name': '기범',
        'Date of Birth': '21/08/1987',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 58,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'Super Junior',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kichun',
        'Full Name': 'Hwang Kichun',
        'Korean Name': '황기천',
        'K Stage Name': '기천',
        'Date of Birth': '14/03/1992',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 63,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Bigflo',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kid',
        'Full Name': 'Kim Junhoe',
        'Korean Name': '김준회',
        'K Stage Name': '키드',
        'Date of Birth': '26/10/1995',
        Group: 'VARSITY',
        Debut: '5/01/2017',
        Company: 'GKC',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 58,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kidoh',
        'Full Name': 'Jin Hyosang',
        'Korean Name': '진효상',
        'K Stage Name': '키도',
        'Date of Birth': '16/12/1992',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'Topp Dogg',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kihyun',
        'Full Name': 'Yoo Kihyun',
        'Korean Name': '유기현',
        'K Stage Name': '기현',
        'Date of Birth': '22/11/1993',
        Group: 'MONSTA X',
        Debut: '14/05/2014',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Goyang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kim Lip',
        'Full Name': 'Kim Jungeun',
        'Korean Name': '김정은',
        'K Stage Name': '김립',
        'Date of Birth': '10/02/1999',
        Group: 'LOONA',
        Debut: '20/08/2018',
        Company: 'Blockberry',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'Loona Odd Eye Circle| ARTMS',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kim Sanggyun',
        'Full Name': 'Kim Sanggyun',
        'Korean Name': '김상균',
        'K Stage Name': '김상균',
        'Date of Birth': '23/05/1995',
        Group: 'JBJ95',
        Debut: '30/10/2018',
        Company: 'Hunus',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': 'JBJ',
        'Former Group': 'Topp Dogg| XENO-T',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kim Yoon',
        'Full Name': 'Kim Seongsu',
        'Korean Name': '김성수',
        'K Stage Name': '김윤',
        'Date of Birth': '17/02/1994',
        Group: 'DUSTIN',
        Debut: '6/01/2020',
        Company: 'LPA',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: 65,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kino',
        'Full Name': 'Kang Hyungku',
        'Korean Name': '강형구',
        'K Stage Name': '키노',
        'Date of Birth': '27/01/1998',
        Group: 'PENTAGON',
        Debut: '10/10/2016',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kio',
        'Full Name': 'Udo Musashi',
        'Korean Name': '우도 무사시',
        'K Stage Name': '키오',
        'Date of Birth': '28/11/2004',
        Group: 'T1419',
        Debut: '21/09/2007',
        Company: 'CJ E&M',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kiseop',
        'Full Name': 'Lee Kiseop',
        'Korean Name': '이기섭',
        'K Stage Name': '기섭',
        'Date of Birth': '17/01/1991',
        Group: 'U-KISS',
        Debut: '28/08/2008',
        Company: 'NH Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seongnam',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kisu',
        'Full Name': 'Choi Kisu',
        'Korean Name': '최기수',
        'K Stage Name': '기수',
        'Date of Birth': '2/10/1990',
        Group: '24K',
        Debut: '6/09/2012',
        Company: 'Choeun',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 60,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kiwon',
        'Full Name': 'Lee Kiwon',
        'Korean Name': '이기원',
        'K Stage Name': '기원',
        'Date of Birth': '27/06/1996',
        Group: 'GIDONGDAE',
        Debut: '3/04/2013',
        Company: 'Simtong',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'RAINZ',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kokoro',
        'Full Name': 'Kato Kokoro',
        'Korean Name': '카토 코코로',
        'K Stage Name': '코코로',
        'Date of Birth': '1/11/2000',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Cherry Bullet',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kongyeol',
        'Full Name': 'Go Minsoo',
        'Korean Name': '고민수',
        'K Stage Name': '고결',
        'Date of Birth': '19/05/1996',
        Group: 'UP10TION',
        Debut: '10/09/2015',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Bucheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kongyoo',
        'Full Name': 'Kong Yoojin',
        'Korean Name': '공유진',
        'K Stage Name': '공유',
        'Date of Birth': '11/05/2001',
        Group: 'Bonus Baby',
        Debut: '1/01/2017',
        Company: 'Maroo',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kookheon',
        'Full Name': 'Kim Kookheon',
        'Korean Name': '김국헌',
        'K Stage Name': '국헌',
        'Date of Birth': '15/04/1997',
        Group: 'B.O.Y',
        Debut: '20/01/2020',
        Company: 'Music Works',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'MYTEEN',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kotone',
        'Full Name': 'Kamimoto Kotone',
        'Korean Name': '카미모토 코토네',
        'K Stage Name': '코토네',
        'Date of Birth': '10/03/2004',
        Group: 'tripleS',
        Debut: '13/02/2023',
        Company: 'MODHAUS',
        Country: 'Japan',
        'Second Country': '',
        Height: 162,
        Weight: '',
        Birthplace: 'Tokyo',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kriesha Chu',
        'Full Name': 'Kriesha Ziskind Teo Tiu',
        'Korean Name': '크리샤 지스킨드 테오 티우',
        'K Stage Name': '크리샤 츄',
        'Date of Birth': '20/12/1998',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'Philippines',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Cebu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kris',
        'Full Name': 'Wu Yifan',
        'Korean Name': '오역범',
        'K Stage Name': '크리스',
        'Date of Birth': '6/11/1990',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'Canada',
        'Second Country': 'China',
        Height: 187,
        Weight: 73,
        Birthplace: 'Guangzhou',
        'Other Group': '',
        'Former Group': 'EXO',
        Gender: 'M'
    },
    {
        'Stage Name': 'Krystal',
        'Full Name': 'Krystal Jung',
        'Korean Name': '크리스탈 정',
        'K Stage Name': '크리스탈',
        'Date of Birth': '24/10/1994',
        Group: 'f(x)',
        Debut: '5/09/2009',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'San Francisco',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kuhn',
        'Full Name': 'Noh Sooil',
        'Korean Name': '노수일',
        'K Stage Name': '쿤',
        'Date of Birth': '11/11/1995',
        Group: 'UP10TION',
        Debut: '10/09/2015',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Ansan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kun',
        'Full Name': 'Qian Kun',
        'Korean Name': '첸쿤',
        'K Stage Name': '쿤',
        'Date of Birth': '1/01/1996',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'China',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kwanghee',
        'Full Name': 'Hwang Kwanghee',
        'Korean Name': '황광희',
        'K Stage Name': '광희',
        'Date of Birth': '25/08/1988',
        Group: 'ZE:A',
        Debut: '7/01/2010',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Paju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kwangjin',
        'Full Name': 'Kwon Kwangjin',
        'Korean Name': '권광진',
        'K Stage Name': '광진',
        'Date of Birth': '12/08/1992',
        Group: 'N.Flying',
        Debut: '20/05/2015',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'CNBLUE',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kwangmin',
        'Full Name': 'Jo Kwangmin',
        'Korean Name': '조광민',
        'K Stage Name': '광민',
        'Date of Birth': '24/04/1995',
        Group: 'Boyfriend',
        Debut: '26/05/2011',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: '',
        Birthplace: 'Anyang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kyeongheon',
        'Full Name': 'Yoon Kyeongheon',
        'Korean Name': '윤경헌',
        'K Stage Name': '경헌',
        'Date of Birth': '6/01/1998',
        Group: 'We In The Zone',
        Debut: '27/05/2019',
        Company: 'Choon',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 60,
        Birthplace: 'Suncheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kyla',
        'Full Name': 'Kyla Massie',
        'Korean Name': '카일라 매시',
        'K Stage Name': '카일라',
        'Date of Birth': '26/12/2001',
        Group: 'Pristin',
        Debut: '21/03/2017',
        Company: 'Pledis',
        Country: 'USA',
        'Second Country': 'South Korea',
        Height: '',
        Weight: '',
        Birthplace: 'California',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kyle',
        'Full Name': 'Ma Jaekyung',
        'Korean Name': '마재경',
        'K Stage Name': '카일',
        'Date of Birth': '15/01/1997',
        Group: 'Romeo',
        Debut: '4/11/2015',
        Company: 'CT',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kyrin',
        'Full Name': 'Park Geumju',
        'Korean Name': '박금주',
        'K Stage Name': '카이린',
        'Date of Birth': '23/02/2000',
        Group: "Girls' World",
        Debut: '20/06/2002',
        Company: 'Liz',
        Country: 'South Korea',
        'Second Country': '',
        Height: 156,
        Weight: '',
        Birthplace: 'Daejeon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kyuhwan',
        'Full Name': 'Lee Kyuhwan',
        'Korean Name': '이규환',
        'K Stage Name': '규환',
        'Date of Birth': '18/09/1994',
        Group: 'SIGNAL',
        Debut: '28/08/2019',
        Company: 'J-Star',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'E7',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kyuhyuk',
        'Full Name': 'Shim Kyuhyuk',
        'Korean Name': '심규혁',
        'K Stage Name': '규혁',
        'Date of Birth': '11/01/1995',
        Group: 'Hot Blood Youth',
        Debut: '17/05/2017',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kyuhyun',
        'Full Name': 'Cho Kyuhyun',
        'Korean Name': '조규현',
        'K Stage Name': '규현',
        'Date of Birth': '3/02/1988',
        Group: 'Super Junior',
        Debut: '6/11/2005',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 68,
        Birthplace: 'Seoul',
        'Other Group': 'Super Junior-M',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kyujin',
        'Full Name': 'Jang Kyujin',
        'Korean Name': '장규진',
        'K Stage Name': '규진',
        'Date of Birth': '26/05/2006',
        Group: 'NMIXX',
        Debut: '22/02/2022',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kyulkyung',
        'Full Name': 'Zhou Jieqiong',
        'Korean Name': '저우제충',
        'K Stage Name': '결경',
        'Date of Birth': '16/12/1998',
        Group: 'Pristin',
        Debut: '21/03/2017',
        Company: 'Pledis',
        Country: 'China',
        'Second Country': '',
        Height: 167,
        Weight: 49,
        Birthplace: 'Taizhou',
        'Other Group': 'I.O.I| Pristin V',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kyungho',
        'Full Name': 'Jang Kyungho',
        'Korean Name': '장경호',
        'K Stage Name': '경호',
        'Date of Birth': '7/05/2001',
        Group: 'TOO',
        Debut: '1/04/2020',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kyungil',
        'Full Name': 'Song Kyungil',
        'Korean Name': '송경일',
        'K Stage Name': '경일',
        'Date of Birth': '28/11/1987',
        Group: 'HISTORY',
        Debut: '26/04/2013',
        Company: 'LOEN',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: 72,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kyungjun',
        'Full Name': 'Woo Kyungjun',
        'Korean Name': '우경준',
        'K Stage Name': '경준',
        'Date of Birth': '30/08/2002',
        Group: 'TNX',
        Debut: '17/05/2022',
        Company: 'P NATION',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kyungmin',
        'Full Name': 'Jo Kyungmin',
        'Korean Name': '조경민',
        'K Stage Name': '경민',
        'Date of Birth': '28/10/2004',
        Group: '8TURN',
        Debut: '30/01/2023',
        Company: 'MNH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 187,
        Weight: 60,
        Birthplace: 'Gyeonggi',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kyungmun',
        'Full Name': 'Lim Kyungmun',
        'Korean Name': '임경문',
        'K Stage Name': '경문',
        'Date of Birth': '29/06/2003',
        Group: 'YOUNITE',
        Debut: '20/04/2022',
        Company: 'Brand New',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Kyungri',
        'Full Name': 'Park Kyungri',
        'Korean Name': '박경리',
        'K Stage Name': '경리',
        'Date of Birth': '5/07/1990',
        Group: '9Muses',
        Debut: '12/08/2010',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 50,
        Birthplace: 'Busan',
        'Other Group': '9MUSES A',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Kyungyoon',
        'Full Name': 'Lee Kyungyoon',
        'Korean Name': '이경윤',
        'K Stage Name': '경윤',
        'Date of Birth': '21/02/2000',
        Group: 'Dongkiz',
        Debut: '24/04/2019',
        Company: 'Dongyo',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 58,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'L',
        'Full Name': 'Kim Myungsoo',
        'Korean Name': '김명수',
        'K Stage Name': '엘',
        'Date of Birth': '13/03/1992',
        Group: 'Infinite',
        Debut: '9/06/2010',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 60,
        Birthplace: 'Seoul',
        'Other Group': 'Infinite F',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'L.Joe',
        'Full Name': 'Lee Byunghun',
        'Korean Name': '이병헌',
        'K Stage Name': '엘조',
        'Date of Birth': '23/11/1993',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gunsan',
        'Other Group': '',
        'Former Group': 'Teen Top',
        Gender: 'M'
    },
    {
        'Stage Name': 'Lai Kuanlin',
        'Full Name': 'Lai Kuanlin',
        'Korean Name': '라이관린',
        'K Stage Name': '라이관린',
        'Date of Birth': '23/09/2001',
        Group: 'Wanna One',
        Debut: '7/08/2017',
        Company: 'YMC',
        Country: 'Taiwan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'New Taipei',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Laon',
        'Full Name': 'Shin Kyuhyun',
        'Korean Name': '신규현',
        'K Stage Name': '라온',
        'Date of Birth': '19/12/1995',
        Group: 'ENOi',
        Debut: '19/04/2019',
        Company: 'KITHEWHALE',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 60,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Lara',
        'Full Name': 'Moon Shinae',
        'Korean Name': '문신애',
        'K Stage Name': '라라',
        'Date of Birth': '9/08/2000',
        Group: 'Dreamnote',
        Debut: '7/11/2018',
        Company: 'iME',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: 44,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Laun',
        'Full Name': 'Kim Minseok',
        'Korean Name': '김민석',
        'K Stage Name': '라운',
        'Date of Birth': '12/08/1999',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seongsan',
        'Other Group': '',
        'Former Group': 'ONF',
        Gender: 'M'
    },
    {
        'Stage Name': 'Lay',
        'Full Name': 'Zhang Yixing',
        'Korean Name': '장이씽',
        'K Stage Name': '레이',
        'Date of Birth': '7/10/1991',
        Group: 'EXO',
        Debut: '8/04/2012',
        Company: 'SM',
        Country: 'China',
        'Second Country': '',
        Height: 177,
        Weight: 60,
        Birthplace: 'Changsa',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Layeon',
        'Full Name': 'Lee Jimin',
        'Korean Name': '이지민',
        'K Stage Name': '라연',
        'Date of Birth': '13/08/1998',
        Group: 'Purplebeck',
        Debut: '24/06/2019',
        Company: 'Majesty',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 51,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'LE',
        'Full Name': 'Ahn Hyejin',
        'Korean Name': '안효진',
        'K Stage Name': '엘리',
        'Date of Birth': '10/12/1991',
        Group: 'EXID',
        Debut: '16/02/2012',
        Company: 'Yedang',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: 'Cheonan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lea',
        'Full Name': 'Ogawa Mizuki',
        'Korean Name': '오가와 미즈키',
        'K Stage Name': '레아',
        'Date of Birth': '12/08/1995',
        Group: 'SECRET NUMBER',
        Debut: '19/05/2020',
        Company: 'Vine',
        Country: 'Japan',
        'Second Country': '',
        Height: 169,
        Weight: '',
        Birthplace: 'Tokyo',
        'Other Group': '',
        'Former Group': 'Skarf',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lee Haein',
        'Full Name': 'Lee Haein',
        'Korean Name': '이해인',
        'K Stage Name': '이해인',
        'Date of Birth': '4/07/1992',
        Group: 'I.B.I',
        Debut: '29/10/2014',
        Company: 'Star Crew',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 49,
        Birthplace: 'Changwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lee Hoo',
        'Full Name': 'Moon Junyoung',
        'Korean Name': '문준영',
        'K Stage Name': '',
        'Date of Birth': '9/02/1989',
        Group: 'ZE:A',
        Debut: '7/01/2010',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Lee Hyeop',
        'Full Name': 'Lee Hyeop',
        'Korean Name': '이협',
        'K Stage Name': '이협',
        'Date of Birth': '13/08/1999',
        Group: 'DRIPPIN',
        Debut: '26/10/2020',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 57,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Lee Jin',
        'Full Name': 'Shim Yerin',
        'Korean Name': '심예린',
        'K Stage Name': '이진',
        'Date of Birth': '12/03/2001',
        Group: 'Hi-L',
        Debut: '11/08/2021',
        Company: 'Kpop Live',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lee Joon',
        'Full Name': 'Lee Changseon',
        'Korean Name': '이창선',
        'K Stage Name': '이준',
        'Date of Birth': '7/02/1988',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'MBLAQ',
        Gender: 'M'
    },
    {
        'Stage Name': 'Lee Know',
        'Full Name': 'Lee Minho',
        'Korean Name': '이민호',
        'K Stage Name': '리노',
        'Date of Birth': '25/10/1998',
        Group: 'Stray Kids',
        Debut: '25/03/2018',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gimpo',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Lee Soo',
        'Full Name': 'Jin Isoo',
        'Korean Name': '진이수',
        'K Stage Name': '이수',
        'Date of Birth': '27/06/2001',
        Group: 'PRECIOUS',
        Debut: '23/09/2020',
        Company: 'Umi',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: 47,
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lee Suhyun',
        'Full Name': 'Lee Suhyun',
        'Korean Name': '이수현',
        'K Stage Name': '이수현',
        'Date of Birth': '5/11/1996',
        Group: 'I.B.I',
        Debut: '29/10/2014',
        Company: 'Star Crew',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 50,
        Birthplace: 'Ansan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Leedo',
        'Full Name': 'Kim Geonhak',
        'Korean Name': '김건학',
        'K Stage Name': '이도',
        'Date of Birth': '26/07/1997',
        Group: 'Oneus',
        Debut: '9/01/2019',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Leeseo',
        'Full Name': 'Lee Hyunseo',
        'Korean Name': '이현서',
        'K Stage Name': '이서',
        'Date of Birth': '21/02/2007',
        Group: 'IVE',
        Debut: '1/12/2021',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 48,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Leeteuk',
        'Full Name': 'Park Jeongsu',
        'Korean Name': '박정수',
        'K Stage Name': '이특',
        'Date of Birth': '1/07/1983',
        Group: 'Super Junior',
        Debut: '6/11/2005',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 59,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Leia',
        'Full Name': 'Larissa Kartes',
        'Korean Name': '라리사 카르치스',
        'K Stage Name': '레아',
        'Date of Birth': '14/05/2001',
        Group: 'Blackswan',
        Debut: '16/10/2020',
        Company: 'DR',
        Country: 'Brazil',
        'Second Country': 'Japan',
        Height: '',
        Weight: '',
        Birthplace: 'Curitiba',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lena',
        'Full Name': 'Kang Rena',
        'Korean Name': '강레나',
        'K Stage Name': '레나',
        'Date of Birth': '17/04/2002',
        Group: 'GWSN',
        Debut: '5/09/2018',
        Company: 'Kiwi Pop',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Leo',
        'Full Name': 'Hayase Leo',
        'Korean Name': '하야세 레오',
        'K Stage Name': '레오',
        'Date of Birth': '8/10/2002',
        Group: 'T1419',
        Debut: '21/09/2007',
        Company: 'CJ E&M',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Leo',
        'Full Name': 'Jung Taekwon',
        'Korean Name': '정택운',
        'K Stage Name': '레오',
        'Date of Birth': '10/11/1990',
        Group: 'VIXX',
        Debut: '24/05/2012',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: 63,
        Birthplace: 'Seoul',
        'Other Group': 'VIXX LR',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Leon',
        'Full Name': 'Kim Donghyun',
        'Korean Name': '김동현',
        'K Stage Name': '리온',
        'Date of Birth': '25/07/2000',
        Group: 'TRENDZ',
        Debut: '5/01/2022',
        Company: 'Interpark',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Lew',
        'Full Name': 'Lee Euiwoong',
        'Korean Name': '이의웅',
        'K Stage Name': '루',
        'Date of Birth': '5/04/2001',
        Group: 'TEMPEST',
        Debut: '2/03/2022',
        Company: 'Yuehua',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 55,
        Birthplace: '',
        'Other Group': 'HyeongseopXEuiwoong',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Lex',
        'Full Name': 'Jeon Hyungmin',
        'Korean Name': '전형민',
        'K Stage Name': '렉스',
        'Date of Birth': '12/01/1993',
        Group: 'Bigflo',
        Debut: '19/07/2014',
        Company: 'HO',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Lia',
        'Full Name': 'Choi Jisu',
        'Korean Name': '최지수',
        'K Stage Name': '리아',
        'Date of Birth': '21/07/2000',
        Group: 'ITZY',
        Debut: '12/02/2019',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lien',
        'Full Name': 'Shimada Sho',
        'Korean Name': '시마다 쇼',
        'K Stage Name': '리안',
        'Date of Birth': '11/03/1998',
        Group: 'Mirae',
        Debut: '17/03/2021',
        Company: 'DSP',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Osaka',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Lily',
        'Full Name': 'Lily Jin Morrow',
        'Korean Name': '릴리 진 머로우',
        'K Stage Name': '릴리',
        'Date of Birth': '17/10/2002',
        Group: 'NMIXX',
        Debut: '22/02/2022',
        Company: 'JYP',
        Country: 'Australia',
        'Second Country': 'South Korea',
        Height: '',
        Weight: '',
        Birthplace: 'Marysville',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lim Jimin',
        'Full Name': 'Lim Jimin',
        'Korean Name': '임지민',
        'K Stage Name': '임지민',
        'Date of Birth': '22/05/2001',
        Group: 'JUST B',
        Debut: '30/06/2021',
        Company: 'Bluedot',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 59,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Lime',
        'Full Name': 'Kim Hyerim',
        'Korean Name': '김혜림',
        'K Stage Name': '라임',
        'Date of Birth': '19/01/1993',
        Group: 'Hello Venus',
        Debut: '9/05/2012',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: 169,
        Weight: 50,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lin Lin',
        'Full Name': 'Huang Ziting',
        'Korean Name': '황자정',
        'K Stage Name': '린린',
        'Date of Birth': '5/07/2003',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'Taiwan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Cherry Bullet',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lina',
        'Full Name': 'Oh Sejin',
        'Korean Name': '오세진',
        'K Stage Name': '리나',
        'Date of Birth': '27/07/1997',
        Group: 'ANS',
        Debut: '16/09/2019',
        Company: 'ANS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lina',
        'Full Name': 'Oh Hyesoo',
        'Korean Name': '오혜수',
        'K Stage Name': '리나',
        'Date of Birth': '23/05/1997',
        Group: 'RedSquare',
        Debut: '19/05/2020',
        Company: 'About',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Linzy',
        'Full Name': 'Im Minji',
        'Korean Name': '임민지',
        'K Stage Name': '린지',
        'Date of Birth': '22/10/1989',
        Group: 'Fiestar',
        Debut: '31/08/2012',
        Company: 'LOEN',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lisa',
        'Full Name': 'Pranpriya Manoban',
        'Korean Name': '쁘란쁘리야 마노반',
        'K Stage Name': '리사',
        'Date of Birth': '27/03/1997',
        Group: 'BLACKPINK',
        Debut: '8/08/2016',
        Company: 'YG',
        Country: 'Thailand',
        'Second Country': '',
        Height: 170,
        Weight: 46,
        Birthplace: 'Bangkok',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Liv',
        'Full Name': 'Kim Chaeyoung',
        'Korean Name': '김채영',
        'K Stage Name': '리브',
        'Date of Birth': '5/07/1997',
        Group: 'IRRIS',
        Debut: '6/07/2022',
        Company: 'Mellow',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gunsan',
        'Other Group': '',
        'Former Group': 'Good Day| RedSquare',
        Gender: 'F'
    },
    {
        'Stage Name': 'Liyah',
        'Full Name': 'Moon Liya',
        'Korean Name': '문리야',
        'K Stage Name': '리야',
        'Date of Birth': '11/05/2000',
        Group: 'MIXX',
        Debut: '2/05/2016',
        Company: 'Chiko',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 52,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Liz',
        'Full Name': 'Kim Jiwon',
        'Korean Name': '김지원',
        'K Stage Name': '리즈',
        'Date of Birth': '21/11/2004',
        Group: 'IVE',
        Debut: '1/12/2021',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 49,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lizzy',
        'Full Name': 'Park Sooyoung',
        'Korean Name': '박수영',
        'K Stage Name': '리지',
        'Date of Birth': '31/07/1992',
        Group: 'After School',
        Debut: '15/01/2009',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': 'Orange Caramel',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Loha',
        'Full Name': 'Kang Yejin',
        'Korean Name': '강예진',
        'K Stage Name': '로하',
        'Date of Birth': '27/12/2001',
        Group: 'Nature',
        Debut: '3/08/2018',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lola',
        'Full Name': 'Choi Yoojung',
        'Korean Name': '최유정',
        'K Stage Name': '로라',
        'Date of Birth': '22/02/2001',
        Group: 'Pixy',
        Debut: '24/02/2021',
        Company: 'ALLART',
        Country: 'South Korea',
        'Second Country': '',
        Height: 169,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Longguo',
        'Full Name': 'Jin Longguo',
        'Korean Name': '진룽궈',
        'K Stage Name': '룽궈',
        'Date of Birth': '2/03/1996',
        Group: 'JBJ',
        Debut: '18/10/2017',
        Company: 'Fave',
        Country: 'China',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Heilong',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Lou',
        'Full Name': 'Kim Hosung',
        'Korean Name': '김호성',
        'K Stage Name': '로우',
        'Date of Birth': '21/12/1996',
        Group: 'VAV',
        Debut: '31/10/2015',
        Company: 'A team',
        Country: 'South Korea',
        'Second Country': '',
        Height: 187,
        Weight: 69,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Loudi',
        'Full Name': 'Won Jihoon',
        'Korean Name': '원지훈',
        'K Stage Name': '로우디',
        'Date of Birth': '13/04/1996',
        Group: '14U',
        Debut: '17/04/2017',
        Company: 'BG',
        Country: 'Indonesia',
        'Second Country': 'South Korea| China',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Louis',
        'Full Name': 'Kim Taewoo',
        'Korean Name': '김태우',
        'K Stage Name': '루이',
        'Date of Birth': '23/04/1999',
        Group: 'BLANK2Y',
        Debut: '24/05/2022',
        Company: 'Keystone',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: 62,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '1the9',
        Gender: 'M'
    },
    {
        'Stage Name': 'Louis',
        'Full Name': 'Yang Dongsik',
        'Korean Name': '양동식',
        'K Stage Name': '루이',
        'Date of Birth': '8/04/2001',
        Group: 'Kingdom',
        Debut: '18/02/2021',
        Company: 'GF',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Love',
        'Full Name': 'Park Jisung',
        'Korean Name': '박지성',
        'K Stage Name': '러브',
        'Date of Birth': '17/01/1994',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'OnlyOneOf',
        Gender: 'M'
    },
    {
        'Stage Name': 'Lu',
        'Full Name': 'Im Hayoung',
        'Korean Name': '임하영',
        'K Stage Name': '루',
        'Date of Birth': '3/12/1997',
        Group: 'NATURE',
        Debut: '3/08/2018',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'LuA',
        'Full Name': 'Jo Hankyeung',
        'Korean Name': '조한경',
        'K Stage Name': '루아',
        'Date of Birth': '14/09/1991',
        Group: 'BADKIZ',
        Debut: '24/03/2014',
        Company: 'Zoo',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lua',
        'Full Name': 'Kim Sookyung',
        'Korean Name': '김수경',
        'K Stage Name': '루아',
        'Date of Birth': '6/10/2000',
        Group: 'Weki Meki',
        Debut: '8/08/2017',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lucas',
        'Full Name': 'Wong Junghei',
        'Korean Name': '웡육헤이',
        'K Stage Name': '루카스',
        'Date of Birth': '25/01/1999',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'Hong Kong',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'SuperM',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Lucy',
        'Full Name': 'Noh Hyojung',
        'Korean Name': '노효정',
        'K Stage Name': '루시',
        'Date of Birth': '31/08/2002',
        Group: 'Weki Meki',
        Debut: '8/08/2017',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Goyang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lucy',
        'Full Name': 'Kim Daeun',
        'Korean Name': '김대은',
        'K Stage Name': '루시',
        'Date of Birth': '9/04/2004',
        Group: 'woo!ah!',
        Debut: '15/05/2020',
        Company: 'NV',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Luda',
        'Full Name': 'Lee Luda',
        'Korean Name': '이루다',
        'K Stage Name': '루다',
        'Date of Birth': '6/03/1997',
        Group: 'WJSN',
        Debut: '25/02/2016',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 157,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': 'WJSN Chocome| WJMK',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Luha',
        'Full Name': 'Lee Gyeonghun',
        'Korean Name': '이경훈',
        'K Stage Name': '루하',
        'Date of Birth': '15/02/1993',
        Group: '14U',
        Debut: '17/04/2017',
        Company: 'BG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Luhan',
        'Full Name': 'Lu Han',
        'Korean Name': '루한',
        'K Stage Name': '루한',
        'Date of Birth': '20/04/1990',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'China',
        'Second Country': '',
        Height: 178,
        Weight: 62,
        Birthplace: 'Haidian',
        'Other Group': '',
        'Former Group': 'EXO',
        Gender: 'M'
    },
    {
        'Stage Name': 'Luna',
        'Full Name': 'Park Sunyoung',
        'Korean Name': '박선영',
        'K Stage Name': '루나',
        'Date of Birth': '12/08/1993',
        Group: 'f(x)',
        Debut: '5/09/2009',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Lune',
        'Full Name': 'Jung Sungmin',
        'Korean Name': '정성민',
        'K Stage Name': '룬',
        'Date of Birth': '27/02/2000',
        Group: 'DKB',
        Debut: '3/02/2020',
        Company: 'Brave',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 55,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Lutan',
        'Full Name': 'Choi Gyeongseok',
        'Korean Name': '최경석',
        'K Stage Name': '루탄',
        'Date of Birth': '6/12/2003',
        Group: 'BLITZERS',
        Debut: '12/05/2021',
        Company: 'Wuzo',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Manny',
        'Full Name': 'Syo Dongchun',
        'Korean Name': '쇼동청',
        'K Stage Name': '만니',
        'Date of Birth': '17/11/2001',
        Group: 'VARSITY',
        Debut: '5/01/2017',
        Company: 'GKC',
        Country: 'China',
        'Second Country': '',
        Height: 170,
        Weight: 64,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Marco',
        'Full Name': 'Lee Hyunggeun',
        'Korean Name': '이형근',
        'K Stage Name': '마르코',
        'Date of Birth': '11/05/1994',
        Group: 'Hot Blood Youth',
        Debut: '17/05/2017',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Marin',
        'Full Name': 'Yasufuku Marin',
        'Korean Name': '야스후쿠 마린',
        'K Stage Name': '마린',
        'Date of Birth': '17/03/2001',
        Group: 'Bling Bling',
        Debut: '17/11/2020',
        Company: 'Major9',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Osaka',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Mark',
        'Full Name': 'Mark Yien Tuan',
        'Korean Name': '마크 이엔 투안',
        'K Stage Name': '마크',
        'Date of Birth': '4/09/1993',
        Group: 'GOT7',
        Debut: '16/01/2014',
        Company: 'JYP',
        Country: 'USA',
        'Second Country': 'Taiwan',
        Height: 175,
        Weight: 59,
        Birthplace: 'Los Angeles',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Mark',
        'Full Name': 'Mark Lee',
        'Korean Name': '마크 리',
        'K Stage Name': '마크',
        'Date of Birth': '2/08/1999',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'Canada',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Vancouver',
        'Other Group': 'NCT U| NCT 127| NCT Dream| SuperM',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Mashiho',
        'Full Name': 'Takata Mashiho',
        'Korean Name': '타카타 마시호',
        'K Stage Name': '마시호',
        'Date of Birth': '25/03/2001',
        Group: 'Treasure',
        Debut: '7/08/2020',
        Company: 'YG',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Mashiro',
        'Full Name': 'Sakamoto Mashiro',
        'Korean Name': '사카모토 마시로',
        'K Stage Name': '마시로',
        'Date of Birth': '16/12/1999',
        Group: 'Kep1er',
        Debut: '1/03/2022',
        Company: 'WakeOne',
        Country: 'Japan',
        'Second Country': '',
        Height: 157,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Max Changmin',
        'Full Name': 'Shim Changmin',
        'Korean Name': '심창민',
        'K Stage Name': '최강창민',
        'Date of Birth': '18/02/1988',
        Group: 'TVXQ',
        Debut: '6/09/1995',
        Company: 'MBK| Turbo Co.',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'May',
        'Full Name': 'Jeong Chaeyoung',
        'Korean Name': '정채영',
        'K Stage Name': '메이',
        'Date of Birth': '27/07/2001',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'NeonPunch',
        Gender: 'F'
    },
    {
        'Stage Name': 'May',
        'Full Name': 'Hirokawa Mao',
        'Korean Name': '히로카와 마오',
        'K Stage Name': '메이',
        'Date of Birth': '16/11/2004',
        Group: 'Cherry Bullet',
        Debut: '21/01/2019',
        Company: 'FNC',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Mayna',
        'Full Name': 'Shaoxi Mengna',
        'Korean Name': '',
        'K Stage Name': '메이나',
        'Date of Birth': '12/05/2000',
        Group: 'HOT ISSUE',
        Debut: '17/05/2017',
        Company: 'FNC',
        Country: 'China',
        'Second Country': 'Romania',
        Height: 170,
        Weight: 47,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Medic Jin',
        'Full Name': 'Bae Yujin',
        'Korean Name': '배유진',
        'K Stage Name': '메딕진',
        'Date of Birth': '25/08/1996',
        Group: 'Girlkind',
        Debut: '16/01/2018',
        Company: 'Nextlevel',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: 167,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Meiqi',
        'Full Name': 'Meng Meiqi',
        'Korean Name': '멍메이치',
        'K Stage Name': '미기',
        'Date of Birth': '15/10/1998',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'China',
        'Second Country': '',
        Height: 164,
        Weight: '',
        Birthplace: 'Luoyang',
        'Other Group': '',
        'Former Group': 'WJSN',
        Gender: 'F'
    },
    {
        'Stage Name': 'Mia',
        'Full Name': 'Han Eunji',
        'Korean Name': '한은지',
        'K Stage Name': '미아',
        'Date of Birth': '13/01/2000',
        Group: 'Everglow',
        Debut: '18/03/2019',
        Company: 'Yuehua',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Mia',
        'Full Name': 'Son Hyunmi',
        'Korean Name': '손현미',
        'K Stage Name': '미아',
        'Date of Birth': '31/12/1997',
        Group: 'MIXX',
        Debut: '2/05/2016',
        Company: 'Chiko',
        Country: 'South Korea',
        'Second Country': '',
        Height: 174,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Mihee',
        'Full Name': 'Lee Mihee',
        'Korean Name': '이미희',
        'K Stage Name': '미희',
        'Date of Birth': '24/08/2000',
        Group: 'BOTOPASS',
        Debut: '26/08/2020',
        Company: 'WKS ENE',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Mijoo',
        'Full Name': 'Lee Mijoo',
        'Korean Name': '이미주',
        'K Stage Name': '미주',
        'Date of Birth': '23/09/1994',
        Group: 'Lovelyz',
        Debut: '17/11/2014',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 47,
        Birthplace: 'Okcheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Mikey',
        'Full Name': 'Morisaki Daisuke',
        'Korean Name': '모리사키 다이스케',
        'K Stage Name': '마이키',
        'Date of Birth': '5/05/2001',
        Group: 'BLANK2Y',
        Debut: '24/05/2022',
        Company: 'Keystone',
        Country: 'Japan',
        'Second Country': '',
        Height: 176,
        Weight: 58,
        Birthplace: 'Hyogo',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Miko',
        'Full Name': 'Matsuda Miko',
        'Korean Name': '마츠다 미코',
        'K Stage Name': '미코',
        'Date of Birth': '28/10/1995',
        Group: 'Honey Popcorn',
        Debut: '21/03/2018',
        Company: 'Kyun Create',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Miku',
        'Full Name': 'Katae Miku',
        'Korean Name': '카타에 미쿠',
        'K Stage Name': '미쿠',
        'Date of Birth': '9/10/2002',
        Group: 'Pink Fantasy',
        Debut: '28/10/2018',
        Company: 'My Doll',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Mill',
        'Full Name': 'Lee Yongsoo',
        'Korean Name': '이용수',
        'K Stage Name': '밀',
        'Date of Birth': '30/03/1999',
        Group: 'OnlyOneOf',
        Debut: '28/05/2019',
        Company: 'RSVP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Milo',
        'Full Name': 'Kim Minhak',
        'Korean Name': '김민학',
        'K Stage Name': '마일로',
        'Date of Birth': '20/08/1996',
        Group: 'Romeo',
        Debut: '4/11/2015',
        Company: 'CT',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Mimi',
        'Full Name': 'Jung Mimi',
        'Korean Name': '정미미',
        'K Stage Name': '미미',
        'Date of Birth': '1/01/1993',
        Group: 'Gugudan',
        Debut: '28/06/2016',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Mimi',
        'Full Name': 'Kim Mihyun',
        'Korean Name': '김미현',
        'K Stage Name': '미미',
        'Date of Birth': '1/05/1995',
        Group: 'Oh My Girl',
        Debut: '21/04/2015',
        Company: 'WM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: 50,
        Birthplace: 'Jeju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Min',
        'Full Name': 'Lee Minyoung',
        'Korean Name': '이민영',
        'K Stage Name': '민',
        'Date of Birth': '21/06/1991',
        Group: 'miss A',
        Debut: '1/07/2010',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Min',
        'Full Name': 'Lee Mincheon',
        'Korean Name': '이민천',
        'K Stage Name': '민',
        'Date of Birth': '16/10/1997',
        Group: 'We In The Zone',
        Debut: '27/05/2019',
        Company: 'Choon',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: 50,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Mina',
        'Full Name': 'Kwon Mina',
        'Korean Name': '권민아',
        'K Stage Name': '민아',
        'Date of Birth': '21/09/1993',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 159,
        Weight: 43,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': 'AOA| AOA BLACK',
        Gender: 'F'
    },
    {
        'Stage Name': 'Mina',
        'Full Name': 'Kang Mina',
        'Korean Name': '강미나',
        'K Stage Name': '미나',
        'Date of Birth': '4/12/1999',
        Group: 'Gugudan',
        Debut: '28/06/2016',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: 48,
        Birthplace: 'Icheon',
        'Other Group': 'Gugudan Oguogu',
        'Former Group': 'I.O.I',
        Gender: 'F'
    },
    {
        'Stage Name': 'Mina',
        'Full Name': 'Myoui Mina',
        'Korean Name': '묘이 미나',
        'K Stage Name': '미나',
        'Date of Birth': '24/03/1997',
        Group: 'Twice',
        Debut: '20/10/2015',
        Company: 'JYP',
        Country: 'Japan',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: 'San Antonio',
        'Other Group': 'MISAMO',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Minah',
        'Full Name': 'Bang Minah',
        'Korean Name': '방민아',
        'K Stage Name': '민아',
        'Date of Birth': '13/05/1993',
        Group: "Girl's Day",
        Debut: '9/07/2010',
        Company: 'DreamT',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 47,
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Minchan',
        'Full Name': 'Hong Minchan',
        'Korean Name': '홍민찬',
        'K Stage Name': '민찬',
        'Date of Birth': '16/09/1998',
        Group: 'Verivery',
        Debut: '1/09/2019',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Mingi',
        'Full Name': 'Son Mingi',
        'Korean Name': '송민기',
        'K Stage Name': '민기',
        'Date of Birth': '9/08/1999',
        Group: 'ATEEZ',
        Debut: '24/10/2018',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Mingyu',
        'Full Name': 'Kim Mingyu',
        'Korean Name': '김민규',
        'K Stage Name': '민규',
        'Date of Birth': '6/04/1997',
        Group: 'Seventeen',
        Debut: '26/05/2015',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: 185,
        Weight: 65,
        Birthplace: 'Anyang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minha',
        'Full Name': 'Park Minha',
        'Korean Name': '박민하',
        'K Stage Name': '민하',
        'Date of Birth': '27/06/1991',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: 47,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '9Muses',
        Gender: 'F'
    },
    {
        'Stage Name': 'Minhee',
        'Full Name': 'Kang Minhee',
        'Korean Name': '강민희',
        'K Stage Name': '민희',
        'Date of Birth': '17/09/2002',
        Group: 'CRAVITY',
        Debut: '14/04/2020',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 185,
        Weight: 58,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'X1',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minhee',
        'Full Name': 'Joo Minhee',
        'Korean Name': '주민희',
        'K Stage Name': '민희',
        'Date of Birth': '3/01/1993',
        Group: 'Stellar',
        Debut: '28/08/2011',
        Company: 'Pascal',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Minho',
        'Full Name': 'Yang Minho',
        'Korean Name': '양민호',
        'K Stage Name': '민호',
        'Date of Birth': '14/10/2002',
        Group: '8TURN',
        Debut: '30/01/2023',
        Company: 'MNH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 59,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minho',
        'Full Name': 'Lee Minho',
        'Korean Name': '이민호',
        'K Stage Name': '민호',
        'Date of Birth': '4/09/1995',
        Group: 'MY.st',
        Debut: '26/03/2020',
        Company: 'MYStar',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minho',
        'Full Name': 'Choi Minho',
        'Korean Name': '최민호',
        'K Stage Name': '민호',
        'Date of Birth': '9/12/1991',
        Group: 'SHINee',
        Debut: '25/05/2008',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 181,
        Weight: 60,
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minhwan',
        'Full Name': 'Choi Minhwan',
        'Korean Name': '최민환',
        'K Stage Name': '민환',
        'Date of Birth': '11/11/1992',
        Group: 'FTISLAND',
        Debut: '7/06/2007',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minhyuk',
        'Full Name': 'Lee Minhyuk',
        'Korean Name': '이민혁',
        'K Stage Name': '민혁',
        'Date of Birth': '29/11/1990',
        Group: 'BtoB',
        Debut: '21/03/2012',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: 61,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minhyuk',
        'Full Name': 'Kang Minhyuk',
        'Korean Name': '강민혁',
        'K Stage Name': '민혁',
        'Date of Birth': '28/06/1991',
        Group: 'CNBLUE',
        Debut: '14/01/2010',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Ilsan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minhyuk',
        'Full Name': 'Kim Minhyuk',
        'Korean Name': '김민혁',
        'K Stage Name': '민혁',
        'Date of Birth': '6/11/1999',
        Group: 'D-CRUNCH',
        Debut: '6/08/2018',
        Company: 'All-S',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minhyuk',
        'Full Name': 'Lee Minhyuk',
        'Korean Name': '이민혁',
        'K Stage Name': '민혁',
        'Date of Birth': '3/11/1993',
        Group: 'MONSTA X',
        Debut: '14/05/2014',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minhyun',
        'Full Name': 'Hwang Minhyun',
        'Korean Name': '황민현',
        'K Stage Name': '민현',
        'Date of Birth': '9/08/1995',
        Group: "NU'EST",
        Debut: '15/03/2012',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': "NU'EST",
        'Former Group': 'Wanna One',
        Gender: 'M'
    },
    {
        'Stage Name': 'Mini',
        'Full Name': '',
        'Korean Name': '',
        'K Stage Name': '민이',
        'Date of Birth': '12/06/2000',
        Group: 'Purplebeck',
        Debut: '24/06/2019',
        Company: 'Majesty',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: 47,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Minjae',
        'Full Name': 'Song Minjae',
        'Korean Name': '송민재',
        'K Stage Name': '민재',
        'Date of Birth': '23/08/2003',
        Group: 'MCND',
        Debut: '27/02/2020',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minjae',
        'Full Name': 'Seong Minjae',
        'Korean Name': '성민재',
        'K Stage Name': '민재',
        'Date of Birth': '18/12/1994',
        Group: 'Sonamoo',
        Debut: '29/12/2014',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Minjae',
        'Full Name': 'Jo Minjae',
        'Korean Name': '조민재',
        'K Stage Name': '민재',
        'Date of Birth': '8/08/1994',
        Group: 'Spectrum',
        Debut: '9/05/2018',
        Company: 'WYNN',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minjae',
        'Full Name': 'Shin Minjae',
        'Korean Name': '신민재',
        'K Stage Name': '민재',
        'Date of Birth': '13/08/1991',
        Group: 'Tahiti',
        Debut: '23/06/2012',
        Company: 'J-line',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Minjae',
        'Full Name': 'Kim Minjae',
        'Korean Name': '김민재',
        'K Stage Name': '민재',
        'Date of Birth': '10/04/2003',
        Group: 'xikers',
        Debut: '30/03/2023',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minji',
        'Full Name': 'Kim Minji',
        'Korean Name': '김민지',
        'K Stage Name': '민지',
        'Date of Birth': '7/05/2004',
        Group: 'NewJeans',
        Debut: '22/07/2022',
        Company: 'ADOR',
        Country: 'South Korea',
        'Second Country': '',
        Height: 169,
        Weight: '',
        Birthplace: 'Yeongwol',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F',
        'Image URL': '/kpop/newjeans/minji/198470_zzZw.png'
    },
    {
        'Stage Name': 'Minjoo',
        'Full Name': 'Kim Minjoo',
        'Korean Name': '김민주',
        'K Stage Name': '민주',
        'Date of Birth': '5/02/2001',
        Group: 'IZ*ONE',
        Debut: '29/10/2018',
        Company: 'Off The Record',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Minju',
        'Full Name': 'Kang Minjoo',
        'Korean Name': '강민주',
        'K Stage Name': '민주',
        'Date of Birth': '11/03/2001',
        Group: 'GWSN',
        Debut: '5/09/2018',
        Company: 'Kiwi Pop',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Minjun',
        'Full Name': 'Kim Minjun',
        'Korean Name': '김민준',
        'K Stage Name': '민준',
        'Date of Birth': '26/02/1999',
        Group: 'NINE.i',
        Debut: '30/03/2022',
        Company: 'FirstOne',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minjung',
        'Full Name': 'Cha Minjung',
        'Korean Name': '차민정',
        'K Stage Name': '민정',
        'Date of Birth': '15/07/2004',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Busters',
        Gender: 'F'
    },
    {
        'Stage Name': 'Minkyung',
        'Full Name': 'Kang Minkyung',
        'Korean Name': '강민경',
        'K Stage Name': '민경',
        'Date of Birth': '3/08/1990',
        Group: 'Davichi',
        Debut: '4/02/2008',
        Company: 'CJ E&M',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Goyang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Minnie',
        'Full Name': 'Nicha Yontararak',
        'Korean Name': '니차 욘타라락',
        'K Stage Name': '민니',
        'Date of Birth': '23/10/1997',
        Group: '(G)I-DLE',
        Debut: '2/05/2018',
        Company: 'Cube',
        Country: 'Thailand',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Mino',
        'Full Name': 'Song Minho',
        'Korean Name': '송민호',
        'K Stage Name': '민호',
        'Date of Birth': '30/03/1993',
        Group: 'WINNER',
        Debut: '12/08/2014',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yongin',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minpyo',
        'Full Name': 'Gook Minpyo',
        'Korean Name': '국민표',
        'K Stage Name': '민표',
        'Date of Birth': '15/11/1994',
        Group: 'B.I.G',
        Debut: '9/07/2014',
        Company: 'GH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: 57,
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minseo',
        'Full Name': 'Kim Minseo',
        'Korean Name': '김민서',
        'K Stage Name': '민서',
        'Date of Birth': '13/05/2002',
        Group: 'DRIPPIN',
        Debut: '26/10/2020',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 53,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minseo',
        'Full Name': 'Park Minseo',
        'Korean Name': '박민서',
        'K Stage Name': '민서',
        'Date of Birth': '21/03/2003',
        Group: 'Saturday',
        Debut: '18/07/2018',
        Company: 'SD',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: 45,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Minseo',
        'Full Name': 'Kim Minseo',
        'Korean Name': '김민서',
        'K Stage Name': '민서',
        'Date of Birth': '12/08/2004',
        Group: 'woo!ah!',
        Debut: '15/05/2020',
        Company: 'NV',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Minsu',
        'Full Name': 'Park Minsu',
        'Korean Name': '김민수',
        'K Stage Name': '민수',
        'Date of Birth': '15/04/1993',
        Group: 'Boys Republic',
        Debut: '3/06/2013',
        Company: 'Happy Tribe| Universal',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: 55,
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minsu',
        'Full Name': 'Kim Minsu',
        'Korean Name': '김민수',
        'K Stage Name': '민수',
        'Date of Birth': '20/03/2000',
        Group: 'TOO',
        Debut: '1/04/2020',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minsung',
        'Full Name': 'Kim Minhwi',
        'Korean Name': '김민휘',
        'K Stage Name': '민성',
        'Date of Birth': '24/12/1996',
        Group: 'Romeo',
        Debut: '4/11/2015',
        Company: 'CT',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Mint',
        'Full Name': 'Kunpat Phonpawiworakun',
        'Korean Name': '쿤팟 퍼언빠위워라쿤',
        'K Stage Name': '민트',
        'Date of Birth': '23/06/1994',
        Group: 'Tiny-G',
        Debut: '23/08/2012',
        Company: 'GNG',
        Country: 'Thailand',
        'Second Country': '',
        Height: 150,
        Weight: 42,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Minwoo',
        'Full Name': 'No Minwoo',
        'Korean Name': '노민우',
        'K Stage Name': '민우',
        'Date of Birth': '31/07/1995',
        Group: 'Boyfriend',
        Debut: '26/05/2011',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 174,
        Weight: 60,
        Birthplace: 'Anyang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minwoo',
        'Full Name': 'Lee Minwoo',
        'Korean Name': '이민우',
        'K Stage Name': '민우',
        'Date of Birth': '28/07/1979',
        Group: 'Shinhwa',
        Debut: '24/03/1998',
        Company: 'SM| Good| Shinhwa',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: 60,
        Birthplace: 'Namwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minwoo',
        'Full Name': 'Ha Minwoo',
        'Korean Name': '하민우',
        'K Stage Name': '민우',
        'Date of Birth': '6/09/1990',
        Group: 'ZE:A',
        Debut: '7/01/2010',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yangsan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minwoo†',
        'Full Name': 'Seo Minwoo',
        'Korean Name': '서민우',
        'K Stage Name': '민우',
        'Date of Birth': '8/02/1985',
        Group: '100%',
        Debut: '18/09/2012',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Minyoung',
        'Full Name': 'Kim Minyeong',
        'Korean Name': '김민영',
        'K Stage Name': '민영',
        'Date of Birth': '12/09/1990',
        Group: 'Brave Girls',
        Debut: '8/04/2011',
        Company: 'Brave',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seongnam',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Minzy',
        'Full Name': 'Kong Minji',
        'Korean Name': '공민지',
        'K Stage Name': '민지',
        'Date of Birth': '18/01/1994',
        Group: '2NE1',
        Debut: '6/05/2009',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Mir',
        'Full Name': 'Lee Yoonsang',
        'Korean Name': '이윤상',
        'K Stage Name': '미르',
        'Date of Birth': '6/03/2000',
        Group: 'Astin',
        Debut: '16/11/2022',
        Company: 'AB Creative',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Mir',
        'Full Name': 'Bang Cheolyong',
        'Korean Name': '방철용',
        'K Stage Name': '미르',
        'Date of Birth': '10/03/1991',
        Group: 'MBLAQ',
        Debut: '14/10/2009',
        Company: 'J. Tune',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Bukhamyeon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Mire',
        'Full Name': 'Su Mire',
        'Korean Name': '수미레',
        'K Stage Name': '미레',
        'Date of Birth': '26/03/2006',
        Group: 'TRI.BE',
        Debut: '17/02/2021',
        Company: 'TR - Mellow',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Miryo',
        'Full Name': 'Jo Mihye',
        'Korean Name': '조미혜',
        'K Stage Name': '미료',
        'Date of Birth': '2/11/1981',
        Group: 'Brown Eyed Girls',
        Debut: '2/03/2006',
        Company: 'Mystic',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Miso',
        'Full Name': 'Jeon Jimin',
        'Korean Name': '전지민',
        'K Stage Name': '미소',
        'Date of Birth': '25/10/2000',
        Group: 'Dreamnote',
        Debut: '7/11/2018',
        Company: 'iME',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 43,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Miso',
        'Full Name': 'Park Jihyun',
        'Korean Name': '박지현',
        'K Stage Name': '미소',
        'Date of Birth': '16/09/1996',
        Group: 'P.O.P',
        Debut: '26/07/2017',
        Company: 'DWM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Miso',
        'Full Name': 'Park Miso',
        'Korean Name': '박미소',
        'K Stage Name': '미소',
        'Date of Birth': '4/04/1991',
        Group: 'Tahiti',
        Debut: '23/06/2012',
        Company: 'J-line',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'MiU',
        'Full Name': 'Ito Miyu',
        'Korean Name': '이토 미유',
        'K Stage Name': '미유',
        'Date of Birth': '9/01/2003',
        Group: 'LIMELIGHT',
        Debut: '17/02/2023',
        Company: 143,
        Country: 'Japan',
        'Second Country': '',
        Height: 164,
        Weight: 42,
        Birthplace: 'Tokyo',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Miya',
        'Full Name': 'Miyauchi Haruka',
        'Korean Name': '미야우치 하루카',
        'K Stage Name': '미야',
        'Date of Birth': '26/05/1993',
        Group: 'GWSN',
        Debut: '5/09/2018',
        Company: 'Kiwi Pop',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Miyeon',
        'Full Name': 'Jo Miyeon',
        'Korean Name': '조미연',
        'K Stage Name': '미연',
        'Date of Birth': '31/01/1997',
        Group: '(G)I-DLE',
        Debut: '2/05/2018',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'MJ',
        'Full Name': 'Kim Myungjun',
        'Korean Name': '김명준',
        'K Stage Name': '엠제이',
        'Date of Birth': '5/03/1994',
        Group: 'ASTRO',
        Debut: '23/02/2016',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Nowon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'MK',
        'Full Name': 'Park Minkyun',
        'Korean Name': '박민균',
        'K Stage Name': '엠케이',
        'Date of Birth': '16/11/1995',
        Group: 'ONF',
        Debut: '2/08/2017',
        Company: 'WM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Ilsan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Moko',
        'Full Name': 'Sakura Moko',
        'Korean Name': '사쿠라 모코',
        'K Stage Name': '모코',
        'Date of Birth': '19/03/1991',
        Group: 'Honey Popcorn',
        Debut: '21/03/2018',
        Company: 'Kyun Create',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Momo',
        'Full Name': 'Hirai Momo',
        'Korean Name': '히라이 모모',
        'K Stage Name': '모모',
        'Date of Birth': '9/11/1996',
        Group: 'Twice',
        Debut: '20/10/2015',
        Company: 'JYP',
        Country: 'Japan',
        'Second Country': '',
        Height: 167,
        Weight: '',
        Birthplace: 'Kyoto',
        'Other Group': 'MISAMO',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Momoka',
        'Full Name': 'Matsuda Momoka',
        'Korean Name': '마쓰다 모모카',
        'K Stage Name': '모모카',
        'Date of Birth': '26/12/2000',
        Group: 'Pink Fantasy',
        Debut: '28/10/2018',
        Company: 'My Doll',
        Country: 'Japan',
        'Second Country': '',
        Height: 152,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Monday',
        'Full Name': 'Kim Jimin',
        'Korean Name': '김지민',
        'K Stage Name': '먼데이',
        'Date of Birth': '10/05/2002',
        Group: 'Weeekly',
        Debut: '30/07/2020',
        Company: 'Play M',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Monika',
        'Full Name': 'Kang Yeh',
        'Korean Name': '강예',
        'K Stage Name': '모니카',
        'Date of Birth': '21/05/1991',
        Group: 'BADKIZ',
        Debut: '24/03/2014',
        Company: 'Zoo',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Moon Bin',
        'Full Name': 'Moon Bin',
        'Korean Name': '문빈',
        'K Stage Name': '문빈',
        'Date of Birth': '26/01/1998',
        Group: 'ASTRO',
        Debut: '23/02/2016',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Cheongju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Moon Sua',
        'Full Name': 'Moon Sua',
        'Korean Name': '문수아',
        'K Stage Name': '문수아',
        'Date of Birth': '9/09/1999',
        Group: 'Billlie',
        Debut: '10/11/2021',
        Company: 'MYSTIC',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 47,
        Birthplace: 'Cheongju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Moonbyul',
        'Full Name': 'Moon Byulyi',
        'Korean Name': '문별이',
        'K Stage Name': '문별',
        'Date of Birth': '22/12/1992',
        Group: 'Mamamoo',
        Debut: '18/06/2014',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 45,
        Birthplace: 'Bucheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Moonhee',
        'Full Name': 'Choi Moonhee',
        'Korean Name': '최문희',
        'K Stage Name': '문희',
        'Date of Birth': '15/04/1997',
        Group: 'Bonus Baby',
        Debut: '1/01/2017',
        Company: 'Maroo',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'MyB',
        Gender: 'F'
    },
    {
        'Stage Name': 'Moonvok',
        'Full Name': 'Jang Moonvok',
        'Korean Name': '장문복',
        'K Stage Name': '문복',
        'Date of Birth': '11/04/1995',
        Group: 'Limitless',
        Debut: '9/07/2019',
        Company: 'ONO',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: 48,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Mu',
        'Full Name': 'Suh Kyungmin',
        'Korean Name': '서경민',
        'K Stage Name': '뮤',
        'Date of Birth': '14/09/2003',
        Group: 'EPEX',
        Debut: '8/06/2021',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: 184,
        Weight: 63,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Mujin',
        'Full Name': 'Ko Sungho',
        'Korean Name': '고성호',
        'K Stage Name': '무진',
        'Date of Birth': '20/11/2000',
        Group: 'Kingdom',
        Debut: '18/02/2021',
        Company: 'GF',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Munik',
        'Full Name': 'Jang Moonik',
        'Korean Name': '장문익',
        'K Stage Name': '문익',
        'Date of Birth': '20/03/2001',
        Group: 'Dongkiz',
        Debut: '24/04/2019',
        Company: 'Dongyo',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: 50,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Muzin',
        'Full Name': 'Kim Hyunwoo',
        'Korean Name': '김현우',
        'K Stage Name': '무진',
        'Date of Birth': '29/03/2001',
        Group: 'BAE173',
        Debut: '19/11/2020',
        Company: 'PocketDol',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Myungho',
        'Full Name': 'Ji Myungho',
        'Korean Name': '지명호',
        'K Stage Name': '명호',
        'Date of Birth': '12/10/2001',
        Group: '8TURN',
        Debut: '30/01/2023',
        Company: 'MNH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 62,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Myungji',
        'Full Name': 'Kim Myungji',
        'Korean Name': '김명지',
        'K Stage Name': '명지',
        'Date of Birth': '9/10/1997',
        Group: 'Tiny-G',
        Debut: '23/08/2012',
        Company: 'GNG',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'N',
        'Full Name': 'Cha Hakyeon',
        'Korean Name': '차학연',
        'K Stage Name': '엔',
        'Date of Birth': '30/06/1990',
        Group: 'VIXX',
        Debut: '24/05/2012',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 65,
        Birthplace: 'Changwon',
        'Other Group': 'Big Byung',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Nada',
        'Full Name': 'Yoon Yejin',
        'Korean Name': '윤예진',
        'K Stage Name': '나다',
        'Date of Birth': '24/05/1991',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Wassup',
        Gender: 'F'
    },
    {
        'Stage Name': 'Naeun',
        'Full Name': 'Son Naeun',
        'Korean Name': '손나은',
        'K Stage Name': '나은',
        'Date of Birth': '10/02/1994',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'Apink',
        Gender: 'F'
    },
    {
        'Stage Name': 'Naeun',
        'Full Name': 'Lee Naeun',
        'Korean Name': '이나은',
        'K Stage Name': '나은',
        'Date of Birth': '5/05/1999',
        Group: 'APRIL',
        Debut: '24/08/2015',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daejeon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nahyun',
        'Full Name': 'Kang Nahyun',
        'Korean Name': '강나현',
        'K Stage Name': '나현',
        'Date of Birth': '25/01/2002',
        Group: 'HOT ISSUE',
        Debut: '17/05/2017',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nahyun',
        'Full Name': 'Kim Nahyun',
        'Korean Name': '김나현',
        'K Stage Name': '나현',
        'Date of Birth': '9/12/1995',
        Group: 'Sonamoo',
        Debut: '29/12/2014',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nako',
        'Full Name': 'Yabuki Nako',
        'Korean Name': '야부키 나코',
        'K Stage Name': '나코',
        'Date of Birth': '18/06/2001',
        Group: 'IZ*ONE',
        Debut: '29/10/2018',
        Company: 'Off The Record',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Tokyo',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nakta',
        'Full Name': 'Shin Yooncheol',
        'Korean Name': '신윤철',
        'K Stage Name': '낙타',
        'Date of Birth': '24/04/1993',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': 'Topp Dogg',
        Gender: 'M'
    },
    {
        'Stage Name': 'Nakyung',
        'Full Name': 'Lee Nakyung',
        'Korean Name': '이나경',
        'K Stage Name': '나경',
        'Date of Birth': '1/06/2000',
        Group: 'fromis_9',
        Debut: '24/01/2018',
        Company: 'Off The Record',
        Country: 'South Korea',
        'Second Country': '',
        Height: 159,
        Weight: 42,
        Birthplace: 'Mungyeong',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nakyung',
        'Full Name': 'Kim Nakyung',
        'Korean Name': '김나경',
        'K Stage Name': '나경',
        'Date of Birth': '13/10/2002',
        Group: 'tripleS',
        Debut: '13/02/2023',
        Company: 'MODHAUS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: '',
        Birthplace: 'Ulsan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Namjoo',
        'Full Name': 'Kim Namjoo',
        'Korean Name': '김남주',
        'K Stage Name': '남주',
        'Date of Birth': '15/04/1995',
        Group: 'Apink',
        Debut: '19/04/2011',
        Company: 'Plan A',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nana',
        'Full Name': 'Im Jinah',
        'Korean Name': '임진아',
        'K Stage Name': '나나',
        'Date of Birth': '14/09/1991',
        Group: 'After School',
        Debut: '15/01/2009',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Cheongju',
        'Other Group': 'Orange Caramel',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nana',
        'Full Name': 'Kwon Nayeon',
        'Korean Name': '권나연',
        'K Stage Name': '나나',
        'Date of Birth': '9/03/2001',
        Group: 'woo!ah!',
        Debut: '15/05/2020',
        Company: 'NV',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nancy',
        'Full Name': 'Nancy Mcdonie',
        'Korean Name': '낸시 맥다니',
        'K Stage Name': '낸시',
        'Date of Birth': '13/04/2000',
        Group: 'MOMOLAND',
        Debut: '9/11/2016',
        Company: 'Double Kick',
        Country: 'South Korea',
        'Second Country': 'USA',
        Height: 162,
        Weight: 45,
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nara',
        'Full Name': 'Kwon Nara',
        'Korean Name': '권나라',
        'K Stage Name': '나라',
        'Date of Birth': '13/03/1991',
        Group: 'Hello Venus',
        Debut: '9/05/2012',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: 52,
        Birthplace: 'Seongnam',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Narachan',
        'Full Name': 'Jung Hyunwoo',
        'Korean Name': '정현우',
        'K Stage Name': '나라찬',
        'Date of Birth': '23/09/1996',
        Group: 'MONT',
        Debut: '14/05/2014',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 58,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Narae',
        'Full Name': 'Park Narae',
        'Korean Name': '박나래',
        'K Stage Name': '나래',
        'Date of Birth': '23/02/1988',
        Group: 'SPICA',
        Debut: '7/02/2012',
        Company: 'CJ E&M',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': 'SPICA-S',
        'Former Group': 'SPICA',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nari',
        'Full Name': 'Kim Nari',
        'Korean Name': '김나리',
        'K Stage Name': '나리',
        'Date of Birth': '5/10/1992',
        Group: 'Wassup',
        Debut: '7/08/2013',
        Company: 'Mafia',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Narin',
        'Full Name': 'Lee Narin',
        'Korean Name': '이나린',
        'K Stage Name': '나린',
        'Date of Birth': '29/10/2001',
        Group: 'Bling Bling',
        Debut: '17/11/2020',
        Company: 'Major9',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Narin',
        'Full Name': 'Lee Eunseo',
        'Korean Name': '이은서',
        'K Stage Name': '나린',
        'Date of Birth': '6/06/2000',
        Group: 'Queenz Eye',
        Debut: '24/10/2022',
        Company: 'Big Mountain',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Narsha',
        'Full Name': 'Park Hyojin',
        'Korean Name': '박효진',
        'K Stage Name': '나르샤',
        'Date of Birth': '28/12/1981',
        Group: 'Brown Eyed Girls',
        Debut: '2/03/2006',
        Company: 'Mystic',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nason',
        'Full Name': 'Kim Duyeop',
        'Korean Name': '김두엽',
        'K Stage Name': '네이슨',
        'Date of Birth': '12/06/1995',
        Group: 'CHECKMATE',
        Debut: '19/06/2012',
        Company: 'Yedang',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Nayeon',
        'Full Name': 'Im Nayeon',
        'Korean Name': '임나연',
        'K Stage Name': '나연',
        'Date of Birth': '22/09/1995',
        Group: 'Twice',
        Debut: '20/10/2015',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nayoung',
        'Full Name': 'Kim Nayoung',
        'Korean Name': '김나영',
        'K Stage Name': '나영',
        'Date of Birth': '23/11/1995',
        Group: 'Gugudan',
        Debut: '28/06/2016',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: 169,
        Weight: 50,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nayoung',
        'Full Name': 'Kim Nayoung',
        'Korean Name': '김나영',
        'K Stage Name': '나영',
        'Date of Birth': '30/11/2002',
        Group: 'LIGHTSUM',
        Debut: '10/06/2021',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 154,
        Weight: 43,
        Birthplace: 'Chuncheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nayoung',
        'Full Name': 'Im Nayoung',
        'Korean Name': '임나영',
        'K Stage Name': '나영',
        'Date of Birth': '18/12/1995',
        Group: 'Pristin',
        Debut: '21/03/2017',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: 51,
        Birthplace: 'Chungcheongham',
        'Other Group': 'I.O.I| Pristin V',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nayu',
        'Full Name': 'Kim Yewon',
        'Korean Name': '김예원',
        'K Stage Name': '나유',
        'Date of Birth': '23/07/2002',
        Group: 'ILY:1',
        Debut: '4/04/2022',
        Company: 'FC ENM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nayun',
        'Full Name': 'Kim Nayun',
        'Korean Name': '김나윤',
        'K Stage Name': '나윤',
        'Date of Birth': '31/07/1998',
        Group: 'MOMOLAND',
        Debut: '9/11/2016',
        Company: 'Double Kick',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 48,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'NC.A',
        'Full Name': 'Im Soeun',
        'Korean Name': '임소은',
        'K Stage Name': '앤씨아',
        'Date of Birth': '7/10/1996',
        Group: 'UNI.T',
        Debut: '18/05/2018',
        Company: 'Unit Culture',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Osan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'New',
        'Full Name': 'Choi Chanhee',
        'Korean Name': '최찬희',
        'K Stage Name': '뉴',
        'Date of Birth': '26/04/1998',
        Group: 'The Boyz',
        Debut: '31/12/2017',
        Company: 'Cre.Ker',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 51,
        Birthplace: 'Jeonju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'New Sun',
        'Full Name': 'Choi Yoonsun',
        'Korean Name': '최윤선',
        'K Stage Name': '뉴썬',
        'Date of Birth': '19/06/1997',
        Group: 'Sonamoo',
        Debut: '29/12/2014',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nichkhun',
        'Full Name': 'Nichkhun Horvejkul',
        'Korean Name': '닛차쿤 허라웻차꾼',
        'K Stage Name': '닉쿤',
        'Date of Birth': '24/06/1988',
        Group: '2PM',
        Debut: '4/07/2008',
        Company: 'JYP',
        Country: 'Thailand',
        'Second Country': 'USA',
        Height: 180,
        Weight: 64,
        Birthplace: 'California',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Nicole',
        'Full Name': 'Nicole Jung',
        'Korean Name': '정니콜',
        'K Stage Name': '니콜',
        'Date of Birth': '7/10/1991',
        Group: 'KARA',
        Debut: '29/03/2007',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': 'USA',
        Height: '',
        Weight: '',
        Birthplace: 'Los Angeles',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Niel',
        'Full Name': 'Ahn Daniel',
        'Korean Name': '안다니엘',
        'K Stage Name': '니엘',
        'Date of Birth': '16/08/1994',
        Group: 'Teen Top',
        Debut: '9/07/2010',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Anyang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ni-Ki',
        'Full Name': 'Nishimura Riki',
        'Korean Name': '니시무라 리키',
        'K Stage Name': '니키',
        'Date of Birth': '9/12/2005',
        Group: 'ENHYPEN',
        Debut: '30/11/2020',
        Company: 'Be:lift',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Nina',
        'Full Name': 'Nina Nadia Mateer',
        'Korean Name': '니나 나디아 마티어',
        'K Stage Name': '니나',
        'Date of Birth': '15/03/2003',
        Group: 'IRRIS',
        Debut: '6/07/2022',
        Company: 'Mellow',
        Country: 'Australia',
        'Second Country': 'South Korea',
        Height: 174,
        Weight: 51,
        Birthplace: 'Brisbane',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nine',
        'Full Name': 'Jung Wookjin',
        'Korean Name': '정욱진',
        'K Stage Name': '나인',
        'Date of Birth': '13/12/1999',
        Group: 'OnlyOneOf',
        Debut: '28/05/2019',
        Company: 'RSVP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ningning',
        'Full Name': 'Ning Yizhuo',
        'Korean Name': '닝이쭤',
        'K Stage Name': '닝닝',
        'Date of Birth': '23/10/2002',
        Group: 'aespa',
        Debut: '17/11/2020',
        Company: 'SM',
        Country: 'China',
        'Second Country': '',
        Height: 165,
        Weight: 43,
        Birthplace: 'Harbin',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F',
        'Image URL': '/kpop/aespa/ningning/1.jpg'
    },
    {
        'Stage Name': 'Noah',
        'Full Name': 'Hwang Seundae',
        'Korean Name': '황승대',
        'K Stage Name': '노아',
        'Date of Birth': '18/03/1998',
        Group: 'CHECKMATE',
        Debut: '19/06/2012',
        Company: 'Yedang',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Noah',
        'Full Name': 'Song Yubin',
        'Korean Name': '송유빈',
        'K Stage Name': '노아',
        'Date of Birth': '2/08/2000',
        Group: 'T1419',
        Debut: '21/09/2007',
        Company: 'CJ E&M',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Noeul',
        'Full Name': 'No Eul',
        'Korean Name': '노을',
        'K Stage Name': '노을',
        'Date of Birth': '10/05/1989',
        Group: 'Rainbow',
        Debut: '12/11/2009',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 44,
        Birthplace: 'Jeonju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Nova',
        'Full Name': 'Maria Pavlovna Emelyanova',
        'Korean Name': '마리아 파블로브나 에멜야노바',
        'K Stage Name': '노바',
        'Date of Birth': '10/10/2002',
        Group: 'X:IN',
        Debut: '11/04/2023',
        Company: 'Escrow',
        Country: 'Russia',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Moscow',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'O.V',
        'Full Name': 'Kim Sanchan',
        'Korean Name': '김상찬',
        'K Stage Name': '오뷔',
        'Date of Birth': '3/01/1999',
        Group: 'D-CRUNCH',
        Debut: '6/08/2018',
        Company: 'All-S',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Olivia Hye',
        'Full Name': 'Son Hyeju',
        'Korean Name': '손혜주',
        'K Stage Name': '올리비아 혜',
        'Date of Birth': '13/11/2001',
        Group: 'LOONA',
        Debut: '20/08/2018',
        Company: 'Blockberry',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'Loona yyxy',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'On',
        'Full Name': 'Kim Dongwoo',
        'Korean Name': '김동우',
        'K Stage Name': '온',
        'Date of Birth': '',
        Group: 'ABLUE',
        Debut: '23/10/2022',
        Company: 'J-Star',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 65,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'On',
        'Full Name': 'Choi Juhwan',
        'Korean Name': '최주환',
        'K Stage Name': '온',
        'Date of Birth': '16/10/2002',
        Group: 'T1419',
        Debut: '21/09/2007',
        Company: 'CJ E&M',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Onda',
        'Full Name': 'Jo Selim',
        'Korean Name': '조세림',
        'K Stage Name': '온다',
        'Date of Birth': '18/05/2000',
        Group: 'Everglow',
        Debut: '18/03/2019',
        Company: 'Yuehua',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Onejunn',
        'Full Name': 'Jo Wonjun',
        'Korean Name': '조원준',
        'K Stage Name': '원준',
        'Date of Birth': '22/11/1988',
        Group: 'Boys Republic',
        Debut: '3/06/2013',
        Company: 'Happy Tribe| Universal',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 61,
        Birthplace: 'Changwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Onew',
        'Full Name': 'Lee Jinki',
        'Korean Name': '이진기',
        'K Stage Name': '온유',
        'Date of Birth': '14/12/1989',
        Group: 'SHINee',
        Debut: '25/05/2008',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 61,
        Birthplace: 'Gwangmyeong',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'P.O',
        'Full Name': 'Pyo Jihoon',
        'Korean Name': '표지훈',
        'K Stage Name': '피오',
        'Date of Birth': '2/02/1993',
        Group: 'Block B',
        Debut: '15/04/2011',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 181,
        Weight: 64,
        Birthplace: 'Seoul',
        'Other Group': 'Bastarz',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Park Kyung',
        'Full Name': 'Park Kyung',
        'Korean Name': '박경',
        'K Stage Name': '박경',
        'Date of Birth': '8/07/1992',
        Group: 'Block B',
        Debut: '15/04/2011',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 56,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Peniel',
        'Full Name': 'Shin Donggeun',
        'Korean Name': '신동근',
        'K Stage Name': '프니엘',
        'Date of Birth': '10/03/1993',
        Group: 'BtoB',
        Debut: '21/03/2012',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': 'USA',
        Height: 175,
        Weight: 63,
        Birthplace: 'Chicago',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'P-Goon',
        'Full Name': 'Park Sehyuk',
        'Korean Name': '박세혁',
        'K Stage Name': '피군',
        'Date of Birth': '18/10/1991',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'Topp Dogg',
        Gender: 'M'
    },
    {
        'Stage Name': 'Pharita',
        'Full Name': 'Pharita Chaikong',
        'Korean Name': '파리따 차이콩',
        'K Stage Name': '파리타',
        'Date of Birth': '26/08/2005',
        Group: 'BABYMONSTER',
        Debut: '0/01/1900',
        Company: 'YG',
        Country: 'Thailand',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Bangkok',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Prince',
        'Full Name': 'Pasidh Vataniyapromote',
        'Korean Name': '파싯 와타니야쁘라못',
        'K Stage Name': '프린스',
        'Date of Birth': '10/01/2003',
        Group: 'Ghost9',
        Debut: '23/09/2020',
        Company: 'Maroo',
        Country: 'Thailand',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'PSY',
        'Full Name': 'Park Jaesang',
        'Korean Name': '박재상',
        'K Stage Name': '싸이',
        'Date of Birth': '31/12/1977',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Q',
        'Full Name': 'Ji Changmin',
        'Korean Name': '지창민',
        'K Stage Name': '큐',
        'Date of Birth': '5/11/1998',
        Group: 'The Boyz',
        Debut: '31/12/2017',
        Company: 'Cre.Ker',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 56,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Qri',
        'Full Name': 'Lee Jihyun',
        'Korean Name': '이지현',
        'K Stage Name': '큐리',
        'Date of Birth': '12/12/1986',
        Group: 'T-ara',
        Debut: '29/07/2009',
        Company: 'MBK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: 44,
        Birthplace: 'Goyang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'ra.L',
        'Full Name': 'Yi Taehyun',
        'Korean Name': '이태현',
        'K Stage Name': '라엘',
        'Date of Birth': '29/09/2003',
        Group: 'TRENDZ',
        Debut: '5/01/2022',
        Company: 'Interpark',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Rachel',
        'Full Name': 'Sung Nayeon',
        'Korean Name': '성나연',
        'K Stage Name': '레이첼',
        'Date of Birth': '28/08/2000',
        Group: 'APRIL',
        Debut: '24/08/2015',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': 'USA',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Rain',
        'Full Name': 'Jung Jihoon',
        'Korean Name': '정지훈',
        'K Stage Name': '비',
        'Date of Birth': '25/06/1982',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seosan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Raina',
        'Full Name': 'Oh Hyerin',
        'Korean Name': '오혜린',
        'K Stage Name': '레이나',
        'Date of Birth': '7/05/1989',
        Group: 'After School',
        Debut: '15/01/2009',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Ulsan',
        'Other Group': 'Orange Caramel',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Rainie',
        'Full Name': 'Chu Chingyu',
        'Korean Name': '주칭위',
        'K Stage Name': '레이니',
        'Date of Birth': '16/11/2000',
        Group: 'bugAboo',
        Debut: '25/10/2021',
        Company: 'A team',
        Country: 'Taiwan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Rakwon',
        'Full Name': 'Seok Rakwon',
        'Korean Name': '석락원',
        'K Stage Name': '락원',
        'Date of Birth': '14/11/2003',
        Group: 'ATBO',
        Debut: '27/07/2022',
        Company: 'IST',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Rano',
        'Full Name': 'Byun Yongseop',
        'Korean Name': '변용섭',
        'K Stage Name': '라노',
        'Date of Birth': '10/12/1998',
        Group: "E'LAST",
        Debut: '9/06/2020',
        Company: 'E Entertainment',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 60,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Raon',
        'Full Name': 'Lee Seoyoung',
        'Korean Name': '이서영',
        'K Stage Name': '라온',
        'Date of Birth': '21/01/2000',
        Group: 'ANS',
        Debut: '16/09/2019',
        Company: 'ANS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Raots',
        'Full Name': 'Ren Yuchen',
        'Korean Name': '런위천',
        'K Stage Name': '라오츠',
        'Date of Birth': '1/03/2000',
        Group: 'JWiiver',
        Debut: '17/02/2022',
        Company: 'JTG',
        Country: 'China',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ravi',
        'Full Name': 'Kim Wonsik',
        'Korean Name': '김원식',
        'K Stage Name': '라비',
        'Date of Birth': '15/02/1993',
        Group: 'VIXX',
        Debut: '24/05/2012',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: 65,
        Birthplace: 'Seoul',
        'Other Group': 'VIXX LR',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ravn',
        'Full Name': 'Kim Yoongjo',
        'Korean Name': '김영조',
        'K Stage Name': '레이븐',
        'Date of Birth': '2/09/1995',
        Group: 'Oneus',
        Debut: '9/01/2019',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Rayeon',
        'Full Name': 'Lee Rayeon',
        'Korean Name': '이나연',
        'K Stage Name': '나연',
        'Date of Birth': '24/11/2001',
        Group: 'FANATICS',
        Debut: '6/08/2019',
        Company: 'FENT',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 45,
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Red',
        'Full Name': 'Lee Heejin',
        'Korean Name': '이희진',
        'K Stage Name': '빨강',
        'Date of Birth': '10/07/1991',
        Group: 'Pungdeng-E',
        Debut: '11/12/2013',
        Company: 'DOMA',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Rei',
        'Full Name': 'Naoi Rei',
        'Korean Name': '나오이 레이',
        'K Stage Name': '레이',
        'Date of Birth': '3/02/2004',
        Group: 'IVE',
        Debut: '1/12/2021',
        Company: 'Starship',
        Country: 'Japan',
        'Second Country': '',
        Height: 169,
        Weight: 47,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Remi',
        'Full Name': 'Katsuno Rise',
        'Korean Name': '카츠노 리세',
        'K Stage Name': '레미',
        'Date of Birth': '26/04/2001',
        Group: 'Cherry Bullet',
        Debut: '21/01/2019',
        Company: 'FNC',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ren',
        'Full Name': 'Choi Minki',
        'Korean Name': '최민기',
        'K Stage Name': '렌',
        'Date of Birth': '3/11/1995',
        Group: "NU'EST",
        Debut: '15/03/2012',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': "NU'EST W",
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Rena',
        'Full Name': 'Kang Yebin',
        'Korean Name': '강예빈',
        'K Stage Name': '레나',
        'Date of Birth': '19/10/1998',
        Group: 'Hinapia',
        Debut: '3/11/2019',
        Company: 'Alseulbit',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: 49,
        Birthplace: '',
        'Other Group': 'Pristin V',
        'Former Group': 'Pristin',
        Gender: 'F'
    },
    {
        'Stage Name': 'Renjun',
        'Full Name': 'Hwang Renjun',
        'Korean Name': '황런쥔',
        'K Stage Name': '런쥔',
        'Date of Birth': '23/03/2000',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'China',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Jilin',
        'Other Group': 'NCT Dream',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ria',
        'Full Name': 'Kuwayama Ria',
        'Korean Name': '쿠와야마 리아',
        'K Stage Name': '리아',
        'Date of Birth': '3/02/1998',
        Group: 'BOTOPASS',
        Debut: '26/08/2020',
        Company: 'WKS ENE',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ricky',
        'Full Name': 'Yoo Changhyun',
        'Korean Name': '유창현',
        'K Stage Name': '리키',
        'Date of Birth': '27/02/1995',
        Group: 'Teen Top',
        Debut: '9/07/2010',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Rie',
        'Full Name': 'Lee Seungho',
        'Korean Name': '이성호',
        'K Stage Name': '리에',
        'Date of Birth': '6/11/1996',
        Group: 'OnlyOneOf',
        Debut: '28/05/2019',
        Company: 'RSVP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Rihan',
        'Full Name': 'Choi Yeonsik',
        'Korean Name': '최연식',
        'K Stage Name': '리한',
        'Date of Birth': '22/05/1998',
        Group: 'JWiiver',
        Debut: '17/02/2022',
        Company: 'JTG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Riho',
        'Full Name': 'Jin Seungwook',
        'Korean Name': '진승욱',
        'K Stage Name': '리호',
        'Date of Birth': '1/03/1998',
        Group: 'VARSITY',
        Debut: '5/01/2017',
        Company: 'GKC',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 59,
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Riina',
        'Full Name': 'Lee Seunghyeon',
        'Korean Name': '이승현',
        'K Stage Name': '리이나',
        'Date of Birth': '21/02/2001',
        Group: 'H1-KEY',
        Debut: '5/01/2022',
        Company: 'GLG',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: 55,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Rina',
        'Full Name': 'Kang Soeun',
        'Korean Name': '강소은',
        'K Stage Name': '리나',
        'Date of Birth': '27/09/2001',
        Group: 'Weki Meki',
        Debut: '8/08/2017',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Rinji',
        'Full Name': 'Hwang Rinji',
        'Korean Name': '황린지',
        'K Stage Name': '린지',
        'Date of Birth': '5/05/2006',
        Group: 'Pixy',
        Debut: '24/02/2021',
        Company: 'ALLART',
        Country: 'South Korea',
        'Second Country': '',
        Height: 169,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Rio',
        'Full Name': 'Son Gwanghyuk',
        'Korean Name': '손광혁',
        'K Stage Name': '리오',
        'Date of Birth': '16/07/1999',
        Group: '14U',
        Debut: '17/04/2017',
        Company: 'BG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ririka',
        'Full Name': 'Kishida Ririka',
        'Korean Name': '키시다 리리카',
        'K Stage Name': '리리카',
        'Date of Birth': '2/07/2002',
        Group: 'ILY:1',
        Debut: '4/04/2022',
        Company: 'FC ENM',
        Country: 'Japan',
        'Second Country': '',
        Height: 162,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Riwon',
        'Full Name': 'Kim Riwon',
        'Korean Name': '김리원',
        'K Stage Name': '리원',
        'Date of Birth': '11/01/2007',
        Group: 'CLASS:y',
        Debut: '5/05/2022',
        Company: 'Universal',
        Country: 'South Korea',
        'Second Country': '',
        Height: 156,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'RM',
        'Full Name': 'Kim Namjoon',
        'Korean Name': '김남준',
        'K Stage Name': '알엠',
        'Date of Birth': '12/09/1994',
        Group: 'BTS',
        Debut: '13/06/2013',
        Company: 'Big Hit',
        Country: 'South Korea',
        'Second Country': '',
        Height: 181,
        Weight: 67,
        Birthplace: 'Ilsan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Roa',
        'Full Name': 'Kim Minkyung',
        'Korean Name': '김민경',
        'K Stage Name': '로아',
        'Date of Birth': '29/07/1997',
        Group: 'Hinapia',
        Debut: '3/11/2019',
        Company: 'Alseulbit',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: 49,
        Birthplace: '',
        'Other Group': 'Pristin V',
        'Former Group': 'Pristin',
        Gender: 'F'
    },
    {
        'Stage Name': 'Roa',
        'Full Name': '',
        'Korean Name': '',
        'K Stage Name': '로아',
        'Date of Birth': '',
        Group: 'X:IN',
        Debut: '11/04/2023',
        Company: 'Escrow',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Rocky',
        'Full Name': 'Park Minhyuk',
        'Korean Name': '박민혁',
        'K Stage Name': '라키',
        'Date of Birth': '25/02/1999',
        Group: 'ASTRO',
        Debut: '23/02/2016',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Jinju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Roda',
        'Full Name': 'Shin Joongmin',
        'Korean Name': '신중민',
        'K Stage Name': '로다',
        'Date of Birth': '19/09/1998',
        Group: 'MONT',
        Debut: '14/05/2014',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 60,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Roel',
        'Full Name': 'Kim Sunho',
        'Korean Name': '김선호',
        'K Stage Name': '로엘',
        'Date of Birth': '13/02/1997',
        Group: 'ARGON',
        Debut: '11/03/2019',
        Company: 'MSH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Rokhyun',
        'Full Name': 'Kim Rokhyun',
        'Korean Name': '김록현',
        'K Stage Name': '록현',
        'Date of Birth': '10/02/1991',
        Group: '100%',
        Debut: '18/09/2012',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Suncheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Romin',
        'Full Name': 'Choi Youngmin',
        'Korean Name': '최영민',
        'K Stage Name': '로민',
        'Date of Birth': '24/04/2001',
        Group: "E'LAST",
        Debut: '9/06/2020',
        Company: 'E Entertainment',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 58,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ron',
        'Full Name': 'Cheon Byeonghwa',
        'Korean Name': '천병화',
        'K Stage Name': '론',
        'Date of Birth': '22/01/1991',
        Group: 'Bigflo',
        Debut: '19/07/2014',
        Company: 'HO',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: 62,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Rona',
        'Full Name': 'Chang Ching',
        'Korean Name': '장찡',
        'K Stage Name': '로나',
        'Date of Birth': '5/06/2002',
        Group: 'ILY:1',
        Debut: '4/04/2022',
        Company: 'FC ENM',
        Country: 'Taiwan',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Taipei',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Roni',
        'Full Name': 'Jeong Rowoon',
        'Korean Name': '정로운',
        'K Stage Name': '로니',
        'Date of Birth': '31/07/1994',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 174,
        Weight: 55,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'XEED',
        Gender: 'M'
    },
    {
        'Stage Name': 'Rora',
        'Full Name': 'Lee Dain',
        'Korean Name': '이다인',
        'K Stage Name': '로라',
        'Date of Birth': '5/08/2008',
        Group: 'BABYMONSTER',
        Debut: '0/01/1900',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Rosé',
        'Full Name': 'Roseanne Park',
        'Korean Name': '로잔느 박',
        'K Stage Name': '로제',
        'Date of Birth': '11/02/1997',
        Group: 'BLACKPINK',
        Debut: '8/08/2016',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: '',
        Birthplace: 'Auckland',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Roshin',
        'Full Name': 'Bae Sijun',
        'Korean Name': '배시준',
        'K Stage Name': '로신',
        'Date of Birth': '13/04/2001',
        Group: 'JWiiver',
        Debut: '17/02/2022',
        Company: 'JTG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Rowoon',
        'Full Name': 'Kim Seokwoo',
        'Korean Name': '김석우',
        'K Stage Name': '로운',
        'Date of Birth': '7/08/1996',
        Group: 'SF9',
        Debut: '5/10/2016',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Royeon',
        'Full Name': 'Jeon Hyunjoo',
        'Korean Name': '전현주',
        'K Stage Name': '로연',
        'Date of Birth': '20/02/1998',
        Group: 'ANS',
        Debut: '16/09/2019',
        Company: 'ANS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Rubin',
        'Full Name': 'Lee Haejoon',
        'Korean Name': '이해준',
        'K Stage Name': '루빈',
        'Date of Birth': '16/08/1995',
        Group: '1TEAM',
        Debut: '27/03/2019',
        Company: 'LiveWorks',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ruby',
        'Full Name': 'Heo Jiwon',
        'Korean Name': '허지원',
        'K Stage Name': '루비',
        'Date of Birth': '21/08/2002',
        Group: 'PRIMROSE',
        Debut: '13/03/2023',
        Company: 'AO',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Rui',
        'Full Name': 'Jang Rui',
        'Korean Name': '장루이',
        'K Stage Name': '루이',
        'Date of Birth': '7/03/2000',
        Group: "7 O'clock",
        Debut: '26/08/2014',
        Company: 'Jungle',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 63,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Rui',
        'Full Name': 'Watanabe Rui',
        'Korean Name': '와타나베 루이',
        'K Stage Name': '루이',
        'Date of Birth': '8/10/1994',
        Group: 'H.U.B',
        Debut: '9/02/2017',
        Company: 'New Planet',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'N-Bulance',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ruka',
        'Full Name': 'Kawai Ruka',
        'Korean Name': '카와이 루카',
        'K Stage Name': '루카',
        'Date of Birth': '20/03/2002',
        Group: 'BABYMONSTER',
        Debut: '0/01/1900',
        Company: 'YG',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ryeowook',
        'Full Name': 'Kim Ryeowook',
        'Korean Name': '김려욱',
        'K Stage Name': '려욱',
        'Date of Birth': '21/06/1987',
        Group: 'Super Junior',
        Debut: '6/11/2005',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 58,
        Birthplace: 'Incheon',
        'Other Group': 'Super Junior-M',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ryujei',
        'Full Name': 'Chae Hocheol',
        'Korean Name': '채호철',
        'K Stage Name': '류제이',
        'Date of Birth': '8/11/1997',
        Group: 'JWiiver',
        Debut: '17/02/2022',
        Company: 'JTG',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 54,
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ryujin',
        'Full Name': 'Shin Ryujin',
        'Korean Name': '신류진',
        'K Stage Name': '류진',
        'Date of Birth': '17/04/2001',
        Group: 'ITZY',
        Debut: '12/02/2019',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'S.Coups',
        'Full Name': 'Choi Seungcheol',
        'Korean Name': '최승철',
        'K Stage Name': '에스.쿱스',
        'Date of Birth': '8/08/1995',
        Group: 'Seventeen',
        Debut: '26/05/2015',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 63,
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Saebom',
        'Full Name': 'Kim Seyoung',
        'Korean Name': '김세영',
        'K Stage Name': '새봄',
        'Date of Birth': '13/01/1995',
        Group: 'Favorite',
        Debut: '5/07/2017',
        Company: 'Astory',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwacheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Saebom',
        'Full Name': 'Kim Saerok',
        'Korean Name': '김새록',
        'K Stage Name': '새봄',
        'Date of Birth': '30/01/1997',
        Group: 'NATURE',
        Debut: '3/08/2018',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Saebyeok',
        'Full Name': 'Shim Jaeyoung',
        'Korean Name': '심재영',
        'K Stage Name': '새벽',
        'Date of Birth': '9/11/1996',
        Group: 'Ho1iday',
        Debut: '26/04/2013',
        Company: 'LOEN',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Saebyeol',
        'Full Name': 'Han Saebyeol',
        'Korean Name': '한새별',
        'K Stage Name': '새별',
        'Date of Birth': '23/05/1996',
        Group: 'Matilda',
        Debut: '18/03/2016',
        Company: 'Box',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: 168,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Saena',
        'Full Name': 'Jung Sehyun',
        'Korean Name': '정세현',
        'K Stage Name': '새나',
        'Date of Birth': '12/03/2004',
        Group: 'FIFTY FIFTY',
        Debut: '18/11/2022',
        Company: 'ATTRAKT',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 42,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Saerom',
        'Full Name': 'Lee Saerom',
        'Korean Name': '이새롬',
        'K Stage Name': '새롬',
        'Date of Birth': '7/01/1997',
        Group: 'fromis_9',
        Debut: '24/01/2018',
        Company: 'Off The Record',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gangwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sakang',
        'Full Name': 'Jeon Sakang',
        'Korean Name': '정사강',
        'K Stage Name': '사강',
        'Date of Birth': '26/10/2002',
        Group: 'TheEastLight',
        Debut: '15/11/2018',
        Company: 'Stardium',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sakura',
        'Full Name': 'Miyawaki Sakura',
        'Korean Name': '미야와키 사쿠라',
        'K Stage Name': '사쿠라',
        'Date of Birth': '19/03/1998',
        Group: 'LE SSERAFIM',
        Debut: '2/05/2022',
        Company: 'Source',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Kagoshima',
        'Other Group': '',
        'Former Group': 'IZ*ONE',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sally',
        'Full Name': 'Liu Xiening',
        'Korean Name': '류셰닝',
        'K Stage Name': '샐리',
        'Date of Birth': '23/10/1996',
        Group: 'Gugudan',
        Debut: '28/06/2016',
        Company: 'Jellyfish',
        Country: 'China',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Guangdong',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Samuel',
        'Full Name': 'Kim Samuel',
        'Korean Name': '김사무엘',
        'K Stage Name': '사무엘',
        'Date of Birth': '17/01/2002',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': 'USA',
        Height: '',
        Weight: '',
        Birthplace: 'Los Angeles',
        'Other Group': '',
        'Former Group': '1PUNCH',
        Gender: 'M'
    },
    {
        'Stage Name': 'San',
        'Full Name': 'Choi San',
        'Korean Name': '최산',
        'K Stage Name': '산',
        'Date of Birth': '10/07/1999',
        Group: 'ATEEZ',
        Debut: '24/10/2018',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sana',
        'Full Name': 'Minatozaki Sana',
        'Korean Name': '미나토자키 사나',
        'K Stage Name': '사나',
        'Date of Birth': '29/12/1996',
        Group: 'Twice',
        Debut: '20/10/2015',
        Company: 'JYP',
        Country: 'Japan',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: 'Osaka',
        'Other Group': 'MISAMO',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sandeul',
        'Full Name': 'Lee Junghwan',
        'Korean Name': '이정환',
        'K Stage Name': '산들',
        'Date of Birth': '20/03/1992',
        Group: 'B1A4',
        Debut: '19/04/2010',
        Company: 'WM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 62,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sanga',
        'Full Name': 'Lee Sanga',
        'Korean Name': '이상아',
        'K Stage Name': '상아',
        'Date of Birth': '5/08/1999',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Pink Fantasy',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sangah',
        'Full Name': 'Yoo Sangah',
        'Korean Name': '윤상아',
        'K Stage Name': '상아',
        'Date of Birth': '4/09/2002',
        Group: 'LIGHTSUM',
        Debut: '10/06/2021',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sangdo',
        'Full Name': 'Yoo Sangdo',
        'Korean Name': '유상도',
        'K Stage Name': '상도',
        'Date of Birth': '2/03/1993',
        Group: 'Topp Dogg',
        Debut: '1/04/2020',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sangho',
        'Full Name': 'Jo Sangho',
        'Korean Name': '조상호',
        'K Stage Name': '상호',
        'Date of Birth': '10/02/1995',
        Group: 'SNUPER',
        Debut: '16/11/2015',
        Company: 'Widmay',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Changwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sanghoon',
        'Full Name': 'Lee Sanghoon',
        'Korean Name': '이상훈',
        'K Stage Name': '상훈',
        'Date of Birth': '23/12/1993',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Cheongju',
        'Other Group': '',
        'Former Group': '100%',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sangil',
        'Full Name': 'Shim Sangil',
        'Korean Name': '심상일',
        'K Stage Name': '상일',
        'Date of Birth': '1/05/1993',
        Group: 'SNUPER',
        Debut: '16/11/2015',
        Company: 'Widmay',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sangmin',
        'Full Name': 'Kim Sangmin',
        'Korean Name': '김상민',
        'K Stage Name': '상민',
        'Date of Birth': '7/07/1992',
        Group: 'CROSS GENE',
        Debut: '8/07/2012',
        Company: 'Amuse',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 67,
        Birthplace: 'Jinju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sangwoo',
        'Full Name': 'Kim Sangwoo',
        'Korean Name': '김상우',
        'K Stage Name': '상우',
        'Date of Birth': '3/09/2002',
        Group: 'JUST B',
        Debut: '30/06/2021',
        Company: 'Bluedot',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sangyeon',
        'Full Name': 'Lee Sangyeon',
        'Korean Name': '이상연',
        'K Stage Name': '상연',
        'Date of Birth': '4/11/1996',
        Group: 'The Boyz',
        Debut: '31/12/2017',
        Company: 'Cre.Ker',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 61,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sanha',
        'Full Name': 'Yoon Sanha',
        'Korean Name': '윤산하',
        'K Stage Name': '산하',
        'Date of Birth': '21/03/2000',
        Group: 'ASTRO',
        Debut: '23/02/2016',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Satbyeol',
        'Full Name': 'Jeon Yujin',
        'Korean Name': '전유진',
        'K Stage Name': '샛별',
        'Date of Birth': '27/02/2001',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Girls Alert| Pixy',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sea',
        'Full Name': 'Kim Sujeong',
        'Korean Name': '김수정',
        'K Stage Name': '세아',
        'Date of Birth': '5/12/1999',
        Group: 'Highteen',
        Debut: '14/10/2016',
        Company: 'Elijah',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sebin',
        'Full Name': 'Jang Sebin',
        'Korean Name': '장세빈',
        'K Stage Name': '세빈',
        'Date of Birth': '24/04/1996',
        Group: 'SNUPER',
        Debut: '16/11/2015',
        Company: 'Widmay',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Pocheon',
        'Other Group': 'OMEGA X',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seea',
        'Full Name': 'Kang Eunyoung',
        'Korean Name': '강은영',
        'K Stage Name': '시아',
        'Date of Birth': '21/08/1994',
        Group: 'Pink Fantasy',
        Debut: '28/10/2018',
        Company: 'My Doll',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 43,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seeun',
        'Full Name': 'Yoon Seeun',
        'Korean Name': '윤세은',
        'K Stage Name': '세은',
        'Date of Birth': '14/06/2003',
        Group: 'STAYC',
        Debut: '12/11/2020',
        Company: 'Hi-Up',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 49,
        Birthplace: 'Pyeongtaek',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seeun',
        'Full Name': 'Park Seeun',
        'Korean Name': '박세은',
        'K Stage Name': '세은',
        'Date of Birth': '17/08/2005',
        Group: 'xikers',
        Debut: '30/03/2023',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Segye',
        'Full Name': 'Shin Segye',
        'Korean Name': '신세계',
        'K Stage Name': '세계',
        'Date of Birth': '19/08/1998',
        Group: 'AREAL',
        Debut: '21/08/2020',
        Company: 'JZ Factory',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Rose Finger',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sehun',
        'Full Name': 'Oh Sehun',
        'Korean Name': '오세훈',
        'K Stage Name': '세훈',
        'Date of Birth': '12/04/1994',
        Group: 'EXO',
        Debut: '8/04/2012',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 181,
        Weight: 63,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sehyung',
        'Full Name': 'Kang Sehyung',
        'Korean Name': '강세형',
        'K Stage Name': '세형',
        'Date of Birth': '13/12/1998',
        Group: 'Berry Good',
        Debut: '21/05/2014',
        Company: 'Asia Bridge',
        Country: 'South Korea',
        'Second Country': '',
        Height: 169,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sei',
        'Full Name': 'Lee Seojung',
        'Korean Name': '이서정',
        'K Stage Name': '세이',
        'Date of Birth': '7/01/2000',
        Group: 'Weki Meki',
        Debut: '8/08/2017',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sejeong',
        'Full Name': 'Kim Sejeong',
        'Korean Name': '김세정',
        'K Stage Name': '세정',
        'Date of Birth': '28/08/1996',
        Group: 'Gugudan',
        Debut: '28/06/2016',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 48,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'I.O.I',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sejin',
        'Full Name': 'Lee Sejin',
        'Korean Name': '이세진',
        'K Stage Name': '세진',
        'Date of Birth': '22/07/1999',
        Group: '14U',
        Debut: '17/04/2017',
        Company: 'BG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sejun',
        'Full Name': 'Lim Sejun',
        'Korean Name': '임세준',
        'K Stage Name': '세준',
        'Date of Birth': '4/05/1996',
        Group: 'VICTON',
        Debut: '9/11/2016',
        Company: 'Plan A',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seline',
        'Full Name': 'Jung Yeonjeong',
        'Korean Name': '정연정',
        'K Stage Name': '셀린',
        'Date of Birth': '20/06/2000',
        Group: 'Cignature',
        Debut: '4/02/2020',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Semi',
        'Full Name': 'Gu Semi',
        'Korean Name': '구세미',
        'K Stage Name': '세미',
        'Date of Birth': '10/04/2002',
        Group: 'Cignature',
        Debut: '4/02/2020',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 46,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Semmi',
        'Full Name': 'Oh Heesun',
        'Korean Name': '오희선',
        'K Stage Name': '세미',
        'Date of Birth': '22/10/1995',
        Group: 'Matilda',
        Debut: '18/03/2016',
        Company: 'Box',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: 165,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seogoong',
        'Full Name': 'Park Hyunho',
        'Korean Name': '박현호',
        'K Stage Name': '서궁',
        'Date of Birth': '1/05/1992',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seongnam',
        'Other Group': '',
        'Former Group': 'Topp Dogg',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seoham',
        'Full Name': 'Park Seoham',
        'Korean Name': '박서함',
        'K Stage Name': '서함',
        'Date of Birth': '28/10/1993',
        Group: 'KNK',
        Debut: '3/03/2016',
        Company: 'YNB',
        Country: 'South Korea',
        'Second Country': '',
        Height: 190,
        Weight: 70,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seoho',
        'Full Name': 'Lee Seoho',
        'Korean Name': '이서호',
        'K Stage Name': '서호',
        'Date of Birth': '7/06/1996',
        Group: 'Oneus',
        Debut: '9/01/2019',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seohyun',
        'Full Name': 'Seo Joohyun',
        'Korean Name': '서주현',
        'K Stage Name': '서현',
        'Date of Birth': '28/06/1991',
        Group: 'SNSD',
        Debut: '4/08/2021',
        Company: 'Good Luck',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: 48,
        Birthplace: 'Seoul',
        'Other Group': 'TTS',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seoi',
        'Full Name': 'Lee Yejin',
        'Korean Name': '이예진',
        'K Stage Name': '서이',
        'Date of Birth': '12/02/2000',
        Group: 'H1-KEY',
        Debut: '5/01/2022',
        Company: 'GLG',
        Country: 'South Korea',
        'Second Country': '',
        Height: 169,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seokcheol',
        'Full Name': 'Lee Seokchul',
        'Korean Name': '이석철',
        'K Stage Name': '석철',
        'Date of Birth': '11/01/2000',
        Group: 'TheEastLight',
        Debut: '15/11/2018',
        Company: 'Stardium',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: 170,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seokhwa',
        'Full Name': 'Kang Seokhwa',
        'Korean Name': '강석화',
        'K Stage Name': '석화',
        'Date of Birth': '1/12/2000',
        Group: 'WEi',
        Debut: '5/10/2020',
        Company: 'OUI',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: 56,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seokyoung',
        'Full Name': 'Kim Seokyung',
        'Korean Name': '김서경',
        'K Stage Name': '서경',
        'Date of Birth': '16/04/1999',
        Group: 'GWSN',
        Debut: '5/09/2018',
        Company: 'Kiwi Pop',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seol',
        'Full Name': 'Min Jihye',
        'Korean Name': '민지혜',
        'K Stage Name': '설',
        'Date of Birth': '1/01/1997',
        Group: 'P.O.P',
        Debut: '26/07/2017',
        Company: 'DWM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gunsan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seola',
        'Full Name': 'Kim Hyunjung',
        'Korean Name': '김현정',
        'K Stage Name': '설아',
        'Date of Birth': '24/12/1994',
        Group: 'WJSN',
        Debut: '25/02/2016',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': 'WJMK| WJSN The Black',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seolhyun',
        'Full Name': 'Kim Seolhyun',
        'Korean Name': '김설현',
        'K Stage Name': '설현',
        'Date of Birth': '3/01/1995',
        Group: 'AoA',
        Debut: '30/07/2012',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 47,
        Birthplace: 'Bucheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seonghwa',
        'Full Name': 'Park Seonghwa',
        'Korean Name': '박성화',
        'K Stage Name': '성화',
        'Date of Birth': '3/04/1998',
        Group: 'ATEEZ',
        Debut: '24/10/2018',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: '',
        Birthplace: 'Jinju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seonglee',
        'Full Name': 'Kim Seonglee',
        'Korean Name': '김성리',
        'K Stage Name': '성리',
        'Date of Birth': '6/04/1994',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'RAINZ',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seongmin',
        'Full Name': 'Ahn Seongmin',
        'Korean Name': '안성민',
        'K Stage Name': '성민',
        'Date of Birth': '1/08/2003',
        Group: 'CRAVITY',
        Debut: '14/04/2020',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seongsoo',
        'Full Name': 'Park Seongsoo',
        'Korean Name': '박성수',
        'K Stage Name': '성수',
        'Date of Birth': '7/04/1999',
        Group: 'ABLUE',
        Debut: '23/10/2022',
        Company: 'J-Star',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 65,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'BECZ',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seongwu',
        'Full Name': 'Ong Seunwu',
        'Korean Name': '옹성우',
        'K Stage Name': '성우',
        'Date of Birth': '25/08/1995',
        Group: 'Wanna One',
        Debut: '7/08/2017',
        Company: 'YMC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seonyu',
        'Full Name': 'Kim Seonyu',
        'Korean Name': '김선유',
        'K Stage Name': '선유',
        'Date of Birth': '20/03/2008',
        Group: 'CLASS:y',
        Debut: '5/05/2022',
        Company: 'Universal',
        Country: 'South Korea',
        'Second Country': '',
        Height: 159,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seoryoung',
        'Full Name': 'Lee Seoryung',
        'Korean Name': '이서령',
        'K Stage Name': '서령',
        'Date of Birth': '26/01/2000',
        Group: 'GWSN',
        Debut: '5/09/2018',
        Company: 'Kiwi Pop',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seowon',
        'Full Name': 'Jang Seowon',
        'Korean Name': '장서원',
        'K Stage Name': '서원',
        'Date of Birth': '5/12/2006',
        Group: 'Lapillus',
        Debut: '22/06/2022',
        Company: 'MLD',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seowon',
        'Full Name': 'Seo Won',
        'Korean Name': '서원',
        'K Stage Name': '서원',
        'Date of Birth': '18/12/2000',
        Group: 'NINE.i',
        Debut: '30/03/2022',
        Company: 'FirstOne',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seoyeon',
        'Full Name': 'Ahn Seoyeon',
        'Korean Name': '안서연',
        'K Stage Name': '서연',
        'Date of Birth': '26/03/2005',
        Group: 'CSR',
        Debut: '27/07/2022',
        Company: 'A2Z',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Bucheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seoyeon',
        'Full Name': 'Kim Minju',
        'Korean Name': '김민주',
        'K Stage Name': '서연',
        'Date of Birth': '26/11/1995',
        Group: 'Favorite',
        Debut: '5/07/2017',
        Company: 'Astory',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwacheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seoyeon',
        'Full Name': 'Lee Seoyeon',
        'Korean Name': '이서연',
        'K Stage Name': '서연',
        'Date of Birth': '22/01/2000',
        Group: 'fromis_9',
        Debut: '24/01/2018',
        Company: 'Off The Record',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seoyeon',
        'Full Name': 'Yoon Seoyeon',
        'Korean Name': '윤서연',
        'K Stage Name': '서연',
        'Date of Birth': '6/08/2003',
        Group: 'tripleS',
        Debut: '13/02/2023',
        Company: 'MODHAUS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seoyoon',
        'Full Name': 'Kim Suyoung',
        'Korean Name': '김수영',
        'K Stage Name': '서윤',
        'Date of Birth': '26/01/1995',
        Group: 'BOTOPASS',
        Debut: '26/08/2020',
        Company: 'WKS ENE',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'ILUV',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seoyoung',
        'Full Name': 'Lee Seoyoung',
        'Korean Name': '이서영',
        'K Stage Name': '서영',
        'Date of Birth': '27/06/1994',
        Group: 'Hello Venus',
        Debut: '9/05/2012',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 50,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seoyul',
        'Full Name': 'Seo Yuri',
        'Korean Name': '서유리',
        'K Stage Name': '서율',
        'Date of Birth': '26/11/1997',
        Group: 'Berry Good',
        Debut: '21/05/2014',
        Company: 'Asia Bridge',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Serim',
        'Full Name': 'Park Serim',
        'Korean Name': '박세림',
        'K Stage Name': '세림',
        'Date of Birth': '3/03/1999',
        Group: 'CRAVITY',
        Debut: '14/04/2020',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Serri',
        'Full Name': 'Park Miyeon',
        'Korean Name': '박미연',
        'K Stage Name': '세리',
        'Date of Birth': '16/09/1990',
        Group: 'Dal Shabet',
        Debut: '3/01/2011',
        Company: 'Happy Face',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seulgi',
        'Full Name': 'Kang Seulgi',
        'Korean Name': '강슬기',
        'K Stage Name': '슬기',
        'Date of Birth': '10/02/1994',
        Group: 'Red Velvet',
        Debut: '1/08/2014',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': 'Red Velvet - IRENE SEULGI',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seulgi',
        'Full Name': 'Jeong Seulgi',
        'Korean Name': '정슬기',
        'K Stage Name': '슬기',
        'Date of Birth': '12/05/1990',
        Group: 'S#aFLA',
        Debut: '14/07/2021',
        Company: 'Winnerzone',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seungah',
        'Full Name': 'Oh Seungah',
        'Korean Name': '오승아',
        'K Stage Name': '승아',
        'Date of Birth': '13/09/1988',
        Group: 'Rainbow',
        Debut: '12/11/2009',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 45,
        Birthplace: 'Seoul',
        'Other Group': 'Rainbow Blaxx| Rainbow Pixie',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seungchan',
        'Full Name': 'Kang Seungchan',
        'Korean Name': '강승찬',
        'K Stage Name': '승찬',
        'Date of Birth': '1/01/2003',
        Group: 'Newkidd',
        Debut: '25/04/2019',
        Company: 'J-FLO',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seungeun',
        'Full Name': 'Shim Seungeun',
        'Korean Name': '심승은',
        'K Stage Name': '승은',
        'Date of Birth': '27/12/2000',
        Group: 'BVNDIT',
        Debut: '10/04/2019',
        Company: 'MNH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seunghan',
        'Full Name': 'Lee Seunghan',
        'Korean Name': '이승한',
        'K Stage Name': '승한',
        'Date of Birth': '22/02/1996',
        Group: 'DUSTIN',
        Debut: '6/01/2020',
        Company: 'LPA',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seunghee',
        'Full Name': 'Oh Seunghee',
        'Korean Name': '오승희',
        'K Stage Name': '승희',
        'Date of Birth': '10/10/1995',
        Group: 'CLC',
        Debut: '19/03/2015',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seunghee',
        'Full Name': 'Hyun Seunghee',
        'Korean Name': '현승희',
        'K Stage Name': '승희',
        'Date of Birth': '25/01/1996',
        Group: 'Oh My Girl',
        Debut: '21/04/2015',
        Company: 'WM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 48,
        Birthplace: 'Chuncheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seungheon',
        'Full Name': 'Lee Seungheon',
        'Korean Name': '이승헌',
        'K Stage Name': '승헌',
        'Date of Birth': '15/05/2007',
        Group: '8TURN',
        Debut: '30/01/2023',
        Company: 'MNH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: 51,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seungho',
        'Full Name': 'Yang Seungho',
        'Korean Name': '양승호',
        'K Stage Name': '승호',
        'Date of Birth': '16/10/1987',
        Group: 'MBLAQ',
        Debut: '14/10/2009',
        Company: 'J. Tune',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seunghun',
        'Full Name': 'Kim Seunghun',
        'Korean Name': '김승훈',
        'K Stage Name': '승훈',
        'Date of Birth': '26/02/1999',
        Group: 'CIX',
        Debut: '23/07/2019',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: 181,
        Weight: 69,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seunghwan',
        'Full Name': 'Lee Seunghwan',
        'Korean Name': '이승환',
        'K Stage Name': '승환',
        'Date of Birth': '20/05/2000',
        Group: '1the9',
        Debut: '9/02/2019',
        Company: 'MBK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 64,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seunghwan',
        'Full Name': 'Park Seunghwan',
        'Korean Name': '박승환',
        'K Stage Name': '승환',
        'Date of Birth': '6/04/2002',
        Group: 'AIMERS',
        Debut: '17/11/2022',
        Company: 'Hyper Rhythm',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seunghwan',
        'Full Name': 'Jeong Seunghwan',
        'Korean Name': '정승환',
        'K Stage Name': '승환',
        'Date of Birth': '27/01/2004',
        Group: 'ATBO',
        Debut: '27/07/2022',
        Company: 'IST',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seunghwan',
        'Full Name': 'Lee Seunghwan',
        'Korean Name': '이승환',
        'K Stage Name': '승환',
        'Date of Birth': '10/12/1994',
        Group: 'Romeo',
        Debut: '4/11/2015',
        Company: 'CT',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seunghyub',
        'Full Name': 'Lee Seunghyub',
        'Korean Name': '이승협',
        'K Stage Name': '승협',
        'Date of Birth': '31/10/1992',
        Group: 'N.Flying',
        Debut: '20/05/2015',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seunghyun',
        'Full Name': 'Lee Seunghyun',
        'Korean Name': '이승현',
        'K Stage Name': '승현',
        'Date of Birth': '17/07/1998',
        Group: 'AIMERS',
        Debut: '17/11/2022',
        Company: 'Hyper Rhythm',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Spectrum',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seunghyun',
        'Full Name': 'Song Seungyun',
        'Korean Name': '송승현',
        'K Stage Name': '승현',
        'Date of Birth': '21/08/1992',
        Group: 'FTISLAND',
        Debut: '7/06/2007',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seunghyun',
        'Full Name': 'Lee Seunghyun',
        'Korean Name': '이승현',
        'K Stage Name': '승현',
        'Date of Birth': '31/12/2001',
        Group: 'TheEastLight',
        Debut: '15/11/2018',
        Company: 'Stardium',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seungjun',
        'Full Name': 'Ji Seungjun',
        'Korean Name': '지승준',
        'K Stage Name': '승준',
        'Date of Birth': '15/09/1998',
        Group: 'Astin',
        Debut: '16/11/2022',
        Company: 'AB Creative',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seungkwan',
        'Full Name': 'Boo Seungkwan',
        'Korean Name': '부승관',
        'K Stage Name': '승관',
        'Date of Birth': '16/01/1998',
        Group: 'Seventeen',
        Debut: '26/05/2015',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: 58,
        Birthplace: 'Jeju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seungmin',
        'Full Name': 'Bae Seungmin',
        'Korean Name': '배승민',
        'K Stage Name': '승민',
        'Date of Birth': '13/10/1998',
        Group: 'Golden Child',
        Debut: '28/08/2017',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seungmin',
        'Full Name': 'Lee Seungmin',
        'Korean Name': '이승민',
        'K Stage Name': '승민',
        'Date of Birth': '3/02/1996',
        Group: 'Hash Tag',
        Debut: '11/10/2017',
        Company: 'LUK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seungmin',
        'Full Name': 'Kim Seungmin',
        'Korean Name': '김승민',
        'K Stage Name': '승민',
        'Date of Birth': '22/09/2000',
        Group: 'Stray Kids',
        Debut: '25/03/2018',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seungri',
        'Full Name': 'Lee Seunghyun',
        'Korean Name': '이승현',
        'K Stage Name': '승리',
        'Date of Birth': '12/12/1990',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 60,
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': 'BIGBANG',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seungsik',
        'Full Name': 'Kang Seungsik',
        'Korean Name': '강승식',
        'K Stage Name': '승식',
        'Date of Birth': '16/04/1995',
        Group: 'VICTON',
        Debut: '9/11/2016',
        Company: 'Plan A',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seungwoo',
        'Full Name': 'Han Seungwoo',
        'Korean Name': '한승우',
        'K Stage Name': '승우',
        'Date of Birth': '24/12/1994',
        Group: 'VICTON',
        Debut: '9/11/2016',
        Company: 'Plan A',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'X1',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seungyeon',
        'Full Name': 'Jang Seunyeon',
        'Korean Name': '장승연',
        'K Stage Name': '승연',
        'Date of Birth': '6/11/1996',
        Group: 'CLC',
        Debut: '19/03/2015',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: 'Seongnam',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seungyeon',
        'Full Name': 'Han Seungyeon',
        'Korean Name': '한승연',
        'K Stage Name': '승연',
        'Date of Birth': '24/07/1988',
        Group: 'KARA',
        Debut: '29/03/2007',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seungyeop',
        'Full Name': 'Choi Seungyeop',
        'Korean Name': '최승엽',
        'K Stage Name': '승엽',
        'Date of Birth': '8/05/1997',
        Group: "E'LAST",
        Debut: '9/06/2020',
        Company: 'E Entertainment',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 60,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seungyoon',
        'Full Name': 'Cho Seungyoon',
        'Korean Name': '조승연',
        'K Stage Name': '승연',
        'Date of Birth': '5/08/1996',
        Group: 'UNIQ',
        Debut: '20/10/2014',
        Company: 'Yuehua',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: 68,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'X1',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seyeon',
        'Full Name': 'Park Seyeon',
        'Korean Name': '박세연',
        'K Stage Name': '세연',
        'Date of Birth': '6/05/2001',
        Group: 'Purplebeck',
        Debut: '24/06/2019',
        Company: 'Majesty',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 46,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seyong',
        'Full Name': 'Kim Seyong',
        'Korean Name': '김세용',
        'K Stage Name': '세용',
        'Date of Birth': '20/11/1991',
        Group: 'MYNAME',
        Debut: '27/10/2011',
        Company: 'H2',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Seyoung',
        'Full Name': 'Ha Seyoung',
        'Korean Name': '하세영',
        'K Stage Name': '세영',
        'Date of Birth': '3/02/1999',
        Group: 'ARTBEAT',
        Debut: '16/11/2022',
        Company: 'AB Creative',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 50,
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Seyoung',
        'Full Name': 'Lee Seyoung',
        'Korean Name': '이세영',
        'K Stage Name': '세영',
        'Date of Birth': '8/02/1990',
        Group: 'CROSS GENE',
        Debut: '8/07/2012',
        Company: 'Amuse',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 60,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Shana',
        'Full Name': 'Nonaka Shana',
        'Korean Name': '노나카 샤나',
        'K Stage Name': '샤나',
        'Date of Birth': '13/03/2003',
        Group: 'Lapillus',
        Debut: '22/06/2022',
        Company: 'MLD',
        Country: 'Japan',
        'Second Country': '',
        Height: 158,
        Weight: '',
        Birthplace: 'Kochi',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sheon',
        'Full Name': 'Kim Suyeon',
        'Korean Name': '김수연',
        'K Stage Name': '션',
        'Date of Birth': '28/01/2003',
        Group: 'Billlie',
        Debut: '10/11/2021',
        Company: 'MYSTIC',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Shiho',
        'Full Name': 'Kwon Subin',
        'Korean Name': '권수빈',
        'K Stage Name': '시호',
        'Date of Birth': '22/08/2001',
        Group: 'BOTOPASS',
        Debut: '26/08/2020',
        Company: 'WKS ENE',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Shihyun',
        'Full Name': 'Kim Shihyun',
        'Korean Name': '김시현',
        'K Stage Name': '시현',
        'Date of Birth': '6/05/1998',
        Group: 'We In The Zone',
        Debut: '27/05/2019',
        Company: 'Choon',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Changwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Shin',
        'Full Name': 'Shin Wonho',
        'Korean Name': '신원호',
        'K Stage Name': '신',
        'Date of Birth': '23/10/1991',
        Group: 'CROSS GENE',
        Debut: '8/07/2012',
        Company: 'Amuse',
        Country: 'South Korea',
        'Second Country': '',
        Height: 185,
        Weight: 66,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Shin',
        'Full Name': 'Kim Suhyun',
        'Korean Name': '김수현',
        'K Stage Name': '신',
        'Date of Birth': '18/03/2000',
        Group: 'Ghost9',
        Debut: '23/09/2020',
        Company: 'Maroo',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Shin Hyesung',
        'Full Name': 'Jung Pilkyo',
        'Korean Name': '정필교',
        'K Stage Name': '신혜성',
        'Date of Birth': '27/11/1979',
        Group: 'Shinhwa',
        Debut: '24/03/1998',
        Company: 'SM| Good| Shinhwa',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 65,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Shindong',
        'Full Name': 'Shin Donghee',
        'Korean Name': '신동희',
        'K Stage Name': '신동',
        'Date of Birth': '28/09/1985',
        Group: 'Super Junior',
        Debut: '6/11/2005',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 90,
        Birthplace: 'Mungyeong',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Shinwon',
        'Full Name': 'Go Shinwon',
        'Korean Name': '고신원',
        'K Stage Name': '신원',
        'Date of Birth': '11/12/1995',
        Group: 'PENTAGON',
        Debut: '10/10/2016',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Shinye',
        'Full Name': 'Kam Yerim',
        'Korean Name': '감예림',
        'K Stage Name': '신예',
        'Date of Birth': '7/03/2004',
        Group: 'MAJORS',
        Debut: '9/03/2021',
        Company: 'ANS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 46,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Shinyoung',
        'Full Name': 'Park Shinyoung',
        'Korean Name': '박신영',
        'K Stage Name': '신영',
        'Date of Birth': '19/03/2000',
        Group: 'G-reyish',
        Debut: '1/06/2017',
        Company: 'Elijah',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Shita',
        'Full Name': 'Chae Gaho',
        'Korean Name': '채가호',
        'K Stage Name': '시타',
        'Date of Birth': '1/10/1998',
        Group: 'JWiiver',
        Debut: '17/02/2022',
        Company: 'JTG',
        Country: 'Macau',
        'Second Country': '',
        Height: 183,
        Weight: 63,
        Birthplace: 'Macau',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Shotaro',
        'Full Name': 'Osaki Shotaro',
        'Korean Name': '오사키 쇼타로',
        'K Stage Name': '쇼타로',
        'Date of Birth': '25/11/2000',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'NCT U',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Shownu',
        'Full Name': 'Son Hyunwoo',
        'Korean Name': '손현우',
        'K Stage Name': '셔누',
        'Date of Birth': '18/06/1992',
        Group: 'MONSTA X',
        Debut: '14/05/2014',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Shuhua',
        'Full Name': 'Yeh Shuhua',
        'Korean Name': '예슈화',
        'K Stage Name': '슈화',
        'Date of Birth': '6/01/2000',
        Group: '(G)I-DLE',
        Debut: '2/05/2018',
        Company: 'Cube',
        Country: 'Taiwan',
        'Second Country': '',
        Height: 161,
        Weight: 45,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sian',
        'Full Name': 'Choi Changmin',
        'Korean Name': '최창민',
        'K Stage Name': '시안',
        'Date of Birth': '1/02/2001',
        Group: 'T1419',
        Debut: '21/09/2007',
        Company: 'CJ E&M',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sieun',
        'Full Name': 'Lee Sieun',
        'Korean Name': '이시은',
        'K Stage Name': '시은',
        'Date of Birth': '29/05/2001',
        Group: 'ARTBEAT',
        Debut: '16/11/2022',
        Company: 'AB Creative',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 50,
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sieun',
        'Full Name': 'Lee Sieun',
        'Korean Name': '이시은',
        'K Stage Name': '시은',
        'Date of Birth': '19/09/1993',
        Group: 'CHECKMATE',
        Debut: '19/06/2012',
        Company: 'Yedang',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sieun',
        'Full Name': 'Park Sieun',
        'Korean Name': '박시은',
        'K Stage Name': '시은',
        'Date of Birth': '1/08/2001',
        Group: 'STAYC',
        Debut: '12/11/2020',
        Company: 'Hi-Up',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 44,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sihyeon',
        'Full Name': 'Kang Sihyeon',
        'Korean Name': '강시현',
        'K Stage Name': '시현',
        'Date of Birth': '15/07/1998',
        Group: 'ARIAZ',
        Debut: '23/10/2019',
        Company: 'Rising Star',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sihyeon',
        'Full Name': 'Hwang Sihyeon',
        'Korean Name': '황시현',
        'K Stage Name': '시현',
        'Date of Birth': '12/03/2005',
        Group: 'CSR',
        Debut: '27/07/2022',
        Company: 'A2Z',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Uijeongbu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sihyeon',
        'Full Name': 'Kim Sihyeon',
        'Korean Name': '김시현',
        'K Stage Name': '시현',
        'Date of Birth': '5/08/1999',
        Group: 'Everglow',
        Debut: '18/03/2019',
        Company: 'Yuehua',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: 51,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sihyoung',
        'Full Name': 'Kim Sihyoung',
        'Korean Name': '김시형',
        'K Stage Name': '시형',
        'Date of Birth': '15/05/1992',
        Group: 'HISTORY',
        Debut: '26/04/2013',
        Company: 'LOEN',
        Country: 'South Korea',
        'Second Country': '',
        Height: 182,
        Weight: 63,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sihyun',
        'Full Name': 'Park Sihyun',
        'Korean Name': '박시현',
        'K Stage Name': '시현',
        'Date of Birth': '29/11/1986',
        Group: 'SPICA',
        Debut: '7/02/2012',
        Company: 'CJ E&M',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'SPICA-S',
        'Former Group': 'SPICA',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sika',
        'Full Name': 'Ren Shika',
        'Korean Name': '렌 시카',
        'K Stage Name': '시카',
        'Date of Birth': '22/08/1995',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'China',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'FANATICS',
        Gender: 'F'
    },
    {
        'Stage Name': 'Simyeong',
        'Full Name': 'Lee Simyeong',
        'Korean Name': '이시명',
        'K Stage Name': '시명',
        'Date of Birth': '27/05/1999',
        Group: 'BVNDIT',
        Debut: '10/04/2019',
        Company: 'MNH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'SinB',
        'Full Name': 'Hwang Eunbi',
        'Korean Name': '황은비',
        'K Stage Name': '신비',
        'Date of Birth': '3/06/1998',
        Group: 'VIVIZ',
        Debut: '9/02/2022',
        Company: 'Big Planet Made',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Cheongju',
        'Other Group': '',
        'Former Group': 'Gfriend',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sio',
        'Full Name': 'Jeong Jiho',
        'Korean Name': '정지호',
        'K Stage Name': '시오',
        'Date of Birth': '6/10/2004',
        Group: 'FIFTY FIFTY',
        Debut: '18/11/2022',
        Company: 'ATTRAKT',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 45,
        Birthplace: 'Iksan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sion',
        'Full Name': 'Kim Sion',
        'Korean Name': '김시온',
        'K Stage Name': '시온',
        'Date of Birth': '19/10/2004',
        Group: 'YOUNITE',
        Debut: '20/04/2022',
        Company: 'Brand New',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sitala',
        'Full Name': 'Sitala Wongkrachang',
        'Korean Name': '시탈라 웡크라창',
        'K Stage Name': '시탈라',
        'Date of Birth': '23/04/1996',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'Thailand',
        'Second Country': '',
        Height: 173,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'H1-KEY',
        Gender: 'F'
    },
    {
        'Stage Name': 'Siu',
        'Full Name': 'Hwang Inseok',
        'Korean Name': '황인석',
        'K Stage Name': '시우',
        'Date of Birth': '19/05/1995',
        Group: 'DUSTIN',
        Debut: '6/01/2020',
        Company: 'LPA',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Siwan',
        'Full Name': 'Yim Siwan',
        'Korean Name': '임시완',
        'K Stage Name': '시완',
        'Date of Birth': '1/12/1988',
        Group: 'ZE:A',
        Debut: '7/01/2010',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Siwon',
        'Full Name': 'Choi Siwon',
        'Korean Name': '최시원',
        'K Stage Name': '시원',
        'Date of Birth': '7/04/1986',
        Group: 'Super Junior',
        Debut: '6/11/2005',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 185,
        Weight: 65,
        Birthplace: 'Seoul',
        'Other Group': 'Super Junior-M',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Siwoo',
        'Full Name': 'Park Siwoo',
        'Korean Name': '박시우',
        'K Stage Name': '시우',
        'Date of Birth': '5/02/2001',
        Group: 'BLANK2Y',
        Debut: '24/05/2022',
        Company: 'Keystone',
        Country: 'South Korea',
        'Second Country': '',
        Height: 174,
        Weight: 54,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Siwoo',
        'Full Name': 'Yoo Siwoo',
        'Korean Name': '유시우',
        'K Stage Name': '시우',
        'Date of Birth': '11/05/2001',
        Group: 'TRCNG',
        Debut: '10/10/2017',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Siyeon',
        'Full Name': 'Lee Siyeon',
        'Korean Name': '이시연',
        'K Stage Name': '시연',
        'Date of Birth': '1/10/1995',
        Group: 'Dreamcatcher',
        Debut: '13/01/2017',
        Company: 'Happy Face',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': 'Minx',
        Gender: 'F'
    },
    {
        'Stage Name': 'Siyoon',
        'Full Name': 'Kim Siyoon',
        'Korean Name': '김시윤',
        'K Stage Name': '시윤',
        'Date of Birth': '16/02/2005',
        Group: 'Billlie',
        Debut: '10/11/2021',
        Company: 'MYSTIC',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Siyoung',
        'Full Name': 'Park Siyoung',
        'Korean Name': '박시영',
        'K Stage Name': '시영',
        'Date of Birth': '6/05/2003',
        Group: 'Mirae',
        Debut: '17/03/2021',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: 55,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sodam',
        'Full Name': 'Park Sodam',
        'Korean Name': '박소담',
        'K Stage Name': '박소담',
        'Date of Birth': '26/11/2004',
        Group: 'BLANK2Y',
        Debut: '24/05/2022',
        Company: 'Keystone',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Soeun',
        'Full Name': 'Bang Soeun',
        'Korean Name': '방소은',
        'K Stage Name': '소은',
        'Date of Birth': '10/12/2005',
        Group: 'TRI.BE',
        Debut: '17/02/2021',
        Company: 'TR - Mellow',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Soeun',
        'Full Name': 'Park Soeun',
        'Korean Name': '박소은',
        'K Stage Name': '소은',
        'Date of Birth': '26/10/2002',
        Group: 'Weeekly',
        Debut: '30/07/2020',
        Company: 'Play M',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sohee',
        'Full Name': 'Uhm Sohee',
        'Korean Name': '음소희',
        'K Stage Name': '소희',
        'Date of Birth': '1/01/2004',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': "ICHILLIN'",
        Gender: 'F'
    },
    {
        'Stage Name': 'Sohee',
        'Full Name': 'Kim Sohee',
        'Korean Name': '김소희',
        'K Stage Name': '소희',
        'Date of Birth': '31/12/1999',
        Group: 'ALICE',
        Debut: '1/07/2017',
        Company: 'IOK Company',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sohee',
        'Full Name': 'Kim Sohee',
        'Korean Name': '소희',
        'K Stage Name': '김소희',
        'Date of Birth': '20/01/1995',
        Group: 'Nature',
        Debut: '3/08/2018',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: 41,
        Birthplace: 'Busan',
        'Other Group': 'C.I.V.A',
        'Former Group': 'I.B.I',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sohee',
        'Full Name': 'Kim Sohee',
        'Korean Name': '김소희',
        'K Stage Name': '소희',
        'Date of Birth': '14/08/2003',
        Group: 'Rocket Punch',
        Debut: '7/08/2019',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: 46,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sohye',
        'Full Name': 'Kim Sohye',
        'Korean Name': '김소혜',
        'K Stage Name': '소혜',
        'Date of Birth': '19/07/1999',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'I.O.I',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sohyun',
        'Full Name': 'Kwon Sohyun',
        'Korean Name': '권소현',
        'K Stage Name': '소현',
        'Date of Birth': '30/08/1994',
        Group: '4minute',
        Debut: '15/06/2009',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sojin',
        'Full Name': 'Jo Sojin',
        'Korean Name': '조소진',
        'K Stage Name': '소진',
        'Date of Birth': '11/10/1991',
        Group: '9Muses',
        Debut: '12/08/2010',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 47,
        Birthplace: 'Gwangju',
        'Other Group': '9MUSES A',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sojin',
        'Full Name': 'Park Sojin',
        'Korean Name': '박소진',
        'K Stage Name': '소진',
        'Date of Birth': '21/05/1986',
        Group: "Girl's Day",
        Debut: '9/07/2010',
        Company: 'DreamT',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 47,
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sojin',
        'Full Name': 'Hong Sojin',
        'Korean Name': '홍소진',
        'K Stage Name': '소진',
        'Date of Birth': '24/09/1999',
        Group: 'Hash Tag',
        Debut: '11/10/2017',
        Company: 'LUK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sojung',
        'Full Name': 'Lee Sojung',
        'Korean Name': '이소정',
        'K Stage Name': '소정',
        'Date of Birth': '3/09/1993',
        Group: "Ladies' Code",
        Debut: '7/03/2013',
        Company: 'Polaris',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Wonju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Solar',
        'Full Name': 'Kim Yongsun',
        'Korean Name': '김용선',
        'K Stage Name': '솔라',
        'Date of Birth': '21/02/1991',
        Group: 'Mamamoo',
        Debut: '18/06/2014',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: 43,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Solbin',
        'Full Name': 'Ahn Solbin',
        'Korean Name': '안솔빈',
        'K Stage Name': '솔빈',
        'Date of Birth': '19/08/1997',
        Group: 'LABOUM',
        Debut: '27/08/2014',
        Company: 'NH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 169,
        Weight: 52,
        Birthplace: 'Ojeonggu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Solji',
        'Full Name': 'Heo Solji',
        'Korean Name': '허솔지',
        'K Stage Name': '솔지',
        'Date of Birth': '10/01/1989',
        Group: 'EXID',
        Debut: '16/02/2012',
        Company: 'Yedang',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: '',
        Birthplace: 'Seongnam',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Somi',
        'Full Name': 'Jeon Somi',
        'Korean Name': '전소미',
        'K Stage Name': '소미',
        'Date of Birth': '9/03/2001',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': 'Canada',
        Height: '',
        Weight: '',
        Birthplace: 'Ontario',
        'Other Group': '',
        'Former Group': 'I.O.I',
        Gender: 'F'
    },
    {
        'Stage Name': 'Somin',
        'Full Name': 'Jang Somin',
        'Korean Name': '장소민',
        'K Stage Name': '소민',
        'Date of Birth': '10/11/1993',
        Group: 'BADKIZ',
        Debut: '24/03/2014',
        Company: 'Zoo',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwangmyeon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Somin',
        'Full Name': 'Jeon Somin',
        'Korean Name': '전소민',
        'K Stage Name': '소민',
        'Date of Birth': '22/08/1996',
        Group: 'KARD',
        Debut: '29/03/2007',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'April',
        Gender: 'F'
    },
    {
        'Stage Name': 'Somyi',
        'Full Name': 'Ahn Somyi',
        'Korean Name': '안솜이',
        'K Stage Name': '솜이',
        'Date of Birth': '26/01/2000',
        Group: 'DIA',
        Debut: '14/07/2015',
        Company: 'MBK',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Changwon',
        'Other Group': 'L.U.B',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Song',
        'Full Name': 'Song Yunhyeong',
        'Korean Name': '송윤형',
        'K Stage Name': '송',
        'Date of Birth': '8/02/1995',
        Group: 'iKON',
        Debut: '15/09/2015',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Songhee',
        'Full Name': 'Yoon Songhee',
        'Korean Name': '윤송희',
        'K Stage Name': '송희',
        'Date of Birth': '8/11/1998',
        Group: 'BVNDIT',
        Debut: '10/04/2019',
        Company: 'MNH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'SongSun',
        'Full Name': 'Kim Songsun',
        'Korean Name': '김송선',
        'K Stage Name': '송선',
        'Date of Birth': '22/03/1997',
        Group: 'TRI.BE',
        Debut: '17/02/2021',
        Company: 'TR - Mellow',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Songyee',
        'Full Name': 'Park Songyee',
        'Korean Name': '박송이',
        'K Stage Name': '송이',
        'Date of Birth': '25/09/2004',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'woo!ah!',
        Gender: 'F'
    },
    {
        'Stage Name': 'Soobin',
        'Full Name': 'Choi Soobin',
        'Korean Name': '최수빈',
        'K Stage Name': '수빈',
        'Date of Birth': '5/12/2000',
        Group: 'TXT',
        Debut: '4/03/2019',
        Company: 'Big Hit',
        Country: 'South Korea',
        'Second Country': '',
        Height: 184,
        Weight: 65,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Soobin',
        'Full Name': 'Park Soobin',
        'Korean Name': '박수빈',
        'K Stage Name': '수빈',
        'Date of Birth': '14/09/1996',
        Group: 'WJSN',
        Debut: '25/02/2016',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 159,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': 'WJSN Chocome',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Soodam',
        'Full Name': 'Lee Soodam',
        'Korean Name': '이수담',
        'K Stage Name': '수담',
        'Date of Birth': '9/11/1999',
        Group: 'SECRET NUMBER',
        Debut: '19/05/2020',
        Company: 'Vine',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Soohyun',
        'Full Name': 'Shin Soohyun',
        'Korean Name': '신수현',
        'K Stage Name': '수현',
        'Date of Birth': '11/03/1989',
        Group: 'U-KISS',
        Debut: '28/08/2008',
        Company: 'NH Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Pocheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Soojin',
        'Full Name': 'Seo Soojin',
        'Korean Name': '서수진',
        'K Stage Name': '수진',
        'Date of Birth': '9/03/1998',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '(G)I-DLE',
        Gender: 'F'
    },
    {
        'Stage Name': 'Soojin',
        'Full Name': 'Lee Soojin',
        'Korean Name': '이수진',
        'K Stage Name': '수진',
        'Date of Birth': '12/12/2001',
        Group: 'Weeekly',
        Debut: '30/07/2020',
        Company: 'Play M',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Soojung',
        'Full Name': 'Hwang Soojung',
        'Korean Name': '황수정',
        'K Stage Name': '수정',
        'Date of Birth': '10/05/2001',
        Group: 'Hi-L',
        Debut: '11/08/2021',
        Company: 'Kpop Live',
        Country: 'South Korea',
        'Second Country': '',
        Height: 169,
        Weight: 47,
        Birthplace: 'Yongin',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Soomin',
        'Full Name': 'Kim Soomin',
        'Korean Name': '김수민',
        'K Stage Name': '수민',
        'Date of Birth': '3/10/2007',
        Group: 'tripleS',
        Debut: '13/02/2023',
        Company: 'MODHAUS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 158,
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sooyoung',
        'Full Name': 'Choi Sooyoung',
        'Korean Name': '최수영',
        'K Stage Name': '수영',
        'Date of Birth': '10/02/1990',
        Group: 'SNSD',
        Debut: '4/08/2021',
        Company: 'Good Luck',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 48,
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sora',
        'Full Name': 'Sakata Sora',
        'Korean Name': '사카타 소라',
        'K Stage Name': '소라',
        'Date of Birth': '30/08/2003',
        Group: 'woo!ah!',
        Debut: '15/05/2020',
        Company: 'NV',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sorn',
        'Full Name': 'Chonnasorn Sajakul',
        'Korean Name': '촌나손 사짜꾼',
        'K Stage Name': '손',
        'Date of Birth': '18/11/1996',
        Group: 'CLC',
        Debut: '19/03/2015',
        Company: 'Cube',
        Country: 'Thailand',
        'Second Country': '',
        Height: 164,
        Weight: '',
        Birthplace: 'Bangkok',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Soso',
        'Full Name': 'Wang Chingyi',
        'Korean Name': '왕징이',
        'K Stage Name': '소소',
        'Date of Birth': '14/03/2001',
        Group: 'GWSN',
        Debut: '5/09/2018',
        Company: 'Kiwi Pop',
        Country: 'Taiwan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Soul',
        'Full Name': 'Haku Shota',
        'Korean Name': '하쿠 쇼타',
        'K Stage Name': '소울',
        'Date of Birth': '1/02/2005',
        Group: 'P1Harmony',
        Debut: '28/10/2020',
        Company: 'FNC',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sowon',
        'Full Name': 'Kim Sojung',
        'Korean Name': '김소정',
        'K Stage Name': '소원',
        'Date of Birth': '7/12/1995',
        Group: 'Gfriend',
        Debut: '15/01/2015',
        Company: 'Source',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sowon',
        'Full Name': 'Nam Soeun',
        'Korean Name': '남소은',
        'K Stage Name': '소원',
        'Date of Birth': '13/12/2004',
        Group: 'TRACER',
        Debut: '3/04/2022',
        Company: 'Gleamedia',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Soyee',
        'Full Name': 'Jang Sojin',
        'Korean Name': '장소진',
        'K Stage Name': '소이',
        'Date of Birth': '21/11/1996',
        Group: 'Gugudan',
        Debut: '28/06/2016',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Soyeon',
        'Full Name': 'Park Soyeon',
        'Korean Name': '박소연',
        'K Stage Name': '소연',
        'Date of Birth': '5/10/1987',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: 46,
        Birthplace: 'Andong',
        'Other Group': '',
        'Former Group': 'T-ara',
        Gender: 'F'
    },
    {
        'Stage Name': 'Soyeon',
        'Full Name': 'Jeon Soyeon',
        'Korean Name': '전소연',
        'K Stage Name': '소연',
        'Date of Birth': '26/08/1998',
        Group: '(G)I-DLE',
        Debut: '2/05/2018',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 157,
        Weight: 42,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Soyeon',
        'Full Name': 'Jung Soyeon',
        'Korean Name': '정소연',
        'K Stage Name': '소연',
        'Date of Birth': '4/05/1994',
        Group: 'LABOUM',
        Debut: '27/08/2014',
        Company: 'NH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: 44,
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Soyou',
        'Full Name': 'Kang Jihyun',
        'Korean Name': '강지현',
        'K Stage Name': '소유',
        'Date of Birth': '12/02/1992',
        Group: 'SISTAR',
        Debut: '6/03/2010',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Soyoung',
        'Full Name': 'Yoo Soyoung',
        'Korean Name': '유소영',
        'K Stage Name': '소영',
        'Date of Birth': '29/03/1996',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'After School',
        Gender: 'F'
    },
    {
        'Stage Name': 'Soyul',
        'Full Name': 'Park Hyekyeong',
        'Korean Name': '박혜경',
        'K Stage Name': '소율',
        'Date of Birth': '15/05/1991',
        Group: 'Crayon Pop',
        Debut: '18/07/2012',
        Company: 'Chrome',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'St. Van',
        'Full Name': 'Lee Geumhyuk',
        'Korean Name': '이금혁',
        'K Stage Name': '세인트 반',
        'Date of Birth': '22/12/1991',
        Group: 'VAV',
        Debut: '31/10/2015',
        Company: 'A team',
        Country: 'South Korea',
        'Second Country': '',
        Height: 182,
        Weight: 70,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Steve',
        'Full Name': 'Lim Dohyun',
        'Korean Name': '임도현',
        'K Stage Name': '스티브',
        'Date of Birth': '9/03/2002',
        Group: 'YOUNITE',
        Debut: '20/04/2022',
        Company: 'Brand New',
        Country: 'South Korea',
        'Second Country': 'USA',
        Height: '',
        Weight: '',
        Birthplace: 'Los Angeles',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Steven',
        'Full Name': 'Kim Steven',
        'Korean Name': '킴 스티븐',
        'K Stage Name': '스티븐',
        'Date of Birth': '17/01/2000',
        Group: 'Luminous',
        Debut: '9/09/2021',
        Company: 'Barunson',
        Country: 'South Korea',
        'Second Country': 'Australia',
        Height: 176,
        Weight: 57,
        Birthplace: 'Sydney',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sua',
        'Full Name': 'Yoo Sua',
        'Korean Name': '유수아',
        'K Stage Name': '수아',
        'Date of Birth': '23/07/2005',
        Group: 'CSR',
        Debut: '27/07/2022',
        Company: 'A2Z',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Anyang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sua',
        'Full Name': 'Kim Bora',
        'Korean Name': '김보라',
        'K Stage Name': '수아',
        'Date of Birth': '10/08/1994',
        Group: 'Dreamcatcher',
        Debut: '13/01/2017',
        Company: 'Happy Face',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: '',
        Birthplace: 'Changwon',
        'Other Group': '',
        'Former Group': 'Minx',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sua',
        'Full Name': 'Yang Sua',
        'Korean Name': '양수아',
        'K Stage Name': '수아',
        'Date of Birth': '24/03/1995',
        Group: 'Hash Tag',
        Debut: '11/10/2017',
        Company: 'LUK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 169,
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sua',
        'Full Name': 'Choi Sooah',
        'Korean Name': '최수아',
        'K Stage Name': '수아',
        'Date of Birth': '24/02/2003',
        Group: 'Pixy',
        Debut: '24/02/2021',
        Company: 'ALLART',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: 44,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Subin',
        'Full Name': 'Park Subin',
        'Korean Name': '박수빈',
        'K Stage Name': '수빈',
        'Date of Birth': '12/02/1994',
        Group: 'Dal Shabet',
        Debut: '3/01/2011',
        Company: 'Happy Face',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Subin',
        'Full Name': 'Oh Subin',
        'Korean Name': '오수빈',
        'K Stage Name': '수빈',
        'Date of Birth': '30/09/1998',
        Group: 'Hash Tag',
        Debut: '11/10/2017',
        Company: 'LUK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Subin',
        'Full Name': 'Jung Subin',
        'Korean Name': '정수빈',
        'K Stage Name': '수빈',
        'Date of Birth': '5/04/1999',
        Group: 'VICTON',
        Debut: '9/11/2016',
        Company: 'Plan A',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Suga',
        'Full Name': 'Min Yoongi',
        'Korean Name': '민윤기',
        'K Stage Name': '슈가',
        'Date of Birth': '9/03/1993',
        Group: 'BTS',
        Debut: '13/06/2013',
        Company: 'Big Hit',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 59,
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sugyeong',
        'Full Name': 'Jo Sugyeong',
        'Korean Name': '조수경',
        'K Stage Name': '수경',
        'Date of Birth': '12/01/2000',
        Group: 'Favorite',
        Debut: '5/07/2017',
        Company: 'Astory',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Haenam',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Suho',
        'Full Name': 'Kim Junmyeon',
        'Korean Name': '김준면',
        'K Stage Name': '수호',
        'Date of Birth': '22/05/1991',
        Group: 'EXO',
        Debut: '8/04/2012',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: 65,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Suhye',
        'Full Name': 'Kim Suhye',
        'Korean Name': '김수혜',
        'K Stage Name': '수혜',
        'Date of Birth': '13/12/2004',
        Group: 'LIMELIGHT',
        Debut: '17/02/2023',
        Company: 143,
        Country: 'South Korea',
        'Second Country': '',
        Height: 158,
        Weight: 40,
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Suhyeon',
        'Full Name': 'Kim Suhyun',
        'Korean Name': '김수현',
        'K Stage Name': '수현',
        'Date of Birth': '15/01/2000',
        Group: 'Billlie',
        Debut: '10/11/2021',
        Company: 'MYSTIC',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: 45,
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Suhyeon',
        'Full Name': 'Kim Seunghyun',
        'Korean Name': '김승현',
        'K Stage Name': '수현',
        'Date of Birth': '19/04/1988',
        Group: 'S#aFLA',
        Debut: '14/07/2021',
        Company: 'Winnerzone',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '1PS',
        Gender: 'F'
    },
    {
        'Stage Name': 'Suhyun',
        'Full Name': 'Choi Hyunggeun',
        'Korean Name': '최형근',
        'K Stage Name': '수현',
        'Date of Birth': '1/10/1992',
        Group: 'SNUPER',
        Debut: '16/11/2015',
        Company: 'Widmay',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Bucheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Suil',
        'Full Name': 'Ma Suil',
        'Korean Name': '마수일',
        'K Stage Name': '수일',
        'Date of Birth': '30/03/1999',
        Group: 'Luminous',
        Debut: '9/09/2021',
        Company: 'Barunson',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 50,
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sujeong',
        'Full Name': 'Ryu Sujeong',
        'Korean Name': '류수정',
        'K Stage Name': '수정',
        'Date of Birth': '19/11/1997',
        Group: 'Lovelyz',
        Debut: '17/11/2014',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: 48,
        Birthplace: 'Daejeon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Suji',
        'Full Name': 'Lee Suji',
        'Korean Name': '이수지',
        'K Stage Name': '수지',
        'Date of Birth': '20/03/1998',
        Group: 'UNI.T',
        Debut: '18/05/2018',
        Company: 'Unit Culture',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': 'The Ark',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sujin',
        'Full Name': 'Bang Sujin',
        'Korean Name': '방수진',
        'K Stage Name': '수진',
        'Date of Birth': '26/06/1996',
        Group: 'Wassup',
        Debut: '7/08/2013',
        Company: 'Mafia',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sukjun',
        'Full Name': 'Lee Sukjun',
        'Korean Name': '이석준',
        'K Stage Name': '석준',
        'Date of Birth': '31/01/2000',
        Group: 'ABLUE',
        Debut: '23/10/2022',
        Company: 'J-Star',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: 65,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'ANTARES',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sulli†',
        'Full Name': 'Choi Jinri',
        'Korean Name': '최진리',
        'K Stage Name': '설리',
        'Date of Birth': '29/03/1994',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': 'f(x)',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sullyoon',
        'Full Name': 'Seol Yoona',
        'Korean Name': '설윤아',
        'K Stage Name': '설윤',
        'Date of Birth': '26/01/2004',
        Group: 'NMIXX',
        Debut: '22/02/2022',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sumin',
        'Full Name': 'Park Sumin',
        'Korean Name': '박수민',
        'K Stage Name': '수민',
        'Date of Birth': '9/09/2001',
        Group: 'Dreamnote',
        Debut: '7/11/2018',
        Company: 'iME',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 46,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sumin',
        'Full Name': 'Ji Sumin',
        'Korean Name': '지수민',
        'K Stage Name': '수민',
        'Date of Birth': '3/03/1994',
        Group: 'Sonamoo',
        Debut: '29/12/2014',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: 'Yongin',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sumin',
        'Full Name': 'Bae Sumin',
        'Korean Name': '배수민',
        'K Stage Name': '수민',
        'Date of Birth': '13/03/2001',
        Group: 'STAYC',
        Debut: '12/11/2020',
        Company: 'Hi-Up',
        Country: 'South Korea',
        'Second Country': '',
        Height: 159,
        Weight: 46,
        Birthplace: 'Pohang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sumin',
        'Full Name': 'Choi Sumin',
        'Korean Name': '최수민',
        'K Stage Name': '수민',
        'Date of Birth': '7/04/2004',
        Group: 'xikers',
        Debut: '30/03/2023',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sun J',
        'Full Name': 'Jeon Heesun',
        'Korean Name': '전희선',
        'K Stage Name': '썬제이',
        'Date of Birth': '13/02/2001',
        Group: 'Girlkind',
        Debut: '16/01/2018',
        Company: 'Nextlevel',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: 165,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sungah',
        'Full Name': 'Son Sungah',
        'Korean Name': '손성아',
        'K Stage Name': '성아',
        'Date of Birth': '8/07/1989',
        Group: '9Muses',
        Debut: '12/08/2010',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': 'Fiji',
        Height: 172,
        Weight: 48,
        Birthplace: 'Suma',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sungchan',
        'Full Name': 'Jung Sungchan',
        'Korean Name': '정성찬',
        'K Stage Name': '성찬',
        'Date of Birth': '13/09/2001',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'NCT U',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sunghoon',
        'Full Name': 'Park Sunghoon',
        'Korean Name': '박성훈',
        'K Stage Name': '성훈',
        'Date of Birth': '8/12/2002',
        Group: 'ENHYPEN',
        Debut: '30/11/2020',
        Company: 'Be:lift',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sunghyuk',
        'Full Name': 'Seo Sunghyuk',
        'Korean Name': '서성혁',
        'K Stage Name': '성혁',
        'Date of Birth': '26/08/1999',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sunghyun',
        'Full Name': 'Kim Sunghyun',
        'Korean Name': '김성현',
        'K Stage Name': '성현',
        'Date of Birth': '16/03/1996',
        Group: 'IN2IT',
        Debut: '27/01/2016',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: 186,
        Weight: 71,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sungjae',
        'Full Name': 'Yook Sungjae',
        'Korean Name': '육성재',
        'K Stage Name': '성재',
        'Date of Birth': '2/05/1995',
        Group: 'BtoB',
        Debut: '21/03/2012',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 68,
        Birthplace: 'Yongin',
        'Other Group': 'Big Byung',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sungjin',
        'Full Name': 'Park Sungjin',
        'Korean Name': '박성진',
        'K Stage Name': '성진',
        'Date of Birth': '16/01/1993',
        Group: 'DAY6',
        Debut: '7/09/2015',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sungjong',
        'Full Name': 'Lee Sungjong',
        'Korean Name': '이성종',
        'K Stage Name': '성종',
        'Date of Birth': '3/09/1993',
        Group: 'Infinite',
        Debut: '9/06/2010',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 54,
        Birthplace: 'Gwangju',
        'Other Group': 'Infinite F',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sungjoo',
        'Full Name': 'Kim Sungjoo',
        'Korean Name': '김성주',
        'K Stage Name': '성주',
        'Date of Birth': '16/02/1994',
        Group: 'UNIQ',
        Debut: '20/10/2014',
        Company: 'Yuehua',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 65,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sungjun',
        'Full Name': 'Lee Sungjun',
        'Korean Name': '이성준',
        'K Stage Name': '성준',
        'Date of Birth': '15/06/2002',
        Group: 'BLANK2Y',
        Debut: '24/05/2022',
        Company: 'Keystone',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sungjun',
        'Full Name': 'Park Sungjun',
        'Korean Name': '박성준',
        'K Stage Name': '성준',
        'Date of Birth': '17/12/1992',
        Group: 'Boys Republic',
        Debut: '3/06/2013',
        Company: 'Happy Tribe| Universal',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 55,
        Birthplace: 'Ansan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sungjun',
        'Full Name': 'Oh Sungjun',
        'Korean Name': '오성준',
        'K Stage Name': '성준',
        'Date of Birth': '30/08/2005',
        Group: 'TNX',
        Debut: '17/05/2022',
        Company: 'P NATION',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Ulsan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sungkyu',
        'Full Name': 'Kim Sungkyu',
        'Korean Name': '김성규',
        'K Stage Name': '성규',
        'Date of Birth': '28/04/1989',
        Group: 'Infinite',
        Debut: '9/06/2010',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 62,
        Birthplace: 'Jeonju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sungmin',
        'Full Name': 'Oh Sungmin',
        'Korean Name': '오성민',
        'K Stage Name': '성민',
        'Date of Birth': '31/12/1990',
        Group: 'Bigflo',
        Debut: '19/07/2014',
        Company: 'HO',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sungmin',
        'Full Name': 'Lee Sungmin',
        'Korean Name': '이성민',
        'K Stage Name': '성민',
        'Date of Birth': '1/01/1986',
        Group: 'Super Junior',
        Debut: '6/11/2005',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 57,
        Birthplace: 'Gyeonggi',
        'Other Group': 'Super Junior-M',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sungoh',
        'Full Name': 'Yoo Sungoh',
        'Korean Name': '유성오',
        'K Stage Name': '성오',
        'Date of Birth': '8/01/1991',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '24K',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sungwoon',
        'Full Name': 'Ha Sungwoon',
        'Korean Name': '하성운',
        'K Stage Name': '성운',
        'Date of Birth': '22/03/1994',
        Group: 'Wanna One',
        Debut: '7/08/2017',
        Company: 'YMC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Goyang',
        'Other Group': 'HOTSHOT',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sungyeol',
        'Full Name': 'Lee Sungyeol',
        'Korean Name': '이성열',
        'K Stage Name': '성열',
        'Date of Birth': '27/08/1991',
        Group: 'Infinite',
        Debut: '9/06/2010',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: 59,
        Birthplace: 'Yongin',
        'Other Group': 'Infinite F',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sungyeon',
        'Full Name': 'Bae Sungyeon',
        'Korean Name': '배성연',
        'K Stage Name': '성연',
        'Date of Birth': '25/05/1999',
        Group: 'Pristin',
        Debut: '21/03/2017',
        Company: 'Pledis',
        Country: 'USA',
        'Second Country': 'South Korea',
        Height: '',
        Weight: '',
        Birthplace: 'California',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sunhwa',
        'Full Name': 'Han Sunhwa',
        'Korean Name': '한선화',
        'K Stage Name': '선화',
        'Date of Birth': '6/10/1990',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': 'SECRET',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sunmi',
        'Full Name': 'Lee Sunmi',
        'Korean Name': '이선미',
        'K Stage Name': '선미',
        'Date of Birth': '2/05/1992',
        Group: 'Wonder Girls',
        Debut: '13/02/2007',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: '',
        Birthplace: 'Iksan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sunn',
        'Full Name': 'Hwang Jiwon',
        'Korean Name': '황지원',
        'K Stage Name': '선',
        'Date of Birth': '7/02/2000',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Jincheon',
        'Other Group': '',
        'Former Group': 'Good Day| Cignature',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sunny',
        'Full Name': 'Lee Sunkyu',
        'Korean Name': '이순규',
        'K Stage Name': '써니',
        'Date of Birth': '15/05/1989',
        Group: 'SNSD',
        Debut: '4/08/2021',
        Company: 'Good Luck',
        Country: 'South Korea',
        'Second Country': '',
        Height: 158,
        Weight: 43,
        Birthplace: 'California',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sunoo',
        'Full Name': 'Kim Sunwoo',
        'Korean Name': '김선우',
        'K Stage Name': '선우',
        'Date of Birth': '24/06/2003',
        Group: 'ENHYPEN',
        Debut: '30/11/2020',
        Company: 'Be:lift',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sunshine',
        'Full Name': 'Kim Minjung',
        'Korean Name': '김민정',
        'K Stage Name': '선샤인',
        'Date of Birth': '20/06/2002',
        Group: 'NATURE',
        Debut: '3/08/2018',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sunwoo',
        'Full Name': 'Choi Sunwoo',
        'Korean Name': '최선우',
        'K Stage Name': '선우',
        'Date of Birth': '12/03/1992',
        Group: 'Boys Republic',
        Debut: '3/06/2013',
        Company: 'Happy Tribe| Universal',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: 65,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sunwoo',
        'Full Name': 'Kim Sunwoo',
        'Korean Name': '김선우',
        'K Stage Name': '선우',
        'Date of Birth': '12/04/2000',
        Group: 'The Boyz',
        Debut: '31/12/2017',
        Company: 'Cre.Ker',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 64,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Sunyoul',
        'Full Name': 'Seon Yein',
        'Korean Name': '선예인',
        'K Stage Name': '선율',
        'Date of Birth': '6/11/1996',
        Group: 'UP10TION',
        Debut: '10/09/2015',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Wando',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Suri',
        'Full Name': 'Kang Suri',
        'Korean Name': '강수리',
        'K Stage Name': '수리',
        'Date of Birth': '11/10/1994',
        Group: 'CHECKMATE',
        Debut: '19/06/2012',
        Company: 'Yedang',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Surin',
        'Full Name': 'Kim Surin',
        'Korean Name': '김수린',
        'K Stage Name': '수린',
        'Date of Birth': '27/12/1991',
        Group: 'Two X',
        Debut: '20/08/2012',
        Company: 'J. Tune',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Suwoong',
        'Full Name': 'Lee Suwoong',
        'Korean Name': '이수웅',
        'K Stage Name': '수웅',
        'Date of Birth': '20/01/1995',
        Group: 'Boys Republic',
        Debut: '3/06/2013',
        Company: 'Happy Tribe| Universal',
        Country: 'South Korea',
        'Second Country': '',
        Height: 174,
        Weight: 56,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Suyeon',
        'Full Name': 'Ji Suyeon',
        'Korean Name': '지수연',
        'K Stage Name': '수연',
        'Date of Birth': '20/04/1997',
        Group: 'Weki Meki',
        Debut: '8/08/2017',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Suyun',
        'Full Name': 'Kim Suyun',
        'Korean Name': '김수윤',
        'K Stage Name': '수윤',
        'Date of Birth': '17/03/2001',
        Group: 'Rocket Punch',
        Debut: '7/08/2019',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 169,
        Weight: 52,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Suzy',
        'Full Name': 'Kim Suji',
        'Korean Name': '김수지',
        'K Stage Name': '수지',
        'Date of Birth': '3/06/2001',
        Group: 'MAJORS',
        Debut: '9/03/2021',
        Company: 'ANS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 45,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Suzy',
        'Full Name': 'Bae Suji',
        'Korean Name': '배수지',
        'K Stage Name': '수지',
        'Date of Birth': '10/10/1994',
        Group: 'miss A',
        Debut: '1/07/2010',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Swan',
        'Full Name': 'Kim Suan',
        'Korean Name': '김수안',
        'K Stage Name': '수안',
        'Date of Birth': '28/12/2000',
        Group: 'CRAXY',
        Debut: '3/03/2020',
        Company: 'SAITEINMENT',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Swan',
        'Full Name': 'Park Soojin',
        'Korean Name': '박수진',
        'K Stage Name': '수안',
        'Date of Birth': '11/07/2003',
        Group: 'Purple Kiss',
        Debut: '15/03/2021',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Sya',
        'Full Name': 'Lee Junyoung',
        'Korean Name': '이준영',
        'K Stage Name': '샤',
        'Date of Birth': '9/09/2002',
        Group: 'BLITZERS',
        Debut: '12/05/2021',
        Company: 'Wuzo',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'T.O.P',
        'Full Name': 'Choi Seunghyun',
        'Korean Name': '최승현',
        'K Stage Name': '탑',
        'Date of Birth': '4/11/1987',
        Group: 'BIGBANG',
        Debut: '19/08/2006',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 65,
        Birthplace: 'Seoul',
        'Other Group': 'GD&TOP',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taecyeon',
        'Full Name': 'Ok Taecyeon',
        'Korean Name': '옥택연',
        'K Stage Name': '택연',
        'Date of Birth': '27/12/1988',
        Group: '2PM',
        Debut: '4/07/2008',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 185,
        Weight: 76,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taedong',
        'Full Name': 'Kim Taedong',
        'Korean Name': '김태동',
        'K Stage Name': '태동',
        'Date of Birth': '7/11/1997',
        Group: 'OMEGA X',
        Debut: '17/10/2016',
        Company: 'JT Corea',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 61,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'GIDONGDAE',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taeeun',
        'Full Name': 'Moon Taeeun',
        'Korean Name': '문태은',
        'K Stage Name': '태은',
        'Date of Birth': '9/02/2000',
        Group: 'IM',
        Debut: '4/04/2022',
        Company: 'FC ENM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taeha',
        'Full Name': 'Yoo Taeha',
        'Korean Name': '유태하',
        'K Stage Name': '태하',
        'Date of Birth': '5/10/1995',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Berry Good',
        Gender: 'F'
    },
    {
        'Stage Name': 'Taeha',
        'Full Name': 'Kim Taeha',
        'Korean Name': '김태하',
        'K Stage Name': '태하',
        'Date of Birth': '3/06/1998',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Jeonju',
        'Other Group': '',
        'Former Group': 'MOMOLAND',
        Gender: 'F'
    },
    {
        'Stage Name': 'Taeheon',
        'Full Name': 'Kim Taeheon',
        'Korean Name': '김태헌',
        'K Stage Name': '태헌',
        'Date of Birth': '18/06/1989',
        Group: 'ZE:A',
        Debut: '7/01/2010',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taehun',
        'Full Name': 'Lee Taehoon',
        'Korean Name': '이태훈',
        'K Stage Name': '태훈',
        'Date of Birth': '21/03/2000',
        Group: 'NINE.i',
        Debut: '30/03/2022',
        Company: 'FirstOne',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taehun',
        'Full Name': 'Choi Taehun',
        'Korean Name': '최태훈',
        'K Stage Name': '태훈',
        'Date of Birth': '19/11/2002',
        Group: 'TNX',
        Debut: '17/05/2022',
        Company: 'P NATION',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taehyun',
        'Full Name': 'Nam Taehyun',
        'Korean Name': '남태현',
        'K Stage Name': '태현',
        'Date of Birth': '10/05/1994',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Hanam',
        'Other Group': '',
        'Former Group': 'WINNER',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taehyun',
        'Full Name': 'Noh Taehyun',
        'Korean Name': '노태현',
        'K Stage Name': '태현',
        'Date of Birth': '15/10/1993',
        Group: 'HOTSHOT',
        Debut: '29/10/2014',
        Company: 'Star Crew',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': 'JBJ',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taehyun',
        'Full Name': 'Kang Taehyun',
        'Korean Name': '강태현',
        'K Stage Name': '태현',
        'Date of Birth': '5/02/2002',
        Group: 'TXT',
        Debut: '4/03/2019',
        Company: 'Big Hit',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taeil',
        'Full Name': 'Lee Taeil',
        'Korean Name': '이태일',
        'K Stage Name': '태일',
        'Date of Birth': '24/09/1990',
        Group: 'Block B',
        Debut: '15/04/2011',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 57,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taeil',
        'Full Name': 'Moon Taeil',
        'Korean Name': '문태일',
        'K Stage Name': '태일',
        'Date of Birth': '14/06/1994',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: 60,
        Birthplace: 'Seoul',
        'Other Group': 'NCT U| NCT 127',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taekhyeon',
        'Full Name': 'Jung Taekhyeon',
        'Korean Name': '정택현',
        'K Stage Name': '택현',
        'Date of Birth': '28/07/2003',
        Group: '1the9',
        Debut: '9/02/2019',
        Company: 'MBK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 66,
        Birthplace: 'Gunpo',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taemin',
        'Full Name': 'Lee Taemin',
        'Korean Name': '이태민',
        'K Stage Name': '태민',
        'Date of Birth': '18/07/1993',
        Group: 'SHINee',
        Debut: '25/05/2008',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 53,
        Birthplace: 'Seoul',
        'Other Group': 'SuperM',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taera',
        'Full Name': 'Lee Yujin',
        'Korean Name': '이유진',
        'K Stage Name': '태라',
        'Date of Birth': '12/04/1994',
        Group: 'Gate9',
        Debut: '26/01/1999',
        Company: 'JYP| SidusHQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Taerae',
        'Full Name': 'Kim Taerae',
        'Korean Name': '김태래',
        'K Stage Name': '태래',
        'Date of Birth': '5/09/2002',
        Group: 'TEMPEST',
        Debut: '2/03/2022',
        Company: 'Yuehua',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taeryeong',
        'Full Name': 'Lim Jeongmin',
        'Korean Name': '임정민',
        'K Stage Name': '태령',
        'Date of Birth': '27/12/2000',
        Group: 'LUNARSOLAR',
        Debut: '2/09/2020',
        Company: 'J Planet',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Taeseon',
        'Full Name': 'Yang Taeseon',
        'Korean Name': '양태선',
        'K Stage Name': '태선',
        'Date of Birth': '17/09/2000',
        Group: 'TRCNG',
        Debut: '10/10/2017',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 182,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taesung',
        'Full Name': 'Lee Taesung',
        'Korean Name': '이태승',
        'K Stage Name': '태승',
        'Date of Birth': '19/12/2003',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 184,
        Weight: 64,
        Birthplace: '',
        'Other Group': 'TEEN TEEN',
        'Former Group': 'Ghost9',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taewoong',
        'Full Name': 'Yoo Taewoong',
        'Korean Name': '유태웅',
        'K Stage Name': '태웅',
        'Date of Birth': '24/05/1994',
        Group: 'SNUPER',
        Debut: '16/11/2015',
        Company: 'Widmay',
        Country: 'South Korea',
        'Second Country': 'Japan',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taeyang',
        'Full Name': 'Dong Youngbae',
        'Korean Name': '동영배',
        'K Stage Name': '태양',
        'Date of Birth': '18/05/1988',
        Group: 'BIGBANG',
        Debut: '19/08/2006',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: 174,
        Weight: 56,
        Birthplace: 'Uljeongbu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taeyang',
        'Full Name': 'Yoo Taeyang',
        'Korean Name': '유태양',
        'K Stage Name': '태양',
        'Date of Birth': '28/02/1997',
        Group: 'SF9',
        Debut: '5/10/2016',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taeyeon',
        'Full Name': 'Kim Taeyeon',
        'Korean Name': '김태연',
        'K Stage Name': '태연',
        'Date of Birth': '9/03/1989',
        Group: 'SNSD',
        Debut: '4/08/2021',
        Company: 'Good Luck',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 44,
        Birthplace: 'Jeonju',
        'Other Group': 'TTS| Oh!GG',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Taeyong',
        'Full Name': 'Lee Taeyong',
        'Korean Name': '이태용',
        'K Stage Name': '태용',
        'Date of Birth': '1/07/1995',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'NCT U| NCT 127|SuperM',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taeyoung',
        'Full Name': 'Kim Taeyoung',
        'Korean Name': '김태영',
        'K Stage Name': '태영',
        'Date of Birth': '18/06/1999',
        Group: "7 O'clock",
        Debut: '26/08/2014',
        Company: 'Jungle',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taeyoung',
        'Full Name': 'Kim Taeyoung',
        'Korean Name': '김태영',
        'K Stage Name': '태영',
        'Date of Birth': '27/01/2003',
        Group: 'CRAVITY',
        Debut: '14/04/2020',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'TAG',
        'Full Name': 'Yeom Taekyun',
        'Korean Name': '염태균',
        'K Stage Name': '태그',
        'Date of Birth': '30/09/2002',
        Group: 'Ciipher',
        Debut: '15/03/2021',
        Company: 'Rain',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 54,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'TAG',
        'Full Name': 'Son Youngtaek',
        'Korean Name': '손영택',
        'K Stage Name': '태그',
        'Date of Birth': '13/04/1998',
        Group: 'Golden Child',
        Debut: '28/08/2017',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Takuya',
        'Full Name': 'Terada Takuya',
        'Korean Name': '테라다 타쿠야',
        'K Stage Name': '타쿠야',
        'Date of Birth': '18/03/1992',
        Group: 'CROSS GENE',
        Debut: '8/07/2012',
        Company: 'Amuse',
        Country: 'Japan',
        'Second Country': '',
        Height: 187,
        Weight: 68,
        Birthplace: 'Moriya',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Tan',
        'Full Name': 'Choi Seokwon',
        'Korean Name': '최석원',
        'K Stage Name': '탄',
        'Date of Birth': '25/08/1996',
        Group: 'Ciipher',
        Debut: '15/03/2021',
        Company: 'Rain',
        Country: 'South Korea',
        'Second Country': '',
        Height: 184,
        Weight: 75,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Tao',
        'Full Name': 'Hwang Zitao',
        'Korean Name': '황자도',
        'K Stage Name': '타오',
        'Date of Birth': '2/05/1993',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'China',
        'Second Country': '',
        Height: 183,
        Weight: 70,
        Birthplace: 'Qingdao',
        'Other Group': '',
        'Former Group': 'EXO',
        Gender: 'M'
    },
    {
        'Stage Name': 'Taro',
        'Full Name': 'Choi Wonjin',
        'Korean Name': '최원진',
        'K Stage Name': '타로',
        'Date of Birth': '9/11/1994',
        Group: 'Hot Blood Youth',
        Debut: '17/05/2017',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Ten',
        'Full Name': 'Chittaphon Leechaiyapornkul',
        'Korean Name': '칫따폰 리차이야뽄꾼',
        'K Stage Name': '텐',
        'Date of Birth': '27/02/1996',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'Thailand',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Bangkok',
        'Other Group': 'NCT U|SuperM',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Teo',
        'Full Name': 'Jang Sungsik',
        'Korean Name': '장성식',
        'K Stage Name': '테오',
        'Date of Birth': '22/10/1997',
        Group: 'DKB',
        Debut: '3/02/2020',
        Company: 'Brave',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 64,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Terry',
        'Full Name': 'Han Dasom',
        'Korean Name': '한다솜',
        'K Stage Name': '테리',
        'Date of Birth': '2/02/1998',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'NeonPunch',
        Gender: 'F'
    },
    {
        'Stage Name': 'The8',
        'Full Name': 'Xu Minghao',
        'Korean Name': '쉬밍하오',
        'K Stage Name': '디에잇',
        'Date of Birth': '7/11/1997',
        Group: 'Seventeen',
        Debut: '26/05/2015',
        Company: 'Pledis',
        Country: 'China',
        'Second Country': '',
        Height: 175,
        Weight: 53,
        Birthplace: 'Anshan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Theo',
        'Full Name': 'Choi Taeyang',
        'Korean Name': '최태양',
        'K Stage Name': '태오',
        'Date of Birth': '1/07/2001',
        Group: 'P1Harmony',
        Debut: '28/10/2020',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Thunder',
        'Full Name': 'Park Sanghyun',
        'Korean Name': '박상현',
        'K Stage Name': '천둥',
        'Date of Birth': '7/10/1990',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': 'MBLAQ',
        Gender: 'M'
    },
    {
        'Stage Name': 'Tiffany',
        'Full Name': 'Hwang Miyoung',
        'Korean Name': '황미영',
        'K Stage Name': '티파니',
        'Date of Birth': '1/08/1989',
        Group: 'SNSD',
        Debut: '4/08/2021',
        Company: 'Good Luck',
        Country: 'South Korea',
        'Second Country': 'USA',
        Height: 163,
        Weight: 50,
        Birthplace: 'San Francisco',
        'Other Group': 'TTS',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Timoteo',
        'Full Name': 'Kim Moongyu',
        'Korean Name': '김문규',
        'K Stage Name': '티모테오',
        'Date of Birth': '25/01/1993',
        Group: 'HOTSHOT',
        Debut: '29/10/2014',
        Company: 'Star Crew',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Tina',
        'Full Name': 'Christine Joy Park',
        'Korean Name': '크리스틴 조이 박',
        'K Stage Name': '티나',
        'Date of Birth': '1/09/1994',
        Group: 'Blady',
        Debut: '16/05/2011',
        Company: 'Star Planet',
        Country: 'USA',
        'Second Country': 'South Korea',
        Height: '',
        Weight: '',
        Birthplace: 'Los Angeles',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Tsuki',
        'Full Name': 'Fukutomi Tsuki',
        'Korean Name': '후쿠토미 츠키',
        'K Stage Name': '츠키',
        'Date of Birth': '21/09/2002',
        Group: 'Billlie',
        Debut: '10/11/2021',
        Company: 'MYSTIC',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Tzuyu',
        'Full Name': 'Chou Tzuyu',
        'Korean Name': '저우쯔위',
        'K Stage Name': '쯔위',
        'Date of Birth': '14/06/1999',
        Group: 'Twice',
        Debut: '20/10/2015',
        Company: 'JYP',
        Country: 'Taiwan',
        'Second Country': '',
        Height: 169,
        Weight: '',
        Birthplace: 'Tainan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'U',
        'Full Name': 'Sun Hengyu',
        'Korean Name': '쑨헝위',
        'K Stage Name': '유',
        'Date of Birth': '22/06/2001',
        Group: 'BLANK2Y',
        Debut: '24/05/2022',
        Company: 'Keystone',
        Country: 'China',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'U',
        'Full Name': 'Mizuguchi Yuto',
        'Korean Name': '미즈구치 유토',
        'K Stage Name': '유',
        'Date of Birth': '16/03/1999',
        Group: 'ONF',
        Debut: '2/08/2017',
        Company: 'WM',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Osaka',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Uchae',
        'Full Name': 'Yu Hyejun',
        'Korean Name': '유혜준',
        'K Stage Name': '유채',
        'Date of Birth': '7/05/2002',
        Group: 'NATURE',
        Debut: '3/08/2018',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Uee',
        'Full Name': 'Kim Yujin',
        'Korean Name': '김유진',
        'K Stage Name': '유이',
        'Date of Birth': '9/04/1988',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': 'After School',
        Gender: 'F'
    },
    {
        'Stage Name': 'Uji',
        'Full Name': 'Jang Yuji',
        'Korean Name': '정유지',
        'K Stage Name': '유지',
        'Date of Birth': '2/01/1991',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'EXID| BESTie',
        Gender: 'F'
    },
    {
        'Stage Name': 'U-Know Yunho',
        'Full Name': 'Jung Yunho',
        'Korean Name': '정윤호',
        'K Stage Name': '유노윤호',
        'Date of Birth': '6/02/1986',
        Group: 'TVXQ',
        Debut: '6/09/1995',
        Company: 'MBK| Turbo Co.',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'U-Kwon',
        'Full Name': 'Kim Yukwon',
        'Korean Name': '김유권',
        'K Stage Name': '유권',
        'Date of Birth': '9/04/1992',
        Group: 'Block B',
        Debut: '15/04/2011',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 63,
        Birthplace: 'Suwon',
        'Other Group': 'Bastarz',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Umji',
        'Full Name': 'Kim Yewon',
        'Korean Name': '김예원',
        'K Stage Name': '엄지',
        'Date of Birth': '19/08/1998',
        Group: 'VIVIZ',
        Debut: '9/02/2022',
        Company: 'Big Planet Made',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': 'Gfriend',
        Gender: 'F'
    },
    {
        'Stage Name': 'U-Si',
        'Full Name': 'Yoo Jiyeon',
        'Korean Name': '윤지연',
        'K Stage Name': '유시',
        'Date of Birth': '13/11/1997',
        Group: 'BADKIZ',
        Debut: '24/03/2014',
        Company: 'Zoo',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Iksan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'V',
        'Full Name': 'Kim Taehyung',
        'Korean Name': '김태형',
        'K Stage Name': '뷔',
        'Date of Birth': '30/12/1995',
        Group: 'BTS',
        Debut: '13/06/2013',
        Company: 'Big Hit',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: 62,
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Vahn',
        'Full Name': 'Kim Jungwoo',
        'Korean Name': '김정우',
        'K Stage Name': '반',
        'Date of Birth': '7/05/1999',
        Group: 'NINE.i',
        Debut: '30/03/2022',
        Company: 'FirstOne',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 58,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Vari',
        'Full Name': 'Jae Hyun',
        'Korean Name': '재현',
        'K Stage Name': '베리',
        'Date of Birth': '3/09/2000',
        Group: 'NINE.i',
        Debut: '30/03/2022',
        Company: 'FirstOne',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Vernon',
        'Full Name': 'Choi Hansol',
        'Korean Name': '최한솔',
        'K Stage Name': '버논',
        'Date of Birth': '18/02/1998',
        Group: 'Seventeen',
        Debut: '26/05/2015',
        Company: 'Pledis',
        Country: 'USA',
        'Second Country': 'South Korea',
        Height: 176,
        Weight: 56,
        Birthplace: 'New York',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Via',
        'Full Name': 'Kim Via',
        'Korean Name': '김비아',
        'K Stage Name': '비아',
        'Date of Birth': '17/08/2000',
        Group: 'FANATICS',
        Debut: '6/08/2019',
        Company: 'FENT',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 46,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Victoria',
        'Full Name': 'Song Qian',
        'Korean Name': '송치엔',
        'K Stage Name': '빅토리아',
        'Date of Birth': '2/02/1987',
        Group: 'f(x)',
        Debut: '5/09/2009',
        Company: 'SM',
        Country: 'China',
        'Second Country': '',
        Height: 168,
        Weight: 45,
        Birthplace: 'Qingdao',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Viki',
        'Full Name': 'Kang Eunhye',
        'Korean Name': '강은혜',
        'K Stage Name': '비키',
        'Date of Birth': '28/03/1988',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'Dal Shabet',
        Gender: 'F'
    },
    {
        'Stage Name': 'Vita',
        'Full Name': 'Kim Jimin',
        'Korean Name': '김지민',
        'K Stage Name': '비타',
        'Date of Birth': '3/02/2000',
        Group: 'MAJORS',
        Debut: '9/03/2021',
        Company: 'ANS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: 54,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Vivi',
        'Full Name': 'Wong Viian',
        'Korean Name': '황가희',
        'K Stage Name': '비비',
        'Date of Birth': '9/12/1996',
        Group: 'LOONA',
        Debut: '20/08/2018',
        Company: 'Blockberry',
        Country: 'Hong Kong',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Hong Kong',
        'Other Group': 'Loona 1/3',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Vivian',
        'Full Name': 'Park Sunghee',
        'Korean Name': '박성희',
        'K Stage Name': '비비안',
        'Date of Birth': '8/12/1998',
        Group: 'Gate9',
        Debut: '26/01/1999',
        Company: 'JYP| SidusHQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Way',
        'Full Name': 'Heo Minsun',
        'Korean Name': '허민선',
        'K Stage Name': '웨이',
        'Date of Birth': '12/07/1990',
        Group: 'Crayon Pop',
        Debut: '18/07/2012',
        Company: 'Chrome',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Wei',
        'Full Name': 'Lee Sungjun',
        'Korean Name': '이성준',
        'K Stage Name': '웨이',
        'Date of Birth': '8/07/1996',
        Group: 'UP10TION',
        Debut: '10/09/2015',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wendy',
        'Full Name': 'Son Seunghwan',
        'Korean Name': '손승완',
        'K Stage Name': '웬디',
        'Date of Birth': '21/02/1994',
        Group: 'Red Velvet',
        Debut: '1/08/2014',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Wenhan',
        'Full Name': 'Li Wenhan',
        'Korean Name': '리원한',
        'K Stage Name': '원한',
        'Date of Birth': '22/07/1994',
        Group: 'UNIQ',
        Debut: '20/10/2014',
        Company: 'Yuehua',
        Country: 'China',
        'Second Country': '',
        Height: 180,
        Weight: 64,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wheein',
        'Full Name': 'Jung Wheein',
        'Korean Name': '정휘인',
        'K Stage Name': '휘인',
        'Date of Birth': '17/04/1995',
        Group: 'Mamamoo',
        Debut: '18/06/2014',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: 43,
        Birthplace: 'Jeonju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Win',
        'Full Name': 'Bang Junhyuk',
        'Korean Name': '방준혁',
        'K Stage Name': '윈',
        'Date of Birth': '19/12/2004',
        Group: 'MCND',
        Debut: '27/02/2020',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Windy',
        'Full Name': 'Park Jinju',
        'Korean Name': '박진주',
        'K Stage Name': '윈디',
        'Date of Birth': '23/02/2000',
        Group: "Girls' World",
        Debut: '20/06/2002',
        Company: 'Liz',
        Country: 'South Korea',
        'Second Country': '',
        Height: 155,
        Weight: '',
        Birthplace: 'Daejeon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'WinL',
        'Full Name': 'Yang Seungho',
        'Korean Name': '양승호',
        'K Stage Name': '윈엘',
        'Date of Birth': '16/06/1996',
        Group: 'ABLUE',
        Debut: '23/10/2022',
        Company: 'J-Star',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 68,
        Birthplace: 'Paju',
        'Other Group': '',
        'Former Group': 'BECZ',
        Gender: 'M'
    },
    {
        'Stage Name': 'Winnie',
        'Full Name': 'Pattipong Kasemsant',
        'Korean Name': '파티퐁 카셈산트',
        'K Stage Name': '위니',
        'Date of Birth': '18/11/1998',
        Group: 'NINE.i',
        Debut: '30/03/2022',
        Company: 'FirstOne',
        Country: 'Thailand',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Winter',
        'Full Name': 'Kim Minjung',
        'Korean Name': '김민정',
        'K Stage Name': '윈터',
        'Date of Birth': '1/01/2001',
        Group: 'aespa',
        Debut: '17/11/2020',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yangsan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Winwin',
        'Full Name': 'Dong Sicheng',
        'Korean Name': '뚱쓰청',
        'K Stage Name': '윈윈',
        'Date of Birth': '28/10/1997',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'China',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Zhejiang',
        'Other Group': 'NCT 127',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wish',
        'Full Name': 'Kwak Dawit',
        'Korean Name': '곽다윗',
        'K Stage Name': '위시',
        'Date of Birth': '11/06/2002',
        Group: 'EPEX',
        Debut: '8/06/2021',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: 184,
        Weight: 69,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Won',
        'Full Name': 'Park Sungwon',
        'Korean Name': '박성원',
        'K Stage Name': '성원',
        'Date of Birth': '18/12/2003',
        Group: 'Ciipher',
        Debut: '15/03/2021',
        Company: 'Rain',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 58,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '1the9',
        Gender: 'M'
    },
    {
        'Stage Name': 'Won Bin',
        'Full Name': 'Won Bin',
        'Korean Name': '원빈',
        'K Stage Name': '원빈',
        'Date of Birth': '1/07/2004',
        Group: 'ATBO',
        Debut: '27/07/2022',
        Company: 'IST',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: '',
        Birthplace: 'Gyeonggi',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Won Hyuk',
        'Full Name': 'Won Hyuk',
        'Korean Name': '원혁',
        'K Stage Name': '원혁',
        'Date of Birth': '22/02/2002',
        Group: "E'LAST",
        Debut: '9/06/2020',
        Company: 'E Entertainment',
        Country: 'South Korea',
        'Second Country': '',
        Height: 181,
        Weight: 63,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wonchae',
        'Full Name': 'Hwang Chaewon',
        'Korean Name': '황채원',
        'K Stage Name': '원채',
        'Date of Birth': '22/12/2002',
        Group: 'Queenz Eye',
        Debut: '24/10/2022',
        Company: 'Big Mountain',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Woncheol',
        'Full Name': 'Jung Woncheol',
        'Korean Name': '정원철',
        'K Stage Name': '원철',
        'Date of Birth': '12/01/1996',
        Group: 'MY.st',
        Debut: '26/03/2020',
        Company: 'MYStar',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wondae',
        'Full Name': 'Seol Wondae',
        'Korean Name': '설원대',
        'K Stage Name': '원대',
        'Date of Birth': '28/04/1998',
        Group: 'Dongkiz',
        Debut: '24/04/2019',
        Company: 'Dongyo',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 63,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wonho',
        'Full Name': 'Shin Hoseok',
        'Korean Name': '신호석',
        'K Stage Name': '원호',
        'Date of Birth': '1/03/1993',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Anyang',
        'Other Group': '',
        'Former Group': 'MONSTA X',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wonjin',
        'Full Name': 'Ham Wonjin',
        'Korean Name': '함원진',
        'K Stage Name': '원진',
        'Date of Birth': '22/03/2001',
        Group: 'CRAVITY',
        Debut: '14/04/2020',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 174,
        Weight: 57,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wonjin',
        'Full Name': 'Won Jin',
        'Korean Name': '원진',
        'K Stage Name': '원진',
        'Date of Birth': '1/01/2003',
        Group: 'GIDONGDAE',
        Debut: '3/04/2013',
        Company: 'Simtong',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wonjun',
        'Full Name': 'Jeong Wonjun',
        'Korean Name': '정원준',
        'K Stage Name': '원준',
        'Date of Birth': '21/07/1998',
        Group: 'ABLUE',
        Debut: '23/10/2022',
        Company: 'J-Star',
        Country: 'South Korea',
        'Second Country': '',
        Height: 187,
        Weight: 73,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'ZPZG',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wonjun',
        'Full Name': 'Lee Wonjun',
        'Korean Name': '이원준',
        'K Stage Name': '원준',
        'Date of Birth': '8/03/2002',
        Group: "E'LAST",
        Debut: '9/06/2020',
        Company: 'E Entertainment',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wonpil',
        'Full Name': 'Kim Wonpil',
        'Korean Name': '김원필',
        'K Stage Name': '원필',
        'Date of Birth': '28/04/1994',
        Group: 'DAY6',
        Debut: '7/09/2015',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wontak',
        'Full Name': 'Ju Wontak',
        'Korean Name': '주원탁',
        'K Stage Name': '원탁',
        'Date of Birth': '24/02/1996',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'RAINZ',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wonwoo',
        'Full Name': 'Jeon Wonwoo',
        'Korean Name': '전원우',
        'K Stage Name': '원우',
        'Date of Birth': '17/07/1996',
        Group: 'Seventeen',
        Debut: '26/05/2015',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 57,
        Birthplace: 'Changwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wony',
        'Full Name': 'Ahn Seungwon',
        'Korean Name': '안승원',
        'K Stage Name': '워니',
        'Date of Birth': '4/10/2004',
        Group: 'We;Na',
        Debut: '5/09/2022',
        Company: 'Shine E&M',
        Country: 'South Korea',
        'Second Country': '',
        Height: 155,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Wonyoung',
        'Full Name': 'Jang Wonyoung',
        'Korean Name': '장원영',
        'K Stage Name': '원영',
        'Date of Birth': '31/08/2004',
        Group: 'IVE',
        Debut: '1/12/2021',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'IZ*ONE',
        Gender: 'F'
    },
    {
        'Stage Name': 'Woo-ah',
        'Full Name': 'Kim Chaewon',
        'Korean Name': '김채원',
        'K Stage Name': '우아',
        'Date of Birth': '20/06/1997',
        Group: 'CRAXY',
        Debut: '3/03/2020',
        Company: 'SAITEINMENT',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Woobin',
        'Full Name': 'Seo Woobin',
        'Korean Name': '서우빈',
        'K Stage Name': '우빈',
        'Date of Birth': '16/10/2000',
        Group: 'CRAVITY',
        Debut: '14/04/2020',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Woobin',
        'Full Name': 'Jeong Woobin',
        'Korean Name': '정우빈',
        'K Stage Name': '우빈',
        'Date of Birth': '1/04/2000',
        Group: 'Luminous',
        Debut: '9/09/2021',
        Company: 'Barunson',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Woochul',
        'Full Name': 'Shin Woochul',
        'Korean Name': '신우철',
        'K Stage Name': '우철',
        'Date of Birth': '2/10/2002',
        Group: 'Newkidd',
        Debut: '25/04/2019',
        Company: 'J-FLO',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Woodam',
        'Full Name': 'Park Woodam',
        'Korean Name': '박우담',
        'K Stage Name': '우담',
        'Date of Birth': '2/08/1995',
        Group: 'D1CE',
        Debut: '1/08/2019',
        Company: 'Happyface',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Woohee',
        'Full Name': 'Bae Woohee',
        'Korean Name': '배우희',
        'K Stage Name': '우희',
        'Date of Birth': '21/11/1991',
        Group: 'Dal Shabet',
        Debut: '3/01/2011',
        Company: 'Happy Face',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': 'UNI.T',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Woohyun',
        'Full Name': 'Nam Woohyun',
        'Korean Name': '남우현',
        'K Stage Name': '우현',
        'Date of Birth': '8/02/1991',
        Group: 'Infinite',
        Debut: '9/06/2010',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 52,
        Birthplace: 'Seoul',
        'Other Group': 'Toheart',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Woojin',
        'Full Name': 'Kim Woojin',
        'Korean Name': '김우진',
        'K Stage Name': '우진',
        'Date of Birth': '8/04/1997',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daejeon',
        'Other Group': '',
        'Former Group': 'Stray Kids',
        Gender: 'M'
    },
    {
        'Stage Name': 'Woojin',
        'Full Name': 'Park Woojin',
        'Korean Name': '박우진',
        'K Stage Name': '우진',
        'Date of Birth': '2/11/1999',
        Group: 'AB6IX',
        Debut: '22/05/2019',
        Company: 'Brand New',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': 'Wanna One',
        Gender: 'M'
    },
    {
        'Stage Name': 'Woojin',
        'Full Name': 'Lee Woojin',
        'Korean Name': '이우진',
        'K Stage Name': '우진',
        'Date of Birth': '7/04/2003',
        Group: 'Ghost9',
        Debut: '23/09/2020',
        Company: 'Maroo',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 65,
        Birthplace: '',
        'Other Group': 'TEEN TEEN',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Woojin',
        'Full Name': 'Lee Woojin',
        'Korean Name': '이우진',
        'K Stage Name': '우진',
        'Date of Birth': '22/11/1996',
        Group: 'MY.st',
        Debut: '26/03/2020',
        Company: 'MYStar',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Woojin',
        'Full Name': 'Lee Woojin',
        'Korean Name': '이우진',
        'K Stage Name': '우진',
        'Date of Birth': '3/04/2003',
        Group: 'TheEastLight',
        Debut: '15/11/2018',
        Company: 'Stardium',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Woojoo',
        'Full Name': 'Kim Sangyoon',
        'Korean Name': '김상윤',
        'K Stage Name': '우주',
        'Date of Birth': '18/09/1996',
        Group: '14U',
        Debut: '17/04/2017',
        Company: 'BG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Woojoo',
        'Full Name': 'Kim Woojoo',
        'Korean Name': '김우주',
        'K Stage Name': '우주',
        'Date of Birth': '12/08/1996',
        Group: 'Wassup',
        Debut: '7/08/2013',
        Company: 'Mafia',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Wooju',
        'Full Name': 'Cho Wooju',
        'Korean Name': '조우주',
        'K Stage Name': '우주',
        'Date of Birth': '21/01/2004',
        Group: 'BLITZERS',
        Debut: '12/05/2021',
        Company: 'Wuzo',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Woolim',
        'Full Name': 'Hwang Woolim',
        'Korean Name': '황우림',
        'K Stage Name': '우림',
        'Date of Birth': '29/08/1996',
        Group: 'Playback',
        Debut: '25/06/2015',
        Company: 'Coridel',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Woonggi',
        'Full Name': 'Cha Woonggi',
        'Korean Name': '차웅기',
        'K Stage Name': '웅기',
        'Date of Birth': '23/04/2002',
        Group: 'TOO',
        Debut: '1/04/2020',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Woono',
        'Full Name': 'Song Wonho',
        'Korean Name': '홍원호',
        'K Stage Name': '우노',
        'Date of Birth': '5/04/2003',
        Group: 'YOUNITE',
        Debut: '20/04/2022',
        Company: 'Brand New',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Woori',
        'Full Name': 'Go Woori',
        'Korean Name': '고우리',
        'K Stage Name': '우리',
        'Date of Birth': '22/02/1988',
        Group: 'Rainbow',
        Debut: '12/11/2009',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 44,
        Birthplace: 'Jeonju',
        'Other Group': 'Rainbow Blaxx',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Wooseok',
        'Full Name': 'Jeong Wooseok',
        'Korean Name': '정우석',
        'K Stage Name': '우석',
        'Date of Birth': '31/01/1998',
        Group: 'PENTAGON',
        Debut: '10/10/2016',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wooshin',
        'Full Name': 'Kim Wooseok',
        'Korean Name': '김우석',
        'K Stage Name': '우신',
        'Date of Birth': '27/10/1996',
        Group: 'UP10TION',
        Debut: '10/09/2015',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daejeon',
        'Other Group': '',
        'Former Group': 'X1',
        Gender: 'M'
    },
    {
        'Stage Name': 'Woosu',
        'Full Name': 'Kim Minseok',
        'Korean Name': '김민석',
        'K Stage Name': '우수',
        'Date of Birth': '23/11/1999',
        Group: 'IZ',
        Debut: '31/08/2017',
        Company: 'Music K',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Woosung',
        'Full Name': 'Choi Sunghyuk',
        'Korean Name': '최성혁',
        'K Stage Name': '우성',
        'Date of Birth': '24/09/1994',
        Group: 'SNUPER',
        Debut: '16/11/2015',
        Company: 'Widmay',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wooyeon',
        'Full Name': 'Park Jinkyung',
        'Korean Name': '박진경',
        'K Stage Name': '우연',
        'Date of Birth': '11/02/2003',
        Group: 'woo!ah!',
        Debut: '15/05/2020',
        Company: 'NV',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Wooyeop',
        'Full Name': 'Jo Wooyeop',
        'Korean Name': '조우엽',
        'K Stage Name': '우엽',
        'Date of Birth': '27/09/2000',
        Group: 'TRCNG',
        Debut: '10/10/2017',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 179,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wooyoung',
        'Full Name': 'Jang Wooyoung',
        'Korean Name': '장우영',
        'K Stage Name': '우영',
        'Date of Birth': '30/04/1989',
        Group: '2PM',
        Debut: '4/07/2008',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 65,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wooyoung',
        'Full Name': 'Hwang Wooyoung',
        'Korean Name': '황우영',
        'K Stage Name': '우영',
        'Date of Birth': '29/06/2003',
        Group: 'AIMERS',
        Debut: '17/11/2022',
        Company: 'Hyper Rhythm',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wooyoung',
        'Full Name': 'Jung Wooyoung',
        'Korean Name': '정우영',
        'K Stage Name': '우영',
        'Date of Birth': '26/11/1999',
        Group: 'ATEEZ',
        Debut: '24/10/2018',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Woozi',
        'Full Name': 'Lee Jihoon',
        'Korean Name': '이지훈',
        'K Stage Name': '우지',
        'Date of Birth': '22/11/1996',
        Group: 'Seventeen',
        Debut: '26/05/2015',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 54,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wow',
        'Full Name': 'Kim Seyoon',
        'Korean Name': '김세윤',
        'K Stage Name': '와우',
        'Date of Birth': '28/02/1993',
        Group: 'A.C.E',
        Debut: '23/05/2017',
        Company: 'Beat',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gyeonggi',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Wyatt',
        'Full Name': 'Shim Jaeyoung',
        'Korean Name': '심재영',
        'K Stage Name': '와이엇',
        'Date of Birth': '23/01/1995',
        Group: 'ONF',
        Debut: '2/08/2017',
        Company: 'WM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Xeheun',
        'Full Name': 'Lee Seheun',
        'Korean Name': '이세흔',
        'K Stage Name': '세흔',
        'Date of Birth': '11/07/1999',
        Group: 'Girlkind',
        Debut: '16/01/2018',
        Company: 'Nextlevel',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: 162,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'XEN',
        'Full Name': 'Lee Jinwoo',
        'Korean Name': '이진우',
        'K Stage Name': '젠',
        'Date of Birth': '20/02/1998',
        Group: 'OMEGA X',
        Debut: '17/10/2016',
        Company: 'JT Corea',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '1TEAM',
        Gender: 'M'
    },
    {
        'Stage Name': 'Xero',
        'Full Name': 'Shin Jiho',
        'Korean Name': '신지호',
        'K Stage Name': '제로',
        'Date of Birth': '3/02/1994',
        Group: 'Topp Dogg',
        Debut: '1/04/2020',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwangji',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Xia Junsu',
        'Full Name': 'Kim Junsu',
        'Korean Name': '김준수',
        'K Stage Name': '시아 준수',
        'Date of Birth': '15/12/1986',
        Group: 'JYJ',
        Debut: '8/09/2010',
        Company: 'C-JeS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'TVXQ',
        Gender: 'M'
    },
    {
        'Stage Name': 'Xiao',
        'Full Name': 'Lee Dongyeol',
        'Korean Name': '이동열',
        'K Stage Name': '샤오',
        'Date of Birth': '13/12/1998',
        Group: 'UP10TION',
        Debut: '10/09/2015',
        Company: 'TOP Media',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'XiaoTing',
        'Full Name': 'Shen Xiao Ting',
        'Korean Name': '션샤오팅',
        'K Stage Name': '샤오팅',
        'Date of Birth': '12/11/1999',
        Group: 'Kep1er',
        Debut: '1/03/2022',
        Company: 'WakeOne',
        Country: 'China',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Xin',
        'Full Name': 'Wang Xinwi',
        'Korean Name': '왕씬위',
        'K Stage Name': '씬',
        'Date of Birth': '10/11/1996',
        Group: 'VARSITY',
        Debut: '5/01/2017',
        Company: 'GKC',
        Country: 'China',
        'Second Country': '',
        Height: 185,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Xion',
        'Full Name': 'Son Dongju',
        'Korean Name': '손동주',
        'K Stage Name': '시온',
        'Date of Birth': '10/01/2000',
        Group: 'Oneus',
        Debut: '9/01/2019',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Xiumin',
        'Full Name': 'Kim Minseok',
        'Korean Name': '김민석',
        'K Stage Name': '시우민',
        'Date of Birth': '26/03/1990',
        Group: 'EXO',
        Debut: '8/04/2012',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: 65,
        Birthplace: 'Guri',
        'Other Group': 'EXO-CBX',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Xiweol',
        'Full Name': 'Kang Minseok',
        'Korean Name': '강민석',
        'K Stage Name': '시월',
        'Date of Birth': '21/10/1996',
        Group: 'VARSITY',
        Debut: '5/01/2017',
        Company: 'GKC',
        Country: 'South Korea',
        'Second Country': '',
        Height: 174,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Xiyeon',
        'Full Name': 'Park Junghyun',
        'Korean Name': '박정현',
        'K Stage Name': '시연',
        'Date of Birth': '14/11/2000',
        Group: 'Pristin',
        Debut: '21/03/2017',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 51,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Xuanyi',
        'Full Name': 'Wu Xuanyi',
        'Korean Name': '우쉬안이',
        'K Stage Name': '선의',
        'Date of Birth': '26/01/1995',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'China',
        'Second Country': '',
        Height: 166,
        Weight: '',
        Birthplace: 'Hainan',
        'Other Group': '',
        'Former Group': 'WJSN',
        Gender: 'F'
    },
    {
        'Stage Name': 'Y',
        'Full Name': 'Choi Sungyoon',
        'Korean Name': '최성윤',
        'K Stage Name': '와이',
        'Date of Birth': '31/07/1995',
        Group: 'Golden Child',
        Debut: '28/08/2017',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yan An',
        'Full Name': 'Yan An',
        'Korean Name': '옌안',
        'K Stage Name': '옌안',
        'Date of Birth': '25/10/1996',
        Group: 'PENTAGON',
        Debut: '10/10/2016',
        Company: 'Cube',
        Country: 'China',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Shanghai',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yano',
        'Full Name': 'Seo Sangwon',
        'Korean Name': '서상원',
        'K Stage Name': '야노',
        'Date of Birth': '27/09/1995',
        Group: 'Topp Dogg',
        Debut: '1/04/2020',
        Company: 'n.CH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yeah',
        'Full Name': 'Kim Haeun',
        'Korean Name': '김하은',
        'K Stage Name': '예아',
        'Date of Birth': '9/10/1999',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gongju',
        'Other Group': '',
        'Former Group': 'Good Day| Cignature',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yebin',
        'Full Name': 'Baek Yebin',
        'Korean Name': '백예빈',
        'K Stage Name': '예빈',
        'Date of Birth': '13/07/1997',
        Group: 'DIA',
        Debut: '14/07/2015',
        Company: 'MBK',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 43,
        Birthplace: 'Chuncheon',
        'Other Group': 'UNI.T',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yebin',
        'Full Name': 'Kim Yebin',
        'Korean Name': '김예빈',
        'K Stage Name': '예빈',
        'Date of Birth': '1/11/2004',
        Group: 'HOT ISSUE',
        Debut: '17/05/2017',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yechan',
        'Full Name': 'Shin Yechan',
        'Korean Name': '신예찬',
        'K Stage Name': '예찬',
        'Date of Birth': '14/05/2001',
        Group: 'OMEGA X',
        Debut: '17/10/2016',
        Company: 'JT Corea',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: 64,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '1the9',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yechan',
        'Full Name': 'Kim Yechan',
        'Korean Name': '김예찬',
        'K Stage Name': '예찬',
        'Date of Birth': '26/11/1995',
        Group: 'Pink Fantasy',
        Debut: '28/10/2018',
        Company: 'My Doll',
        Country: 'South Korea',
        'Second Country': '',
        Height: 159,
        Weight: 45,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yechan',
        'Full Name': 'Jeong Yechan',
        'Korean Name': '정예찬',
        'K Stage Name': '예찬',
        'Date of Birth': '27/10/2005',
        Group: 'TRENDZ',
        Debut: '5/01/2022',
        Company: 'Interpark',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yechan',
        'Full Name': 'Lee Yechan',
        'Korean Name': '이예찬',
        'K Stage Name': '예찬',
        'Date of Birth': '21/10/2005',
        Group: 'xikers',
        Debut: '30/03/2023',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yesan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yedam',
        'Full Name': 'Bang Yedam',
        'Korean Name': '방예담',
        'K Stage Name': '예담',
        'Date of Birth': '7/05/2002',
        Group: 'Treasure',
        Debut: '7/08/2020',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'YeeEun',
        'Full Name': 'Jang Yeeun',
        'Korean Name': '장예은',
        'K Stage Name': '예은',
        'Date of Birth': '10/08/1998',
        Group: 'CLC',
        Debut: '19/03/2015',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: '',
        Birthplace: 'Dongducheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeeun',
        'Full Name': 'Hwang Yeeun',
        'Korean Name': '황예은',
        'K Stage Name': '예은',
        'Date of Birth': '12/01/1997',
        Group: 'Blady',
        Debut: '16/05/2011',
        Company: 'Star Planet',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gimhae',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeham',
        'Full Name': 'Gu Yeham',
        'Korean Name': '구예함',
        'K Stage Name': '예함',
        'Date of Birth': '7/10/2005',
        Group: 'CSR',
        Debut: '27/07/2022',
        Company: 'A2Z',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Pyeongtaek',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yehana',
        'Full Name': 'Kim Yewon',
        'Korean Name': '김예원',
        'K Stage Name': '예하나',
        'Date of Birth': '22/02/1999',
        Group: 'Pristin',
        Debut: '21/03/2017',
        Company: 'Pledis',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Ilsan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yehyeon',
        'Full Name': 'Kim Yehyeon',
        'Korean Name': '김예현',
        'K Stage Name': '예현',
        'Date of Birth': '12/09/1999',
        Group: 'GIDONGDAE',
        Debut: '3/04/2013',
        Company: 'Simtong',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 63,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yein',
        'Full Name': 'Jeong Yein',
        'Korean Name': '정예인',
        'K Stage Name': '예인',
        'Date of Birth': '4/06/1998',
        Group: 'Lovelyz',
        Debut: '17/11/2014',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yein',
        'Full Name': 'Ahn Yein',
        'Korean Name': '안예인',
        'K Stage Name': '예인',
        'Date of Birth': '4/05/1995',
        Group: 'MELODYDAY',
        Debut: '25/09/2019',
        Company: 'KD',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeji',
        'Full Name': 'Hwang Yeji',
        'Korean Name': '황예지',
        'K Stage Name': '예지',
        'Date of Birth': '26/05/2000',
        Group: 'ITZY',
        Debut: '12/02/2019',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeju',
        'Full Name': 'Kim Yeju',
        'Korean Name': '김예주',
        'K Stage Name': '예주',
        'Date of Birth': '1/02/2004',
        Group: "ICHILLIN'",
        Debut: '8/09/2021',
        Company: 'KM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yejun',
        'Full Name': 'Oh Yejun',
        'Korean Name': '오예준',
        'K Stage Name': '예준',
        'Date of Birth': '23/08/2002',
        Group: "E'LAST",
        Debut: '9/06/2020',
        Company: 'E Entertainment',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 58,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yel',
        'Full Name': 'Han Shinyoung',
        'Korean Name': '한신영',
        'K Stage Name': '옐',
        'Date of Birth': '25/12/2004',
        Group: 'H1-KEY',
        Debut: '5/01/2022',
        Company: 'GLG',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yellow',
        'Full Name': 'Kim Eunbi',
        'Korean Name': '김은비',
        'K Stage Name': '노랑',
        'Date of Birth': '5/05/1993',
        Group: 'Pungdeng-E',
        Debut: '11/12/2013',
        Company: 'DOMA',
        Country: 'South Korea',
        'Second Country': '',
        Height: 156,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yena',
        'Full Name': 'Yang Yena',
        'Korean Name': '양예나',
        'K Stage Name': '예나',
        'Date of Birth': '22/05/2000',
        Group: 'APRIL',
        Debut: '24/08/2015',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Daegu',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yena',
        'Full Name': 'Jeong Yena',
        'Korean Name': '정예나',
        'K Stage Name': '예나',
        'Date of Birth': '10/04/2000',
        Group: 'G-reyish',
        Debut: '1/06/2017',
        Company: 'Elijah',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yena',
        'Full Name': 'Choi Yena',
        'Korean Name': '최예나',
        'K Stage Name': '예나',
        'Date of Birth': '29/09/1999',
        Group: 'IZ*ONE',
        Debut: '29/10/2018',
        Company: 'Off The Record',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yenny',
        'Full Name': 'Park Yeeun',
        'Korean Name': '박예은',
        'K Stage Name': '예은',
        'Date of Birth': '26/05/1989',
        Group: 'Wonder Girls',
        Debut: '13/02/2007',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Goyang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeo One',
        'Full Name': 'Yeon Changgu',
        'Korean Name': '여창구',
        'K Stage Name': '여원',
        'Date of Birth': '27/03/1996',
        Group: 'PENTAGON',
        Debut: '10/10/2016',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yeoeun',
        'Full Name': 'Jung Jieun',
        'Korean Name': '정지은',
        'K Stage Name': '여은',
        'Date of Birth': '25/01/1990',
        Group: 'MELODYDAY',
        Debut: '25/09/2019',
        Company: 'KD',
        Country: 'South Korea',
        'Second Country': '',
        Height: 169,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeojin',
        'Full Name': 'Im Yeojin',
        'Korean Name': '임여진',
        'K Stage Name': '여진',
        'Date of Birth': '11/11/2002',
        Group: 'LOONA',
        Debut: '20/08/2018',
        Company: 'Blockberry',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Guro',
        'Other Group': 'Loona 1/3',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeonha',
        'Full Name': 'Ahn Yeonji',
        'Korean Name': '안연지',
        'K Stage Name': '연하',
        'Date of Birth': '19/05/2000',
        Group: 'P.O.P',
        Debut: '26/07/2017',
        Company: 'DWM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Yeondong',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeonhee',
        'Full Name': 'Kim Yeonhee',
        'Korean Name': '김연희',
        'K Stage Name': '연희',
        'Date of Birth': '6/12/2000',
        Group: 'Rocket Punch',
        Debut: '7/08/2019',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeonho',
        'Full Name': 'Ju Yeonho',
        'Korean Name': '주연호',
        'K Stage Name': '연호',
        'Date of Birth': '31/05/2000',
        Group: 'Verivery',
        Debut: '1/09/2019',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yeonjae',
        'Full Name': 'Choi Yeonjae',
        'Korean Name': '최연재',
        'K Stage Name': '연재',
        'Date of Birth': '6/12/2000',
        Group: 'mimiirose',
        Debut: '16/09/2022',
        Company: 'YES IM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeonje',
        'Full Name': 'Yang Yeonje',
        'Korean Name': '양연제',
        'K Stage Name': '혜성',
        'Date of Birth': '15/10/1999',
        Group: 'ALICE',
        Debut: '1/07/2017',
        Company: 'IOK Company',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeonji',
        'Full Name': 'Kwak Yeonji',
        'Korean Name': '곽연지',
        'K Stage Name': '연지',
        'Date of Birth': '8/01/2008',
        Group: 'tripleS',
        Debut: '13/02/2023',
        Company: 'MODHAUS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeonjoo',
        'Full Name': 'Song Yeonjoo',
        'Korean Name': '정연주',
        'K Stage Name': '연주',
        'Date of Birth': '26/06/1997',
        Group: 'P.O.P',
        Debut: '26/07/2017',
        Company: 'DWM',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Ansan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeonjun',
        'Full Name': 'Choi Yeonjun',
        'Korean Name': '최연준',
        'K Stage Name': '연준',
        'Date of Birth': '13/09/1999',
        Group: 'TXT',
        Debut: '4/03/2019',
        Company: 'Big Hit',
        Country: 'South Korea',
        'Second Country': '',
        Height: 183,
        Weight: 63,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yeonjung',
        'Full Name': 'Yu Yeonjung',
        'Korean Name': '유연정',
        'K Stage Name': '연정',
        'Date of Birth': '3/08/1999',
        Group: 'WJSN',
        Debut: '25/02/2016',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 52,
        Birthplace: 'Gwangmyeong',
        'Other Group': '',
        'Former Group': 'I.O.I',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeonkyu',
        'Full Name': 'Kim Yeonkyu',
        'Korean Name': '김연규',
        'K Stage Name': '연규',
        'Date of Birth': '3/05/2004',
        Group: 'ATBO',
        Debut: '27/07/2022',
        Company: 'IST',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yeonseo',
        'Full Name': 'Ryu Yeonseo',
        'Korean Name': '류연서',
        'K Stage Name': '연서',
        'Date of Birth': '26/09/2004',
        Group: 'We;Na',
        Debut: '5/09/2022',
        Company: 'Shine E&M',
        Country: 'South Korea',
        'Second Country': '',
        Height: 158,
        Weight: 45,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeontae',
        'Full Name': 'Jeong Yeontae',
        'Korean Name': '정연태',
        'K Stage Name': '연태',
        'Date of Birth': '6/07/1992',
        Group: 'IN2IT',
        Debut: '27/01/2016',
        Company: 'Star Empire',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 58,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yeonwoo',
        'Full Name': 'Lee Dabin',
        'Korean Name': '이다빈',
        'K Stage Name': '연우',
        'Date of Birth': '1/08/1996',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 49,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'MOMOLAND',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeoreum',
        'Full Name': 'Ahn Chaeyeon',
        'Korean Name': '안채연',
        'K Stage Name': '여름',
        'Date of Birth': '4/06/1996',
        Group: 'Hello Venus',
        Debut: '9/05/2012',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 48,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeoreum',
        'Full Name': 'Lee Jinsook',
        'Korean Name': '이진숙',
        'K Stage Name': '여름',
        'Date of Birth': '10/01/1999',
        Group: 'WJSN',
        Debut: '25/02/2016',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': 'WJSN Chocome',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeori',
        'Full Name': 'Choi Yeori',
        'Korean Name': '최여리',
        'K Stage Name': '여리',
        'Date of Birth': '8/09/1999',
        Group: 'ARIAZ',
        Debut: '23/10/2019',
        Company: 'Rising Star',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeori',
        'Full Name': 'Kim Soyeon',
        'Korean Name': '김소연',
        'K Stage Name': '여리',
        'Date of Birth': '30/05/1994',
        Group: 'Gate9',
        Debut: '26/01/1999',
        Company: 'JYP| SidusHQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeosang',
        'Full Name': 'Kang Yeosang',
        'Korean Name': '강여상',
        'K Stage Name': '여상',
        'Date of Birth': '15/06/1999',
        Group: 'ATEEZ',
        Debut: '24/10/2018',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yeoun',
        'Full Name': 'Lee Kyeongbin',
        'Korean Name': '이경빈',
        'K Stage Name': '여운',
        'Date of Birth': '3/09/1997',
        Group: 'ARGON',
        Debut: '11/03/2019',
        Company: 'MSH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yeowool',
        'Full Name': 'Ha Yeowool',
        'Korean Name': '하여울',
        'K Stage Name': '여울',
        'Date of Birth': '11/04/1998',
        Group: 'Purplebeck',
        Debut: '24/06/2019',
        Company: 'Majesty',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: 46,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeram',
        'Full Name': 'Yang Yeram',
        'Korean Name': '양예람',
        'K Stage Name': '예람',
        'Date of Birth': '31/03/2000',
        Group: 'Astin',
        Debut: '16/11/2022',
        Company: 'AB Creative',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yeri',
        'Full Name': 'Kim Yerim',
        'Korean Name': '김예림',
        'K Stage Name': '예리',
        'Date of Birth': '5/03/1999',
        Group: 'Red Velvet',
        Debut: '1/08/2014',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 158,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yerim',
        'Full Name': 'Jeong Yerim',
        'Korean Name': '정예림',
        'K Stage Name': '예림',
        'Date of Birth': '10/03/1997',
        Group: 'Purplebeck',
        Debut: '24/06/2019',
        Company: 'Majesty',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: 44,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yerin',
        'Full Name': 'Baek Yerin',
        'Korean Name': '백예린',
        'K Stage Name': '백예린',
        'Date of Birth': '26/06/1997',
        Group: '15&',
        Debut: '5/10/2012',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: 'Daejeon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yerin',
        'Full Name': 'Jung Yerin',
        'Korean Name': '정예린',
        'K Stage Name': '예린',
        'Date of Birth': '19/08/1996',
        Group: 'Gfriend',
        Debut: '15/01/2015',
        Company: 'Source',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeryung',
        'Full Name': '',
        'Korean Name': '',
        'K Stage Name': '예령',
        'Date of Birth': '22/06/2002',
        Group: 'Lusty',
        Debut: '26/06/2019',
        Company: 'babaplay',
        Country: 'South Korea',
        'Second Country': '',
        Height: 169,
        Weight: 49,
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeseo',
        'Full Name': 'Kang Yeseo',
        'Korean Name': '강예서',
        'K Stage Name': '예서',
        'Date of Birth': '22/08/2005',
        Group: 'Kep1er',
        Debut: '1/03/2022',
        Company: 'WakeOne',
        Country: 'South Korea',
        'Second Country': '',
        Height: 157,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Busters',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeseul',
        'Full Name': 'Kim Yeseul',
        'Korean Name': '김예슬',
        'K Stage Name': '예슬',
        'Date of Birth': '24/03/2004',
        Group: 'Hi-L',
        Debut: '11/08/2021',
        Company: 'Kpop Live',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yeso',
        'Full Name': 'Lee Yeso',
        'Korean Name': '이예소',
        'K Stage Name': '예소',
        'Date of Birth': '13/11/2001',
        Group: 'G-reyish',
        Debut: '1/06/2017',
        Company: 'Elijah',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yesung',
        'Full Name': 'Kim Jongwoon',
        'Korean Name': '김종운',
        'K Stage Name': '예성',
        'Date of Birth': '24/08/1984',
        Group: 'Super Junior',
        Debut: '6/11/2005',
        Company: 'SM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 64,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yeum',
        'Full Name': 'Jung Myeongwoo',
        'Korean Name': '정명우',
        'K Stage Name': '예음',
        'Date of Birth': '19/11/2001',
        Group: 'PRIMROSE',
        Debut: '13/03/2023',
        Company: 'AO',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yewang',
        'Full Name': 'Seo Yewang',
        'Korean Name': '서예왕',
        'K Stage Name': '예왕',
        'Date of Birth': '12/03/2005',
        Group: 'EPEX',
        Debut: '8/06/2021',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 64,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yewon',
        'Full Name': 'Park Yewon',
        'Korean Name': '박예원',
        'K Stage Name': '예원',
        'Date of Birth': '11/01/2004',
        Group: 'HOT ISSUE',
        Debut: '17/05/2017',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yewon',
        'Full Name': 'Han Yewon',
        'Korean Name': '한예원',
        'K Stage Name': '예원',
        'Date of Birth': '7/02/2003',
        Group: 'mimiirose',
        Debut: '16/09/2022',
        Company: 'YES IM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yezi',
        'Full Name': 'Lee Yeji',
        'Korean Name': '이예지',
        'K Stage Name': '예지',
        'Date of Birth': '26/08/1994',
        Group: 'Fiestar',
        Debut: '31/08/2012',
        Company: 'LOEN',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: '',
        Birthplace: 'Gangneung',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yibo',
        'Full Name': 'Wang Yibo',
        'Korean Name': '왕이보',
        'K Stage Name': '이보',
        'Date of Birth': '5/08/1997',
        Group: 'UNIQ',
        Debut: '20/10/2014',
        Company: 'Yuehua',
        Country: 'China',
        'Second Country': '',
        Height: 182,
        Weight: 65,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yijeong',
        'Full Name': 'Jang Yijeong',
        'Korean Name': '장이정',
        'K Stage Name': '이정',
        'Date of Birth': '10/09/1993',
        Group: 'HISTORY',
        Debut: '26/04/2013',
        Company: 'LOEN',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: 58,
        Birthplace: 'Jinju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yiren',
        'Full Name': 'Wang Yiren',
        'Korean Name': '왕이런',
        'K Stage Name': '이런',
        'Date of Birth': '29/12/2000',
        Group: 'Everglow',
        Debut: '18/03/2019',
        Company: 'Yuehua',
        Country: 'China',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yixuan',
        'Full Name': 'Zhou Yixuan',
        'Korean Name': '조이쉔',
        'K Stage Name': '이쉔',
        'Date of Birth': '11/12/1990',
        Group: 'UNIQ',
        Debut: '20/10/2014',
        Company: 'Yuehua',
        Country: 'China',
        'Second Country': '',
        Height: 183,
        Weight: 65,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yiyeon',
        'Full Name': 'Jung Dasol',
        'Korean Name': '정다솔',
        'K Stage Name': '이연',
        'Date of Birth': '28/05/1995',
        Group: 'BVNDIT',
        Debut: '10/04/2019',
        Company: 'MNH',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yoel',
        'Full Name': 'Kim Yoel',
        'Korean Name': '킴요엘',
        'K Stage Name': '요엘',
        'Date of Birth': '25/01/2001',
        Group: 'AIMERS',
        Debut: '17/11/2022',
        Company: 'Hyper Rhythm',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yohan',
        'Full Name': 'Kim Yohan',
        'Korean Name': '김요한',
        'K Stage Name': '요한',
        'Date of Birth': '22/09/1999',
        Group: 'WEi',
        Debut: '5/10/2020',
        Company: 'OUI',
        Country: 'South Korea',
        'Second Country': '',
        Height: 181,
        Weight: 66,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'X1',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yonggeun',
        'Full Name': 'Jo Yonggeun',
        'Korean Name': '조용근',
        'K Stage Name': '용근',
        'Date of Birth': '23/01/1995',
        Group: 'D1CE',
        Debut: '1/08/2019',
        Company: 'Happyface',
        Country: 'South Korea',
        'Second Country': '',
        Height: 174,
        Weight: 54,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yongguk',
        'Full Name': 'Bang Yongguk',
        'Korean Name': '방용국',
        'K Stage Name': '용국',
        'Date of Birth': '31/03/1990',
        Group: 'B.A.P',
        Debut: '27/01/2012',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 182,
        Weight: 60,
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yongha',
        'Full Name': 'Yoo Yongha',
        'Korean Name': '유용하',
        'K Stage Name': '용하',
        'Date of Birth': '11/01/1999',
        Group: 'WEi',
        Debut: '5/10/2020',
        Company: 'OUI',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 64,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '1the9',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yonghee',
        'Full Name': 'Kim Yonghee',
        'Korean Name': '김용희',
        'K Stage Name': '용희',
        'Date of Birth': '17/02/2000',
        Group: 'CIX',
        Debut: '23/07/2019',
        Company: 'C9',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 59,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yonghoon',
        'Full Name': 'Jin Yonghoon',
        'Korean Name': '진용훈',
        'K Stage Name': '용훈',
        'Date of Birth': '17/08/1994',
        Group: 'Onewe',
        Debut: '9/01/2019',
        Company: 'RBW',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'MAS',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yonghwa',
        'Full Name': 'Jung Yonghwa',
        'Korean Name': '정용화',
        'K Stage Name': '용화',
        'Date of Birth': '22/06/1989',
        Group: 'CNBLUE',
        Debut: '14/01/2010',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yongseok',
        'Full Name': 'Choi Yongseok',
        'Korean Name': '최용석',
        'K Stage Name': '용석',
        'Date of Birth': '23/05/1996',
        Group: 'CHECKMATE',
        Debut: '19/06/2012',
        Company: 'Yedang',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yongseok',
        'Full Name': 'Kim Yongseok',
        'Korean Name': '김용석',
        'K Stage Name': '용석',
        'Date of Birth': '8/01/1993',
        Group: 'CROSS GENE',
        Debut: '8/07/2012',
        Company: 'Amuse',
        Country: 'South Korea',
        'Second Country': '',
        Height: 176,
        Weight: 64,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yongseung',
        'Full Name': 'Kim Yongseung',
        'Korean Name': '김용승',
        'K Stage Name': '용승',
        'Date of Birth': '17/06/2000',
        Group: 'Verivery',
        Debut: '1/09/2019',
        Company: 'Jellyfish',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yooa',
        'Full Name': 'Yoo Siah',
        'Korean Name': '유시아',
        'K Stage Name': '유아',
        'Date of Birth': '17/09/1995',
        Group: 'Oh My Girl',
        Debut: '21/04/2015',
        Company: 'WM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 50,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yoochun',
        'Full Name': 'Park Yoochun',
        'Korean Name': '박유천',
        'K Stage Name': '유천',
        'Date of Birth': '4/07/1986',
        Group: 'JYJ',
        Debut: '8/09/2010',
        Company: 'C-JeS',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'TVXQ',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yoohyeon',
        'Full Name': 'Kim Yoohyeon',
        'Korean Name': '김유현',
        'K Stage Name': '유현',
        'Date of Birth': '7/01/1997',
        Group: 'Dreamcatcher',
        Debut: '13/01/2017',
        Company: 'Happy Face',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': 'Minx',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yoojin',
        'Full Name': 'Jang Yoojin',
        'Korean Name': '정유진',
        'K Stage Name': '유진',
        'Date of Birth': '14/09/1992',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Brave Girls',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yoojin',
        'Full Name': 'Ahn Yoojin',
        'Korean Name': '안유진',
        'K Stage Name': '유진',
        'Date of Birth': '29/02/2000',
        Group: 'ARTBEAT',
        Debut: '16/11/2022',
        Company: 'AB Creative',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yoojun',
        'Full Name': 'Jung Gun',
        'Korean Name': '정건',
        'K Stage Name': '유준',
        'Date of Birth': '27/12/2000',
        Group: 'BAE173',
        Debut: '19/11/2020',
        Company: 'PocketDol',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yoojun',
        'Full Name': 'Jun Yoojun',
        'Korean Name': '정유준',
        'K Stage Name': '유준',
        'Date of Birth': '26/07/1995',
        Group: 'D1CE',
        Debut: '1/08/2019',
        Company: 'Happyface',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yoojung',
        'Full Name': 'Lee Taeyeop',
        'Korean Name': '이태엽',
        'K Stage Name': '유정',
        'Date of Birth': '19/05/1997',
        Group: 'OnlyOneOf',
        Debut: '28/05/2019',
        Company: 'RSVP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yoojung',
        'Full Name': 'Choi Yoojung',
        'Korean Name': '최유정',
        'K Stage Name': '유정',
        'Date of Birth': '12/11/1999',
        Group: 'Weki Meki',
        Debut: '8/08/2017',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Guri',
        'Other Group': 'I.O.I| WJMK',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yookyung',
        'Full Name': 'Seo Yookyung',
        'Korean Name': '서유경',
        'K Stage Name': '유경',
        'Date of Birth': '15/03/1993',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 48,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'AOA BLACK',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yoomin',
        'Full Name': 'Na Yoomin',
        'Korean Name': '나유민',
        'K Stage Name': '유민',
        'Date of Birth': '29/08/1993',
        Group: 'MELODYDAY',
        Debut: '25/09/2019',
        Company: 'KD',
        Country: 'South Korea',
        'Second Country': '',
        Height: 165,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yoon',
        'Full Name': 'Heo Yoon',
        'Korean Name': '허윤',
        'K Stage Name': '윤',
        'Date of Birth': '23/09/1994',
        Group: '4TEN',
        Debut: '26/08/2014',
        Company: 'Jungle',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yoon',
        'Full Name': 'Ji Hayoon',
        'Korean Name': '지하윤',
        'K Stage Name': '윤',
        'Date of Birth': '8/07/1997',
        Group: 'Gate9',
        Debut: '26/01/1999',
        Company: 'JYP| SidusHQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yoon',
        'Full Name': 'Shim Jayoon',
        'Korean Name': '심자윤',
        'K Stage Name': '윤',
        'Date of Birth': '14/04/2004',
        Group: 'STAYC',
        Debut: '12/11/2020',
        Company: 'Hi-Up',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: 49,
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yoon',
        'Full Name': 'Kang Seungyoon',
        'Korean Name': '강승윤',
        'K Stage Name': '승윤',
        'Date of Birth': '21/01/1994',
        Group: 'WINNER',
        Debut: '12/08/2014',
        Company: 'YG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yoona',
        'Full Name': 'Ogura Yuuna',
        'Korean Name': '오구라 유우나',
        'K Stage Name': '유우나',
        'Date of Birth': '13/10/2000',
        Group: 'bugAboo',
        Debut: '25/10/2021',
        Company: 'A team',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Sapporo',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yoona',
        'Full Name': 'Im Yoona',
        'Korean Name': '임윤아',
        'K Stage Name': '윤아',
        'Date of Birth': '30/05/1990',
        Group: 'SNSD',
        Debut: '4/08/2021',
        Company: 'Good Luck',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 47,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yoonbin',
        'Full Name': 'Ha Yoonbin',
        'Korean Name': '하윤빈',
        'K Stage Name': '윤빈',
        'Date of Birth': '11/12/2000',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yoonhye',
        'Full Name': 'Jung Yoonhye',
        'Korean Name': '정윤혜',
        'K Stage Name': '윤혜',
        'Date of Birth': '4/10/2001',
        Group: 'FANATICS',
        Debut: '6/08/2019',
        Company: 'FENT',
        Country: 'South Korea',
        'Second Country': '',
        Height: 173,
        Weight: '',
        Birthplace: '',
        'Other Group': 'FANATICS FLAVOR',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yoonhye',
        'Full Name': 'Jung Yoonhye',
        'Korean Name': '정윤혜',
        'K Stage Name': '윤혜',
        'Date of Birth': '14/04/1990',
        Group: 'Rainbow',
        Debut: '12/11/2009',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 47,
        Birthplace: 'Daejeon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yoonji',
        'Full Name': '',
        'Korean Name': '',
        'K Stage Name': '윤지',
        'Date of Birth': '21/07/1998',
        Group: 'Lusty',
        Debut: '26/06/2019',
        Company: 'babaplay',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: 45,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yoonjo',
        'Full Name': 'Shin Yoonjo',
        'Korean Name': '신윤조',
        'K Stage Name': '윤조',
        'Date of Birth': '14/12/1992',
        Group: 'UNI.T',
        Debut: '18/05/2018',
        Company: 'Unit Culture',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'Hello Venus',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yoonsan',
        'Full Name': 'Yoon Sanghyuk',
        'Korean Name': '윤상혁',
        'K Stage Name': '윤산',
        'Date of Birth': '22/08/1994',
        Group: 'HOTSHOT',
        Debut: '29/10/2014',
        Company: 'Star Crew',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yoonsung',
        'Full Name': 'Cho Yoonsung',
        'Korean Name': '조윤성',
        'K Stage Name': '윤성',
        'Date of Birth': '13/11/2003',
        Group: '8TURN',
        Debut: '30/01/2023',
        Company: 'MNH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 177,
        Weight: 62,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yoonwoo',
        'Full Name': 'Lee Choonghyun',
        'Korean Name': '이충현',
        'K Stage Name': '윤우',
        'Date of Birth': '28/08/2000',
        Group: 'TRENDZ',
        Debut: '5/01/2022',
        Company: 'Interpark',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yoosung',
        'Full Name': 'Jeong Yoosung',
        'Korean Name': '정유성',
        'K Stage Name': '유성',
        'Date of Birth': '8/10/1992',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 52,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Bigflo',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yooyeon',
        'Full Name': 'Kim Yooyeon',
        'Korean Name': '김유연',
        'K Stage Name': '유연',
        'Date of Birth': '9/02/2001',
        Group: 'tripleS',
        Debut: '13/02/2023',
        Company: 'MODHAUS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: 46,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yooyoung',
        'Full Name': 'Lee Yooyoung',
        'Korean Name': '이유영',
        'K Stage Name': '유영',
        'Date of Birth': '23/01/1995',
        Group: 'Hello Venus',
        Debut: '9/05/2012',
        Company: 'Fantagio',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 49,
        Birthplace: 'Suwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yoseob',
        'Full Name': 'Yang Yoseob',
        'Korean Name': '양요섭',
        'K Stage Name': '요섭',
        'Date of Birth': '5/01/1990',
        Group: 'Highlight',
        Debut: '14/10/2009',
        Company: 'Around Us',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: 56,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'Beast',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yoshi',
        'Full Name': 'Kanemoto Yoshinori',
        'Korean Name': '카네모토 요시노리',
        'K Stage Name': '요시',
        'Date of Birth': '15/05/2000',
        Group: 'Treasure',
        Debut: '7/08/2020',
        Company: 'YG',
        Country: 'Japan',
        'Second Country': '',
        Height: 179,
        Weight: 59,
        Birthplace: 'Kobe',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'You',
        'Full Name': 'Kim Taeyou',
        'Korean Name': '김태유',
        'K Stage Name': '유',
        'Date of Birth': '17/11/1999',
        Group: 'ABLUE',
        Debut: '23/10/2022',
        Company: 'J-Star',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 70,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'BECZ',
        Gender: 'M'
    },
    {
        'Stage Name': 'Youi',
        'Full Name': 'Kim Jihyeo',
        'Korean Name': '유아이',
        'K Stage Name': '유아이',
        'Date of Birth': '24/04/2000',
        Group: 'Dreamnote',
        Debut: '7/11/2018',
        Company: 'iME',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: 45,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Young K',
        'Full Name': 'Kang Young-hyun',
        'Korean Name': '강영현',
        'K Stage Name': '영케이',
        'Date of Birth': '19/12/1993',
        Group: 'DAY6',
        Debut: '7/09/2015',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': 'Canada',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Youngbin',
        'Full Name': 'Lee Youngbin',
        'Korean Name': '이영빈',
        'K Stage Name': '영빈',
        'Date of Birth': '23/11/2001',
        Group: 'BLANK2Y',
        Debut: '24/05/2022',
        Company: 'Keystone',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Youngbin',
        'Full Name': 'Jeong Youngbin',
        'Korean Name': '정영빈',
        'K Stage Name': '영빈',
        'Date of Birth': '5/10/1998',
        Group: 'Luminous',
        Debut: '9/09/2021',
        Company: 'Barunson',
        Country: 'South Korea',
        'Second Country': '',
        Height: 172,
        Weight: 55,
        Birthplace: 'Seongju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Youngbin',
        'Full Name': 'Kim Youngbin',
        'Korean Name': '김영빈',
        'K Stage Name': '영빈',
        'Date of Birth': '23/11/1993',
        Group: 'SF9',
        Debut: '5/10/2016',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Youngeun',
        'Full Name': 'Seo Youngeun',
        'Korean Name': '서영은',
        'K Stage Name': '영은',
        'Date of Birth': '27/12/2004',
        Group: 'Kep1er',
        Debut: '1/03/2022',
        Company: 'WakeOne',
        Country: 'South Korea',
        'Second Country': '',
        Height: 159,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Youngheun',
        'Full Name': 'Go Youngheun',
        'Korean Name': '고영흔',
        'K Stage Name': '영흔',
        'Date of Birth': '20/11/1994',
        Group: 'Blackswan',
        Debut: '16/10/2020',
        Company: 'DR',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Youngho',
        'Full Name': 'Noh Youngho',
        'Korean Name': '노영호',
        'K Stage Name': '영호',
        'Date of Birth': '24/03/1993',
        Group: 'SIGNAL',
        Debut: '28/08/2019',
        Company: 'J-Star',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Younghoon',
        'Full Name': 'Kim Younghoon',
        'Korean Name': '김영훈',
        'K Stage Name': '영훈',
        'Date of Birth': '8/08/1997',
        Group: 'The Boyz',
        Debut: '31/12/2017',
        Company: 'Cre.Ker',
        Country: 'South Korea',
        'Second Country': '',
        Height: 182,
        Weight: 63,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Youngjae',
        'Full Name': 'Yoo Youngjae',
        'Korean Name': '유영재',
        'K Stage Name': '영재',
        'Date of Birth': '24/01/1994',
        Group: 'B.A.P',
        Debut: '27/01/2012',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 178,
        Weight: 65,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Youngjae',
        'Full Name': 'Choi Youngjae',
        'Korean Name': '최영재',
        'K Stage Name': '영재',
        'Date of Birth': '17/09/1996',
        Group: 'GOT7',
        Debut: '16/01/2014',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 59,
        Birthplace: 'Mokpo',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Youngji',
        'Full Name': 'Hur Youngji',
        'Korean Name': '허영지',
        'K Stage Name': '영지',
        'Date of Birth': '30/08/1994',
        Group: 'KARA',
        Debut: '29/03/2007',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Goyang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Youngmin',
        'Full Name': 'Lim Youngmin',
        'Korean Name': '임영민',
        'K Stage Name': '영민',
        'Date of Birth': '25/12/1995',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': 'MXM',
        'Former Group': 'AB6IX',
        Gender: 'M'
    },
    {
        'Stage Name': 'Youngmin',
        'Full Name': 'Jo Youngmin',
        'Korean Name': '조영민',
        'K Stage Name': '영민',
        'Date of Birth': '24/04/1995',
        Group: 'Boyfriend',
        Debut: '26/05/2011',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: '',
        Birthplace: 'Anyang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Youngseo',
        'Full Name': 'Ryu Youngseo',
        'Korean Name': '류영서',
        'K Stage Name': '영서',
        'Date of Birth': '13/06/2002',
        Group: 'BAE173',
        Debut: '19/11/2020',
        Company: 'PocketDol',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Youngwoong',
        'Full Name': 'Cho Youngwoon',
        'Korean Name': '조영웅',
        'K Stage Name': '영웅',
        'Date of Birth': '1/05/1999',
        Group: '14U',
        Debut: '17/04/2017',
        Company: 'BG',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yua',
        'Full Name': 'Mikami Yua',
        'Korean Name': '미카미 유아',
        'K Stage Name': '유아',
        'Date of Birth': '16/08/1993',
        Group: 'Honey Popcorn',
        Debut: '21/03/2018',
        Company: 'Kyun Create',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yubeen',
        'Full Name': 'Jin Yubeen',
        'Korean Name': '진유빈',
        'K Stage Name': '유빈',
        'Date of Birth': '5/09/2006',
        Group: 'TRACER',
        Debut: '3/04/2022',
        Company: 'Gleamedia',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yubin',
        'Full Name': 'Cho Yubin',
        'Korean Name': '조유빈',
        'K Stage Name': '유빈',
        'Date of Birth': '9/10/1999',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 156,
        Weight: 40,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Pink Fantasy',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yubin',
        'Full Name': 'Oh Yubin',
        'Korean Name': '오유빈',
        'K Stage Name': '유빈',
        'Date of Birth': '26/11/1998',
        Group: 'ARTBEAT',
        Debut: '16/11/2022',
        Company: 'AB Creative',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: 47,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yubin',
        'Full Name': 'Kim Yubin',
        'Korean Name': '김유빈',
        'K Stage Name': '유빈',
        'Date of Birth': '27/02/2002',
        Group: 'Bling Bling',
        Debut: '17/11/2020',
        Company: 'Major9',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 47,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yubin',
        'Full Name': 'Jang Yubin',
        'Korean Name': '장유빈',
        'K Stage Name': '유빈',
        'Date of Birth': '10/06/2004',
        Group: 'Mirae',
        Debut: '17/03/2021',
        Company: 'DSP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yubin',
        'Full Name': 'Bae Yubin',
        'Korean Name': '배유빈',
        'K Stage Name': '유빈',
        'Date of Birth': '9/09/1997',
        Group: 'Oh My Girl',
        Debut: '21/04/2015',
        Company: 'WM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: 49,
        Birthplace: 'Chuncheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yubin',
        'Full Name': 'Gong Yubin',
        'Korean Name': '공유빈',
        'K Stage Name': '유빈',
        'Date of Birth': '3/02/2005',
        Group: 'tripleS',
        Debut: '13/02/2023',
        Company: 'MODHAUS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: 'Yongin',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yubin',
        'Full Name': 'Kim Yubin',
        'Korean Name': '김유빈',
        'K Stage Name': '유빈',
        'Date of Birth': '4/10/1988',
        Group: 'Wonder Girls',
        Debut: '13/02/2007',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yue',
        'Full Name': 'Nancy Yang',
        'Korean Name': '낸시 양',
        'K Stage Name': '유에',
        'Date of Birth': '3/07/2004',
        Group: 'Lapillus',
        Debut: '22/06/2022',
        Company: 'MLD',
        Country: 'USA',
        'Second Country': 'China',
        Height: 163,
        Weight: 48,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yuem',
        'Full Name': 'Park Subin',
        'Korean Name': '박수빈',
        'K Stage Name': '유음',
        'Date of Birth': '25/12/1996',
        Group: 'H.U.B',
        Debut: '9/02/2017',
        Company: 'New Planet',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yugyeom',
        'Full Name': 'Kim Yugyeom',
        'Korean Name': '김유겸',
        'K Stage Name': '유겸',
        'Date of Birth': '17/11/1997',
        Group: 'GOT7',
        Debut: '16/01/2014',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: 180,
        Weight: 64,
        Birthplace: 'Namyangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yuha',
        'Full Name': 'Kang Kyungwon',
        'Korean Name': '강경원',
        'K Stage Name': '유하',
        'Date of Birth': '5/11/1997',
        Group: 'Hinapia',
        Debut: '3/11/2019',
        Company: 'Alseulbit',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: 50,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Pristin',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yujeong',
        'Full Name': 'Kim Yujeong',
        'Korean Name': '김유정',
        'K Stage Name': '유정',
        'Date of Birth': '14/02/1992',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: 42,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'LABOUM',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yujeong',
        'Full Name': 'Nam Yujeong',
        'Korean Name': '남유정',
        'K Stage Name': '유정',
        'Date of Birth': '2/05/1991',
        Group: 'Brave Girls',
        Debut: '8/04/2011',
        Company: 'Brave',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yujeong',
        'Full Name': 'Lee Yujeong',
        'Korean Name': '이유정',
        'K Stage Name': '유정',
        'Date of Birth': '14/06/2004',
        Group: 'LIGHTSUM',
        Debut: '10/06/2021',
        Company: 'Cube',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 41,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yuji',
        'Full Name': 'Son Yuji',
        'Korean Name': '손유지',
        'K Stage Name': '유지',
        'Date of Birth': '25/11/1998',
        Group: '3YE',
        Debut: '21/05/2019',
        Company: 'GH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 160,
        Weight: 45,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Apple.B',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yujin',
        'Full Name': 'Ahn Yujin',
        'Korean Name': '안유진',
        'K Stage Name': '유진',
        'Date of Birth': '1/09/2003',
        Group: 'IVE',
        Debut: '1/12/2021',
        Company: 'Starship',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Cheongju',
        'Other Group': '',
        'Former Group': 'IZ*ONE',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yujin',
        'Full Name': 'Choi Yujin',
        'Korean Name': '최유진',
        'K Stage Name': '유진',
        'Date of Birth': '12/08/1996',
        Group: 'Kep1er',
        Debut: '1/03/2022',
        Company: 'WakeOne',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: '',
        Birthplace: 'Jeonju',
        'Other Group': '',
        'Former Group': 'CLC',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yujin',
        'Full Name': 'Seo Yoojin',
        'Korean Name': '소유진',
        'K Stage Name': '유진',
        'Date of Birth': '18/06/1994',
        Group: 'Playback',
        Debut: '25/06/2015',
        Company: 'Coridel',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yujin',
        'Full Name': 'Jeong Yujin',
        'Korean Name': '정유진',
        'K Stage Name': '유진',
        'Date of Birth': '23/10/1989',
        Group: 'S#aFLA',
        Debut: '14/07/2021',
        Company: 'Winnerzone',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'D-Unit',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yuju',
        'Full Name': 'Choi Yuju',
        'Korean Name': '최유주',
        'K Stage Name': '유주',
        'Date of Birth': '5/03/1997',
        Group: 'Cherry Bullet',
        Debut: '21/01/2019',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yuju',
        'Full Name': 'Choi Yuna',
        'Korean Name': '최유나',
        'K Stage Name': '유주',
        'Date of Birth': '4/10/1997',
        Group: 'Gfriend',
        Debut: '15/01/2015',
        Company: 'Source',
        Country: 'South Korea',
        'Second Country': '',
        Height: 169,
        Weight: '',
        Birthplace: 'Goyang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yujun',
        'Full Name': 'Jung Yujun',
        'Korean Name': '정유준',
        'K Stage Name': '유준',
        'Date of Birth': '5/10/2005',
        Group: 'xikers',
        Debut: '30/03/2023',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Incheon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yuki',
        'Full Name': 'Mori Koyuki',
        'Korean Name': '모리 코유키',
        'K Stage Name': '유키',
        'Date of Birth': '6/11/2002',
        Group: 'Purple Kiss',
        Debut: '15/03/2021',
        Company: 'RBW',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yuki',
        'Full Name': 'Lim Jiyeon',
        'Korean Name': '임지연',
        'K Stage Name': '유키',
        'Date of Birth': '30/01/2002',
        Group: 'Saturday',
        Debut: '18/07/2018',
        Company: 'SD',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: 44,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yuku',
        'Full Name': 'Amanumu Yuku',
        'Korean Name': '아마누마 유쿠',
        'K Stage Name': '유쿠',
        'Date of Birth': '12/05/2002',
        Group: 'DKB',
        Debut: '3/02/2020',
        Company: 'Brave',
        Country: 'Japan',
        'Second Country': '',
        Height: 176,
        Weight: 55,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yukyung',
        'Full Name': 'Lee Yukyung',
        'Korean Name': '이유경',
        'K Stage Name': '유경',
        'Date of Birth': '5/11/1999',
        Group: 'ALICE',
        Debut: '1/07/2017',
        Company: 'IOK Company',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yulhee',
        'Full Name': 'Kim Yulhee',
        'Korean Name': '김율희',
        'K Stage Name': '율희',
        'Date of Birth': '27/11/1997',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 166,
        Weight: 52,
        Birthplace: 'Bucheon',
        'Other Group': '',
        'Former Group': 'LABOUM',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yuna',
        'Full Name': 'Seo Yuna',
        'Korean Name': '서유나',
        'K Stage Name': '유나',
        'Date of Birth': '30/12/1992',
        Group: 'AoA',
        Debut: '30/07/2012',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: 163,
        Weight: 45,
        Birthplace: 'Busan',
        'Other Group': 'AOA BLACK',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yuna',
        'Full Name': 'Lee Yuna',
        'Korean Name': '이유나',
        'K Stage Name': '유나',
        'Date of Birth': '6/04/1993',
        Group: 'Brave Girls',
        Debut: '8/04/2011',
        Company: 'Brave',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yuna',
        'Full Name': 'Iimura Yuna',
        'Korean Name': '이이무라 유나',
        'K Stage Name': '유나',
        'Date of Birth': '23/04/2005',
        Group: 'CSR',
        Debut: '27/07/2022',
        Company: 'A2Z',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Ibaraki',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yuna',
        'Full Name': 'Shin Yuna',
        'Korean Name': '신유나',
        'K Stage Name': '유나',
        'Date of Birth': '9/12/2003',
        Group: 'ITZY',
        Debut: '12/02/2019',
        Company: 'JYP',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Suwon',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yungyu',
        'Full Name': 'Lee Yungyu',
        'Korean Name': '이윤규',
        'K Stage Name': '윤규',
        'Date of Birth': '7/11/2005',
        Group: '8TURN',
        Debut: '30/01/2023',
        Company: 'MNH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 175,
        Weight: 62,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yunho',
        'Full Name': 'Jeong Yunho',
        'Korean Name': '정윤호',
        'K Stage Name': '윤호',
        'Date of Birth': '23/03/1999',
        Group: 'ATEEZ',
        Debut: '24/10/2018',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 184,
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yunji',
        'Full Name': 'Kim Yunji',
        'Korean Name': '김윤지',
        'K Stage Name': '윤지',
        'Date of Birth': '26/08/1996',
        Group: 'ARIAZ',
        Debut: '23/10/2019',
        Company: 'Rising Star',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yunji',
        'Full Name': 'Lee Yunji',
        'Korean Name': '이윤지',
        'K Stage Name': '윤지',
        'Date of Birth': '4/04/1992',
        Group: 'Playback',
        Debut: '25/06/2015',
        Company: 'Coridel',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yunjin',
        'Full Name': 'Huh Yunjin',
        'Korean Name': '허윤진',
        'K Stage Name': '윤진',
        'Date of Birth': '8/10/2001',
        Group: 'LE SSERAFIM',
        Debut: '2/05/2022',
        Company: 'Source',
        Country: 'South Korea',
        'Second Country': '',
        Height: 171,
        Weight: 53,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yunju',
        'Full Name': 'Seo Yunju',
        'Korean Name': '서윤주',
        'K Stage Name': '윤주',
        'Date of Birth': '26/12/2005',
        Group: 'mimiirose',
        Debut: '16/09/2022',
        Company: 'YES IM',
        Country: 'South Korea',
        'Second Country': '',
        Height: 162,
        Weight: '',
        Birthplace: 'Pohang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yunkyung',
        'Full Name': 'Seo Yunkyung',
        'Korean Name': '서윤경',
        'K Stage Name': '윤경',
        'Date of Birth': '1/11/2001',
        Group: 'Rocket Punch',
        Debut: '7/08/2019',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': '',
        Height: 161,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yunmin',
        'Full Name': 'Yun Min',
        'Korean Name': '윤민',
        'K Stage Name': '윤민',
        'Date of Birth': '26/02/2001',
        Group: 'Newkidd',
        Debut: '25/04/2019',
        Company: 'J-FLO',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yunseong',
        'Full Name': 'Hwang Yunseong',
        'Korean Name': '황윤성',
        'K Stage Name': '윤성',
        'Date of Birth': '30/10/2000',
        Group: 'DRIPPIN',
        Debut: '26/10/2020',
        Company: 'Woollim',
        Country: 'South Korea',
        'Second Country': 'Seoul',
        Height: 181,
        Weight: 60,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yunseul',
        'Full Name': 'Hwang Nayoon',
        'Korean Name': '황나윤',
        'K Stage Name': '윤슬',
        'Date of Birth': '30/03/1999',
        Group: 'IRRIS',
        Debut: '6/07/2022',
        Company: 'Mellow',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': 'Good Day| RedSquare',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yunsung',
        'Full Name': 'Hwang Yunsung',
        'Korean Name': '황윤성',
        'K Stage Name': '윤성',
        'Date of Birth': '19/03/1996',
        Group: 'Romeo',
        Debut: '4/11/2015',
        Company: 'CT',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yuqi',
        'Full Name': 'Song Yuqi',
        'Korean Name': '송우기',
        'K Stage Name': '우기',
        'Date of Birth': '23/09/1999',
        Group: '(G)I-DLE',
        Debut: '2/05/2018',
        Company: 'Cube',
        Country: 'China',
        'Second Country': '',
        Height: 162,
        Weight: 48,
        Birthplace: 'Beijing',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yura',
        'Full Name': 'Kim Ahyoung',
        'Korean Name': '김아영',
        'K Stage Name': '유라',
        'Date of Birth': '6/11/1992',
        Group: "Girl's Day",
        Debut: '9/07/2010',
        Company: 'DreamT',
        Country: 'South Korea',
        'Second Country': '',
        Height: 168,
        Weight: 49,
        Birthplace: 'Ulsan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yuri',
        'Full Name': 'Jo Yuri',
        'Korean Name': '조유리',
        'K Stage Name': '유리',
        'Date of Birth': '22/10/2001',
        Group: 'IZ*ONE',
        Debut: '29/10/2018',
        Company: 'Off The Record',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Busan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yuri',
        'Full Name': 'Kwon Yuri',
        'Korean Name': '권유리',
        'K Stage Name': '유리',
        'Date of Birth': '5/12/1989',
        Group: 'SNSD',
        Debut: '4/08/2021',
        Company: 'Good Luck',
        Country: 'South Korea',
        'Second Country': '',
        Height: 167,
        Weight: 45,
        Birthplace: 'Goyang',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yurim',
        'Full Name': 'Park Yurim',
        'Korean Name': '박유림',
        'K Stage Name': '유림',
        'Date of Birth': '13/04/1998',
        Group: '3YE',
        Debut: '21/05/2019',
        Company: 'GH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 164,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Apple.B',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yuta',
        'Full Name': 'Nakamoto Yuta',
        'Korean Name': '나카모토 유타',
        'K Stage Name': '유타',
        'Date of Birth': '26/10/1995',
        Group: 'NCT',
        Debut: '9/04/2016',
        Company: 'SM',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Osaka',
        'Other Group': 'NCT 127',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yuto',
        'Full Name': 'Adachi Yuto',
        'Korean Name': '아다치 유토',
        'K Stage Name': '유토',
        'Date of Birth': '23/01/1998',
        Group: 'PENTAGON',
        Debut: '10/10/2016',
        Company: 'Cube',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yuuri',
        'Full Name': 'Tokunaga Yuuri',
        'Korean Name': '도쿠나가 유우리',
        'K Stage Name': '유우리',
        'Date of Birth': '16/05/2001',
        Group: 'LUNARSOLAR',
        Debut: '2/09/2020',
        Company: 'J Planet',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Yuvin',
        'Full Name': 'Song Yuvin',
        'Korean Name': '송유빈',
        'K Stage Name': '유빈',
        'Date of Birth': '28/04/1998',
        Group: 'B.O.Y',
        Debut: '20/01/2020',
        Company: 'Music Works',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'MYTEEN',
        Gender: 'M'
    },
    {
        'Stage Name': 'Yves',
        'Full Name': 'Ha Sooyoung',
        'Korean Name': '하수영',
        'K Stage Name': '이브',
        'Date of Birth': '24/05/1997',
        Group: 'LOONA',
        Debut: '20/08/2018',
        Company: 'Blockberry',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': 'Loona yyxy',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'YY',
        'Full Name': 'Kim Moonyong',
        'Korean Name': '김문용',
        'K Stage Name': '와이와이',
        'Date of Birth': '30/08/1991',
        Group: 'UNVS',
        Debut: '23/02/2020',
        Company: 'CHITWN',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Zelo',
        'Full Name': 'Choi Junhong',
        'Korean Name': '최준홍',
        'K Stage Name': '젤로',
        'Date of Birth': '15/10/1996',
        Group: 'B.A.P',
        Debut: '27/01/2012',
        Company: 'TS',
        Country: 'South Korea',
        'Second Country': '',
        Height: 182,
        Weight: 63,
        Birthplace: 'Mokpo',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Zero',
        'Full Name': 'Nasukawa Shota',
        'Korean Name': '나스카와 쇼타',
        'K Stage Name': '제로',
        'Date of Birth': '20/01/2003',
        Group: 'T1419',
        Debut: '21/09/2007',
        Company: 'CJ E&M',
        Country: 'Japan',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Zhou Mi',
        'Full Name': 'Zhou Mi',
        'Korean Name': '조미',
        'K Stage Name': '조미',
        'Date of Birth': '19/04/1986',
        Group: 'Super Junior-M',
        Debut: '6/11/2005',
        Company: 'SM',
        Country: 'China',
        'Second Country': '',
        Height: 184,
        Weight: 64,
        Birthplace: 'Wuhan',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Zico',
        'Full Name': 'Woo Jiho',
        'Korean Name': '우지호',
        'K Stage Name': '지코',
        'Date of Birth': '14/09/1992',
        Group: 'Block B',
        Debut: '15/04/2011',
        Company: 'KQ',
        Country: 'South Korea',
        'Second Country': '',
        Height: 182,
        Weight: 65,
        Birthplace: 'Seoul',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Zin',
        'Full Name': 'Jin Hyunbin',
        'Korean Name': '진현빈',
        'K Stage Name': '지인',
        'Date of Birth': '31/08/2001',
        Group: 'bugAboo',
        Debut: '25/10/2021',
        Company: 'A team',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Ziu',
        'Full Name': 'Park Heejun',
        'Korean Name': '박희준',
        'K Stage Name': '지우',
        'Date of Birth': '16/06/1997',
        Group: 'VAV',
        Debut: '31/10/2015',
        Company: 'A team',
        Country: 'South Korea',
        'Second Country': '',
        Height: 185,
        Weight: 70,
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'ZN',
        'Full Name': 'Bae Jinye',
        'Korean Name': '배진예',
        'K Stage Name': '지엔',
        'Date of Birth': '9/06/1994',
        Group: 'LABOUM',
        Debut: '27/08/2014',
        Company: 'NH',
        Country: 'South Korea',
        'Second Country': '',
        Height: 169,
        Weight: 48,
        Birthplace: 'Bucheon',
        'Other Group': 'UNI.T',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Zoa',
        'Full Name': 'Cho Hyewon',
        'Korean Name': '조혜원',
        'K Stage Name': '조아',
        'Date of Birth': '31/05/2005',
        Group: 'Weeekly',
        Debut: '30/07/2020',
        Company: 'Play M',
        Country: 'South Korea',
        'Second Country': '',
        Height: 170,
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    },
    {
        'Stage Name': 'Zuho',
        'Full Name': 'Bae Juho',
        'Korean Name': '백주호',
        'K Stage Name': '주호',
        'Date of Birth': '4/07/1996',
        Group: 'SF9',
        Debut: '5/10/2016',
        Company: 'FNC',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: '',
        'Other Group': '',
        'Former Group': '',
        Gender: 'M'
    },
    {
        'Stage Name': 'Z-UK',
        'Full Name': 'Jeong Jaewook',
        'Korean Name': '정재욱',
        'K Stage Name': '지욱',
        'Date of Birth': '27/01/1993',
        Group: '',
        Debut: '',
        Company: '',
        Country: 'South Korea',
        'Second Country': '',
        Height: 174,
        Weight: 62,
        Birthplace: '',
        'Other Group': '',
        'Former Group': 'Bigflo',
        Gender: 'M'
    },
    {
        'Stage Name': 'Zuny',
        'Full Name': 'Kim Joomi',
        'Korean Name': '김주미',
        'K Stage Name': '주니',
        'Date of Birth': '8/12/1994',
        Group: "Ladies' Code",
        Debut: '7/03/2013',
        Company: 'Polaris',
        Country: 'South Korea',
        'Second Country': '',
        Height: '',
        Weight: '',
        Birthplace: 'Gwangju',
        'Other Group': '',
        'Former Group': '',
        Gender: 'F'
    }
];
