import { useForceUpdate } from 'framer-motion';
import { Device } from '../App';

const API_BASE_URL = 'https://meii.minji.pack.wtf/api';
// const API_BASE_URL = 'http://localhost:3001/api';

// Helper to handle API responses
async function handleResponse<T>(response: Response): Promise<T> {
    if (!response.ok) {
        // If the fetch fails and we're offline, the service worker
        // will automatically return cached data if available
        if (!navigator.onLine) {
            console.log('Offline - using cached data');
        } else {
            const error = await response.json();
            const errorMessage = (error as any).error || 'An error occurred';
            throw new Error(errorMessage);
        }
    }
    return response.json() as Promise<T>;
}

export const deviceService = {
    // Get all devices
    getAllDevices: async (): Promise<Device[]> => {
        const response = await fetch(`${API_BASE_URL}/devices`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            // If the fetch fails and we're offline, the service worker
            // will automatically return cached data if available
            if (!navigator.onLine) {
                console.log('Offline - using cached data');
            } else {
                const error = await response.json();
                const errorMessage =
                    (error as any).error || 'An error occurred';
                throw new Error(errorMessage);
            }

            return [];
        }

        return response.json() as Promise<Device[]>;
    },

    // Get single device
    getDevice: async (id: string): Promise<Device> => {
        const response = await fetch(`${API_BASE_URL}/devices/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return handleResponse<Device>(response);
    },

    // Create new device
    createDevice: async (device: Device): Promise<Device> => {
        console.log(device);
        const response = await fetch(`${API_BASE_URL}/devices`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(device)
        });
        return handleResponse<Device>(response);
    },

    // Update device
    updateDevice: async (
        id: string,
        device: Partial<Device>
    ): Promise<Device> => {
        const updatedDevice = {
            ...device
        };

        delete updatedDevice.createdAt;
        delete updatedDevice.updatedAt;
        //@ts-ignore
        delete updatedDevice._id;

        const response = await fetch(`${API_BASE_URL}/devices/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedDevice)
        });
        return handleResponse<Device>(response);
    },

    // Delete device
    deleteDevice: async (id: string): Promise<void> => {
        const response = await fetch(`${API_BASE_URL}/devices/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return handleResponse<void>(response);
    }
};
