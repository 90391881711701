import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import {
    MapPin,
    Globe2,
    Network,
    Signal,
    ArrowLeft,
    Pencil,
    Globe,
    Clock,
    Activity,
    Radio,
    Waves,
    ChevronDown,
    BluetoothSearching,
    Loader2
} from 'lucide-react';
import styles from './DeviceDetails.module.css';
import { useDevices } from '../hooks/useDevices';
import { Device } from '../App';
import Timeline from './Timeline';
import { useParams, useNavigate } from 'react-router-dom';

export type DeviceWithLogs = Device & { logs?: BeaconLog[] };

export interface BeaconLog {
    timestamp: number;
    beacon_id: string;
    beacon_name: string;
    receiver_id: string;
    receiver_name: string;
    uuid: string;
    major: number;
    minor: number;
    estimated_distance: number;
    raw_data: string;
    rssi: number;
    tx_power: number | null;
    estimated_proximity: string;
    mac_address: string;
    location_longitude?: number;
    location_latitude?: number;
    location_country?: string;
    location_name?: string;
    ip_address?: string;
}

const DeviceDetails: React.FC = ({}) => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { devices, getDevice } = useDevices();
    const [deviceData, setDeviceData] = useState<DeviceWithLogs | null>(null);
    const [device, setDevice] = useState<Device | null>(null);

    const [latestDeviceLog, setLatestDeviceLog] = useState<BeaconLog>({
        timestamp: 0,
        beacon_id: '',
        beacon_name: '',
        receiver_id: '',
        receiver_name: '',
        uuid: '',
        major: 0,
        minor: 0,
        estimated_distance: 0,
        raw_data: '',
        rssi: 0,
        tx_power: null,
        estimated_proximity: '',
        mac_address: ''
    });
    const [isTimelineExpanded, setIsTimelineExpanded] = useState(false);

    useEffect(() => {
        const device = devices.find((d) => d.deviceId === id);
        setDevice(device || null);
    }, [devices, id]);

    useEffect(() => {
        const fetchDeviceData = async () => {
            if (!id) return;
            const data = await getDevice(id);
            setDeviceData(data);
            // backend returns logs in descending order so already sorted
            setLatestDeviceLog(
                data.logs?.[0] || {
                    timestamp: 0,
                    beacon_id: '',
                    beacon_name: '',
                    receiver_id: '',
                    receiver_name: '',
                    uuid: '',
                    major: 0,
                    minor: 0,
                    estimated_distance: 0,
                    raw_data: '',
                    rssi: 0,
                    tx_power: null,
                    estimated_proximity: '',
                    mac_address: ''
                }
            );
        };
        fetchDeviceData();

        // Set up polling interval
        const interval = setInterval(fetchDeviceData, 5000);

        return () => clearInterval(interval);
    }, [id]);

    const formatCoordinates = (lat?: number, long?: number) => {
        if (!lat || !long) return 'Not Available';
        return `${Number(lat).toFixed(6)}, ${Number(long).toFixed(6)}`;
    };

    const onBack = () => {
        navigate(-1);
    };

    const onEdit = () => {
        navigate(`/device/${id}/edit`);
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className={styles.container}>
            {/* Top Navigation */}
            <div className={styles.topNav}>
                <button onClick={onBack} className={styles.backButton}>
                    <ArrowLeft size={18} className={styles.backIcon} />
                    <span>Back</span>
                </button>
                <button onClick={onEdit} className={styles.editButton}>
                    <Pencil size={18} className={styles.editIcon} />
                    <span>Edit</span>
                </button>
            </div>

            {/* Device Header */}
            <motion.div
                initial={{ scale: 0.95 }}
                animate={{ scale: 1 }}
                className={styles.deviceHeader}>
                <div className={styles.sparkleContainer}>
                    <div className={styles.sparkle} />
                    <div className={styles.sparkle} />
                    <div className={styles.sparkle} />
                    <div className={styles.sparkle} />
                </div>
                <div className={styles.deviceHeaderContent}>
                    {device && (
                        <motion.div
                            initial={{ rotate: -10 }}
                            animate={{ rotate: 0 }}
                            className={styles.deviceImageContainer}>
                            <div className={styles.deviceImageWrapper}>
                                <img
                                    src={`/devices/${device.modelName}.png`}
                                    alt={device.name}
                                    className={styles.deviceImage}
                                />
                            </div>
                            <motion.div
                                animate={{
                                    scale:
                                        device.state === 'online'
                                            ? [1, 1.2, 1]
                                            : 1
                                }}
                                transition={{
                                    repeat: Infinity,
                                    duration: 2,
                                    ease: 'easeInOut'
                                }}
                                className={`${styles.statusIndicator} ${
                                    device.state === 'online'
                                        ? styles.online
                                        : styles.offline
                                }`}
                            />
                        </motion.div>
                    )}
                    {device && (
                        <div className={styles.deviceInfo}>
                            <motion.div
                                className={styles.deviceTypeBadge}
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.3 }}>
                                {device.type === 'beacon' ? (
                                    <>
                                        <BluetoothSearching size={14} />
                                        Beacon
                                    </>
                                ) : (
                                    <>
                                        <Network size={14} />
                                        Gateway
                                    </>
                                )}
                            </motion.div>
                            <motion.h1
                                initial={{ x: -20, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                className={styles.deviceName}>
                                {device.name}
                            </motion.h1>
                            <motion.div
                                initial={{ x: -20, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ delay: 0.1 }}
                                className={styles.deviceMac}>
                                {device.macAddress}
                            </motion.div>
                            <motion.div
                                initial={{ x: -20, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ delay: 0.2 }}
                                className={styles.deviceModel}>
                                {device.modelName}
                            </motion.div>
                        </div>
                    )}
                </div>
            </motion.div>

            {/* Enhanced Stats Grid */}
            <StatsGrid
                device={deviceData || device || undefined}
                latestDeviceLog={latestDeviceLog}
            />

            {/* Stats Grid */}

            {/* Location Details */}
            {latestDeviceLog.location_latitude &&
                latestDeviceLog.location_longitude && (
                    <motion.div
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.3 }}
                        className={styles.locationCard}>
                        <h2 className={styles.locationTitle}>
                            <MapPin className={styles.locationIcon} />
                            Location Details
                        </h2>
                        <div className={styles.locationGrid}>
                            <div className={styles.locationDetail}>
                                <div className={styles.detailLabel}>
                                    Coordinates
                                </div>
                                <div className={styles.detailValue}>
                                    {formatCoordinates(
                                        latestDeviceLog.location_latitude,
                                        latestDeviceLog.location_longitude
                                    )}
                                </div>
                            </div>
                            <div className={styles.locationDetail}>
                                <div className={styles.detailLabel}>
                                    Country
                                </div>
                                <div className={styles.detailValue}>
                                    {latestDeviceLog.location_country ||
                                        'Unknown'}
                                </div>
                            </div>
                            <div className={styles.mapPlaceholder}>
                                <span>Map View</span>
                            </div>
                        </div>
                    </motion.div>
                )}

            {/* Timeline */}
            {deviceData && <Timeline logs={deviceData.logs || []} />}
        </motion.div>
    );
};

interface StatsCardProps {
    icon: React.ReactNode;
    label: string;
    value: string;
}

const StatsCard: React.FC<StatsCardProps> = ({ icon, label, value }) => (
    <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        whileHover={{ scale: 1.02 }}
        className={styles.statsCard}>
        <div className={styles.statsContent}>
            <div className={styles.statsIcon}>{icon}</div>
            <div className={styles.statsInfo}>
                <div className={styles.statsLabel}>{label}</div>
                <div className={styles.statsValue}>{value}</div>
            </div>
        </div>
    </motion.div>
);

// Enhanced Stats Grid Component
const StatsGrid: React.FC<{
    device?: DeviceWithLogs;
    latestDeviceLog?: BeaconLog;
}> = ({ device, latestDeviceLog }) => {
    const getLastSeenTime = (timestamp: number) => {
        const lastSeen = new Date(
            Date.UTC(
                new Date(timestamp).getUTCFullYear(),
                new Date(timestamp).getUTCMonth(),
                new Date(timestamp).getUTCDate(),
                new Date(timestamp).getUTCHours(),
                new Date(timestamp).getUTCMinutes(),
                new Date(timestamp).getUTCSeconds()
            )
        );
        const now = new Date();
        const diffInSeconds = Math.floor(
            (now.getTime() - lastSeen.getTime()) / 1000
        );

        if (diffInSeconds < 60) return `${diffInSeconds}s ago`;
        if (diffInSeconds < 3600)
            return `${Math.floor(diffInSeconds / 60)}m ago`;
        if (diffInSeconds < 86400)
            return `${Math.floor(diffInSeconds / 3600)}h ago`;
        return `${Math.floor(diffInSeconds / 86400)}d ago`;
    };

    const getAverageRSSI = (logs?: BeaconLog[]) => {
        if (!logs?.length) return 'N/A';
        const sum = logs.reduce((acc, log) => acc + log.rssi, 0);
        return `${(sum / logs.length).toFixed(1)} dBm`;
    };

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!latestDeviceLog) return;
        if (latestDeviceLog.location_country) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [latestDeviceLog]);

    const shimmerAnimation = {
        initial: { backgroundPosition: '-200% center' },
        animate: { backgroundPosition: '200% center' },
        transition: { duration: 2, repeat: Infinity, ease: 'linear' }
    };

    const loadingVariants = {
        initial: { opacity: 0.5 },
        animate: { opacity: 1 },
        transition: { duration: 0.8, repeat: Infinity, repeatType: 'reverse' }
    };

    return (
        <div className={styles.statsGridMobile}>
            <motion.div
                className={`${styles.statCard} ${styles.locationStat} ${isLoading ? styles.isLoading : ''}`}
                whileTap={{ scale: 0.98 }}>
                <div className={styles.statIcon}>
                    <div className={styles.sparkleContainer}>
                        <div className={styles.sparkle}></div>
                        <div className={styles.sparkle}></div>
                        <div className={styles.sparkle}></div>
                        <div className={styles.sparkle}></div>
                    </div>

                    <Globe className={styles.statIconBlue} />
                </div>
                <div className={styles.statContent}>
                    <div className={styles.statLabel}>Location</div>
                    <motion.div
                        className={`${styles.statValue} ${!latestDeviceLog?.location_name ? styles.placeholder : ''}`}
                        variants={shimmerAnimation}
                        initial="initial"
                        animate="animate">
                        {latestDeviceLog?.location_name || 'Unknown Location'}
                    </motion.div>
                </div>
                {isLoading && (
                    <div className={styles.loadingSparkles}>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                    </div>
                )}
            </motion.div>

            <motion.div
                className={`${styles.statCard} ${styles.statusStat} ${isLoading ? styles.isLoading : ''}`}
                whileTap={{ scale: 0.98 }}>
                <div className={styles.statIcon}>
                    <div className={styles.sparkleContainer}>
                        <div className={styles.sparkle}></div>
                        <div className={styles.sparkle}></div>
                        <div className={styles.sparkle}></div>
                        <div className={styles.sparkle}></div>
                    </div>

                    <Signal className={styles.statIconGreen} />
                </div>
                <div className={styles.statContent}>
                    <div className={styles.statLabel}>Status</div>
                    <motion.div
                        className={styles.statValue}
                        variants={loadingVariants}
                        initial="initial"
                        animate="animate">
                        <span
                            className={`${styles.statusDot} ${
                                device?.state === 'online'
                                    ? styles.online
                                    : device?.state === 'offline'
                                      ? styles.offline
                                      : styles.unknown
                            }`}
                        />
                        {device?.state === 'online'
                            ? 'Online'
                            : device?.state === 'offline'
                              ? 'Offline'
                              : 'Unknown'}
                    </motion.div>
                </div>
                {isLoading && (
                    <div className={styles.loadingSparkles}>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                    </div>
                )}
            </motion.div>

            <motion.div
                className={`${styles.statCard} ${styles.timeStat} ${isLoading ? styles.isLoading : ''}`}
                whileTap={{ scale: 0.98 }}>
                <div className={styles.statIcon}>
                    <div className={styles.sparkleContainer}>
                        <div className={styles.sparkle}></div>
                        <div className={styles.sparkle}></div>
                        <div className={styles.sparkle}></div>
                        <div className={styles.sparkle}></div>
                    </div>

                    <Clock className={styles.statIconPurple} />
                </div>
                <div className={styles.statContent}>
                    <div className={styles.statLabel}>Last Seen</div>
                    <motion.div
                        className={`${styles.statValue} ${!device?.lastSeen ? styles.placeholder : ''}`}
                        variants={shimmerAnimation}
                        initial="initial"
                        animate="animate">
                        {device?.lastSeen ? (
                            <>
                                {getLastSeenTime(device.lastSeen)}{' '}
                                <span className={styles.rxName}>
                                    {latestDeviceLog?.receiver_name || ''}
                                </span>
                            </>
                        ) : (
                            'Never'
                        )}
                    </motion.div>
                </div>
                {isLoading && (
                    <div className={styles.loadingSparkles}>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                    </div>
                )}
            </motion.div>

            <motion.div
                className={`${styles.statCard} ${styles.signalStat} ${isLoading ? styles.isLoading : ''}`}
                whileTap={{ scale: 0.98 }}>
                <div className={styles.statIcon}>
                    <div className={styles.sparkleContainer}>
                        <div className={styles.sparkle}></div>
                        <div className={styles.sparkle}></div>
                        <div className={styles.sparkle}></div>
                        <div className={styles.sparkle}></div>
                    </div>

                    <Radio className={styles.statIconOrange} />
                </div>
                <div className={styles.statContent}>
                    <div className={styles.statLabel}>Avg Signal</div>
                    <motion.div
                        className={`${styles.statValue} ${device?.logs ? styles.placeholder : ''}`}
                        variants={shimmerAnimation}
                        initial="initial"
                        animate="animate">
                        {device?.logs ? getAverageRSSI(device.logs) : 'No Data'}
                    </motion.div>
                </div>
                {isLoading && (
                    <div className={styles.loadingSparkles}>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                        <div className={styles.loadingSparkle}></div>
                    </div>
                )}
            </motion.div>
        </div>
    );
};

export default DeviceDetails;
