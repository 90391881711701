import { useState, useEffect, useCallback } from 'react';
import { Device } from '../App';
import { deviceService } from '../services/api';
import { DeviceWithLogs } from '../DeviceDetails';

interface UseDevicesResult {
    devices: Device[];
    loading: boolean;
    error: Error | null;
    refreshDevices: () => Promise<void>;
    addDevice: (device: Device) => Promise<void>;
    updateDevice: (id: string, device: Partial<Device>) => Promise<void>;
    deleteDevice: (id: string) => Promise<void>;
    getDevice: (id: string) => Promise<DeviceWithLogs>;
}

export function useDevices(): UseDevicesResult {
    const [devices, setDevices] = useState<Device[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    const refreshDevices = useCallback(async () => {
        try {
            setLoading(true);
            const data = await deviceService.getAllDevices();
            setDevices(data);
            setError(null);
        } catch (err) {
            setError(
                err instanceof Error
                    ? err
                    : new Error('Failed to fetch devices')
            );
        } finally {
            setLoading(false);
        }
    }, []);

    const getDevice = async (id: string) => {
        try {
            return await deviceService.getDevice(id);
        } catch (err) {
            throw err instanceof Error
                ? err
                : new Error('Failed to fetch device');
        }
    };

    const addDevice = async (device: Device) => {
        try {
            await deviceService.createDevice(device);
            await refreshDevices();
        } catch (err) {
            throw err instanceof Error
                ? err
                : new Error('Failed to add device');
        }
    };

    const updateDevice = async (id: string, device: Partial<Device>) => {
        try {
            await deviceService.updateDevice(id, device);
            await refreshDevices();
        } catch (err) {
            throw err instanceof Error
                ? err
                : new Error('Failed to update device');
        }
    };

    const deleteDevice = async (id: string) => {
        try {
            await deviceService.deleteDevice(id);
            await refreshDevices();
        } catch (err) {
            throw err instanceof Error
                ? err
                : new Error('Failed to delete device');
        }
    };

    useEffect(() => {
        refreshDevices();
    }, [refreshDevices]);

    return {
        devices,
        loading,
        error,
        refreshDevices,
        addDevice,
        updateDevice,
        deleteDevice,
        getDevice
    };
}
