import { info } from 'console';
import { Beacon } from './App';
import { connect } from 'http2';

export const availableBeaconBrands = ['Feasycom', 'Minew', 'GhostYu', 'M5'];

export const defaultBeacon: Beacon = {
    deviceId: '',
    createdAt: '',
    updatedAt: '',
    type: 'beacon',
    uuid: '2188865d852345cf9c55729270d8be54',
    major: 2000,
    minor: 1,
    name: '',
    brand: '',
    modelName: '',
    advInterval: 900,
    connectable: true,
    powerOnAlways: false,
    password: 'minew123',
    txPower: 4,
    txPowerUnit: 'dBm',
    triggers: {
        motion: false,
        button: false
    },
    rssiAt1m: -3,
    macAddress: '',
    softwareVersion: '',
    firmwareVersion: '',
    hardwareVersion: '',
    protocol: 'iBeacon',
    state: ''
};

export const availableBeaconConfigs = [
    {
        name: 'Feasycom',
        beaconModels: [
            {
                ...defaultBeacon,
                modelName: 'FSC-BP106',
                advInterval: 1000,
                txPower: 4,
                rssiAt1m: -3,
                tlmFrame: true,
                infoFrame: true,
                powerOnAlways: false,
                connectable: true,
                triggers: {
                    motion: false,
                    button: true
                },
                password: '113377'
            },
            {
                modelName: 'FSC-BP105N'
            }
        ]
    },
    {
        name: 'Minew',
        beaconModels: [
            {
                name: 'E7'
            }
        ]
    },
    {
        name: 'GhostYu',
        beaconModels: [
            {
                modelName: 'iBc41'
            }
        ]
    },
    {
        name: 'M5',
        beaconModels: []
    }
];
