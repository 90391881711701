// user context provider
import React, { createContext, useEffect, useState } from 'react';

interface UserContextProps {
    username: string | null;
    avatar: string | null;
    isAuthenticating: boolean;
    setUsername: (username: string) => void;
    setAvatar: (avatar: string) => void;
    setIsAuthenticating: (isAuthenticating: boolean) => void;
}

const UserContext = createContext<UserContextProps>({
    username: null,
    avatar: null,
    isAuthenticating: false,
    setUsername: () => {},
    setAvatar: () => {},
    setIsAuthenticating: () => {}
});

const UserProvider: React.FC<{ children: React.ReactNode }> = ({
    children
}) => {
    const [username, setUsername] = useState<string | null>(null);
    const [avatar, setAvatar] = useState<string | null>(null);
    const [isAuthenticating, setIsAuthenticating] = useState(false);

    return (
        <UserContext.Provider
            value={{
                username,
                avatar,
                isAuthenticating,
                setUsername,
                setAvatar,
                setIsAuthenticating
            }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };
