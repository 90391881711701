import {
    BluetoothConnected,
    BluetoothSearching,
    ChevronsLeftRightEllipsis,
    Clock,
    MapPin,
    Network,
    PinIcon,
    Plus,
    Search
} from 'lucide-react';
import { useEffect, useState } from 'react';

import styles from './DeviceTracker.module.css';

import { useDevices } from './hooks/useDevices';
import { motion, AnimatePresence } from 'framer-motion';
import LoadingScreen from './LoadingScreen';
import DeviceStateIndicator from './DeviceStateIndicator';
import DeviceDetails from './DeviceDetails';
import DeviceForm from './DeviceForm/DeviceForm';
import TitleHeader from './TitleHeader';
import { useUserContext } from './hooks/useUserContext';
import { authApi } from './Auth';
import { Navigate, useNavigate } from 'react-router-dom';

// Types
export type DeviceType = 'beacon' | 'gateway';

export interface BaseDevice {
    deviceId: string;
    createdAt: string;
    updatedAt: string;
    type: DeviceType;
    state: string;
    lastSeen?: number;
    lastSeenLocation?: string;
    name: string;
    brand: string;
    modelName: string;
    password: string;
    macAddress: string;
}

export interface Triggers {
    motion: boolean;
    button: boolean;
}

export interface Beacon extends BaseDevice {
    type: 'beacon';
    uuid: string;
    major: number;
    minor: number;
    advInterval: number;
    connectable: boolean;
    powerOnAlways: boolean;
    txPower: number;
    txPowerUnit: string;
    triggers: Triggers;
    rssiAt1m: number;
    softwareVersion?: string;
    firmwareVersion?: string;
    hardwareVersion?: string;
    protocol: string;
    tlmFrame?: boolean;
    infoFrame?: boolean;
    linkedItemId?: string;
}

export interface Gateway extends BaseDevice {
    type: 'gateway';
    location: string;
    uploadIntervalSeconds: number;
    scanInterval: number;
    scanWindow: number;
    firmwareModel?: string;
    wifiFirmwareVersion?: string;
    bleFirmwareVersion?: string;
    ssid?: string;
    'wifi-password'?: string;
    lanIpAddress?: string;
    adminPassword?: string;
    protocol?: string;
    url?: string;
    authenticationType?: string;
    username?: string;
    bleModulesForScanning?: number;
    dataFormat?: string;
    timeServer?: string;
}

export type Device = Beacon | Gateway;

interface DeviceFormProps {
    device: Device | null;
    onSave: (device: Device) => void;
    onBack: () => void;
}

export const defaultBeacon: Beacon = {
    deviceId: '',
    createdAt: '',
    updatedAt: '',
    type: 'beacon',
    uuid: '2188865d852345cf9c55729270d8be54',
    major: 2000,
    minor: 1,
    name: '',
    brand: '',
    modelName: '',
    advInterval: 900,
    connectable: true,
    powerOnAlways: false,
    password: 'minew123',
    txPower: 4,
    txPowerUnit: 'dBm',
    triggers: {
        motion: false,
        button: false
    },
    rssiAt1m: -3,
    macAddress: '',
    softwareVersion: '',
    firmwareVersion: '',
    hardwareVersion: '',
    protocol: 'iBeacon',
    state: '',
    tlmFrame: false,
    infoFrame: false,
    linkedItemId: ''
};

export const defaultGateway: Gateway = {
    deviceId: '',
    createdAt: '',
    updatedAt: '',
    type: 'gateway',
    name: '',
    location: '',
    brand: 'Minew Tech',
    modelName: 'MG1 Bluetooth Gateway',
    password: 'mg1',
    uploadIntervalSeconds: 1,
    scanInterval: 150,
    scanWindow: 150,
    macAddress: '',
    firmwareModel: 'g1-e-grapes',
    wifiFirmwareVersion: 'v3.6.0',
    bleFirmwareVersion: 'v3.2.4',
    ssid: 'MG1',
    'wifi-password': 'goodlife',
    lanIpAddress: '192.168.99.1',
    adminPassword: 'goodlife',
    protocol: 'HTTPS',
    url: 'wang-jing.peacock-tone.ts.net',
    authenticationType: 'Basic',
    username: 'MG1',
    bleModulesForScanning: 3,
    dataFormat: 'JSON-PREPARSED',
    timeServer: 'cn.pool.ntp.org',
    state: ''
};

export default function App() {
    const navigate = useNavigate();
    const { devices, loading, error, addDevice, updateDevice, refreshDevices } =
        useDevices();
    const [searchTerm, setSearchTerm] = useState('');
    const [currentView, setCurrentView] = useState<
        'list' | 'add' | 'edit' | 'details'
    >('list');
    const [isLoading, setIsLoading] = useState(true);
    const [editingDevice, setEditingDevice] = useState<Device | null>(null);
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const storedToken = localStorage.getItem('token');

        if (!storedToken) {
            navigate('/account');
        }
    }, []);

    useEffect(() => {
        const timer = setInterval(() => setCurrentTime(new Date()), 60000);
        return () => clearInterval(timer);
    }, []);

    const { setUsername, setAvatar, setIsAuthenticating } = useUserContext();

    const getMeInfo = async () => {
        const token = localStorage.getItem('token');
        if (!token) return;

        const response = await fetch(`${authApi}/api/auth/me`, {
            headers: {
                Authorization: `${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            const me = await response.json();
            setUsername(me.username);
            setAvatar(me.profileUrl ?? '/default-avatar.png');
            setIsAuthenticating(false);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            getMeInfo();
        }
    }, []);

    // Refresh devices every 30 seconds
    useEffect(() => {
        const fetchData = async () => {
            await refreshDevices();
        };

        // Initial fetch
        fetchData();

        // Set up polling interval
        const interval = setInterval(fetchData, 10000);

        return () => clearInterval(interval);
    }, []);

    const filteredDevices = devices.filter(
        (device) =>
            device.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (device.modelName || '')
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
            device.macAddress.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSaveDevice = async (device: Device) => {
        try {
            if (editingDevice) {
                const { deviceId, createdAt, updatedAt, ...deviceData } =
                    device;
                await updateDevice(editingDevice.deviceId, deviceData);
            } else {
                await addDevice(device);
            }
            setEditingDevice(null);
            setCurrentView('list');
        } catch (err) {
            console.error('Failed to save device:', err);
            // Here you might want to show an error message to the user
        }
    };

    useEffect(() => {
        // if (!loading) {
        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 4000);

        return () => clearTimeout(timeout);
        // }
    }, []);

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    // Update device grid with sequential animations
    const getDeviceDelay = (index: number) => {
        // Calculate delay for each card's bounce animation
        const baseDelay = 8; // Total animation cycle in seconds
        const itemDelay = baseDelay / (devices.length || 1);
        return `${index * itemDelay}s`;
    };

    return (
        <>
            <AnimatePresence>{isLoading && <LoadingScreen />}</AnimatePresence>
            <AnimatePresence>
                {!isLoading && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: isLoading ? 0 : 1 }}
                        transition={{ duration: 1.2 }}
                        className={styles.container}>
                        <div className={styles.container}>
                            {currentView === 'list' ? (
                                <div className={styles.listView}>
                                    <TitleHeader />

                                    {/* <div className={styles.searchWrapper}>
                                        <input
                                            type="text"
                                            placeholder="Search devices..."
                                            value={searchTerm}
                                            onChange={(e) =>
                                                setSearchTerm(e.target.value)
                                            }
                                            className={styles.searchInput}
                                        />
                                        <Search className={styles.searchIcon} />
                                    </div> */}

                                    <div className={styles.deviceGrid}>
                                        {filteredDevices.map(
                                            (device, index) => (
                                                <motion.button
                                                    key={device.deviceId}
                                                    initial={{
                                                        opacity: 0,
                                                        scale: 0.8
                                                    }}
                                                    animate={{
                                                        opacity: 1,
                                                        scale: 1
                                                    }}
                                                    transition={{
                                                        duration: 0.4,
                                                        delay:
                                                            0.1 + index * 0.1,
                                                        ease: [
                                                            0.23, 1.12, 0.5,
                                                            1.01
                                                        ]
                                                    }}
                                                    onClick={() => {
                                                        // setEditingDevice(
                                                        //     device
                                                        // );
                                                        // setCurrentView(
                                                        //     'details'
                                                        // );
                                                        navigate(
                                                            `/device/${device.deviceId}`
                                                        );
                                                    }}
                                                    className={`${styles.deviceCard} ${device.state === 'online' ? styles.online : styles.offline}`}
                                                    style={
                                                        {
                                                            '--bounce-delay':
                                                                getDeviceDelay(
                                                                    index
                                                                )
                                                        } as React.CSSProperties
                                                    }>
                                                    {device.state ===
                                                        'online' && (
                                                        <div
                                                            className={
                                                                styles.sparkleContainer
                                                            }>
                                                            <div
                                                                className={
                                                                    styles.sparkle
                                                                }
                                                            />
                                                            <div
                                                                className={
                                                                    styles.sparkle
                                                                }
                                                            />
                                                            <div
                                                                className={
                                                                    styles.sparkle
                                                                }
                                                            />
                                                            <div
                                                                className={
                                                                    styles.sparkle
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                    <div
                                                        className={
                                                            styles.deviceCardContent
                                                        }>
                                                        <div
                                                            className={
                                                                styles.deviceImageWrapper
                                                            }>
                                                            <img
                                                                src={`/devices/${device.modelName}.png`}
                                                                alt={
                                                                    device.name
                                                                }
                                                                width={48}
                                                                height={48}
                                                                className={
                                                                    styles.deviceImage
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.deviceNameContainer
                                                            }>
                                                            <div
                                                                className={
                                                                    styles.deviceNameWrapper
                                                                }>
                                                                <div
                                                                    className={
                                                                        styles.deviceName
                                                                    }>
                                                                    {
                                                                        device.name
                                                                    }
                                                                </div>

                                                                <div
                                                                    className={
                                                                        styles.deviceLastLocation
                                                                    }>
                                                                    <MapPin
                                                                        className={
                                                                            styles.mapPinIcon
                                                                        }
                                                                    />
                                                                    {device.lastSeenLocation?.split(
                                                                        ','
                                                                    )[1] +
                                                                        ', ' +
                                                                        device.lastSeenLocation?.split(
                                                                            ','
                                                                        )[2]}
                                                                </div>
                                                                <DeviceStateIndicator
                                                                    isOnline={
                                                                        device.state ===
                                                                        'online'
                                                                    }
                                                                    lastSeen={
                                                                        device.lastSeen
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.deviceModel
                                                            }>
                                                            {device.modelName}
                                                            <span>•</span>
                                                            {device.macAddress.slice(
                                                                -5
                                                            )}
                                                        </div>
                                                    </div>
                                                </motion.button>
                                            )
                                        )}
                                    </div>

                                    <div className={styles.bottomBar}>
                                        <div className={styles.timeDisplay}>
                                            <span>
                                                {currentTime.toLocaleTimeString(
                                                    [],
                                                    {
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    }
                                                )}
                                            </span>
                                            <span
                                                className={styles.timeDivider}>
                                                |
                                            </span>
                                            <span>
                                                {currentTime.toLocaleDateString(
                                                    [],
                                                    {
                                                        weekday: 'short',
                                                        day: '2-digit',
                                                        month: '2-digit'
                                                    }
                                                )}
                                            </span>
                                        </div>
                                        <button
                                            className={styles.bottomBarButton}>
                                            <Clock
                                                className={styles.clockIcon}
                                            />
                                        </button>
                                        <button
                                            onClick={() =>
                                                setCurrentView('add')
                                            }
                                            className={styles.addDeviceButton}>
                                            <Plus
                                                className={styles.addDeviceIcon}
                                            />
                                        </button>
                                    </div>
                                </div>
                            ) : currentView === 'details' ? (
                                <DeviceDetails />
                            ) : (
                                <DeviceForm
                                    device={editingDevice}
                                    onSave={handleSaveDevice}
                                    onBack={() => {
                                        setCurrentView('list');
                                        setEditingDevice(null);
                                    }}
                                />
                            )}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
}

// function DeviceForm({ device, onSave, onBack }: DeviceFormProps) {
//     const [deviceType, setDeviceType] = useState<DeviceType>(
//         device?.type || 'beacon'
//     );
//     const [formData, setFormData] = useState<Device>(
//         device || (deviceType === 'beacon' ? defaultBeacon : defaultGateway)
//     );

//     const handleTypeChange = (type: DeviceType) => {
//         setDeviceType(type);
//         setFormData(type === 'beacon' ? defaultBeacon : defaultGateway);
//     };

//     // Add loading states where needed:
//     const [isSubmitting, setIsSubmitting] = useState(false);

//     const handleSubmit = async (e: React.FormEvent) => {
//         e.preventDefault();
//         console.log(isSubmitting);
//         setIsSubmitting(true);
//         try {
//             await onSave(formData);
//         } catch (error) {
//             console.error('Error submitting form:', error);
//             // Handle error (show message to user, etc.)
//         } finally {
//             setIsSubmitting(false);
//         }
//     };

//     return (
//         <div className={styles.formContainer}>
//             <div className={styles.formHeaderWrapper}>
//                 <button onClick={onBack} className={styles.backButton}>
//                     <svg
//                         className={styles.chevronLeft}
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         stroke="currentColor"
//                         strokeWidth="2">
//                         <polyline points="15 18 9 12 15 6"></polyline>
//                     </svg>
//                     <span>{device ? 'Edit Device' : 'Add Device'}</span>
//                 </button>
//             </div>

//             {!device && (
//                 <div className={styles.formSection}>
//                     <h3 className={styles.sectionTitle}>
//                         <span className={styles.sectionTitleIcon}>
//                             <ChevronsLeftRightEllipsis size={20} />
//                         </span>
//                         Device Type
//                     </h3>
//                     <div className={styles.deviceTypeSelector}>
//                         <button
//                             type="button"
//                             className={`${styles.deviceTypeButton} ${deviceType === 'beacon' ? styles.selected : ''}`}
//                             onClick={() => handleTypeChange('beacon')}>
//                             <span className={styles.deviceTypeIcon}>
//                                 <BluetoothSearching size={24} />
//                             </span>
//                             <span className={styles.deviceTypeLabel}>
//                                 Beacon
//                             </span>
//                         </button>
//                         <button
//                             type="button"
//                             className={`${styles.deviceTypeButton} ${deviceType === 'gateway' ? styles.selected : ''}`}
//                             onClick={() => handleTypeChange('gateway')}>
//                             <span className={styles.deviceTypeIcon}>
//                                 <Network size={24} />
//                             </span>
//                             <span className={styles.deviceTypeLabel}>
//                                 Gateway
//                             </span>
//                         </button>
//                     </div>
//                 </div>
//             )}

//             <form onSubmit={handleSubmit} className={styles.form}>
//                 {deviceType === 'beacon' ? (
//                     <BeaconForm
//                         data={formData as Beacon}
//                         setData={setFormData}
//                     />
//                 ) : (
//                     <GatewayForm
//                         data={formData as Gateway}
//                         setData={setFormData}
//                     />
//                 )}

//                 <button type="submit" className={styles.submitButton}>
//                     {device ? 'Update Device' : 'Add Device'}
//                 </button>
//             </form>
//         </div>
//     );
// }
