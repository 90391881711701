import { AnimatePresence, motion } from 'framer-motion';
import {
    Activity,
    Bell,
    Bluetooth,
    Cpu,
    Info,
    Radio,
    Network,
    Shield,
    Server,
    Scan,
    Camera,
    X,
    Repeat
} from 'lucide-react';
import styles from './DeviceForm.module.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import jsQR from 'jsqr';
import { availableBeaconConfigs } from '../const';

import { allKPopArtists, groupImages } from '../kpop';

const kpopArtists = allKPopArtists.filter((artist) => {
    const availableGroupImages = groupImages.map((group) =>
        group.name.toLowerCase()
    );
    return availableGroupImages.includes(artist.Group.toLowerCase());
});

// DeviceTypeSelector Component
//@ts-ignore
const DeviceTypeSelector = ({ selectedType, onTypeChange }) => (
    <div className={styles.formSection}>
        <div className={styles.deviceTypeSelector}>
            <button
                type="button"
                className={`${styles.deviceTypeButton} ${selectedType === 'beacon' ? styles.selected : ''}`}
                onClick={() => onTypeChange('beacon')}>
                <span className={styles.deviceTypeIcon}>
                    <Bluetooth size={24} />
                </span>
                <span>Beacon</span>
            </button>
            <button
                type="button"
                className={`${styles.deviceTypeButton} ${selectedType === 'gateway' ? styles.selected : ''}`}
                onClick={() => onTypeChange('gateway')}>
                <span className={styles.deviceTypeIcon}>
                    <Network size={24} />
                </span>
                <span>Gateway</span>
            </button>
        </div>
    </div>
);

// MAC Address Input Component
//@ts-ignore
const MacAddressInput = ({ value, onChange, className, required = false }) => {
    const [isScanning, setIsScanning] = useState(false);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const streamRef = useRef(null);
    const animationFrameRef = useRef(null);
    // @ts-ignore
    const formatMacAddress = (input) => {
        const cleaned = input.replace(/[^0-9A-Fa-f]/g, '');
        const formatted = cleaned.match(/.{1,2}/g)?.join(':') || cleaned;
        return formatted.slice(0, 17).toUpperCase();
    };

    const stopScanning = useCallback(() => {
        if (streamRef.current) {
            // @ts-ignore
            streamRef.current.getTracks().forEach((track) => track.stop());
            streamRef.current = null;
        }
        if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
        }
        setIsScanning(false);
    }, []);

    useEffect(() => {
        return () => {
            stopScanning();
        };
    }, [stopScanning]);

    const scanQRCode = useCallback(async () => {
        if (!videoRef.current || !canvasRef.current) return;
        const canvas = canvasRef.current;
        const video = videoRef.current;
        // @ts-ignore
        const context = canvas.getContext('2d', { willReadFrequently: true });
        // @ts-ignore
        if (video.readyState !== video.HAVE_ENOUGH_DATA) {
            // @ts-ignore
            animationFrameRef.current = requestAnimationFrame(scanQRCode);
            return;
        }

        // Set canvas size to match video
        // @ts-ignore
        canvas.width = video.videoWidth;
        // @ts-ignore
        canvas.height = video.videoHeight;

        // Draw video frame to canvas
        // @ts-ignore
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        try {
            const imageData = context.getImageData(
                0,
                0,
                // @ts-ignore
                canvas.width,
                // @ts-ignore
                canvas.height
                // @ts-ignore
            );
            const code = jsQR(
                imageData.data,
                imageData.width,
                imageData.height
            );

            if (code) {
                console.log('QR code detected:', code.data);
                // Verify if the QR code content matches MAC address format
                const macAddressRegex =
                    /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
                if (macAddressRegex.test(code.data)) {
                    onChange(code.data);
                    stopScanning();
                    return;
                }

                // If it's not in MAC format, try to clean it up
                const cleanedMac = formatMacAddress(code.data);
                if (cleanedMac.length === 17) {
                    onChange(cleanedMac);
                    stopScanning();
                    return;
                }
            }
        } catch (error) {
            console.error('QR scanning error:', error);
        }

        // Continue scanning if no valid QR code found
        if (isScanning) {
            // @ts-ignore
            animationFrameRef.current = requestAnimationFrame(scanQRCode);
        }
    }, [isScanning, onChange, stopScanning]);

    const handleQRScan = async () => {
        try {
            // Try environment facing camera first (for mobile devices)
            let stream;
            try {
                stream = await navigator.mediaDevices.getUserMedia({
                    video: { facingMode: 'environment' }
                });
            } catch {
                // If environment camera fails, try user facing camera
                stream = await navigator.mediaDevices.getUserMedia({
                    video: { facingMode: 'user' }
                });
            }
            // @ts-ignore
            streamRef.current = stream;

            if (videoRef.current) {
                // @ts-ignore
                videoRef.current.srcObject = stream;
                // Wait for video to be ready
                await new Promise((resolve) => {
                    // @ts-ignore
                    videoRef.current.onloadedmetadata = () => {
                        // @ts-ignore
                        resolve();
                    };
                });
                // @ts-ignore
                await videoRef.current.play();
                scanQRCode();
            }
        } catch (error) {
            console.error('Error accessing camera:', error);
            alert('Unable to access camera. Please check permissions.');
            setIsScanning(false);
        }
    };

    const startScanning = () => {
        setIsScanning(true);
    };

    useEffect(() => {
        if (isScanning) {
            handleQRScan();
        }
    }, [isScanning]);

    return (
        <div
            className={styles.macAddressInputWrapper}
            style={{ marginBottom: isScanning ? '350px' : '0px' }}>
            <input
                type="text"
                value={value}
                onChange={(e) => onChange(formatMacAddress(e.target.value))}
                className={`${className} ${styles.macAddressInput}`}
                placeholder="XX:XX:XX:XX:XX:XX"
                pattern="^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$"
                required={required}
            />
            <button
                type="button"
                onClick={startScanning}
                className={styles.qrScanButton}
                title="Scan QR Code">
                <Camera size={20} />
            </button>

            {isScanning && (
                <div className={styles.qrScannerModal}>
                    <div className={styles.qrScannerContent}>
                        <div className={styles.qrScannerHeader}>
                            <h3>Scan QR Code</h3>
                            <button
                                type="button"
                                onClick={stopScanning}
                                className={styles.closeButton}>
                                <X size={24} />
                            </button>
                        </div>

                        <div className={styles.qrScannerViewfinder}>
                            <video
                                ref={videoRef}
                                className={styles.qrVideo}
                                playsInline
                                muted
                                autoPlay
                            />
                            <canvas
                                ref={canvasRef}
                                className={styles.qrCanvas}
                            />
                            <div className={styles.scannerOverlay}>
                                <div className={styles.scannerTarget} />
                            </div>
                        </div>

                        <p className={styles.qrScannerInstructions}>
                            Position the QR code within the frame
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

// Update the MAC address input in BeaconBasicSection
// @ts-ignore
const BeaconBasicSection = ({ data, setData }) => {
    const [suggestedBeaconBrands, setSuggestedBeaconBrands] = useState([]);
    const [suggestedBeaconModels, setSuggestedBeaconModels] = useState([]);
    const [brandInput, setBrandInput] = useState(data.brand);
    const [modelInput, setModelInput] = useState(data.modelName);

    useEffect(() => {
        // Search for beacon models based on selected brand
        const selectedBrandConfig = availableBeaconConfigs.find(
            (config) => config.name.toLowerCase() === brandInput.toLowerCase()
        );
        if (selectedBrandConfig && selectedBrandConfig.beaconModels) {
            const models = selectedBrandConfig.beaconModels.map(
                // @ts-ignore
                (model) => model.modelName
            );

            // @ts-ignore
            setSuggestedBeaconModels(models);
        } else {
            setSuggestedBeaconModels([]);
        }
    }, [brandInput]);
    // @ts-ignore
    const handleBrandChange = (e) => {
        const value = e.target.value;
        setBrandInput(value);
        setData({ ...data, brand: value });
    };
    // @ts-ignore
    const handleModelChange = (e) => {
        const value = e.target.value;
        setModelInput(value);
        setData({ ...data, modelName: value });
    };
    // @ts-ignore
    const handleBrandSelect = (brand) => {
        setBrandInput(brand);
        setData({ ...data, brand });
        // Trigger your function here
    };
    // @ts-ignore
    const handleModelSelect = (model) => {
        setModelInput(model);
        const selectedModel = availableBeaconConfigs.find(
            (config) =>
                config.name.toLowerCase() === brandInput.toLowerCase() &&
                config.beaconModels.some(
                    // @ts-ignore
                    (m) => m.modelName.toLowerCase() === model.toLowerCase()
                )
        );
        if (selectedModel) {
            setData({ ...data, ...selectedModel });
        } else {
            setData({ ...data, modelName: model });
        }
    };

    type kpopArtist = {
        'Stage Name': string;
        'Full Name': string;
        'Korean Name': string;
        'K Stage Name': string;
        'Date of Birth': string;
        Group: string;
        Debut: string;
        Company: string;
        Country: string;
        'Second Country': string;
        Height: string;
        Weight: string;
        Birthplace: string;
        'Other Group': string;
        'Former Group': string;
        Gender: string;
        'Image URL'?: string;
    };
    const [kpopArtist, setKpopArtist] = useState() as [kpopArtist, any];

    const pickRandomKpopArtist = () => {
        const randomIndex = Math.floor(Math.random() * kpopArtists.length);
        const randomArtist = kpopArtists[randomIndex];
        setData({ ...data, name: randomArtist['Stage Name'] });
        setKpopArtist(randomArtist);
    };

    useEffect(() => {
        // Search for beacon brands
        const brands = availableBeaconConfigs.map((config) => config.name);
        // @ts-ignore
        setSuggestedBeaconBrands(brands);

        // check if name matches existing kpop artist
        const matchedKpopArtist = kpopArtists.find(
            (artist) =>
                artist['Stage Name'].toLowerCase() === data.name.toLowerCase()
        );
        if (matchedKpopArtist) {
            setKpopArtist(matchedKpopArtist);
        }
    }, []);

    return (
        <div className={styles.formSection}>
            <div className={styles.formGrid}>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        Name
                        <input
                            type="text"
                            value={data.name}
                            onChange={(e) =>
                                setData({ ...data, name: e.target.value })
                            }
                            className={styles.input}
                            placeholder="MeiiMeii"
                            required
                        />
                        <button
                            type="button"
                            onClick={pickRandomKpopArtist}
                            className={styles.qrScanButton}
                            style={{ marginTop: '8px' }}
                            title="Scan QR Code">
                            <Repeat size={20} />
                        </button>
                    </label>
                </div>

                <AnimatePresence mode="wait">
                    <motion.div
                        key={kpopArtist?.['Stage Name']}
                        initial={{
                            opacity: 0,
                            y: 20
                        }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.2 }}
                        className={styles.formField}>
                        {kpopArtist && (
                            <div className={styles.kpopArtistCard}>
                                <div className={styles.kpopArtistImg}>
                                    <img
                                        src={
                                            kpopArtist['Image URL']
                                                ? kpopArtist['Image URL']
                                                : groupImages.find(
                                                      (group) =>
                                                          group.name.toLowerCase() ===
                                                          kpopArtist.Group.toLowerCase()
                                                  )?.imageUrl
                                        }
                                        alt={kpopArtist['Stage Name']}
                                    />
                                </div>

                                <div className={styles.kpopArtistSection}>
                                    <h3>{kpopArtist['Stage Name']}</h3>
                                    <p>{kpopArtist['Full Name']}</p>
                                    <p>{kpopArtist['Korean Name']}</p>
                                    <p
                                        style={{
                                            fontFamily: 'Geist Bold'
                                        }}>
                                        {kpopArtist['Group']}
                                    </p>
                                </div>

                                <div className={styles.kpopArtistSection}>
                                    <p>📏 {kpopArtist['Height']}</p>
                                    <p>{kpopArtist['Weight']}</p>
                                    <p>🐣 {kpopArtist['Debut']}</p>
                                    <p>📍 {kpopArtist['Country']}</p>
                                    {/* <p>{kpopArtist['Second Country']}</p> */}
                                    <p>👼🏼 {kpopArtist['Birthplace']}</p>
                                </div>
                                {/* <p>{kpopArtist['Other Group']}</p> */}
                                {/* <p>{kpopArtist['Former Group']}</p> */}
                                {/* <p>{kpopArtist.Gender}</p> */}
                            </div>
                        )}
                    </motion.div>
                </AnimatePresence>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        MAC Address
                        <MacAddressInput
                            value={data.macAddress}
                            onChange={(value: any) =>
                                setData({ ...data, macAddress: value })
                            }
                            className={styles.input}
                            required
                        />
                    </label>
                </div>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        Brand
                        <input
                            type="text"
                            value={brandInput}
                            onChange={handleBrandChange}
                            className={styles.input}
                            placeholder="Minew Tech"
                            required
                        />
                        {suggestedBeaconBrands.length > 0 && (
                            <div className={styles.suggestions}>
                                {suggestedBeaconBrands
                                    .filter((brand) =>
                                        brand // @ts-ignore
                                            .toLowerCase()
                                            .includes(brandInput.toLowerCase())
                                    )
                                    .map((brand) => (
                                        <p
                                            key={brand}
                                            onClick={() =>
                                                handleBrandSelect(brand)
                                            }>
                                            {brand}
                                        </p>
                                    ))}
                            </div>
                        )}
                    </label>
                </div>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        Model
                        <input
                            type="text"
                            value={modelInput}
                            onChange={handleModelChange}
                            required
                            className={styles.input}
                            placeholder="E2 - Beacon Plus-LB"
                        />
                        {suggestedBeaconModels &&
                            suggestedBeaconModels.length > 0 && (
                                <div className={styles.suggestions}>
                                    {suggestedBeaconModels
                                        .filter((model) =>
                                            model // @ts-ignore
                                                .toLowerCase()
                                                .includes(
                                                    modelInput.toLowerCase()
                                                )
                                        )
                                        .map((model) => (
                                            <p
                                                key={model}
                                                onClick={() =>
                                                    handleModelSelect(model)
                                                }>
                                                {model}
                                            </p>
                                        ))}
                                </div>
                            )}
                    </label>
                </div>

                <div className={styles.formField}>
                    <label className={`${styles.label}`}>
                        Linked Item
                        <input
                            type="text"
                            value={data.linkedItemId}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    linkedItemId: e.target.value
                                })
                            }
                            className={styles.input}
                            style={{ height: '20px' }}
                            placeholder="21456326546743"
                        />
                    </label>
                </div>
            </div>
        </div>
    );
};

//@ts-ignore
const IBeaconSection = ({ data, setData }) => (
    <div className={styles.formSection} style={{ overflowY: 'scroll' }}>
        <div className={styles.formGrid}>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Protocol
                    <input
                        type="text"
                        value={data.protocol}
                        className={`${styles.input} ${styles.readOnly}`}
                        readOnly
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    UUID
                    <input
                        type="text"
                        value={data.uuid}
                        className={`${styles.input} ${styles.readOnly}`}
                        readOnly
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={`${styles.label} ${styles.required}`}>
                    Advertisement Interval (ms)
                    <input
                        type="number"
                        value={data.advInterval}
                        onChange={(e) =>
                            setData({
                                ...data,
                                advInterval: parseInt(e.target.value)
                            })
                        }
                        className={styles.input}
                        min="100"
                        max="10000"
                        required
                    />
                </label>
            </div>
            <div style={{ display: 'flex' }}>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        Major
                        <input
                            type="number"
                            value={data.major}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    major: parseInt(e.target.value)
                                })
                            }
                            className={styles.input}
                            min="0"
                            max="65535"
                            style={{ width: '75%' }}
                            required
                        />
                    </label>
                </div>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        Minor
                        <input
                            type="number"
                            value={data.minor}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    minor: parseInt(e.target.value)
                                })
                            }
                            className={styles.input}
                            min="0"
                            max="65535"
                            style={{ width: '75%' }}
                            required
                        />
                    </label>
                </div>
            </div>
        </div>
        <div className={styles.formGrid}>
            <div className={styles.formField}>
                <label className={styles.label}>
                    TX Power Unit
                    <input
                        type="text"
                        value={data.txPowerUnit}
                        className={`${styles.input} ${styles.readOnly}`}
                        readOnly
                    />
                </label>
            </div>
            <div style={{ display: 'flex' }}>
                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        TX Power
                        <input
                            type="number"
                            value={data.txPower}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    txPower: parseInt(e.target.value)
                                })
                            }
                            className={styles.input}
                            style={{ width: '75%' }}
                            min="-40"
                            max="4"
                            required
                        />
                    </label>
                </div>

                <div className={styles.formField}>
                    <label className={`${styles.label} ${styles.required}`}>
                        RSSI at 1m
                        <input
                            type="number"
                            value={data.rssiAt1m}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    rssiAt1m: parseInt(e.target.value)
                                })
                            }
                            className={styles.input}
                            min="-100"
                            style={{ width: '75%' }}
                            max="0"
                            required
                        />
                    </label>
                </div>
            </div>
        </div>
    </div>
);
//@ts-ignore
const BeaconConfigSection = ({ data, setData }) => (
    <div className={styles.formSection}>
        <div className={styles.formGrid}>
            <div className={styles.formField}>
                <label className={`${styles.label} ${styles.required}`}>
                    Password/Pin Code
                    <input
                        type="text"
                        value={data.password}
                        onChange={(e) =>
                            setData({ ...data, password: e.target.value })
                        }
                        className={styles.input}
                        placeholder="minew123"
                        required
                    />
                </label>
            </div>
        </div>

        <div className={styles.checkboxGroup}>
            <label className={styles.checkboxLabel}>
                <input
                    type="checkbox"
                    checked={data.connectable}
                    onChange={(e) =>
                        setData({ ...data, connectable: e.target.checked })
                    }
                    className={styles.checkbox}
                />
                Connectable
            </label>
            <label className={styles.checkboxLabel}>
                <input
                    type="checkbox"
                    checked={data.powerOnAlways}
                    onChange={(e) =>
                        setData({ ...data, powerOnAlways: e.target.checked })
                    }
                    className={styles.checkbox}
                />
                Power On Always
            </label>
        </div>
    </div>
);

//@ts-ignore
const BeaconFramesConfig = ({ data, setData }) => (
    <div className={styles.formSection}>
        <div className={styles.checkboxGroup}>
            <label className={styles.checkboxLabel}>
                <input
                    type="checkbox"
                    checked={true}
                    disabled
                    className={styles.checkbox}
                />
                iBeacon
            </label>
            <label className={styles.checkboxLabel}>
                <input
                    type="checkbox"
                    checked={data.tlmFrame}
                    onChange={(e) =>
                        setData({ ...data, tlmFrame: e.target.checked })
                    }
                    className={styles.checkbox}
                />
                TLM
            </label>
            <label className={styles.checkboxLabel}>
                <input
                    type="checkbox"
                    checked={data.infoFrame}
                    onChange={(e) =>
                        setData({ ...data, infoFrame: e.target.checked })
                    }
                    className={styles.checkbox}
                />
                DeviceInfo
            </label>
        </div>
    </div>
);
//@ts-ignore
const BeaconTriggersSection = ({ data, setData }) => (
    <div className={styles.formSection}>
        <div className={styles.checkboxGroup}>
            <label className={styles.checkboxLabel}>
                <input
                    type="checkbox"
                    checked={data.triggers.motion}
                    onChange={(e) =>
                        setData({
                            ...data,
                            triggers: {
                                ...data.triggers,
                                motion: e.target.checked
                            }
                        })
                    }
                    className={styles.checkbox}
                />
                Motion Trigger
            </label>
            <label className={styles.checkboxLabel}>
                <input
                    type="checkbox"
                    checked={data.triggers.button}
                    onChange={(e) =>
                        setData({
                            ...data,
                            triggers: {
                                ...data.triggers,
                                button: e.target.checked
                            }
                        })
                    }
                    className={styles.checkbox}
                />
                Button Trigger
            </label>
        </div>
    </div>
);
//@ts-ignore
const BeaconVersionSection = ({ data, setData }) => (
    <div className={styles.formSection}>
        <div className={styles.formGrid}>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Software Version
                    <input
                        type="text"
                        value={data.softwareVersion || ''}
                        onChange={(e) =>
                            setData({
                                ...data,
                                softwareVersion: e.target.value
                            })
                        }
                        className={styles.input}
                        placeholder="nRF52-SDK13.0"
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Firmware Version
                    <input
                        type="text"
                        value={data.firmwareVersion || ''}
                        onChange={(e) =>
                            setData({
                                ...data,
                                firmwareVersion: e.target.value
                            })
                        }
                        className={styles.input}
                        placeholder="2.3.12"
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Hardware Version
                    <input
                        type="text"
                        value={data.hardwareVersion || ''}
                        onChange={(e) =>
                            setData({
                                ...data,
                                hardwareVersion: e.target.value
                            })
                        }
                        className={styles.input}
                        placeholder="MS71SF6_V1.0.0"
                    />
                </label>
            </div>
        </div>
    </div>
);

// Gateway Form Sections//@ts-ignore
//@ts-ignore
const GatewayBasicSection = ({ data, setData }) => (
    <div className={styles.formSection}>
        <div className={styles.formGrid}>
            <div className={styles.formField}>
                <label className={`${styles.label} ${styles.required}`}>
                    Name
                    <input
                        type="text"
                        value={data.name}
                        onChange={(e) =>
                            setData({ ...data, name: e.target.value })
                        }
                        className={styles.input}
                        placeholder="MG1"
                        required
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={`${styles.label} ${styles.required}`}>
                    Location
                    <input
                        type="text"
                        value={data.location}
                        onChange={(e) =>
                            setData({ ...data, location: e.target.value })
                        }
                        className={styles.input}
                        placeholder="living-room"
                        required
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={`${styles.label} ${styles.required}`}>
                    Brand
                    <input
                        type="text"
                        value={data.brand}
                        onChange={(e) =>
                            setData({ ...data, brand: e.target.value })
                        }
                        className={styles.input}
                        placeholder="Minew Tech"
                        required
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Model
                    <input
                        type="text"
                        value={data.modelName}
                        onChange={(e) =>
                            setData({ ...data, modelName: e.target.value })
                        }
                        className={styles.input}
                        placeholder="MG1 Bluetooth Gateway"
                    />
                </label>
            </div>
        </div>
    </div>
);
//@ts-ignore
const NetworkSection = ({ data, setData }) => (
    <div className={styles.formSection}>
        <div className={styles.formGrid}>
            <div className={styles.formField}>
                <label className={`${styles.label} ${styles.required}`}>
                    MAC Address
                    <MacAddressInput
                        value={data.macAddress}
                        onChange={(value: any) =>
                            setData({ ...data, macAddress: value })
                        }
                        className={styles.input}
                        required
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    LAN IP Address
                    <input
                        type="text"
                        value={data.lanIpAddress || ''}
                        onChange={(e) =>
                            setData({ ...data, lanIpAddress: e.target.value })
                        }
                        className={styles.input}
                        placeholder="192.168.99.1"
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    SSID
                    <input
                        type="text"
                        value={data.ssid || ''}
                        onChange={(e) =>
                            setData({ ...data, ssid: e.target.value })
                        }
                        className={styles.input}
                        placeholder="MG1"
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    WiFi Password
                    <input
                        type="password"
                        value={data['wifi-password'] || ''}
                        onChange={(e) =>
                            setData({
                                ...data,
                                'wifi-password': e.target.value
                            })
                        }
                        className={styles.input}
                        placeholder="••••••••"
                    />
                </label>
            </div>
        </div>
    </div>
);
//@ts-ignore
const SecuritySection = ({ data, setData }) => (
    <div className={styles.formSection}>
        <div className={styles.formGrid}>
            <div className={styles.formField}>
                <label className={`${styles.label} ${styles.required}`}>
                    Password
                    <input
                        type="password"
                        value={data.password}
                        onChange={(e) =>
                            setData({ ...data, password: e.target.value })
                        }
                        className={styles.input}
                        placeholder="••••••••"
                        required
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Admin Password
                    <input
                        type="password"
                        value={data.adminPassword || ''}
                        onChange={(e) =>
                            setData({ ...data, adminPassword: e.target.value })
                        }
                        className={styles.input}
                        placeholder="••••••••"
                    />
                </label>
            </div>
        </div>
    </div>
);
//@ts-ignore
const ScanningSection = ({ data, setData }) => (
    <div className={styles.formSection}>
        <div className={styles.formGrid}>
            <div className={styles.formField}>
                <label className={`${styles.label} ${styles.required}`}>
                    Upload Interval (seconds)
                    <input
                        type="number"
                        value={data.uploadIntervalSeconds}
                        onChange={(e) =>
                            setData({
                                ...data,
                                uploadIntervalSeconds: parseInt(e.target.value)
                            })
                        }
                        className={styles.input}
                        min="1"
                        required
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={`${styles.label} ${styles.required}`}>
                    Scan Interval
                    <input
                        type="number"
                        value={data.scanInterval}
                        onChange={(e) =>
                            setData({
                                ...data,
                                scanInterval: parseInt(e.target.value)
                            })
                        }
                        className={styles.input}
                        min="1"
                        required
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={`${styles.label} ${styles.required}`}>
                    Scan Window
                    <input
                        type="number"
                        value={data.scanWindow}
                        onChange={(e) =>
                            setData({
                                ...data,
                                scanWindow: parseInt(e.target.value)
                            })
                        }
                        className={styles.input}
                        min="1"
                        required
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    BLE Modules for Scanning
                    <input
                        type="number"
                        value={data.bleModulesForScanning || 3}
                        onChange={(e) =>
                            setData({
                                ...data,
                                bleModulesForScanning: parseInt(e.target.value)
                            })
                        }
                        className={styles.input}
                        min="1"
                    />
                </label>
            </div>
        </div>
    </div>
);
//@ts-ignore
const ServerSection = ({ data, setData }) => (
    <div className={styles.formSection}>
        <div className={styles.formGrid}>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Protocol
                    <input
                        type="text"
                        value={data.protocol || ''}
                        onChange={(e) =>
                            setData({ ...data, protocol: e.target.value })
                        }
                        className={styles.input}
                        placeholder="HTTPS"
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    URL
                    <input
                        type="text"
                        value={data.url || ''}
                        onChange={(e) =>
                            setData({ ...data, url: e.target.value })
                        }
                        className={styles.input}
                        placeholder="example.com"
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Authentication Type
                    <input
                        type="text"
                        value={data.authenticationType || ''}
                        onChange={(e) =>
                            setData({
                                ...data,
                                authenticationType: e.target.value
                            })
                        }
                        className={styles.input}
                        placeholder="Basic"
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Username
                    <input
                        type="text"
                        value={data.username || ''}
                        onChange={(e) =>
                            setData({ ...data, username: e.target.value })
                        }
                        className={styles.input}
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Data Format
                    <input
                        type="text"
                        value={data.dataFormat || ''}
                        onChange={(e) =>
                            setData({ ...data, dataFormat: e.target.value })
                        }
                        className={styles.input}
                        placeholder="JSON-PREPARSED"
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Time Server
                    <input
                        type="text"
                        value={data.timeServer || ''}
                        onChange={(e) =>
                            setData({ ...data, timeServer: e.target.value })
                        }
                        className={styles.input}
                        placeholder="cn.pool.ntp.org"
                    />
                </label>
            </div>
        </div>
    </div>
);
//@ts-ignore
const GatewayVersionSection = ({ data, setData }) => (
    <div className={styles.formSection}>
        <div className={styles.formGrid}>
            <div className={styles.formField}>
                <label className={styles.label}>
                    Firmware Model
                    <input
                        type="text"
                        value={data.firmwareModel || ''}
                        onChange={(e) =>
                            setData({ ...data, firmwareModel: e.target.value })
                        }
                        className={styles.input}
                        placeholder="g1-e-grapes"
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    WiFi Firmware Version
                    <input
                        type="text"
                        value={data.wifiFirmwareVersion || ''}
                        onChange={(e) =>
                            setData({
                                ...data,
                                wifiFirmwareVersion: e.target.value
                            })
                        }
                        className={styles.input}
                        placeholder="v3.6.0"
                    />
                </label>
            </div>
            <div className={styles.formField}>
                <label className={styles.label}>
                    BLE Firmware Version
                    <input
                        type="text"
                        value={data.bleFirmwareVersion || ''}
                        onChange={(e) =>
                            setData({
                                ...data,
                                bleFirmwareVersion: e.target.value
                            })
                        }
                        className={styles.input}
                        placeholder="v3.2.4"
                    />
                </label>
            </div>
        </div>
    </div>
);

// Export all sections
export {
    DeviceTypeSelector,
    BeaconBasicSection,
    BeaconConfigSection,
    IBeaconSection,
    BeaconTriggersSection,
    BeaconVersionSection,
    GatewayBasicSection,
    NetworkSection,
    SecuritySection,
    ScanningSection,
    ServerSection,
    GatewayVersionSection,
    BeaconFramesConfig
};
