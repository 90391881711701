import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './LoadingScreen.module.css';

function LoadingScreen() {
    const [showPlaceholders, setShowPlaceholders] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowPlaceholders(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const placeholders = Array.from({ length: 300 }, (_, i) => {
        const row = Math.floor(i / 24);
        const col = i % 24;
        const diagonal = row + col;

        return (
            <motion.div
                key={i}
                animate={{
                    opacity: [0.22, 1, 0.22],
                    scale: [1, 1.05, 1]
                }}
                transition={{
                    duration: 2.2,
                    repeat: Infinity,
                    repeatDelay: 3.0,
                    delay: diagonal * 0.1, // This creates the diagonal wave effect
                    ease: 'easeInOut'
                }}
                className={styles.placeholderCard}>
                <p>Meii</p>
            </motion.div>
        );
    });

    return (
        <motion.div
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className={styles.loadingScreen}>
            {showPlaceholders && (
                <div className={styles.placeholderGrid}>{placeholders}</div>
            )}
        </motion.div>
    );
}

export default LoadingScreen;
