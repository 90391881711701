import React from 'react';
import { Clock } from 'lucide-react';
import styles from './DeviceStateIndicator.module.css';

export const getTimeAgo = (lastSeen: string | number | Date) => {
    const now = new Date();
    const seenDate = new Date(
        Date.UTC(
            new Date(lastSeen).getUTCFullYear(),
            new Date(lastSeen).getUTCMonth(),
            new Date(lastSeen).getUTCDate(),
            new Date(lastSeen).getUTCHours(),
            new Date(lastSeen).getUTCMinutes(),
            new Date(lastSeen).getUTCSeconds()
        )
    );
    const diffInSeconds = Math.floor(
        (now.getTime() - seenDate.getTime()) / 1000
    );

    if (diffInSeconds < 60) return `${diffInSeconds}s`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`;
    return `${Math.floor(diffInSeconds / 86400)}d`;
};

interface DeviceStateIndicatorProps {
    isOnline: boolean;
    lastSeen: number | undefined;
}

const DeviceStateIndicator: React.FC<DeviceStateIndicatorProps> = ({
    isOnline,
    lastSeen
}) => {
    // Don't show lastSeen if it's 0 or undefined
    const showLastSeen = lastSeen !== undefined && lastSeen !== 0;

    return (
        <div className={styles.statusContainer}>
            {/* Status Badge - Only animate when online */}
            <div
                className={`${styles.statusBadge} ${isOnline ? styles.statusBadge : ''}`}
                style={{ animation: isOnline ? undefined : 'none' }}>
                <div
                    className={`${styles.dot} ${isOnline ? styles.online : styles.offline}`}
                    style={{ animation: isOnline ? undefined : 'none' }}
                />
                <span
                    className={`${styles.statusText} ${isOnline ? styles.onlineText : styles.offlineText}`}
                    style={{ animation: isOnline ? undefined : 'none' }}>
                    {isOnline ? 'ONLINE' : 'OFFLINE'}
                </span>
            </div>

            {/* Last Seen - Only show if lastSeen is not 0 and device is offline */}
            {showLastSeen && (
                <div
                    className={`${styles.lastSeen} ${isOnline ? styles.lastSeenOnline : styles.lastSeenOffline}`}
                    style={{ animation: isOnline ? undefined : 'none' }}>
                    <Clock
                        className={styles.clockIcon}
                        style={{ animation: isOnline ? undefined : 'none' }}
                    />
                    <span>{getTimeAgo(lastSeen)}</span>
                </div>
            )}
        </div>
    );
};

export default DeviceStateIndicator;
